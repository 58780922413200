import React, { FC, ReactNode, Fragment, useState, useEffect } from "react";
import { StayDataType } from 'data/types';
import ProductListing from 'components/StayCard/ProductListing';
import { useSearchParams } from "react-router-dom";
import SessionManager from "common/SessionManager";
import CommonLayout from "../AccountPage/CommonLayout";
import constants from 'common/constants';
import { fetchThingsToDo } from 'data/listings';
import { fetchSavedThingsToDo } from "../../data/listings";


export interface SaveListingsHomeProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SaveListingsHome: FC<SaveListingsHomeProps> = ({
  gridClass = "",
  heading = "All Featured things to do",
}) => {

  const [listings, setListings] = React.useState([]);
  const [Savedlistings, setSavedlistings] = React.useState([]);
  const [productTypes, setProductTypes] = React.useState([]);
  const [tabActive, settabActive] = useState<string>("Ticketing");
  const [searchParams, setSearchParams] = useSearchParams();
  //const slectedTicketingTypes:any[] = React.useState([]);
  const [slectedTicketingTypes, setslectedTicketingTypes] = React.useState<any[]>([]);
  const [slectedVehicleTypes, setSlectedVehicleTypes] = React.useState<any[]>([]);
  const [slectedYachtTypes, setSlectedYachtTypes] = React.useState<any[]>([]);


  useEffect(() => {
      SessionManager.getJWTToken((jwtToken: string) => {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwtToken,
          },
        };
        const url = constants.API_CONTEXT_URL + '/account/listUserPreferences';
        fetch(url, requestOptions)
          .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            setSavedlistings(data.response);
            fetchSavedThingsToDo((data1: any) =>{
              let saveListData = data1.filter((o:any) => data.response.some(({assetId}:any) => o.id === assetId));
              setListings(saveListData);
            });
            // check for error response
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
          })
          .catch((error) => {
            alert("Error " + error);
          });
      });

  }, []);

  const renderCard = (listings: any) => {
    return <ProductListing key={listings.id} data={listings}
      href={"/product?productId=" + listings.id + "&name=" + listings.title}
    />;
  };

  return (
    <div>
      <CommonLayout>
      <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
          <div className="nc-AllListings relative">
            <div className={`py-0 grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 ${gridClass}`}>
              {listings.map((stay) => renderCard(stay))}
            </div>
        </div>
      </div>
      </CommonLayout>
    </div>
  );
};

export default SaveListingsHome;

