import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { GlobeAltIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FC, Fragment, useState, useEffect } from "react";
import React from "react";
import ClientStore from '../../common/ClientStore';
import { Dialog, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import constants from "common/constants";
import SessionManager from "../../common/SessionManager";
import SelectSearch,{ SelectSearchOption }  from 'react-select-search';
import { CityDetails } from "../../data/types";
import { StateDetails } from "../../data/types";
import 'react-select-search/style.css'
import Button from 'shared/Button/Button';

interface CitySearchModalProps {
  onClose?: () => void;
}

const CitySearchModal: FC<CitySearchModalProps> = ({
  onClose,
}) => {
  const [location, setLocation] = useState<string>(ClientStore.get('localstorage', 'location_city') == "" ? ClientStore.get('localstorage', 'location_state') : ClientStore.get('localstorage', 'location_city'))
  const [showModal, setShowModal] = useState(false);
  const [locations, setLocations] = useState<any[]>([]);
  const [city, setCity] = useState<any[]>([]);
  const [state, setState] = useState<Set<string>>(new Set<string>());
  const [nearbyLocations, setnearbyLocations] = useState<string[]>([]);
  const [selectedCity, setselectedCity] = useState("");
  const [selectedState, setselectedState] = useState("");

  useEffect(() => {
    GetLocationList();
    if(nearbyLocations?.length == 0){
      GetNearByLocationList(0);
    }
    }, []);

  const GetNearByLocationList = (val:number) => {
    let lat: string = ""
    let longi: string = ""
      navigator && navigator.geolocation.getCurrentPosition((position: any) => {
        lat = position.coords.latitude.toString();
        longi = position.coords.longitude.toString();
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            latitude: lat,
            longitude: longi,
            noOfPlaces: 9,
            radius: 100
          })
        };
        fetch(constants.API_CONTEXT_URL + '/suggestion/nearByPlaces', requestOptions)
          .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            setnearbyLocations(data.response.slice(0, 8));
            if (val == 1) {
              setLocationSession(data.response[0]?.cityName);
            }
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
      },
      (error) => {
        //setLocationSession("Goa");
      }
    );
  }

  function GetLocationList() {
    SessionManager.getJWTToken((jwtToken:string) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken,
      }
    };
    fetch(constants.API_CONTEXT_URL + '/lookup/listAllProductCities')
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        let locationsArray: any[] = [];
        let stateSet: Set<string> = new Set();
        data.response.forEach((ele: CityDetails) => {
          // Add city details to the locationsArray
          locationsArray.push({
            name: `${ele.cityName}, ${ele.stateName}, ${ele.countryName}`,
            value: ele.cityId
          });
          locationsArray.sort((a, b) => a.name.localeCompare(b.name));
          // Add unique state details to the locationsArray
          if (!stateSet.has(ele.stateName)) {
            locationsArray.push({
              name: ele.stateName,
              value: ele.stateName // or any other unique identifier for the state
            });
            stateSet.add(ele.stateName);
          }
        });
        setLocations(locationsArray);

        let cityArray: any[] = [];
        let stateArray = new Set<string>();
        data.response.forEach((ele:CityDetails) => 
          cityArray.push({"id":ele.cityId,"name":ele.cityName}))
          setCity(cityArray);
          
        data.response.forEach((ele:CityDetails) => 
          stateArray.add(ele.stateName));
          setState(stateArray);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    });
  }
  
  function setLocationSession(place:any){
    setLocation(place);
    // Set in local storage
    if (state.has(place)) {
      ClientStore.set('localstorage', 'location_state', place == "All" ? "" : place);
      ClientStore.set('localstorage', 'location_city', "" );
    }
    else{
      ClientStore.set('localstorage', 'location_city', place == "All" ? "" : place);
      ClientStore.set('localstorage', 'location_state', "" );
    }   
    window.location.reload();
  }
  
  const customFilter = (options: SelectSearchOption[], query: string): SelectSearchOption[] => {
    const lowerQuery = query.toLowerCase();
    const exactMatches = options.filter(option => option.name.toLowerCase() === lowerQuery);
    const partialMatches = options.filter(option => option.name.toLowerCase().includes(lowerQuery) && option.name.toLowerCase() !== lowerQuery);
    return [...exactMatches, ...partialMatches];
  };

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const handleSelectedCity = (val:any) => {
    let cityName : string = city.filter(x => x.id == val).map(c => c.name).toString();
    if (state.has(val)) {
      setselectedState(val);
      setLocationSession(val);
    }
    else{
      if(cityName == ""){
        setselectedCity(val);
        setLocationSession(val);
      }
      else{
        setselectedCity(cityName);
        setLocationSession(cityName);
      }
    }
    
    closeModal();
  }

  return (
    <>
      <div className="flex align-center cursor-pointer border-neutral-300 hover:border-neutral-400 rounded-full text-sm text-gray-700" onClick={openModal}>
      <GlobeAltIcon className="w-[18px] h-[18px] opacity-80 hidden lg:block" />
      <span className="ml-2 select-none">{location ? location : "Select City"}</span>
      <ChevronDownIcon
        className={`${showModal ? "-rotate-180" : "text-opacity-70"}
          ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
        aria-hidden="true"
      />
      </div>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-half-screen max-w-xl">
                <div className="inline-flex pb-2 flex-col w-full align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="font-semibold text-neutral-500 text-xl leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Select your Location
                    </h3>
                    <div className="absolute right-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>
                  </div>
                  <div className="px-4 py-2 flex flex justify-space-evenly">
                  <SelectSearch  options={locations} value={selectedCity ?? selectedState} search={true}
                    onChange={(e) => handleSelectedCity(e)} 
                    placeholder="Select by City or State" 
                    filterOptions={[customFilter]}
                    />
                  </div>
                  {nearbyLocations && nearbyLocations.length > 0 ?
                    <>
                      <span className={`px-8 py-2 font-semibold text-neutral-500 text-xl`}
                      style={{alignSelf:'center'}}>
                        Near to you
                      </span>
                      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
                        
                      </div>
                      <div className="flex pb-2 px-2 grid grid-cols-2 gap-4 md:gap-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                          {nearbyLocations.map((item: any) =>
                            <div className="p-2 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                              <span onClick={() => handleSelectedCity(item.cityName)} className="px-1 py-1 hover:text-red-700 text-neutral-500 text-lg cursor-pointer">
                                {item.cityName}
                              </span>
                            </div>
                          )}
                      </div>
                    </>
                    : null
                    }
                  <div className="px-8 py-3 justify-space-evenly hidden lg:flex">
                    <ButtonPrimary onClick={() => GetNearByLocationList(1)}>
                      Select Current Location
                    </ButtonPrimary>&nbsp; &nbsp;
                    <ButtonPrimary onClick={() => handleSelectedCity("All")}>
                      Select All Locations
                    </ButtonPrimary>
                  </div>
                  <div className="px-9  py-3 justify-space-evenly flex lg:hidden">
                    <a className="text-primary-500 hover:text-primary-700" onClick={() => GetNearByLocationList(1)}>
                      Select Current Location
                    </a>
                    <a className="text-primary-500 hover:text-primary-700" onClick={() => handleSelectedCity("All")}>
                      Select All Locations
                    </a>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default CitySearchModal;
