import React, { FC, useState } from "react";
import AllSearchForm from "./AllSearchForm";
import PlacesToVisit from "images/homepage/PlacesToVisit.png";
import Rental from "images/homepage/Rental.png";
import Ticketing from "images/homepage/Ticketing.png";
import Yacht from "images/homepage/Yacht.png";

import {
  BookmarkIcon,
  HeartIcon,
  MagnifyingGlassIcon,
  UserCircleIcon,
  ShoppingCartIcon
} from "@heroicons/react/24/outline";

export type SearchTab = "All" | "Ticketing" | "Rentals" | "Yachts" | "Places To Visit";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "All" | "Ticketing" | "Rentals" | "Yachts" | "Places To Visit";
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "All",
  currentPage,
}) => {
  const tabs: SearchTab[] = ["Ticketing", "Rentals", "Yachts", "Places To Visit"];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const renderTab = () => {
    return (
      <div className="justify-center text-primary-700 flex space-x-1 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <div
              // onClick={() => setTabActive(tab)}
              className={`flex-shrink-0 flex rounded-lg justify-center items-center cursor-pointer text-sm lg:text-sm font-medium ${
                
                  "bg-primary-500 text-white"
                  // : "text-white bg-searchtabs hover:text-primary-500 "
              } `} style={{width:'8.8rem', height:'2.6rem'}}
              key={tab}
            >
              <div className="flex">
              {renderSearchIcon(tab)}
              <span>{tab}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSearchIcon = (val:any) => {
    switch (val) {
      case "All":
        return <><img src={Ticketing} className="w-5"></img>&nbsp;</>;
      case "Ticketing":
        return <><img src={Ticketing} className="w-5"></img>&nbsp;</>;
      case "Rentals":
        return <><img src={Rental} className="w-5"></img>&nbsp;</>;
      case "Yachts":
        return <><img src={Yacht} className="w-5" color="white"></img>&nbsp;</>;
      case "Places To Visit":
        return <><img src={PlacesToVisit} className="w-5"></img>&nbsp;</>;

      default:
        return <><img src={Ticketing} className="w-5"></img>&nbsp;</>;
    }
  }

  const renderForm = () => {
    const isArchivePage = !!currentPage && !!currentTab;
    switch (tabActive) {
      case "All":
        return <AllSearchForm haveDefaultValue={isArchivePage} PageType={"All"} />;
      case "Ticketing":
        return <AllSearchForm haveDefaultValue={isArchivePage} PageType={"Ticketing"}/>;
      case "Rentals":
        return <AllSearchForm haveDefaultValue={isArchivePage} PageType={"Rentals"}/>;
      case "Yachts":
        return <AllSearchForm haveDefaultValue={isArchivePage} PageType={"Yachts"}/>;
      case "Places To Visit":
        return <AllSearchForm haveDefaultValue={isArchivePage} PageType={"PlacesToVisit"}/>;

      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      {renderTab()}
      {/* {renderForm()} */}
    </div>
  );
};

export default HeroSearchForm;
