import React, { useState } from "react";
import BtnLikeIcon from "../../components/BtnLikeIcon/BtnLikeIcon";
import constants from 'common/constants';
import {
  EmailIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailShareButton,LinkedinShareButton,TwitterShareButton,WhatsappShareButton
} from "react-share";

const LikeSaveBtns = (data:any) => {
  let url = window.location.href;
  const shareDetails = { url};
  const handleSharing = async () => {
    let myWindow = window as any;
    if (myWindow.cordova != undefined && myWindow.cordova) {
      document.addEventListener('deviceready', () => {
        if (myWindow.plugins && myWindow.plugins.socialsharing) {
          myWindow.plugins.socialsharing.share(null, data.packageName + "_" + (data.eventDate?.split('T')[0] == null ? "" : data.eventDate.split('T')[0]) + '.png', null, null);
        } else {
          console.warn('Social sharing plugin not available.');
        }
      }, false);
    }
    else if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() =>
            console.log("content shared")
          );
      } catch (error) {
        console.log(`Error : ${error}`);
      }
    } else {
      console.log(
        "not supported!"
      );
    }
  };
  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        <span className="px-0 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <EmailShareButton url={window.location.href} children={<EmailIcon size={32} round={true} />} /> &nbsp;&nbsp;&nbsp;
        </span>
        {/* <span className="-px-2 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <WhatsappShareButton url={window.location.href} children={<WhatsappIcon size={32} round={true} />} /> &nbsp;
        </span> */}
        <div
          className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer text-white bg-black bg-opacity-30 hover:bg-opacity-50`}
          data-nc-id="BtnLikeIcon"
          title="Save"
          onClick={() => handleSharing()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
        </div>
        <span className="ml-2.5">
          {data.productId != undefined ?
          <BtnLikeIcon isLiked={data.isLiked} productId={data.productId}/>
          : null}
          {/* <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer hidden sm:block ml-2.5">Save</span> */}
        </span>
      </div>
    </div>
  );
};

export default LikeSaveBtns;
