import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Auth } from 'aws-amplify';
import SessionManager from "common/SessionManager";
import Constant from "common/constants";
import ClientStore from "../../common/ClientStore";
import { PackageOrder } from "../../data/types";
import axios from 'axios';
import constants from "common/constants";
import { useSearchParams } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export interface PageLoginProps {
  className?: string;
  userSignedIn?: any;
  userSignedInFailed?: any;
}

const PageLogin: FC<PageLoginProps> = ({ className = "", userSignedIn, userSignedInFailed }) => {
  const [errormessage, seterrormessage] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [phone_number, setphone_number] = useState("");
  const [password, setpassword] = useState("");
  const [isVendor, setIsVendor] = React.useState(searchParams.get("Vendor")?.trim()=="true" ? true:false);

  useEffect(() => {

    let inCode = sessionStorage.getItem("inCode");
    let mobile = "+" + searchParams.get("mobile")?.trim();
    setIsVendor(searchParams.get("Vendor")?.trim()=="true" ? true:false);
    if (searchParams.get("mobile")?.trim() != undefined && mobile != null && mobile != '' && inCode != null && inCode != '') {
      var CryptoJS = require("crypto-js");
      var pass = CryptoJS.AES.decrypt(inCode, mobile);
      setphone_number(mobile)
      signIn(mobile, pass.toString(CryptoJS.enc.Utf8), searchParams.get("Vendor")?.trim()=="true" ? true:false);
    }
    sessionStorage.setItem("inCode", '');

  }, []);

  const handleChangePhone = (e: any) => {
    setphone_number(e)
  }

  function onLogin() {
    // var login_mobile = (document.getElementById("login_mobile") as HTMLInputElement).value;
    // var login_password = (document.getElementById("login_password") as HTMLInputElement).value;
    if (phone_number.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) === null) {
      seterrormessage("Phone number not valid");
      return;
    }
    if (password == null || password == "") {
      seterrormessage("Password cannot be empty");
      return;
    }
    signIn(phone_number, password, searchParams.get("Vendor")?.trim()=="true" ? true:false);
  }


  const updateUserRole = () => {
    SessionManager.getJWTToken((jwtToken: string) => {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwtToken,
          },
          body: JSON.stringify({
            "new": false,
            "phoneNumber": phone_number,
            "role": "Vendor",
          })
        };
        const url = constants.API_CONTEXT_URL + '/account/saveUser';
        fetch(url, requestOptions)
          .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            alert("Successfully registered as Vendor!!")
            //SessionManager.setUser(data.response);            
          })
          .catch((error) => {
            alert("Review not published" + error);
          });
      });
  }

  const addUserRole = (user:any) => {
    SessionManager.getJWTToken((jwtToken: string) => {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          companyId: "",
          userId: user?.userId,
          firstName: user?.firstName,
          productId: "",
          phoneNumber: user?.phoneNumber,
          role: "Vendor",
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwtToken,
        },
      };
      fetch(Constant.API_CONTEXT_URL + '/user_role/addUserRole', requestOptions)
        .then(response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && response.json();
          alert("Successfully registered as Vendor!!");
        })
        .catch(error => {
          console.log('There was an error!', error);
        });
    });
  }

  async function signIn(username: string, password: string, isvendor: boolean) {
    try {
      const user = await Auth.signIn(username, password);
      SessionManager.setSession(user)
      getSessionInformation(user,isvendor);
      
      //Check of any non session orders and add to cart
      let PackageOrderobj: PackageOrder;
      let obj = ClientStore.get('localstorage', 'nonSessionOrder');
      if (obj != undefined) {
        PackageOrderobj = JSON.parse(obj);
        if (PackageOrderobj != null) {
          PackageOrderobj.userId = username;
          ClientStore.remove('localstorage', 'nonSessionOrder');
          const url = constants.API_CONTEXT_URL + '/order/saveOrder';
          axios.post(url, PackageOrderobj)
            .then((response) => {
              let orderobj: PackageOrder = response.data.response;
              window.location.assign("#/order-details" + "?OrderId=" + orderobj.id);
            })
            .catch((error) => {
              alert("Order cannot be processed" + error);
            });
        }
      }
    } catch (error: any) {
      seterrormessage(error.message);
      setLoading(false);
      if (error.code === "UserNotConfirmedException") {
        resendConfirmationCode(username);
      }
      if (error.code === "UsernameExistsException") {
        resendConfirmationCode(phone_number);    
      }

      else {
        userSignedInFailed(error);
      }

    }
  }

  const notifyPasswordChanged = () => {
    SessionManager.getJWTToken(async (jwtToken: any) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwtToken,
        },
      };
      const url = constants.API_CONTEXT_URL + '/account/passwordUpdatedNotify';
      fetch(url, requestOptions)
        .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
        })
        .catch((error) => {
          alert("Error sending email " + error);
        });
    })
  }

  async function getSessionInformation(user: any, isvendor:boolean) {
    try {
      // POST request using fetch with error handling
      var token = await SessionManager.getJWTToken((jwt: string) => {

        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwt,
          }
        };
        fetch(Constant.API_CONTEXT_URL + '/account/mySession', requestOptions)
          .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            // check for error response
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            SessionManager.setSession(data.response);
            if(isvendor){
              addUserRole(data.response['user']);
            }

            //send notification for password changed if done
            if (searchParams.get("notifyPasswordChanged")?.trim() == "true") {
              notifyPasswordChanged();
            }
            if (userSignedIn != null && typeof (userSignedIn) === 'function') {
              userSignedIn(user);
            }
            else {
              window.location.assign("/");
            }
          })
          .catch(error => {
            console.error('There was an error!', error);
            window.location.assign("/");
            if (userSignedIn != null && typeof (userSignedIn) === 'function') userSignedIn(user);
          });
      });
    } catch (error) {
      console.log('error signing in', error);
      alert(error);
      userSignedInFailed(error);
      setLoading(false);
    }
  }

  async function resendConfirmationCode(username: string) {
    try {
      await Auth.resendSignUp(username);
      let currentStep = {
        userName: username,
        currentStep: 2
      }
      SessionManager.setCurrentAuthenticationStep(currentStep);
      //window.location.assign("/#/auth");
      window.location.assign("#/mobile-otp-verification?mobile=" + encodeURI(username));
      console.log('code resent successfully');
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || eSanchari</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid grid-cols-1 gap-6"  >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Mobile Number
              </span>
              <div className="flex">

                <PhoneInput
                className="h-11"
                style={{"--PhoneInputCountryFlag-height":"1.3rem","width":"100%"}}
                  international
                  defaultCountry="IN"
                  value={phone_number}
                  onChange={handleChangePhone}
                  placeholder="Mobile Number &#42;"
                  required />

                {/* <Input id="phone_number" placeholder="Mobile Number &#42;"
                  onChange={handleChangePhone} defaultValue={phone_number}
                  required maxLength={10} /> */}
              </div>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm text-primary-400">
                  Forgot password?
                </Link>
              </span>
              {/* <Input type="password" id="login_password" className="mt-1" /> */}
              <Input
                id="login_password"
                type="password"
                placeholder="Password &#42;"
                onChange={(e: any) => setpassword(e.target.value)}
                autoComplete="current-password"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-red-800 ">
                {errormessage}
              </span>

            </label>

            <ButtonPrimary onClick={() => onLogin()}>Continue</ButtonPrimary>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup" className="text-sm text-primary-400">Create an account</Link>
          </span>
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Partner with us!! {` `}
            <Link to="/collaborate" className="text-sm text-primary-400">Become Vendor</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
