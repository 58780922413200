import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import SessionManager from "common/SessionManager";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [errormessage, seterrormessage] = useState("");
  const [userCreated, setUserCreated] = useState("");
  const [loading, setLoading] = useState(false);
  const [register_mobile, setRegister_mobile] = useState("");
  
  function handleChangeUserName() {
    var usrNameValue = (document.getElementById("register_name") as HTMLInputElement).value;

    return usrNameValue;
  }

  function handleChangeEmail() {
    var register_email = (document.getElementById("register_email") as HTMLInputElement).value;
    
    return register_email;
  }
  function handleChangePassword() {
    var register_password = (document.getElementById("register_password") as HTMLInputElement).value;
    
    return register_password;
  }
  const handleChangePhone = (e: any) => {
    setRegister_mobile(e);
   }
  
   function handleChangeRepeatPassword() {
    var register_repeat_password = (document.getElementById("register_repeat_password") as HTMLInputElement).value;
    if (register_repeat_password != "") {
      
    }
    return register_repeat_password;
  }


  // handleTermsCheckbox = (e) => {
  //   this.setState({
  //     errormessage : ""
  //   })
  //   this.setState({
  //     showResults: true
  //   })
  // }

  function handleSubmit() {
   
    seterrormessage("");

    var usrNameValue = handleChangeUserName();
    if(usrNameValue == '' || usrNameValue == null){
      seterrormessage("Please provide your name");
      return
    }

    if(!register_mobile.startsWith('+') || register_mobile == '' || register_mobile == null){
      seterrormessage("Please provide a Country Dial Code followed by mobile number e.g. +919876543210");
      return
    }
    if(isValidPhoneNumber(register_mobile) === false){
      seterrormessage("Please provide a Country Dial Code followed by mobile number e.g. +919876543210");
      return
    }

    var email = handleChangeEmail();
    if(email == '' || email == null){
      seterrormessage("Please provide your email");
      return
    }
    if(email?.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === null){
      seterrormessage("Email is not in correct format");
      return
    }

    var password = handleChangePassword();
    var repeatPassword = handleChangeRepeatPassword();

    if(password != repeatPassword){
      seterrormessage("Password and Confirm password should be same");
      return
    }

    signUp(usrNameValue, password, register_mobile, email);
  }

  async function resendConfirmationCode(username: string) {
    try {
      await Auth.resendSignUp(username);
      let currentStep = {
        userName: username,
        currentStep: 2
      }
      SessionManager.setCurrentAuthenticationStep(currentStep);
      //window.location.assign("/#/auth");
      window.location.assign("#/mobile-otp-verification?mobile=" + encodeURI(username));
      console.log('code resent successfully');
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }

  function signUp(name:string, password:string, phone_number:string, email:string) {
    try {
      Auth.signUp({
        username: phone_number, //username,
        password: password,
        attributes: {
          name: name,
          email: email,          // optional
          phone_number: phone_number,   // optional - E.164 number convention
          // other custom attributes 
        }
      }).then(user => {
        // at this time the user is logged in if no MFA required
        // if (this.userCreated != null) {
        //   this.userCreated(true, user.user, password);
        // }
        // else {
        //   alert("this.userCreated " + this.userCreated);
        // }
        var CryptoJS = require("crypto-js");
        var pass = CryptoJS.AES.encrypt(password, phone_number);
        sessionStorage.setItem("inCode", pass);
        window.location.assign("#/mobile-otp-verification?mobile=" + encodeURI(phone_number));
      }).catch(e => {
        if(e.code === 'InvalidPasswordException') {
          seterrormessage("Password should be 8  characters long, containing numbers, special characters, uppercase letters and lowercase letters.");
           
        }
        if (e.code === "UserNotConfirmedException") {
            resendConfirmationCode(phone_number);    
        }
        if (e.code === "UsernameExistsException") {
          alert("An account with the given phone number already exists.");
          window.location.assign("#/login");
        }
        
        else {
          seterrormessage(e.message)
        }
        
        //console.log(e);
        // if (this.userCreated != null) this.userCreated(false, e);
      });


    } catch (error) {
      seterrormessage(error + "");
      console.log('error signing up:', error);
      // if (this.userCreated != null) this.userCreated(false, error);
      return;
    }
    finally {
      setLoading(false)
    }
  }
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || eSanchari</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          
          {/* FORM */}
          <div className="grid grid-cols-1 gap-6" >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Name<span className="text-primary-900"> *</span>
              </span>
              <Input
                type="text"
                id="register_name"
                placeholder="Enter your name"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Mobile Number<span className="text-primary-900"> *</span>
              </span>
              <PhoneInput
                className="h-11"
                style={{ "--PhoneInputCountryFlag-height": "1.3rem", "width": "100%" }}
                international
                defaultCountry="IN"
                value={register_mobile}
                onChange={handleChangePhone}
                placeholder="Mobile Number &#42;"
                required />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email<span className="text-primary-900"> *</span>
              </span>
              <Input
                type="email"
                id="register_email"
                placeholder="abc@example.com"
                className="mt-1"
                onChange={() => handleChangeEmail}
              />
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Password<span className="text-primary-900"> *</span>
              </span>
              <Input id="register_password" type="password" 
              onChange={() => handleChangePassword}
              className="mt-1" />
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Confirm Password<span className="text-primary-900"> *</span>
              </span>
              <Input id="register_repeat_password" type="password"
               onChange={() => handleChangeRepeatPassword}
                className="mt-1" />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-red-800 ">
                {errormessage}
              </span>
              
            </label>
            <ButtonPrimary onClick={()=> handleSubmit()}>Continue</ButtonPrimary>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="#/login" className="text-primary-400">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
