import React, { useRef, FC, useEffect, useState , Fragment} from "react";
import { GeneralPackage, Order } from '../../data/types';
import constants from 'common/constants';
import ButtonSecondary from "shared/Button/ButtonSecondary";
import axios from 'axios';
import { TrackingDetails, ProductDataType, BusinessDetails } from './../../data/types';
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Logo from "shared/Logo/Logo";
import {
  MapIcon, MapPinIcon  
} from "@heroicons/react/24/outline";
import SessionManager from './../../common/SessionManager';
import html2canvas from 'html2canvas';

const moment = require('moment');

export interface BookingDetailsTableProps {
  className?: string;
  data:Order;
}

const BookingDetailsTable: FC<BookingDetailsTableProps> = ({ className = "",data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [trackingDetails, settTrackingItems] = useState<TrackingDetails[]>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [productDetails, setProductDetails] = useState<BusinessDetails>();
  const [packageDetails, setPackageDetails] = useState<GeneralPackage>();
  const divRef = useRef<HTMLDivElement>(null);
  const [imageUrl, setImageUrl] = useState(data?.productThumbnailUrl);
  
  const downloadImage = async (thumbnailUrl:string) => {
    if(imageUrl== '' || imageUrl == null){
      const url = data.productThumbnailUrl;
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      setImageUrl(objectUrl);
    }
  };

  useEffect(() => {    
    const requestOptions:any = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    };
    axios.get(constants.API_CONTEXT_URL + '/order/viewOrder?orderId=' + data.orderId, requestOptions)
			.then((response) => {
        const uniqueTracks = response?.data?.response?.tracking?.filter(
          (item:TrackingDetails, index:any, self:any) =>
            index === self.findIndex(
              (t:TrackingDetails) => moment(t.createdDate).format("yyyy-MM-DD") === moment(item.createdDate).format("yyyy-MM-DD")
              && t.stage === item.stage
            )
        );
        if(uniqueTracks.some((track:TrackingDetails) => track.stage == 'SalesConfirmed')){
          const updateduniqueTracks = uniqueTracks.filter((tracks:TrackingDetails) => tracks.stage != 'ReadyForSales')
          settTrackingItems(updateduniqueTracks);
          return;
        }
        settTrackingItems(uniqueTracks);
        //downloadImage(data.productThumbnailUrl)        
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

  const cancelOrder = (orderId: string, currentState: string) => {
    if(currentState == "PaymentFailure"){
      alert("The order is already in failed state !")
      return;
    }
    if(currentState == "SalesConfirmed"){
      alert("The order is already completed !")
      return;
    }
    if(currentState == "NoShow"){
      alert("The cancelling time is over !")
      return;
    }
    if (currentState == "CancelledByUser") {
      window.alert("The order is already Cancelled")
    } else {
      if (window.confirm("Are you sure to Cancel the order")) {
        SessionManager.getJWTToken((jwtToken: string) => {
        const requestOptions: any = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwtToken
          },
        };
        const url = constants.API_CONTEXT_URL + '/order/updateStatus?comment=CancelledByUser&orderId=' + data.orderId + '&orderStage=CancelledByUser';
        fetch(url, requestOptions)
          .then(async response => {
            window.location.reload();

          }).catch((error) => {
            console.log(error);
          });
      });
    }
    }
  }

  const TrackingStatus = (val:string) => {
    if(val == "PaymentInitiate"){
      return (
        <span>Booking Initiated</span>
      )
    }
    else  if(val == "PaymentConfirmed"){
      return (
        <span>Booking Confirmed</span>
      )
    }
    else  if(val == "PaymentFailure"){
      return (
        <span>Booking Failed</span>
      )
    }
    else  if(val == "ReadyForSales"){
      return (
        <span className="text-blue-800">Order Confirmed</span>
      )
    }
    else  if(val == "SalesConfirmed"){
      return (
        <span className="text-green-800">Order Completed</span>
      )
    }
    else  if(val == "CancelledByUser"){
      return (
        <span className="text-red-800">Order Cancelled</span>
      )
    }
    else  if(val == "NoShow"){
      return (
        <span className="text-red-800">No Show</span>
      )
    }
    else {
      return (
        <span>New</span>
      )
    }
  return val
  }

  const trackingDetail = (track:TrackingDetails[]) => {
    return (
      <div className="flex my-5 pt-3 md:my-0">
        <div className="flex-shrink-0 flex flex-col items-center py-0">
          <span className="block w-4 h-4 rounded-full border border-neutral-400"></span>
          <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
          <span className="block w-4 h-4 rounded-full border border-neutral-400"></span>
        </div>
        <div className="ml-4 space-y-10 text-sm">
          {track.map((item: TrackingDetails) => (
            <>
              <div key={item.id} className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {TrackingStatus(item.stage)} on {moment(`${item.createdDate}`).format('DD MMM YYYY')}
                </span>
              </div>
            </>
          ))}
        </div>
      </div>
    )
  }
  const renderDetailTop = () => {
    downloadImage(data.productThumbnailUrl)        
    if (data.stage == "CancelledByUser") {
      window.alert("This ticket is Cancelled")
    } else {
      return (
        <div>
          <div className="flex flex-col md:flex-row ">
            <div>
              <span className="text-neutral-500 dark:text-neutral-400">Latest Tracking Detail
              </span>              
              {trackingDetails?.length! > 0 ?
                <div>
                  {trackingDetail(trackingDetails!)}
                </div>
                : null
              }
            </div>
            <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
            <div>
              <ButtonSecondary onClick={() => cancelOrder(data.orderId, data?.stage)}>Cancel</ButtonSecondary>
              &nbsp;&nbsp;&nbsp;
              {data.stage != "PaymentFailure" ?
              <ButtonSecondary onClick={openModal}>
                View Details
              </ButtonSecondary>
              : null}
            </div>
          </div>
        </div>
      );
    }
  };
  
  const handleDownload = async () => {
    if (!divRef.current) {
      console.error('Div element not found');
      return;
    }

    html2canvas(divRef.current, { useCORS: true }).then(function (canvas) {

      const pngUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = data.packageName+"_"+(data.eventDate?.split('T')[0]==null?"":data.eventDate.split('T')[0])+'.png';
      let myWindow = window as any;
      if (myWindow.cordova != undefined && myWindow.cordova) {
        document.addEventListener('deviceready', () => {
          if (myWindow.plugins && myWindow.plugins.socialsharing) {
            myWindow.plugins.socialsharing.share(null, data.packageName+"_"+(data.eventDate?.split('T')[0]==null?"":data.eventDate.split('T')[0])+'.png', pngUrl, null);
          } else {
            console.warn('Social sharing plugin not available.');
          }
        }, false);
      }
      else{
        downloadLink.click();
      }
    });
  }
  const handleSharing = async () => {

    if (!divRef.current) {
      console.error('Div element not found');
      return;
    }

    html2canvas(divRef.current, { useCORS: true }).then(function (canvas) {

      /**************   Download as PNG */
      //html2canvas(divRef.current).then((canvas) => {
      /*const pngUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = data.packageName+"_"+(data.eventDate?.split('T')[0]==null?"":data.eventDate.split('T')[0])+'.png';
      downloadLink.click();
      ******************png*/

      
      /***********  Share as image */
      // Convert the canvas to a Blob object
      canvas.toBlob(async function (blob) {
        // Create a new file object from the Blob
        var file = await new File([blob == null ? "" : blob], 
                          data.packageName + "_" + (data.eventDate?.split('T')[0] == null ? "" : data.eventDate.split('T')[0]) + '.png', 
                          { type: blob?.type });
                                                    
        /*
        // Check if the Web Share API is supported
        if (navigator.share) {
          // Invoke the share dialog
          navigator.share({
            title: 'Share Ticket',
            text: 'Check out this amazing ticket for us!',
            files: [file]
          })
            .then(() => console.log('Shared successfully.'))
            .catch(error => console.error('Error sharing:', error));
        } else {
          // Fallback for browsers that don't support the Web Share API
          console.log('Web Share API not supported.');
        }*/

        // gives type error in ios
        const fileData = {
          files: [file],
          title: 'Share Ticket',
          text: 'Check out this amazing ticket!',
        };
        try {
          let myWindow = window as any;
          if (myWindow.cordova != undefined && myWindow.cordova) {
            document.addEventListener('deviceready', () => {
              if (myWindow.plugins && myWindow.plugins.socialsharing) {
                const pngUrl = canvas.toDataURL('image/png');
                myWindow.plugins.socialsharing.share(null, data.packageName + "_" + (data.eventDate?.split('T')[0] == null ? "" : data.eventDate.split('T')[0]) + '.png', pngUrl, null);
              } else {
                console.warn('Social sharing plugin not available.');
              }
            }, false);
          }
          else if ((navigator.canShare(fileData))) {
            //throw new Error('Can\'t share data.');
            //console.log('Downloading ticket', error)
            const pngUrl = canvas.toDataURL('image/png');
            const downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = data.packageName + "_" + (data.eventDate?.split('T')[0] == null ? "" : data.eventDate.split('T')[0]) + '.png';
            //downloadLink.click();
            await navigator.share(fileData);
          };
          
        } catch (err: any) {
          // //console.error(err.name, err.message);
          // //console.log('Downloading ticket', error)
          // const pngUrl = canvas.toDataURL('image/png');
          // const downloadLink = document.createElement('a');
          // downloadLink.href = pngUrl;
          // downloadLink.download = data.packageName + "_" + (data.eventDate?.split('T')[0] == null ? "" : data.eventDate.split('T')[0]) + '.png';
          // downloadLink.click();
        }


        /*if(file!=null){
        let newVariable: any;

        newVariable = window.navigator;
        
        if (newVariable && newVariable.share) {
          newVariable.share({
            file: [file],
            title: 'Share Ticket',
            text: 'Check out this amazing ticket!',
          })
            .then(() => console.log('Successful share'))
            .catch(
              (error:any) => 
              {
                console.log('Downloading ticket', error)
                const pngUrl = canvas.toDataURL('image/png');
                const downloadLink = document.createElement('a');
                downloadLink.href = pngUrl;
                downloadLink.download = data.packageName+"_"+(data.eventDate?.split('T')[0]==null?"":data.eventDate.split('T')[0])+'.png';
                downloadLink.click();
              }
              
            );
        } else {
          alert('Downloading ticket');
          const pngUrl = canvas.toDataURL('image/png');
          const downloadLink = document.createElement('a');
          downloadLink.href = pngUrl;
          downloadLink.download = data.packageName+"_"+(data.eventDate?.split('T')[0]==null?"":data.eventDate.split('T')[0])+'.png';
          downloadLink.click();
        }
      }*/

      });


    });
  };

  const renderModal = () => {
    return (
      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div ref={divRef} className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div  className="inline-block py-1 w-full max-w-[360px]">
                <div className="inline-flex bg-black pb-2 flex-col w-full text-left align-middle transition-all transform overflow-auto rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-1 py-2 text-center">
                    <span className="text-sm pb-2 flex justify-between items-center text-neutral-400">
                      <Logo className="w-20" />
                      <div className="text-sm sm:text-base text-neutral-7000 dark:text-neutral-500">
                        M-Ticket
                      </div>&nbsp;
                    </span>
                    <div className="">
                      <img loading='eager' src={imageUrl} className="w-full h-40 xs:h-11 xs:w-11" alt="" />
                      <div
                        className="items-center justify-between p-2 xl:p-5 border-b border-neutral-200 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800"
                      >
                        <span className="text-lg text-neutral-900 dark:text-neutral-100 font-semibold flex-grow">
                          {data.packageName}
                        </span> <br/>
                        <span className="text-lg text-neutral-400 dark:text-neutral-100 font-semibold flex-grow">
                        {data.projectName}
                        </span>
                      </div>
                      <div className="flex items-center justify-between p-4 py-1 xl:p-5 xl:py-3 border-b border-neutral-200 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800">
                        <div style={{textAlignLast:"left"}}>
                          <span className="text-xs">
                            Date
                          </span><br />
                          <span className="font-semibold">{moment(`${data.eventDate}`).format('DD MMM YYYY')} </span>
                          {data?.packageStartTime ? 
                          <span className="font-semibold px-1">{moment(data?.packageStartTime?.toString(), "hh:mm A").format('hh:mm A')}</span>
                          : null }
                        </div>
                        <div>
                          <span className="text-xs">
                            {(data?.type?.includes("Yacht") || data?.type == "SpeedBoat") ? 'Hours' :
                              data?.type?.includes("Wheel") || data?.type?.includes("Vehicle")
                                || data?.type?.includes("Cycle") || data?.type?.includes("Car") ? 'Day(s)' : 'Guests'}
                          </span><br />
                          <span className="font-semibold">{data?.nonGuestCount == 0 ? data.ticketDetails?.length : data.nonGuestCount}</span>
                        </div>
                        <div
                          className={`nc-BtnLikeIcon w-8 h-8 lg:flex hidden items-center justify-center rounded-full cursor-pointer text-white bg-black bg-opacity-30 hover:bg-opacity-50`}
                          data-nc-id="BtnLikeIcon"
                          title="Save"
                          onClick={() => handleDownload()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="-4 -3 24 24"
                            stroke="currentColor"
                          >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" stroke="#000000"/>
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" stroke="#000000"/>
                          </svg>
                        </div>
                        <div
                          className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer text-white bg-black bg-opacity-30 hover:bg-opacity-50`}
                          data-nc-id="BtnLikeIcon"
                          title="Save"
                          onClick={() => handleSharing()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="-3 -3 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={1.0}
                              d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
                              stroke="#000000"
                            />
                          </svg>
                        </div>
                      </div>                      
                      <div className="p-4 py-2 xl:p-5 webkitCenter border-b border-neutral-200 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800"
                      >
                        <img crossOrigin ='' loading='eager' style={{ height: "20vh" }} src={constants.API_CONTEXT_URL + "/qrcode/getCode?assetId=" + data.id + "&assetType=ORDER"}></img>
                        {/* { <span className="text-[10px]">{data.orderId}</span> } */}
                      </div>
                      <div className="text-sm flex items-top justify-between p-4 xl:p-5 border-b border-neutral-200 bg-neutral-100">
                        Venue: 
                        <span className="ml-2" style={{textAlign:"left"}}>{productDetails?.firstLine} {productDetails?.secondLine}{", "}
                        {productDetails?.stateCode} {productDetails?.countryCode}<br />
                        Contact: <u><a href= {"tel:"+ productDetails?.phoneNumber} >+91 {productDetails?.phoneNumber}</a></u></span>

                        {productDetails?.latitude ? 
                          <a href="javascript:void();" className="mapiconcenter" onClick={() => locateUs(productDetails?.latitude, productDetails?.longitude)} >
                            <MapPinIcon className="h-7 w-7" />
                            <span className="flex text-xs text-neutral-400 dark:text-neutral-100 flex-grow">Locate</span>
                          </a>
                        : null} 
                      </div>
                      <div className="py-0 p-4 xl:p-5 xl:py-2 webkitCenter border-b border-neutral-200 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800"
                      >
                        <span className="text-[10px]">Terms & Conditions apply, as per service provider.</span>
                      </div>
                    </div>
                    <span className="absolute right-2 top-1">
                      <ButtonClose onClick={closeModal} />
                    </span>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  function closeModal() {
    setIsOpenModal(false);
  }

  function openModal() {
    SessionManager.getJWTToken((jwtToken: string) => {
      viewProduct(data.productId, jwtToken);
    });
    setIsOpenModal(true);
  }
  async function viewProduct(productId: string, jwtToken :string) {
    // POST request using fetch with error handling
    const requestOptions = {
      method: 'GET',

      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken,
      },
    };
    await fetch(constants.API_CONTEXT_URL + '/product/seller/viewProduct?productId=' + productId, requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data1 = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data1 && data1.message) || response.status;
          return Promise.reject(error);
        }
        setProductDetails(data1.response?.businessDetails);
        setPackageDetails(data1.response?.packages?.filter((s:GeneralPackage) => s.id == data.packageId)[0]);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function callPhone(phonenumber:any){
    window.open('tel:' + phonenumber, '_system');
  
  }

  function locateUs(latitude:any, longitude:any){
    window.open('http://maps.google.com/maps?q=' + latitude + "," + longitude, '_system');
  
  }

  function getUserReadableStatus(stat:string){
    let newStats:string = stat;
    if(stat == "SalesConfirmed"){
      newStats = "Order Completed";
    }
    else if(stat == "CancelledByUser"){
      newStats = "User Cancelled";
    }
    else if(stat == "PaymentConfirmed"){
      newStats = "Booking Confirmed";
    }
    else if(stat == "PaymentFailure"){
      newStats = "Booking Failed";
    }
    else if(stat == "NoShow"){
      newStats = "No Show";
    }
    return newStats;
  }

  const renderDetail = () => {
    if(data.stage == "CancelledByUser") return null;
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {renderDetailTop()}
        <div className="my-7 md:my-10 space-y-5 md:pl-24">
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
          <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
            {data.ticketDetails?.length! > 0 ?
              data.ticketDetails?.map((item, idx) => (
                <><span key={item.serialNumber} className="py-3 flex items-center text-sm text-neutral-500 font-normal mt-0.5">Guest {idx + 1}:
                  &nbsp;&nbsp; Name: {item.firstName} &nbsp;&nbsp; Age: {item.age}&nbsp;&nbsp; Phone: {item.phoneNumber}
                </span>
                </>
              ))
              : null
            }
          </div>
        </div>
        {renderModal()}
      </div>
    );
  };

  function getTotalCount(data:Order) {
    let count = data.guestListRequired == true ? data.ticketDetails?.length ?? 1 : data.nonGuestCount;
    let final = count == 0 ? data.ticketDetails?.length : 0;
    if(data?.type?.includes("Yacht")||data?.type == "SpeedBoat"){
      return data.nonGuestCount;
    }
    return final;
  }

  return (
    <div className="lg:p-4 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
      <div
        className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
         dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      >
        <div className={` sm:pr-20 relative  ${className}`}>
          {data.stage == "CancelledByUser" ? "" : <span
            className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
              }`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <i className="text-xl las la-angle-down"></i>
          </span>
          }

          <div className="flex  flex sm:flex-row items-center space-y-6 sm:space-y-0">
            {/* LOGO IMG */}
            <div className="w-24 lg:w-32 flex-shrink-0">
              <img src={imageUrl} className="w-20" alt="" />
            </div>
            {/* FOR MOBILE RESPONSIVE */}
            <div className="block lg:hidden space-y-1">
              <div className="flex font-semibold">
                <div>
                  <span>{data.projectName} &nbsp;- </span>
                  <span>{data.stage== "CancelledByUser"?"Cancelled":data.packageName}</span>
                  <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                    {(data?.type?.includes("Yacht")||data?.type == "SpeedBoat") ? 'Riding Hours' :
                    data?.type?.includes("Wheel") || data?.type?.includes("Vehicle")
                || data?.type?.includes("Cycle") || data?.type?.includes("Car") ? 'Number of Day(s)' : 
                data.type?.includes("Event") ? 'Number of Items' : 'Number of Guests'}<span className="mx-2">·</span>
                    {data?.nonGuestCount == 0 ? data.ticketDetails?.length : data.nonGuestCount}
                  </span>
                </div>
              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">Price</span>
              <span className="mx-1">:</span>
                {(data?.price * data?.ticketDetails?.length! ?? 1) != data?.paidPrice ? 
                <><span style={{ textDecorationLine: 'line-through', color:"red"}}>
                ₹{data?.price * data?.ticketDetails?.length! ?? 1}
                </span> &nbsp;</>
                : null}
                <span style={{color:"green"}}>
                ₹{data?.paidPrice?.toFixed(1)}
                </span>
              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                <span className="VG3hNb">Date</span>
                <span className="mx-1">·</span>
                <span>{moment(`${data.eventDate}`).format('DD MMM YYYY')}</span>
                {data?.packageStartTime ?
                  <span className="font-semibold px-1">{moment(data?.packageStartTime?.toString(), "hh:mm A").format('hh:mm A')}</span>
                  : null}
              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span>{ getUserReadableStatus(data.stage)}</span>
              </div>
            </div>

            {/* NAME */}
            <div className="hidden lg:block  min-w-[220px] flex-[4] ">
              <div className="font-medium text-lg">{data.projectName}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {data.stage== "CancelledByUser"?"Cancelled":data.packageName}
              </div>
            </div>

            {/* TIME */}
            <div className="hidden lg:block min-w-[150px] flex-[4] whitespace-nowrap">
              <div className="font-medium text-lg"> {moment(`${data.eventDate}`).format('DD MMM YYYY')}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {(data?.type?.includes("Yacht")||data?.type == "SpeedBoat") ? 'Riding Hours' :
                    data?.type?.includes("Wheel") || data?.type?.includes("Vehicle")
                || data?.type?.includes("Cycle") || data?.type?.includes("Car") ? 'Number of Day(s)' :
                data.type?.includes("Event") ? 'Number of Items' : 'Number of Guests'}: {data?.nonGuestCount == 0 ? data.ticketDetails?.length : data.nonGuestCount}
              </div>
            </div>

            {/* TYPE */}
            <div className="hidden lg:block min-w-[150px] flex-[4] whitespace-nowrap">
              <div className="font-medium text-lg">
                {(data?.price * data?.ticketDetails?.length! ?? 1) != data?.paidPrice ? 
                <><span style={{ textDecorationLine: 'line-through', color:"red"}}>
                ₹{data?.price * data?.ticketDetails?.length! ?? 1}
                </span> &nbsp;</>
                : null}
                <span style={{color:"green"}}>
                ₹{data?.paidPrice?.toFixed(1)}
                </span>
              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {data.type}
              </div>
            </div>

            <div className="hidden lg:block min-w-[150px] flex-[4] whitespace-nowrap">
              <div className="font-medium text-lg">Status: {getUserReadableStatus(data.stage)}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};
export default BookingDetailsTable;


