import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";
import React from "react";
import ClientStore from "../../common/ClientStore";
import { useLocation } from "react-router-dom";

interface Item {
  label: string;
  value: string;
}

export default function SortByDropdown() {
  const sortHeaders = [
      { label: 'Featured and Nearby', value:'Featured' },
      { label: 'Near By', value:'Distance' },
      { label: 'Products Name(A-Z)', value:'Name' }
  ];
  const [isSorted, setIsSorted] = useState(false);
  const [sortingSelected, setSortingSelected] = useState<Item>(ClientStore.get('localstorage', 'productSortType') ?? "");
  const location = useLocation();

  useEffect(() => {
    let sortingSelected1 = ClientStore.get('localstorage', 'productSortType');
    if(sortingSelected1 != undefined && sortingSelected1 != null){
      setIsSorted(true);
    }
  }, []);

  const SetSortedProducts = (item: Item) => {
    ClientStore.set('localstorage', 'productSortType', item);
    ClientStore.set('localstorage', 'productSearchFocus', 1);
    setIsSorted(true);
    setSortingSelected(item);

    if(location.pathname == '/'){
      window.location.reload();
    }else{
      window.location.assign('#/');
    } 
    window.location.reload();
  }

  return (
    <div className="SortByDropdown border-l border-neutral-300">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm dark:border-neutral-700 focus:outline-none ${open ? "!border-primary-500 " : ""
                }`}
            >
              <span className="text-neutral-500">{isSorted == true ? sortingSelected?.label : "Sort Products"}</span>
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[140px] px-4 mt-4 right-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-7 bg-white dark:bg-neutral-800 p-7">
                    {sortHeaders.map((item, index) => (
                      <a
                        key={index}
                        onClick={() => {SetSortedProducts(item); close();}}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 bg-gray-100 dark:bg-neutral-700"
                      >
                        <p className="ml-2 text-sm font-medium ">{item.label}</p>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
