import React, { useEffect } from "react";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import MyRouter from "routers/index";
import TawkToWidget from './common/TawkToWidget';

Amplify.configure(awsExports);
function App() {
  useEffect(() => {
    // Create a meta tag for viewport settings
    const metaViewport = document.createElement('meta');
    metaViewport.name = 'viewport';
    metaViewport.content = 'width=device-width, initial-scale=1.0';
    document.head.appendChild(metaViewport);

    // Create a meta tag for your analytics
    const metaAnalytics = document.createElement('meta');
    metaAnalytics.name = 'analytics';
    metaAnalytics.content = '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=709471767219850&ev=PageView&noscript=1"/>';
    document.head.appendChild(metaAnalytics);

    const createInlineScript = (content: string) => {
      const scriptTag = document.createElement('script');
      scriptTag.textContent = content;
      document.body.appendChild(scriptTag);
      return scriptTag;
    };

    const inlineScriptContent = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '709471767219850');
    fbq('track', 'PageView');
  `;
  const inlineScript = createInlineScript(inlineScriptContent);
    // Cleanup function to remove the meta tags when the component unmounts
    return () => {
      document.head.removeChild(metaViewport);
      document.head.removeChild(metaAnalytics);
      document.body.removeChild(inlineScript);
    };
  }, []);

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">    
      <MyRouter />
    </div>
  );
}

export default App;
