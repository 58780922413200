import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { MultiSelect } from "react-multi-select-component";
import { Fragment, useEffect, useRef, useState } from "react";
import React from "react";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { fetchFilteredProducts } from "data/listings";
import ClientStore from "../../common/ClientStore";
import { useLocation, useNavigate } from "react-router-dom";

interface Item {
  label: string;
  value: string;
}

export default function CategoryFilterDropdown() {
  const rentalOptions = [
    { label: 'Vehicle', value:'Vehicle' },
    { label: 'Two Wheeler', value:'TwoWheeler' },
    { label: 'Four Wheeler', value:'FourWheeler' },
    { label: 'Geared Cycle', value:'GearedCycle' },
    { label: 'Vintage Cars', value:'VintageCars' },
    { label: 'Caravan', value:'Caravan' }    
  ]
  const yachtOptions = [
    { label: 'Yacht', value:'Yacht' },
    { label: 'Motor Yacht', value:'MotorYacht' },
    { label: 'Sailing Yacht', value:'SailingYacht' },
    { label: 'Displacement Yacht', value:'DisplacementYacht' },
    { label: 'SemiDisplacement Yacht', value:'SemiDisplacementYacht' },
    { label: 'Fishing Yacht', value:'FishingYacht' },
    { label: 'Hybrid Yacht', value:'HybridYacht' },
    { label: 'Classic Yacht', value:'ClassicYacht' },
    { label: 'Explorer Yacht', value:'ExplorerYacht' },
    { label: 'Speed Boat', value:'SpeedBoat' }
  ]
  const placestovisitOptions = [
    { label: 'Adventure Tourism', value:'AdventureTourism' },
    { label: 'Beach Areas', value:'BeachAreas' },
    { label: 'Cultural Tourism', value:'CulturalTourism' },
    { label: 'Dams', value:'Dams' },
    { label: 'Eco Friendly Tourism', value:'EcoFriendlyTourism' },
    { label: 'Forests', value:'Forests' },
    { label: 'Fun Tourism', value:'FunTourism' },
    { label: 'Historical Places', value:'HistoricalPlaces' },
    { label: 'Island', value:'Island' },
    { label: 'Medical Tourism', value:'MedicalTourism' },
    { label: 'Monuments', value:'Monuments' },
    { label: 'Mountain Areas', value:'MountainAreas' },
    { label: 'Nature', value:'Nature' },
    { label: 'Parks', value:'Parks' },
    { label: 'Temples', value:'Temples' },
    { label: 'View Points', value:'ViewPoints' },
    { label: 'Water Falls', value:'WaterFalls' },
    { label: 'Wildlife Tourism', value:'WildlifeTourism' }
  ]
  const ticketingOptions = [
    { label: 'Event', value:'Event' },
    { label: 'Casino', value:'Casino' },
    { label: 'Water Sport', value:'WaterSport' },
    { label: 'Sightseeing', value:'Sightseeing' },
    { label: 'Boating', value:'Boating' },
    { label: 'Crusing', value:'Crusing' },
    { label: 'Zoo', value:'Zoo' },
    { label: 'Museum', value:'Museum' },
    { label: 'Photo Shoots', value:'PhotoShoots' },
    { label: 'Night Clubs', value:'NightClubs' },
    { label: 'Eco Tourism', value:'EcoTourism' },
    { label: 'Dine', value:'Dine' },
    { label: 'Adventure', value:'Adventure' },
    { label: 'Amusement Park', value:'AmusementPark' },
    { label: 'LiveEvents', value:'LiveEvents' },
    { label: 'Concerts', value:'Concerts' },
    { label: 'Parties', value:'Parties' },
    { label: 'Offer Zone', value:'OfferZone' },
    { label: 'Vacation Packages', value:'VacationPackages' },
    { label: 'Pilgrimage', value:'Pilgrimage' },
    { label: 'Day Outing', value:'DayOuting' },
    { label: 'Camping', value:'Camping' },
    { label: 'Spa And Leisure', value:'SpaAndLeisure' },
    { label: 'Games', value:'Games' },
    { label: 'Picnic', value:'Picnic' }    
  ];
  const [ticketingSelected, setTicketingSelected] = useState<Item[]>([]);
  const [rentalSelected, setRentalSelected] = useState<Item[]>([]);
  const [yachtSelected, setYachtSelected] = useState<Item[]>([]);
  const [placestovisitSelected, setPlacestovisitSelected] = useState<Item[]>([]);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const location = useLocation();
  

  const [productFilterTypes, setProductFilterTypes] = useState(ClientStore.get('localstorage', 'productListFilter') ?? []);

  useEffect(() => {
    let ticketingselected = ticketingOptions.filter(x => productFilterTypes.includes(x.value))
    setTicketingSelected(ticketingselected);

    let rentalsselected = rentalOptions.filter(x => productFilterTypes.includes(x.value))
    setRentalSelected(rentalsselected);

    let yachtselected = yachtOptions.filter(x => productFilterTypes.includes(x.value))
    setYachtSelected(yachtselected);

    let placestovisitselected = placestovisitOptions.filter(x => productFilterTypes.includes(x.value))
    setPlacestovisitSelected(placestovisitselected);
    if(ticketingselected.length > 0 || rentalsselected.length > 0 || yachtselected.length > 0 || placestovisitselected.length > 0){
      setIsCategorySelected(true);
    }
  }, []);

  const apply = () => {
    SetFilteredProducts();
    ClientStore.set('localstorage', 'productSearchFocus', 1);
  }

  const clear = () => {
    setTicketingSelected([]);
    setRentalSelected([]);
    setYachtSelected([]);
    setPlacestovisitSelected([]);
    setIsCategorySelected(false);
    ClientStore.set('localstorage', 'productListFilter', []);
    ClientStore.set('localstorage', 'productSearchFocus', 1);
    
    window.location.reload();  
  }

  function SetFilteredProducts() {
    const productTypes1 = ticketingSelected.length > 0 ? ticketingSelected.map(item => item.value) : [];
    const productTypes2 = rentalSelected.length > 0 ? rentalSelected.map(item => item.value) : [];
    const productTypes3 = yachtSelected.length > 0 ? yachtSelected.map(item => item.value) : [];
    const productTypes4 = placestovisitSelected.length > 0 ? placestovisitSelected.map(item => item.value) : [];
    const productTypes = [...productTypes1, ...productTypes2, ...productTypes3, ...productTypes4];
    setProductFilterTypes(productTypes?.filter(item => item != null));
    ClientStore.set('localstorage', 'productListFilter', productTypes?.filter(item => item != null));
    ClientStore.set('localstorage', 'productSearchFocus', 1);
    setIsCategorySelected(true);

    if(productTypes.length === 0){
      setIsCategorySelected(false);
    }

    if(location.pathname == '/'){
      window.location.reload();
    }else{
      window.location.assign('#/');
    } 
    window.location.reload();
  }

  return (
    <div className="CategoryFilterDropdown">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm dark:border-neutral-700 focus:outline-none ${open ? "!border-primary-500 " : ""
                }`}
            >
              <span className="text-neutral-500">{isCategorySelected == true ? "Filtered" : "Select Category"}</span>
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-auto pb-3 rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={() => { clear(); close() }} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => { apply() ; close() }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                  <div className="pb-3">
                    <span className="block px-4 font-medium text-neutral-500 dark:text-neutral-200">
                      Ticketing
                    </span>
                    <div className="relative flex flex-col px-5 py-2 space-y-5">
                      <MultiSelect
                        options={ticketingOptions}
                        value={ticketingSelected}
                        onChange={setTicketingSelected}
                        labelledBy="Select"
                      />
                    </div>
                  </div>
                  <div className="pb-3">
                    <span className="block px-4 font-medium text-neutral-500 dark:text-neutral-200">
                    Rides (Self-driven Vehicles)
                    </span>
                    <div className="relative flex flex-col px-5 py-2 space-y-5">
                      <MultiSelect
                        options={rentalOptions}
                        value={rentalSelected}
                        onChange={setRentalSelected}
                        labelledBy="Select"
                      />
                    </div>
                  </div>
                  <div className="pb-3">
                    <span className="block px-4 font-medium text-neutral-500 dark:text-neutral-200">
                    Yacht
                    </span>
                    <div className="relative flex flex-col px-5 py-2 space-y-5">
                      <MultiSelect
                        options={yachtOptions}
                        value={yachtSelected}
                        onChange={setYachtSelected}
                        labelledBy="Select"
                      />
                    </div>
                  </div>
                  <div className="pb-3">
                    <span className="block px-4 font-medium text-neutral-500 dark:text-neutral-200">
                    Places to Visit
                    </span>
                    <div className="relative flex flex-col px-5 py-2 space-y-5">
                      <MultiSelect
                        options={placestovisitOptions}
                        value={placestovisitSelected}
                        onChange={setPlacestovisitSelected}
                        labelledBy="Select"
                      />
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
