import React, { FC, Fragment, useEffect, useState } from "react";
import CartDetailsTable from "./CartDetailsTable";
import axios from 'axios';
import constants from 'common/constants';
import SessionManager from 'common/SessionManager';
import { CartOrders, Coupon } from '../../data/types';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import RazorPay from "common/RazorPay";
import Input from 'shared/Input/Input';
import PayuMoney from "common/PayuMoney";
import ModalCoupons from "./ModalCoupons";
import ButtonDelete from 'shared/ButtonDelete/ButtonDelete';
import ClientStore from "../../common/ClientStore";

export interface CheckSummaryProps {
  className?: string;
}

const CheckSummary: FC<CheckSummaryProps> = ({ className = "" }) => {
  const [orders, setOrders] = useState<CartOrders>();
  const [paidPrice, setPaidPrice] = useState<any>();
  const [mrpPrice, setmrpPrice] = useState<any>();
  const [newCoupon, setnewCoupon] = useState<string>("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isValidOrders, setIsValidOrders] = useState(true);

  useEffect(() => {
    let packagejson = {
      new: true,
      stage: "New",
      userId: SessionManager.getUserId()
    }
    const url = constants.API_CONTEXT_URL + '/order/listOrdersWithQuote';
        axios.post(url, packagejson)
          .then((response) => {
            deleteEmptyOrders(response)
            axios.post(url, packagejson)
              .then((response) => {
                if(response.data?.response != null){
                  setOrders(response.data?.response);
                  ClientStore.set('localstorage', 'orders_count', response.data?.response?.orders?.length ?? 0);
                  setPaidPrice(response.data?.response?.quote?.paidPriceMap);
                  setmrpPrice(response.data?.response?.quote?.mrpPriceMap);
                  let isValid = !response.data?.response?.orders?.some((x:any) => x.invalidOrdersPresent == true);
                  setIsValidOrders(isValid);
                }
                else{
                  ClientStore.set('localstorage', 'orders_count', 0);
                }
              })
          })
      .catch((error) => {
      });

  }, []);

  function closeModal() {
    setIsOpenModal(false);
  }

  function openModal() {
    setIsOpenModal(true);
  }
  function removeCoupon() {
    setnewCoupon("");
    ApplyCoupon("1")
  }

  const deleteEmptyOrders = (response: any) => {
    response.data?.response?.orders?.forEach(function (value: any) {
      if (value.ticketDetails == null) {
        //console.log(value.orderId)
        //deleteEmptyOrders(value.orderId)
        const url = constants.API_CONTEXT_URL + '/order/deleteOrder?orderId=' + value?.orderId;
        axios.delete(url)
          .then((response) => {
            window.location.reload();
          })
      }
    });
  }

  function initiatePaymentValidation(e:any) {
    e.preventDefault();
    //validate if still orders are valid before payment initiation
    let packagejson = {
      new: true,
      stage: "New",
      userId: SessionManager.getUserId()
    }
    const url = constants.API_CONTEXT_URL + '/order/listOrdersWithQuote';
    axios.post(url, packagejson)
      .then((response) => {
        if (response.data?.response != null) {
          let isValid = !response.data?.response?.orders?.some((x: any) => x.invalidOrdersPresent == true);
          if(isValid != true){
            alert("Orders expired! Please review.");
            window.location.reload();
          }
          else{
            initiatePayment();
          }
        }
        else {
          ClientStore.set('localstorage', 'orders_count', 0);
        }
      });
  }

  function initiatePayment() {
    const initiateUrl = constants.API_CONTEXT_URL + '/order/initiatePaymentDetails';
    const finalizeUrl = constants.API_CONTEXT_URL + '/order/submitPaymentDetails';
    SessionManager.getJWTToken((jwtToken: string) => {
      const config = {
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + jwtToken,
        },
      };
      axios.post(initiateUrl, orders?.quote, config)
        .then((response) => {
          if (orders != null) {
            orders.quote.payment = response.data.response.payment;
          }

          let initiateResponse = JSON.parse(response.data.response.payment.providerInitiationResponse);

          PayuMoney.handlePayment(constants.COMPANY_NAME,
            initiateResponse,
            (data: any, status:string) => {
              if (orders != null) {
                orders.quote.payment.paymentConfirmationResponse = JSON.stringify(data);
                orders.quote.stage = status == "SUCCESS" ? "PaymentConfirmed" : status == "FAILED" ? "PaymentFailure" : status == "CANCEL" ? "New" : "PaymentProcessing"
              }

              axios.post(finalizeUrl, orders?.quote, config)
                .then((finalizeResponse) => {
                  if (finalizeResponse.data.response.success || finalizeResponse.data.response.stage == "PaymentConfirmed") {
                    alert("Payment Successfull.")
                    let currentCartCount = ClientStore.get('localstorage', 'orders_count') ?? 0;
                    ClientStore.set('localstorage', 'orders_count', currentCartCount == 0 ? 0 : currentCartCount - 1);
                    window.location.href = "#/MyBookings";
                  }
                  else {
                    alert("Payment failure: " + finalizeResponse.data.response.errorMessage);
                    let currentCartCount = ClientStore.get('localstorage', 'orders_count') ?? 0;
                    ClientStore.set('localstorage', 'orders_count', currentCartCount == 0 ? 0 : currentCartCount - 1);
                    window.location.href = "#/MyBookings";
                  }
                }
                )
            })

        })
        .catch((error) => {
          alert(error);
        });
    });
  }

  function ApplyCoupon(val:any) {
    let packagejson = {
      new: true,
      stage: "New",
      couponCode:val == "0" ? newCoupon : val == "1" ? null : val,
      userId: SessionManager.getUserId()
    }
    if(val != "0" && val != "1"){
      setnewCoupon(val);
    }
    
    const url = constants.API_CONTEXT_URL + '/order/listOrdersWithQuote';
    axios.post(url, packagejson)
      .then((response) => {
        setOrders(response.data?.response);
        setPaidPrice(response.data?.response?.quote?.paidPriceMap);
        setmrpPrice(response.data?.response?.quote?.mrpPriceMap);
      })
      .catch((error) => {
        alert(error);
      });
  }

  const handleCouponChange = (e: any) => {
    setnewCoupon(e?.target?.value?.toUpperCase());
  }

  const renderModal = () => {
    return (
      <ModalCoupons isOpenModal={isOpenModal} closeModal={closeModal} openModal={openModal}
      ApplyCoupon={ApplyCoupon} subTotal={orders?.quote?.subTotal} orders={orders?.orders!}/>
    );
  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl text-red-600 font-semibold">Summary</h3>
          {orders && orders?.orders?.length! > 0 ?
            orders?.orders?.map((item) => (
              <div className="flex justify-between text-xs text-neutral-6000 dark:text-neutral-300">
                <span key={item.id}>{item.packageName}</span>
                {paidPrice != undefined ? 
                <span>₹{mrpPrice[item.orderId]?.toFixed(1)} </span>
                : null}
              </div>
            ))
            : null
          }
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Discount</span>
            <span>₹{parseFloat(orders?.quote?.discount! ?? 0)?.toFixed(1)}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span><Input
              onChange={(e: any) => handleCouponChange(e)}
              placeholder="Add Coupon" className="pe-3" value={newCoupon}></Input>
            </span>
            <ButtonPrimary disabled={newCoupon==""} onClick={() => ApplyCoupon("0")}>Apply</ButtonPrimary>
          </div>
          <a href="javascript:void();" onClick={() => openModal()} className="text-xs text-red-600 font-semibold">Available Coupons</a>
          {orders != null && orders?.quote?.errorMessage != "" ?
            <div className="text-red-400">
              Error: {orders?.quote?.errorMessage}
            </div>
            : <>{orders?.quote?.promoCode != null ?
              <>
                <div className="flex items-center justify-between">
                  <div className="text-green-400 pt-1">
                    Coupon added: {orders?.quote?.promoCode}
                  </div>
                  <ButtonDelete className="text-red-400" onClick={()=>removeCoupon()}/>
                </div>
              </>
              : null}</>}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>₹{parseFloat(orders?.quote?.totalToBePaid! ?? 0)?.toFixed(1)}</span>
          </div>
        </div>
        <ButtonPrimary disabled={!isValidOrders}>
          {!isValidOrders ? "Please check Orders" :
            <span className="ml-3" onClick={(event) => orders?.quote?.totalToBePaid == "0" ? "" : initiatePaymentValidation(event)}>Pay Now</span>
          }
        </ButtonPrimary>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your cart details</h3>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            {orders && orders?.orders?.length! > 0 ?
              orders?.orders?.map((item) => (
                <CartDetailsTable key={item.id} className="pb-15 lg:pb-15" data={item} mrpPriceMap={orders?.quote?.mrpPriceMap} />
              ))
              : null
            }
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="lg:block flex-grow">{renderSidebar()}</div>
        {isOpenModal == true ?
        renderModal()
        : null}
      </main>
    </div>
  );
};

export default CheckSummary;
