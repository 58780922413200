import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  LifebuoyIcon,
  UsersIcon,
  BriefcaseIcon,
  LightBulbIcon,
  QrCodeIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import SessionManager from "common/SessionManager";
import React from "react";

const logoutSession = () => {
  SessionManager.logout()
}
const solutions = [
  {
    name: "Account",
    href: "/account",
    icon: UserCircleIcon,
  },
  {
    name: "Messages",
    href: "##",
    icon: ChatBubbleBottomCenterTextIcon,
  },
  {
    name: "Wishlists",
    href: "/saved-list",
    icon: HeartIcon,
  },
  {
    name: "Booking",
    href: "/MyBookings",
    icon: HomeIcon,
  },
  {
    name: "Scan QR code",
    href: "/qrCodeScanner",
    icon: QrCodeIcon,
    type:"adminControl"    
  },
];

const solutionsFoot = [
  {
    name: "About Us",
    href: "#/about",
    icon: UsersIcon,
  },
  {
    name: "Help & Support",
    href: "#/contact",
    icon: LightBulbIcon,
  },
  {
    name: "Partner with Us",
    href: "#/collaborate",
    icon: BriefcaseIcon,
  },
  {
    name: "Logout",
    href: "##",
    onclick:logoutSession,
    icon: ArrowRightOnRectangleIcon,
  },
];

export default function AvatarDropdown() {

  const [user, setUser] = useState(SessionManager.getUser())
  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" imgUrl={user.profileImage} userName={user.firstName}/>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative  grid bg-white dark:bg-neutral-800 px-7 pt-5">
                    Welcome {user?.firstName} !!
                  </div>
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7 pt-5">
                    {solutions.map((item, index) => (
                      item.type=="adminControl"?
                      (SessionManager.getRole() == "SanchariAdmin" || SessionManager.getRole() == "Vendor" || SessionManager.getRole() == "VendorEmployee" || SessionManager.getRole() == "SanchariAdminEmployee")?
                      <Link
                        key={index}
                        to={item.href}
                        onClick={() => {close();}}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </Link> : null
                      :<Link
                        key={index}
                        to={item.href}
                        onClick={() => {close();}}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutionsFoot.map((item, index) => (
                      <a
                        key={index}
                        href={item.href}
                        onClick={() => {
                          if (typeof item.onclick === 'function') {
                            item.onclick(); // Call the onclick function if it exists
                          }
                          close(); // Call the close function
                        }}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
