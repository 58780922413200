import React, { Component, ReactDOM, useState } from 'react';
const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      <div
      dangerouslySetInnerHTML={{__html: isReadMore ? text.slice(0, 150) : text}}
      />
      
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? <div className='text-primary-700 cursor-pointer'>...read more</div> 
        : <div className='text-primary-700 cursor-pointer'>Show less</div>}
      </span>
    </p>
  );
};
  
export default ReadMore;