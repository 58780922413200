import StartRating from "components/StartRating/StartRating";
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import constants from 'common/constants';
import SessionManager from './../../common/SessionManager';
import { GeneralPackage, PackageOrder, ProductDataType, ProductDetailsDataType, ticketDetails } from "../../data/types";
import Badge from 'shared/Badge/Badge';
import  moment  from 'moment';
import FormItem from "../PageAddListing1/FormItem";
import Input  from 'shared/Input/Input';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export interface OrderDetailsProps {
  className?: string;
}

const OrderDetails: FC<OrderDetailsProps> = ({ className = "" }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderData, setorderData] = useState<PackageOrder>()
  const [ticketDetails, setticketDetails] = useState<ticketDetails[]>();
  const [product, setProduct] = useState<ProductDataType>();
  const [productDetails, setProductDetails] = useState<ProductDetailsDataType>();
  const [selectedPackage, setselectedPackage] = useState<GeneralPackage>()
  const [eventDate, seteventDate] = useState("");
  const [newTicketDetail, setnewTicketDetail] = useState<ticketDetails>();
  const [errormessage, seterrormessage] = useState<string>("");
  const [errorsavemessage, seterrorsavemessage] = useState<string>("");
  const [userRole, setuserRole] = useState<any>(SessionManager.getRole());
  const [user, setUser] = useState(SessionManager.getUser());

  useEffect(() => {
    let orderIdFromParams = searchParams.get("OrderId");
		let requestOptions:any = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    };
    axios.get(constants.API_CONTEXT_URL + '/order/viewOrder?orderId=' + orderIdFromParams, requestOptions)
			.then((response) => {
				setorderData(response.data.response);
        setticketDetails(response.data?.response?.details?.ticketDetails)
        if (response.data?.response?.details?.ticketDetails == null
          || response.data?.response?.details?.ticketDetails?.length == 0) {
          let newmemberticket:ticketDetails = {
            age: calculate_age(user?.dateOfBirth) ?? "",
            firstName:user?.firstName ?? "",
            lastName: user?.lastName ?? "",
            phoneNumber: user?.phoneNumber ?? "",
            pricePerUnit:response.data?.response?.details?.price ?? "",
            serialNumber: "",
          };
          setnewTicketDetail(newmemberticket);
        }
        getProductDetails(response.data?.response?.productId,response.data?.response?.packageId)
        let s = moment(response.data.response?.details?.eventDate, 'YYYY-MM-DD')
        seteventDate(s.format('DD MMM YYYY').toString());
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

  function getProductDetails(productId: string, packageId:string) {
    SessionManager.getJWTToken((jwtToken:string) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken,
      },
    };
    fetch(constants.API_CONTEXT_URL + '/product/seller/viewProduct?productId=' + productId, requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setProduct(data.response)
        setProductDetails(data.response)
        let package1:GeneralPackage[] = data.response?.packages
        package1.map(item => {
          if(item.id === packageId){
            setselectedPackage(item);
          }
        });
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    });
  }

  const calculate_age = (dob1: any) => {
    var today = new Date();
    var birthDate = new Date(dob1);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log(age_now);
    return age_now.toString();
  }

  const handleAgeChange = (e:any) => {
    seterrormessage("");
			let newmemberticket:ticketDetails = {
        age: e.target.value,
        firstName:newTicketDetail?.firstName ?? "",
        lastName: newTicketDetail?.lastName ?? "",
        phoneNumber: newTicketDetail?.phoneNumber ?? "",
        pricePerUnit:newTicketDetail?.pricePerUnit ?? "",
        serialNumber: newTicketDetail?.serialNumber ?? "",
      };
			setnewTicketDetail(newmemberticket);
	}
  // const editAgeChange = (e:any,index:number) => {
  //   if(newTicketDetail?.age.match('^[0-9]*$') === null){
  //     seterrormessage("Age not valid");
  //     return;
  //   }
  //   let orderDataUpdated:PackageOrder|undefined = orderData;
  //   orderDataUpdated?.details?.ticketDetails?[index]?.age = 2
  //   setorderData(orderDataUpdated);
  // }
  const handlePhoneChange = (e:any) => {
      seterrormessage("");
			let newmemberticket:ticketDetails = {
        age: newTicketDetail?.age ?? "",
        firstName:newTicketDetail?.firstName ?? "",
        lastName: newTicketDetail?.lastName ?? "",
        phoneNumber: e,
        pricePerUnit:newTicketDetail?.pricePerUnit ?? "",
        serialNumber: newTicketDetail?.serialNumber ?? "",
      };
			setnewTicketDetail(newmemberticket);
	}
  const handleNameChange = (e:any) => {
    seterrormessage("");
    let newmemberticket:ticketDetails = {
      age: newTicketDetail?.age ?? "",
      firstName: e.target.value,
      lastName: newTicketDetail?.lastName ?? "",
      phoneNumber:newTicketDetail?.phoneNumber ?? "",
      pricePerUnit:newTicketDetail?.pricePerUnit ?? "",
      serialNumber: newTicketDetail?.serialNumber ?? "",
    };
    setnewTicketDetail(newmemberticket);
}
const handleLastNameChange = (e:any) => {
  seterrormessage("");
  let newmemberticket:ticketDetails = {
    age: newTicketDetail?.age ?? "",
    firstName: newTicketDetail?.firstName ?? "",
    lastName: e.target.value,
    phoneNumber:newTicketDetail?.phoneNumber ?? "",
    pricePerUnit:newTicketDetail?.pricePerUnit ?? "",
    serialNumber: newTicketDetail?.serialNumber ?? "",
  };
  setnewTicketDetail(newmemberticket);
}
const handleBlockRemove = (index:number) => {

  let orderDataUpdated:PackageOrder|undefined = orderData;
  orderDataUpdated?.details?.ticketDetails?.splice(index,1);
  setorderData(orderDataUpdated);
  setticketDetails(orderDataUpdated?.details?.ticketDetails!)
  saveOrder(true);
  }

const saveOrder = (isDeleted:boolean) => {
  const url = constants.API_CONTEXT_URL + '/order/saveOrder';
  seterrorsavemessage("");
  axios.post(url, orderData)
    .then((response) => {
      let orderobj: PackageOrder = response.data.response;
      setorderData(orderobj);
      setticketDetails(orderobj?.details?.ticketDetails!)
      if(!isDeleted){
        window.location.assign("#/CheckSummary");
      }
      
    })
    .catch((error) => {
      seterrorsavemessage("Cannot Save Order Details. Please try again");
    });	
}
const updateOrder=() => {
  //Validation
  seterrormessage("");
  seterrorsavemessage("");
  if(newTicketDetail?.phoneNumber.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) === null){
    seterrormessage("Phone number not valid");
    return;
  }
  if(newTicketDetail?.age.match('^[0-9]*$') === null){
    seterrormessage("Age not valid");
    return;
  }
  if(newTicketDetail?.firstName.match('^[a-zA-Z ]*$') === null){
    seterrormessage("Name not valid");
    return;
  }
  let allTickets = orderData?.details.ticketDetails;
  if(allTickets == null){
    allTickets = [newTicketDetail!]
  }
  else allTickets?.push(newTicketDetail!);
  let updatedOrder:PackageOrder={
    details:{
      comments:orderData?.details.comments!,
      couponCode:orderData?.details.couponCode!,
      eventDate:orderData?.details.eventDate!,
      extras:orderData?.details.extras!,
      id:orderData?.details.id!,
      new:orderData?.details.new!,
      orderId:orderData?.details.orderId!,
      paymentDetails:orderData?.details.paymentDetails!,
      price:orderData?.details.price!,
      ticketDetails:allTickets,
      nonGuestCount:orderData?.details.nonGuestCount??0,
      guestListRequired: orderData?.details?.guestListRequired!
    },
    id:orderData?.id!,
    packageId:orderData?.packageId!,
    productId:orderData?.productId!,
    stage:orderData?.stage!,
    new:orderData?.new!,
    type:orderData?.type!,
    userId:orderData?.userId!
  }
  setorderData(updatedOrder);
  setticketDetails(updatedOrder?.details?.ticketDetails!)
  let newmemberticket:ticketDetails = {
    age: "",
    firstName:"",
    lastName: "",
    phoneNumber: "",
    pricePerUnit:"",
    serialNumber: "",
  };
  setnewTicketDetail(newmemberticket);
}

const CheckIfBufferTimeElapsed = () => {
  let orderIdFromParams = searchParams.get("OrderId");
  SessionManager.getJWTToken((jwtToken:any) => {
    const url = constants.API_CONTEXT_URL + `/order/updateStatus?comment=SalesConfirmed&orderId=${orderIdFromParams}&orderStage=NoShow`;
    axios({
      method: 'put',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken,
      }
    }).then((response) => {
          alert("Success: Order updated to No Show");
          window.location.reload()
        });
      });
}

const UpdateStatus=() =>{
  let orderIdFromParams = searchParams.get("OrderId");
  if(!moment().isSame(orderData?.details?.eventDate, 'day')){
    alert("Date not proper for approving!")
    return;
  }

  let startTime = new Date();
  let hrs = selectedPackage?.packageStartTime?.split(':')[0] || "0";
  let mins = selectedPackage?.packageStartTime?.split(':')[1] || "0";
  startTime.setHours(parseInt(hrs));
  startTime.setMinutes(parseInt(mins));
  startTime.setSeconds(0);

  let validTillTime = new Date(startTime);
  let hrsex = selectedPackage?.packageValidTime?.split(':')[0] || "0";
  let minsex = selectedPackage?.packageValidTime?.split(':')[1] || "0";
  validTillTime.setHours(validTillTime.getHours() + parseInt(hrsex)); // add hours
  validTillTime.setMinutes(validTillTime.getMinutes() + parseInt(minsex)); // add minutes
  validTillTime.setSeconds(0);

  let dateNow = new Date();

  if(dateNow.getTime() >= validTillTime.getTime()){
    alert("Time limit exceeded for approving!")
    return;
  }
  // allow to approve only 1hr before package start time
  let validStartTime = new Date(startTime);
  validStartTime.setHours(validStartTime.getHours() - parseInt("1")); // subtract 1 hour
  if(dateNow.getTime() < validStartTime.getTime()){
    alert("Activity yet to start!")
    return;
  }
  SessionManager.getJWTToken((jwtToken:any) => {
    const url = constants.API_CONTEXT_URL + `/order/updateStatus?comment=SalesConfirmed&orderId=${orderIdFromParams}&orderStage=ReadyForSales`;
    axios({
      method: 'put',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken,
      }
    }).then((response) => {
      alert("Success: Order updated to Sales Confirmed");
      window.location.reload()
    })
    .catch((error) => {
      alert("Error: You do not have required permissions.");
    });
  });
}
const deleteOrder=(orderId:string) =>{
  SessionManager.getJWTToken((jwtToken:any) => {

    axios.delete(constants.API_CONTEXT_URL + `/order/deleteOrder?orderId=${orderId}`, {
      headers: {
        Authorization: 'Bearer ' + jwtToken
      }
    }).then((response) => {
      window.location.assign("/product?productId=" + product?.id);
    });
  });
}

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm Your Booking ➡️
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Your booking with {selectedPackage?.title}</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={productDetails?.mediaAccessList[0]?.url} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {productDetails?.businessDetails?.firstLine} {productDetails?.businessDetails?.secondLine}, {productDetails?.businessDetails?.stateCode}, {productDetails?.businessDetails?.countryCode} - {productDetails?.businessDetails?.pinCode}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  <a href={`#/product?productId=${product?.id}&name=${product?.title}`}>
                  {product?.title} 
                  </a>
                  &nbsp;<Badge name={product?.type} color="green" />
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                Total Price: {orderData?.details?.price}
              </span>
              {/* <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              Order# {orderData?.id} */}
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg text-neutral-400">
                  {eventDate}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">
                <span className="mt-1.5 text-lg font-semibold">{orderData?.details?.guestListRequired == true ? orderData?.details?.ticketDetails?.length : orderData?.details?.nonGuestCount}  Guests</span>
                </span>
              </div>
              <div className="flex flex-col">
              
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Booking detail</h3>          
          <div className="flow-root">
            {(userRole == "SanchariAdmin" || userRole == "Vendor" || userRole == "VendorEmployee" || userRole == "SanchariAdminEmployee") ? 
              <>
                {ticketDetails?.length! > 0 ?
                  ticketDetails?.map((item, idx) => (
                    <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                      <div className="grid pb-2 grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
                        <FormItem >
                          <Input defaultValue={item?.firstName} disabled />
                        </FormItem>
                        <FormItem >
                          <Input defaultValue={item?.lastName} disabled />
                        </FormItem>
                        <FormItem>
                          <Input defaultValue={item?.age} disabled />
                        </FormItem>
                        <FormItem>
                          <Input defaultValue={item?.phoneNumber} disabled/>
                        </FormItem>
                      </div>
                    </div>
                  ))
                  : null}
                  <span>
                    Status: {orderData?.stage}
                  </span>
              </>
             :
              <>
                {ticketDetails?.length! > 0 ?
                  ticketDetails?.map((item, idx) => (
                    <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                      <div className="grid pb-2 grid-cols-1 md:grid-cols-4 gap-8 md:gap-5">
                        <FormItem >
                          <Input defaultValue={item?.firstName} placeholder="First Name" />
                        </FormItem>
                        <FormItem >
                          <Input defaultValue={item?.lastName} placeholder="Last Name" />
                        </FormItem>
                        <FormItem>
                          <Input defaultValue={item?.age}
                            //onChange={(e) => editAgeChange(e,idx)}
                            maxLength={2} placeholder="Age" />
                        </FormItem>
                        <FormItem>
                          <Input defaultValue={item?.phoneNumber}
                            placeholder="Phone" />
                        </FormItem>
                      </div>
                      <i onClick={() => handleBlockRemove(idx)} className="text-3xl pt-3 text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"></i>
                    </div>
                  ))
                  :
                  <span className="block pt-2 text-sm text-red-500 dark:text-neutral-400"> No Guest Added. Please add below!
                  </span>}
                <div className="flex pt-4 flex-col pb-5 sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-5">
                    <FormItem >
                      <Input value={newTicketDetail?.firstName}
                        onChange={(e: any) => handleNameChange(e)}
                        placeholder="First Name" />
                    </FormItem>
                    <FormItem >
                      <Input value={newTicketDetail?.lastName}
                        onChange={(e: any) => handleLastNameChange(e)}
                        placeholder="Last Name" />
                    </FormItem>
                    <FormItem>
                      <Input value={newTicketDetail?.age}
                        onChange={(e: any) => handleAgeChange(e)}
                        maxLength={2} placeholder="Age" />
                    </FormItem>
                    <FormItem>
                      {/* <Input value={newTicketDetail?.phoneNumber}
                        onChange={(e: any) => handlePhoneChange(e)}
                        placeholder="Phone" /> */}
                        <PhoneInput
                            className="h-11"
                            style={{ "--PhoneInputCountryFlag-height": "1rem", "width": "100%" }}
                            international
                            defaultCountry="IN"
                            value={newTicketDetail?.phoneNumber}
                            onChange={(e: any) => handlePhoneChange(e)}
                            placeholder="Phone"
                            required />
                    </FormItem>
                  </div>
                  <ButtonPrimary onClick={() => updateOrder()}
                    disabled={errormessage != "" || newTicketDetail?.age == ""
                      || newTicketDetail?.firstName == "" || newTicketDetail?.phoneNumber == ""
                      || newTicketDetail == null}
                    className="flex-shrink-0">
                    <i className="text-xl las la-plus" style={{marginLeft:"-9px",marginRight:"-9px"}}></i>
                  </ButtonPrimary>
                </div>
                {errormessage != "" ?
                  <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                    <span className="block pt-2 text-sm text-red-500 dark:text-neutral-400"> {errormessage}</span>
                  </div>
                  : null}
              </>}

            <div className="flex pt-5 float-right flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
              {(userRole == "SanchariAdmin" || userRole == "Vendor" || userRole == "VendorEmployee" || userRole == "SanchariAdminEmployee") ?
                <ButtonPrimary onClick={() => UpdateStatus()}
                  disabled ={!(orderData?.stage=="PaymentConfirmed")}
                  className="flex-shrink-0">
                  <span>Approve</span>
                </ButtonPrimary>
                :
                <ButtonPrimary onClick={() => saveOrder(false)}
                  disabled={errormessage != "" || newTicketDetail == undefined ||
                    ticketDetails?.length! == 0}
                  className="flex-shrink-0">
                  <span>Save and Proceed</span>
                </ButtonPrimary>
              }
            </div>
            {errorsavemessage != "" ?
              <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                <span className="block pt-2 text-sm text-red-500 dark:text-neutral-400"> {errorsavemessage}</span>
              </div>
              : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
}

export default OrderDetails;
