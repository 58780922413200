import React, { useState } from "react";
import { FC } from "react";
import { useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import { useRef } from "react";
import { PathName } from "routers/types";
import ButtonSubmit from "./ButtonSubmit";
import { mainSearchDTO } from "data/types";
import { Link, useLocation } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import CategoryFilterDropdown from "../Header/CategoryFilterDropdown";
import SortByDropdown from "../Header/SortByDropdown";
import ClientStore from "../../common/ClientStore";


export interface LocationInputProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  onInputDone?: (value: mainSearchDTO) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  buttonSubmitHref?: PathName;
  hasButtonSubmit?: boolean;
  recentSearch: mainSearchDTO[];
  searchResult: mainSearchDTO[];
  isMobile: boolean;
}

const AllSearchInputNavBar: FC<LocationInputProps> = ({
  defaultValue,
  autoFocus = false,
  onChange,
  onInputDone,
  placeHolder = "Search here..",
  desc = "",
  className = "nc-flex-1.5",
  buttonSubmitHref = "#/",
  hasButtonSubmit = true,
  searchResult = [],
  isMobile
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<mainSearchDTO>();
  const [showPopover, setShowPopover] = useState(autoFocus);
  const location = useLocation();

  useEffect(() => {
    setValue(undefined);
  }, [defaultValue]);


  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const handleSearchSubmit = () => {
    ClientStore.set('localstorage', 'productSearchFilter', inputRef.current!.value);
    ClientStore.set('localstorage', 'productSearchFocus', 1);
    if(location.pathname == '/'){
      window.location.reload();
    }else{
      window.location.assign('#/');
    } 
    window.location.reload();
  }

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: mainSearchDTO) => {
    setValue(item);
    onInputDone && onInputDone(item);
    setShowPopover(false);
  };

  const renderSearchValue = () => {
    return (
      <>
        {searchResult.map((item) => (
          <span
            onClick={() =>handleSelectLocation(item)}
            key={item.id + Math.random()}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 sm:h-6 sm:w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </span>
            <div>
              <span className="block font-medium text-neutral-700">
                {item.display}
              </span>
              <span className="block font-small text-primary-700">
                {item.valueSubType == "PlacesToVisit" ? "Places To Visit" : item.valueSubType}
              </span>
            </div>
          </span>
        ))}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        className={`flex flex-1 relative ${className} items-center cursor-pointer focus:outline-none text-left  ${
          showPopover ? "" : ""
        }`}
      >
        <div className={`flex ${className}`}>
          {isMobile == false ?
          <><CategoryFilterDropdown />
          <SortByDropdown /></>
          : null }
          <input
            className={`block text-neutral-500 border-neutral-300 bg-transparent px-3 focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-sm placeholder-neutral-400 dark:placeholder-neutral-200 ${className} truncate ${isMobile == false ? "border-l": ""}`}
            placeholder={placeHolder}
            value={value?.display}
            autoFocus={showPopover}
            onClick={() => setShowPopover(true)}
            onChange={(e) => {
              onChange && onChange(e.currentTarget.value);
            }}
            ref={inputRef}
          />
        </div>
         {/* BUTTON SUBMIT OF FORM */}
         {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
            <div
             onClick={() => handleSearchSubmit()}
              className="h-5 md:h-5 w-full md:w-5 rounded-full flex items-center justify-center text-neutral-50 focus:outline-none"
            >
              <span className="mr-3 md:hidden">Search</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 20 24"
                stroke="grey"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
              </div>
            )}
      </div>
      {showPopover && (
        <div className="absolute left-0 z-40 w-full bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {/* {value ? renderSearchValue() : renderRecentSearches()} */}
          {renderSearchValue()}
          
        </div>
      )}
    </div>
  );
};

export default AllSearchInputNavBar;
