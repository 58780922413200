import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import GuestsInput, { GuestsInputProps } from "./GuestsInput";
import { FocusedInputShape } from "react-dates";
import StayDatesRangeInput from "./StayDatesRangeInput";
import moment from "moment";
import { FC } from "react";
import AllSearchInput from "./AllSearchInput";
import { mainSearchDTO, ProductDataType } from "data/types";
import constants from "common/constants";
import { SearchService } from "common/util";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface SanchariSearchFormProps {
  haveDefaultValue?: boolean;
  PageType?: string;
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = "Tokyo, Jappan";
const defaultDateRange = {
  startDate: moment(),
  endDate: moment().add(4, "days"),
};
const defaultGuestValue: GuestsInputProps["defaultValue"] = {
  guestAdults: 2,
  guestChildren: 2,
  guestInfants: 1,
};

const AllSearchForm: FC<SanchariSearchFormProps> = ({
  haveDefaultValue = false,
  PageType=""
}) => {
  const [locationInputValue, setLocationInputValue] = useState("");
  const [recentSearch, setRecentSearch] = useState<mainSearchDTO[]>([]);
  const [searchResult, setSearchResult] = useState<mainSearchDTO[]>([]);
  const [rentals, setRentals] = useState(["Vehicle", "TwoWheeler",  "FourWheeler", "GearedCycle", "VintageCars"]);
  const [yachts, setyachts] = useState(["Yacht", "MotorYacht",
  "SailingYacht", "DisplacementYacht", "SemiDisplacementYacht",
  "FishingYacht", "HybridYacht", "ClassicYacht", "ExplorerYacht", "SpeedBoat"]);
  const [thingsToDo, setthingsToDo] = useState(["Event", "Casino", "WaterSport", "Zoo", "Museum", "Sightseeing",
  "Boating", "Crusing", "PhotoShoots", "NightClubs",
  "EcoTourism", "Dine", "Adventure", "AmusementPark"]);
  
  useEffect(() => {
    setSearchResult([]);
  }, [PageType]);

  function searchText(e: React.SetStateAction<string>) {
    setLocationInputValue(e);

    fetch(constants.API_CONTEXT_URL + '/suggestion/mainSearch?search=' + e)
				.then(async response => {
					const isJson = response.headers.get('content-type')?.includes('application/json');
					const data = isJson && await response.json();
	
					// check for error response
					if (!response.ok) {
						// get error message from body or default to response status
						const error = (data && data.message) || response.status;
						return Promise.reject(error);
					}
          // setRecentSearch(data.response);
          if(PageType == "All"){
            setSearchResult(data.response);
          }
          else if(PageType == "Ticketing"){
            setSearchResult(data.response?.filter((x:mainSearchDTO) => thingsToDo.includes(x.valueSubType)));
          }
          else if(PageType == "Rentals"){
            setSearchResult(data.response?.filter((x:mainSearchDTO) => rentals.includes(x.valueSubType)));
          }
          else if(PageType == "Yachts"){
            setSearchResult(data.response?.filter((x:mainSearchDTO) => yachts.includes(x.valueSubType)));
          }
          else if(PageType == "PlacesToVisit"){
            setSearchResult(data.response?.filter((x:mainSearchDTO) => x.valueSubType.includes("PlacesToVisit")));
          }
				})
				.catch(error => {
				
				});
  }

  function onInputDone(item : mainSearchDTO){
    SearchService.route(item);
  }

  //
  useEffect(() => {
    if (haveDefaultValue) {
      setLocationInputValue(defaultLocationValue);
    }
  }, []);
  //

  const renderForm = () => {
    return (
      <form className="w-full p-2 relative mt-0.5 flex rounded-2xl shadow-2xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        {searchResult ?
            <AllSearchInput
            defaultValue={locationInputValue}
            onChange={(e) => searchText(e)}
            className="flex-[1.5]"
            recentSearch={recentSearch}
            searchResult={searchResult}
            onInputDone={onInputDone}
          />
          : <></>
        }
        
      </form>
    );
  };

  return renderForm();
};

export default AllSearchForm;
