import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsList1Props {
  className?: string;
}

const socials: SocialType[] = [
  { name: "Facebook", icon: "lab la-facebook-square text-red-600", href: "https://www.facebook.com/Esanchari-100539619420373" },
  { name: "Twitter", icon: "lab la-twitter text-red-600", href: "https://twitter.com/esanchariindia?s=11&t=0mb1o_mPGFzizxSfK10ICQ" },
  { name: "Youtube", icon: "lab la-youtube text-red-600", href: "https://www.youtube.com/@esanchari1126" },
  { name: "Instagram", icon: "lab la-instagram text-red-600", href: "https://instagram.com/esanchariindia?igshid=YmMyMTA2M2Y=" },
];

function openlinktabs(val: any) {
  window.open(val, '_blank', 'noopener,noreferrer');
}

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={"javascript:void();"}
        onClick={() => openlinktabs(item.href)}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <i className={item.icon}></i>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
