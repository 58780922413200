import React, { FC, useEffect, Fragment, useMemo, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Dialog, Transition } from "@headlessui/react";
import { CartOrders, Coupon, Order } from "../../data/types";
import constants from 'common/constants';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import moment from "moment";
import Badge from 'shared/Badge/Badge';

export interface ModalCouponsProps {
  openModal: () => void;
  closeModal: () => void;
  isOpenModal: boolean;
  ApplyCoupon: (val:any) => void;
  subTotal:any;
  orders:Order[];
}

const ModalCoupons: FC<ModalCouponsProps> = ({
  openModal,
  closeModal,
  isOpenModal,
  ApplyCoupon,
  subTotal,
  orders
}) => {

  const [couponList, setCouponList] = useState<Coupon[]>([]);

  useEffect(() => {
    LoadAvailableCoupons();
  }, []);

  const LoadAvailableCoupons = () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    fetch(constants.API_CONTEXT_URL + '/coupon/listAvailableCoupons', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        let couponList:Coupon[] = data.response?.filter((x:Coupon) => x.type == "All");
        let packageIds = orders?.map((x:Order) => x.packageId);
        let productTypes = orders?.map((x:Order) => x.type);
        let productIds = orders?.map((x:Order) => x.productId);
        let companyIds = orders?.map((x:Order) => x.companyId);
        data.response?.forEach((coupon:Coupon) => {
          if(coupon.type == "Package" && packageIds?.some(v => v === coupon.assetId)){
            couponList.push(coupon);
          }
          if(coupon.type == "Category" && productTypes?.some(v => v === coupon.assetId)){
            couponList.push(coupon);
          }
          if(coupon.type == "Company" && companyIds?.some(v => v === coupon.assetId)){
            couponList.push(coupon);
          }
          if(coupon.type == "Product" && productIds?.some(v => v === coupon.assetId)){
            couponList.push(coupon);
          }
        });
        
        setCouponList(couponList);
      })
      .catch(error => {
        console.error('There was an error loading available coupons: ', error);
      });
  }

  const selectedCoupon = (val:any) => {
    ApplyCoupon(val);
    closeModal();
  }

  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 h-screen w-full max-w-4xl">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h2
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="headlessui-dialog-title-70"
                  >
                    Select Coupon
                  </h2>
                  <span className="absolute right-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>
                <div className="overflow-auto">
                  {couponList.map((item: Coupon) => (
                    <div className="flex space-x-4 p-5" >
                      <div className="flex-grow listingSection__wrap">
                        <div className="flex justify-between space-x-3">
                          <div className="flex flex-col">
                            <div className="text-sm font-semibold">
                              <span>Coupon code: {item.code}</span>
                            </div>
                            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
                              Get discount of {item.percentageDiscount}% on minimum purchase of ₹ {item.minValue}
                            </span>
                            <span>
                            Category: {item?.type == "Category" ?
                              <Badge name={item?.assetId} color="green" />
                              :
                              <Badge name={item?.type} color="green" />
                            }
                            </span>
                          </div>
                          <div className="">
                            {item.minValue && item.minValue <= subTotal ? 
                            <ButtonPrimary onClick={() => selectedCoupon(item.code)}>Select</ButtonPrimary>
                          : null}
                            </div>
                        </div>
                        <div className="w-40 border-b border-neutral-200 dark:border-neutral-700"></div>
                        <span className="block text-neutral-6000 dark:text-neutral-300">
                          Use the coupon before {moment(item.endDate).format('DD MMM yyyy')}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
};

export default ModalCoupons;
