import React from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import CheckSummary from "containers/ProductPage/CheckSummary";
import AllListingsHome from "containers/Company/AllListingsHome";
import MyBookings from "containers/ProductPage/MyBookings";
import BookingDetailsScreenshot from "containers/ProductPage/BookingDetailsScreenshot";
import PayPage from "containers/PayPage/PayPage";
import OrderDetails from "containers/ProductPage/OrderDetails";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import SaveListingsHome from "containers/Company/SaveListingsHome";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageCollaborate from "containers/PageAbout/PageCollaborate";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import MobileOTPInitiate from "containers/PageSignUp/MobileOTPInitiate";
import MobileOTPVerification from "containers/PageSignUp/MobileOTPVerification";
import ResetPassword from "containers/PageSignUp/ResetPassword";
import ResetPasswordGetNumber from "containers/PageSignUp/ResetPasswordGetNumber";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import BlogPost1 from "containers/BlogPage/BlogPost1";
import BlogPost2 from "containers/BlogPage/BlogPost2";
import BlogPost3 from "containers/BlogPage/BlogPost3";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import MainHome from "containers/PageHome/MainHome";
import ProductDetailPage from "containers/ProductPage/ProductDetailPage";
import RefundPolicy from "containers/PagePolicies/RefundPolicy";
import PaymentPolicy from "containers/PagePolicies/PaymentPolicy";
import PrivacyPolicy from "containers/PagePolicies/PrivacyPolicy";
import TermsPolicy from "containers/PagePolicies/TermsPolicy";
import QrcodeScanner from "common/qrcodescanner/QrcodeScanner"
import PageSignUpVendor from "containers/PageSignUp/PageSignUpVendor";

export const pages: Page[] = [
  { path: "/", exact: true, component: MainHome },
  { path: "/#", exact: true, component: MainHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  { path: "/home-3", component: PageHome },
  //
  { path: "/product", component: ProductDetailPage },


  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },
  //
  {
    path: "/listing-experiences",
    component: ListingExperiencesPage,
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/listing-real-estate", component: ListingRealEstatePage },
  //
  { path: "/listing-flights", component: ListingFlightsPage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/CheckSummary", component: CheckSummary },  
  { path: "/AllListingsHome", component: AllListingsHome },
  { path: "/MyBookings", component: MyBookings },
  { path: "/BookingDetailsScreenshot", component: BookingDetailsScreenshot },
  
  { path: "/order-details", component: OrderDetails },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/saved-list", component: SaveListingsHome },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  { path: "/qrCodeScanner", component: QrcodeScanner },
  
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/blogpost1", component: BlogPost1 },
  { path: "/blogpost2", component: BlogPost2 },
  { path: "/blogpost3", component: BlogPost3 },
  
  //
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  //
  { path: "/contact", component: PageContact },
  { path: "/refundpolicy", component: RefundPolicy },
  { path: "/privacypolicy", component: PrivacyPolicy },
  { path: "/paymentpolicy", component: PaymentPolicy },
  { path: "/termsconditionpolicy", component: TermsPolicy },
  { path: "/about", component: PageAbout },
  { path: "/collaborate", component: PageCollaborate},
  { path: "/signup", component: PageSignUp },
  { path: "/signupVendor", component: PageSignUpVendor },
  
  
  { path: "/login", component: PageLogin },
  { path: "/mobile-otp-initiate", component: MobileOTPInitiate },
  { path: "/mobile-otp-verification", component: MobileOTPVerification },
  
  { path: "/forgot-pass", component: ResetPasswordGetNumber },
  { path: "/forgot-pass-setpassword", component: ResetPassword },
  { path: "/subscription", component: PageSubcription },
  //
];

const MyRoutes = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  return (
    <HashRouter
      basename={process.env.NODE_ENV === "production" ? "" : ""}
    >
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </HashRouter>
  );
};

export default MyRoutes;
