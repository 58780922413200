import constants from "common/constants";
import __stayListing from "./jsons/__stayListing.json";
import __carsListing from "./jsons/__carsListing.json";
import __experiencesListing from "./jsons/__experiencesListing.json";
import {
  DEMO_STAY_CATEGORIES,
  DEMO_EXPERIENCES_CATEGORIES,
} from "./taxonomies";
import { CarDataType, ExperiencesDataType, StayDataType } from "./types";
import { DEMO_AUTHORS } from "./authors";
import car1 from "images/cars/1.png";
import car2 from "images/cars/2.png";
import car3 from "images/cars/3.png";
import car4 from "images/cars/4.png";
import car5 from "images/cars/5.png";
import car6 from "images/cars/6.png";
import car7 from "images/cars/7.png";
import car8 from "images/cars/8.png";
import car9 from "images/cars/9.png";
import car10 from "images/cars/10.png";
import car11 from "images/cars/11.png";
import car12 from "images/cars/12.png";
import car13 from "images/cars/13.png";
import car14 from "images/cars/14.png";
import car15 from "images/cars/15.png";
import car16 from "images/cars/16.png";
import ClientStore from './../common/ClientStore';
import SessionManager from 'common/SessionManager';

const carsImgs = [
  car1,
  car2,
  car3,
  car4,
  car5,
  car6,
  car7,
  car8,
  car9,
  car10,
  car11,
  car12,
  car13,
  car14,
  car15,
  car16,
];

function fetchFilteredProducts(responseCallback: any, productType?: any[], pNumber?: number, pSize?:number, sortType?:string, latitude?: string, longitude?: string) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "sort": sortType ?? "Featured",
        "isSortAscending":"true",
        "stage": "Active",
        "thumbnailUrl": "",
        "title": "",
        "searchOption": {
          "city": ClientStore.get('localstorage', 'location_city') ?? "",
          "state": ClientStore.get('localstorage', 'location_state'),
          "latitude": latitude,
          "longitude": longitude,
          "radiusInMeters": "5000000",
          "randomText": ClientStore.get('localstorage', 'productSearchFilter') ?? "",
        },
        "productTypes": productType,
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        ClientStore.set('localstorage', 'productSearchFilter', "");
        ClientStore.set('localstorage', 'productSearchFocus', "");
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
        ClientStore.set('localstorage', 'productSearchFilter', "");
        ClientStore.set('localstorage', 'productSearchFocus', "");
      });
  })
}

function fetchproductTypes(responseCallback: any, productType?: any[]) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        //"description": description? description: "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "stage": "Active",
        "thumbnailUrl": "",
        //"title": title? title: "",
        "productTypes": (productType != undefined && productType!.length > 0) ? productType: ["Event", "Casino", "WaterSport", "Sightseeing",
        "Boating", "Crusing", "Zoo", "Museum", "PhotoShoots", "NightClubs",
        "EcoTourism", "Dine", "Adventure", "AmusementPark",
        "LiveEvents", "Concerts", "Parties"],
        "userId": ""
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchRidesType(responseCallback: any, latitude: string, longitude: string, title?: string, description?: string, vehicleType?: any[]) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "searchOption": {
          "radiusInMeters": "5000000",
          "latitude": latitude,
          "longitude": longitude,
          "city": ClientStore.get('localstorage', 'location_city') ?? "",
          "state": ClientStore.get('localstorage', 'location_state')
        },
        "stage": "Active",
        "thumbnailUrl": "",
        "title": title ? title : "",
        //"type": vehicleType!.length >0 ?vehicleType:"Vehicle",
        "productTypes": vehicleType!.length > 0 ? vehicleType : ["Vehicle", "TwoWheeler", "FourWheeler", "GearedCycle", "VintageCars"],
        "userId": ""
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchYachtsTypes(responseCallback: any, productType?: any[]) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        //"description": description? description: "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "stage": "Active",
        "thumbnailUrl": "",
        //"title": title? title: "",
        "searchOption": {
          "city": ClientStore.get('localstorage', 'location_city') ?? "",
          "state": ClientStore.get('localstorage', 'location_state')
        },
        "productTypes": (productType != undefined && productType!.length > 0) ? productType : ["Yacht", "MotorYacht",
          "SailingYacht", "DisplacementYacht", "SemiDisplacementYacht",
          "FishingYacht", "HybridYacht", "ClassicYacht", "ExplorerYacht", "SpeedBoat"],
        "userId": ""
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}


function fetchPlacesToVisit(responseCallback: any, title?: string, description?: string,pNumber?: number, pSize?:number,searchTextFromParams?:string) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        //"description": description? description: "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "stage": "Active",
        "title": title ? title : "",
        "thumbnailUrl": "",
        "description": description ? description : "",
        //"title": title? title: "",
        "searchOption": {
          "city": (searchTextFromParams != "" && searchTextFromParams != undefined) ? "" : ClientStore.get('localstorage', 'location_city') ?? "",
          "randomText": searchTextFromParams,
          "state": ClientStore.get('localstorage', 'location_state')
        },
        "productTypes": ["PlacesToVisit"],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchAll(responseCallback: any, title?: string, description?: string,pNumber?: number, pSize?:number, sortType?:string, isDesc?:boolean,searchTextFromParams?:string) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "stage": "Active",
        "sort": sortType,
        "isSortAscending":isDesc == false ? "true" : "false",
        "thumbnailUrl": "",
        "title": title ? title : "",
        "searchOption": {
          "city": (searchTextFromParams != "" && searchTextFromParams != undefined) ? "" : ClientStore.get('localstorage', 'location_city') ?? "",
          "randomText": searchTextFromParams,
          "state": ClientStore.get('localstorage', 'location_state')
        },
        "productTypes": [],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchEvent(responseCallback: any, title?: string, description?: string,pNumber?: number, pSize?:number) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "stage": "Active",
        "thumbnailUrl": "",
        "title": title ? title : "",
        "searchOption": {
          "city": ClientStore.get('localstorage', 'location_city') ?? "",
          "state": ClientStore.get('localstorage', 'location_state')
        },
        "productTypes": ["Event"],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchCasino(responseCallback: any, title?: string, description?: string,pNumber?: number, pSize?:number) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "stage": "Active",
        "thumbnailUrl": "",
        "title": title ? title : "",
        "searchOption": {
          "city": ClientStore.get('localstorage', 'location_city') ?? "",
          "state": ClientStore.get('localstorage', 'location_state')
        },
        "productTypes": ["Casino"],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchWaterSport(responseCallback: any, title?: string, description?: string,pNumber?: number, pSize?:number) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "stage": "Active",
        "thumbnailUrl": "",
        "title": title ? title : "",
        "searchOption": {
          "city": ClientStore.get('localstorage', 'location_city') ?? "",
          "state": ClientStore.get('localstorage', 'location_state')
        },
        "productTypes": ["WaterSport"],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchZoo(responseCallback: any, title?: string, description?: string,pNumber?: number, pSize?:number) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "stage": "Active",
        "thumbnailUrl": "",
        "title": title ? title : "",
        "searchOption": {
          "city": ClientStore.get('localstorage', 'location_city') ?? "",
          "state": ClientStore.get('localstorage', 'location_state')
        },
        "productTypes": ["Zoo"],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchMuseum(responseCallback: any, title?: string, description?: string,pNumber?: number, pSize?:number) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "stage": "Active",
        "thumbnailUrl": "",
        "title": title ? title : "",
        "searchOption": {
          "city": ClientStore.get('localstorage', 'location_city') ?? "",
          "state": ClientStore.get('localstorage', 'location_state')
        },
        "productTypes": ["Museum"],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchThingsToDo(responseCallback: any, productType?: any[], title?: string, description?: string,pNumber?: number, pSize?:number, sortType?:string, isDesc?:boolean,searchTextFromParams?:string) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "sort": sortType,
        "isSortAscending":isDesc == false ? "true" : "false",
        "stage": "Active",
        "thumbnailUrl": "",
        "title": title ? title : "",
        "searchOption": {
          "city": (searchTextFromParams != "" && searchTextFromParams != undefined) ? "" : ClientStore.get('localstorage', 'location_city') ?? "",
          "state": ClientStore.get('localstorage', 'location_state'),
          "randomText": searchTextFromParams
        },
        "productTypes": (productType != undefined && productType!.length > 0)? productType :["Event", "Casino", "WaterSport", "Zoo", "Museum", "Sightseeing",
          "Boating", "Crusing", "PhotoShoots", "NightClubs",
          "EcoTourism", "Dine", "Adventure", "AmusementPark"],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchSavedThingsToDo(responseCallback: any, title?: string, description?: string,pNumber?: number, pSize?:number) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "id": "",
        "new": true,
        "phoneNumber": "",
        "listFavourites": true,
        "thumbnailUrl": "",
        "title": title ? title : "",
        "searchOption": {
          "city": "",
          "state": ""
        },
        "productTypes": [],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchRides(responseCallback: any, latitude: string, longitude: string, productType?: any[], title?: string, description?: string,pNumber?: number, pSize?:number, sortType?:string, isDesc?:boolean, searchTextFromParams?:string) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "searchOption": {
          "radiusInMeters": "5000000",
          "latitude": latitude,
          "longitude": longitude,
          "city": (searchTextFromParams != "" && searchTextFromParams != undefined) ? "" : ClientStore.get('localstorage', 'location_city') ?? "",
          "randomText": searchTextFromParams,
          "state": ClientStore.get('localstorage', 'location_state')
        },
        "stage": "Active",
        "sort": sortType,
        "isSortAscending":isDesc == false ? "True" : "False",
        "thumbnailUrl": "",
        "title": title ? title : "",
        //"type": "Vehicle",
        "productTypes": (productType != undefined && productType!.length > 0)? productType :["Vehicle", "TwoWheeler", "FourWheeler", "GearedCycle", "VintageCars"],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

function fetchYachts(responseCallback: any, productType?: any[], title?: string, description?: string,pNumber?: number, pSize?:number, sortType?:string, isDesc?:boolean,searchTextFromParams?:string) {
  SessionManager.getJWTToken((jwtToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({
        "agreementUrl": "",
        "description": description ? description : "",
        "email": "",
        "emailVerified": true,
        "id": "",
        "new": true,
        "phoneNumber": "",
        "phoneNumberVerified": true,
        "stage": "Active",
        "sort": sortType,
        "isSortAscending":isDesc == false ? "True" : "False",
        "thumbnailUrl": "",
        "title": title ? title : "",
        "searchOption": {
          "city": (searchTextFromParams != "" && searchTextFromParams != undefined) ? "" : ClientStore.get('localstorage', 'location_city') ?? "",
          "randomText": searchTextFromParams,
          "state": ClientStore.get('localstorage', 'location_state')
        },
        //"type": "Yacht",
        "productTypes": (productType != undefined && productType!.length > 0)? productType :["Yacht", "MotorYacht",
          "SailingYacht", "DisplacementYacht", "SemiDisplacementYacht",
          "FishingYacht", "HybridYacht", "ClassicYacht", "ExplorerYacht", "SpeedBoat"],
        "userId": "",
        "pagination":{
          "pageNumber":pNumber,
          "pageSize":pSize
        }
      })
    };
    fetch(constants.API_CONTEXT_URL + '/product/listProducts', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        responseCallback(data.response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  })
}

const DEMO_STAY_LISTINGS = __stayListing.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListing_${index}_`,
    saleOff: !index ? "-20% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_EXPERIENCES_LISTINGS = __experiencesListing.map(
  (post, index): ExperiencesDataType => {
    //  ##########  GET CATEGORY BY CAT ID ######## //
    const category = DEMO_EXPERIENCES_CATEGORIES.filter(
      (taxonomy) => taxonomy.id === post.listingCategoryId
    )[0];

    return {
      ...post,
      id: `experiencesListing_${index}_`,
      saleOff: !index ? "-20% today" : post.saleOff,
      isAds: !index ? true : post.isAds,
      author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
      listingCategory: category,
    };
  }
);

const DEMO_CAR_LISTINGS = __carsListing.map((post, index): CarDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_EXPERIENCES_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `carsListing_${index}_`,
    saleOff: !index ? "-20% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
    featuredImage: carsImgs[index],
  };
});

export {
  DEMO_STAY_LISTINGS, DEMO_EXPERIENCES_LISTINGS, DEMO_CAR_LISTINGS,
  fetchFilteredProducts,fetchThingsToDo, fetchRides, fetchSavedThingsToDo, fetchYachts, fetchAll, fetchMuseum, fetchZoo, fetchWaterSport, fetchEvent, fetchCasino, fetchproductTypes, fetchRidesType, fetchYachtsTypes, fetchPlacesToVisit
};
