import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "../../components/HeroSearchForm/ClearDataButton";
import ButtonSubmit from "../../components/HeroSearchForm/ButtonSubmit";
import { PathName } from "routers/types";
import { packageItems } from './../../data/types';

export interface AnchoringPackageProps {
  onChange?: any;
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: PathName;
  hasButtonSubmit?: boolean;
  label:string;
  SelectedAnchor:packageItems;
  defaultValue?: any;
}

const AnchoringPackage: FC<AnchoringPackageProps> = ({
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
  label = "Anchoring Details",
  SelectedAnchor,
  defaultValue
}) => {
  const [anchorHourInputValue, setAnchorHourInputValue] = useState(defaultValue);

  const handleChangeData = (value: number) => {
    setAnchorHourInputValue(value);
    onChange && onChange(value);
  };

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none cursor-pointer ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
              <div className="text-neutral-300 dark:text-neutral-400 sm:px-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="nc-icon-field"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                  />
                </svg>
              </div>
            <div className="flex-grow">
              <NcInputNumber
                  className="bg-white top-full mt-2 px-1 sm:px-0 text-1xl font-semibold text-sm text-neutral-500 dark:text-neutral-400"
                  defaultValue={anchorHourInputValue}
                  onChange={(value:number) => handleChangeData(value)}
                  max={24}
                  min={0}
                  label={label}
                  desc=""
                />
                <span className="block text-sm pb-2 text-neutral-400 leading-none font-light">
                {`In Hours (${SelectedAnchor.pricePerUnit} / hr)`}
              </span>
            </div>
            {/* </Popover.Button> */}

            <div className="relative">
              {open && (
                <ClearDataButton
                  onClick={() => {
                    setAnchorHourInputValue(0)                    
                  }}
                />
              )}
            </div>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit href={buttonSubmitHref} />
              </div>
            )}
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={anchorHourInputValue}
                onChange={(value:number) => handleChangeData(value)}
                max={24}
                min={0}
                label="In Hours"
                desc=""
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default AnchoringPackage;
