import React, { useEffect, useRef, useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionHero3 from "components/SectionHero/SectionHero3";
import CardCategory6 from "components/CardCategory6/CardCategory6";
import SectionDowloadApp from "./SectionDowloadApp";
import ClientStore from "../../common/ClientStore";

const Home_Pics: TaxonomyType[] = [
  {
    id: "1",
    href: "/AllListingsHome?SelectedTab=Parties",
    name: "Parties",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/TILES_1/Parties.jpg"    
  },
  {
    id: "2",
    href: "/AllListingsHome?SelectedTab=Yachts",
    name: "Yacht",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/TILES_1/Yacht.jpg"
  },
  {
    id: "3",
    href: "/AllListingsHome?SelectedTab=Places To Visit",
    name: "Tourist Places",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/TILES_1/Tourist-Places.jpg"
  },
  {
    id: "4",
    href: "/AllListingsHome?SelectedTab=Rides",
    name: "Rent",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/TILES_1/rides.jpg"
  },
  {
    id: "5",
    href: "/AllListingsHome?SelectedTab=WaterSport",
    name: "Water Sports",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/TILES_1/Water-Sports.jpg"
  },
  {
    id: "6",
    href: "/AllListingsHome?SelectedTab=Casino",
    name: "Casinos",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/TILES_1/Casinos.jpg",    
  },
  {
    id: "7",
    href: "/AllListingsHome?SelectedTab=AmusementPark",
    name: "Amusement Park",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/TILES_1/Amusement-Park.jpg"
  },
  {
    id: "8",
    href: "/AllListingsHome?SelectedTab=Adventure",
    name: "Adventure Tours",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/TILES_1/Adventure.jpg"
  },
  {
    id: "9",
    href: "/AllListingsHome?SelectedTab=Concerts",
    name: "Concerts",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/TILES_1/Concerts.jpg"
  },
];

function MainHome() {
  // CUSTOM THEME STYLE
  const [isMobileDevice, setIsMobileDevice] = useState(false); 
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const $body = document.querySelector("body");
    let myWindow = window as any;
    if (myWindow.cordova != undefined && myWindow.cordova) {
      setIsMobileDevice(true);
    }
    if (!$body) return;
    $body.classList.add("theme-red-warmGrey");
    return () => {
      $body.classList.remove("theme-red-warmGrey");
    };
  }, []);

  useEffect(() => {
    if (ClientStore.get('localstorage', 'productSearchFocus') == 1 && inputRef.current) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 1000);
    }
  }, []);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <div className="container px-1 hidden lg:block sm:px-4 mb-24 ">
        <SectionHero3 />
      </div>
      <div className="container px-1 lg:hidden sm:px-10 mb-5 ">
        <SectionHero3 />
      </div>

      <div className="container relative space-y-24 mb-24 ">

        {/* SECTION 1*/}
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 lg:col-span-7 flex">
            <CardCategory6 taxonomy={Home_Pics[5]} />
          </div>
          <div className="col-span-12 lg:col-span-5 gap-6 grid">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-4 lg:col-span-4 flex grid gap-6">
                <CardCategory6 className={"aspect-w-3 aspect-h-5"} taxonomy={Home_Pics[1]} />
                <CardCategory6 className={"aspect-w-4 aspect-h-5"} taxonomy={Home_Pics[8]} />
                <CardCategory6 className={"aspect-w-4 aspect-h-5"} taxonomy={Home_Pics[0]} />
              </div>
              <div className="col-span-4 lg:col-span-4 flex grid gap-6">
                <CardCategory6 className={"aspect-w-6 aspect-h-8"} taxonomy={Home_Pics[6]} />
                <CardCategory6 className={"aspect-w-3 aspect-h-5"} taxonomy={Home_Pics[3]} />
                <CardCategory6 className={"aspect-w-4 aspect-h-5"} taxonomy={Home_Pics[2]} />
              </div>
              <div className="col-span-4 lg:col-span-4 grid grid-rows-2 gap-6">
                <CardCategory6 taxonomy={Home_Pics[4]} />
                <CardCategory6 taxonomy={Home_Pics[7]} />
              </div>
            </div>
          </div>
        </div>
        <div className="relative py-0">
          {ClientStore.get('localstorage', 'productSearchFilter') ?
            <span className="text-neutral-700 text-xl">
              Searched results for "{ClientStore.get('localstorage', 'productSearchFilter')}"
            </span>
            : ClientStore.get('localstorage', 'productListFilter') != null && ClientStore.get('localstorage', 'productListFilter').length > 0 ?
              <span className="text-neutral-700 text-xl">
                Filtered results:
              </span>
              : null}
          <input className="" ref={inputRef} type="text" style={{ position: 'relative', zIndex: -1, height: "1px" }} />
          <SectionGridFeaturePlaces
            ProductTypeList={ClientStore.get('localstorage', 'productListFilter') ?? []}
            SortProductType={ClientStore.get('localstorage', 'productSortType') ?? { label: "Featured", value: "Featured" }} />
        </div>

        {/* SECTION */}
        {isMobileDevice == false ?
          <SectionDowloadApp />
          : null}
        
        {/* SECTION */}
        <SectionSubscribe2 />

        
      </div>
    </div>
  );
}

export default MainHome;
