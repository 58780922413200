import React, { FC, useEffect, useState, Fragment } from "react";
import { Order } from '../../data/types';
import constants from 'common/constants';
import { BusinessDetails } from '../../data/types';
import Logo from "shared/Logo/Logo";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";

const moment = require('moment');

export interface BookingDetailsScreenshotProps {
}

const BookingDetailsScreenshot: FC<BookingDetailsScreenshotProps> = ({ }) => {

  const [productDetails, setProductDetails] = useState<BusinessDetails>();
  const [orderDetails, setOrderDetails] = useState<Order>();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let orderid = searchParams.get("OrderId");
    let packagejson = {
      "orderId": orderid
    }
    const url = constants.API_CONTEXT_URL + '/order/listOrders';
    axios.post(url, packagejson)
      .then((response) => {
        setOrderDetails(response?.data?.response[0]);
        viewProduct(response?.data?.response[0].productId)
      })
      .catch((error) => {
      });
  }, []);

  async function viewProduct(productId: string) {
    // POST request using fetch with error handling
    const requestOptions = {
      method: 'GET'
    };
    await fetch(constants.API_CONTEXT_URL + '/product/seller/viewProduct?productId=' + productId, requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setProductDetails(data.response.businessDetails)
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  return (
    <div className="margin0auto py-1 w-full max-w-[360px]">
      <div className="inline-flex bg-black pb-2 flex-col w-full text-left align-middle transition-all transform overflow-auto rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
        <div className="relative flex-shrink-0 px-1 py-2 text-center">
          <span className="text-sm pb-2 flex justify-between items-center text-neutral-400">
            <Logo className="w-20" />
            <div className="text-sm sm:text-base text-neutral-7000 dark:text-neutral-500">
              M-Ticket
            </div>&nbsp;
          </span>
          <div className="">
            <img src={orderDetails?.productThumbnailUrl} className="w-full h-40 xs:h-11 xs:w-11" alt="" />
            <div
              className="items-center justify-between p-2 xl:p-5 border-b border-neutral-200 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800"
            >
              <span className="text-lg text-neutral-900 dark:text-neutral-100 font-semibold flex-grow">
                {orderDetails?.packageName}
              </span> <br />
              <span className="text-lg text-neutral-400 dark:text-neutral-100 font-semibold flex-grow">
                {orderDetails?.projectName}
              </span>
            </div>
            <div className="flex items-center justify-between p-4 py-1 xl:p-5 xl:py-3 border-b border-neutral-200 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800">
              <div style={{ textAlignLast: "left" }}>
                <span className="text-xs">
                  Date
                </span><br />
                <span className="font-semibold">{moment(`${orderDetails?.eventDate}`).format('DD MMM YYYY')}</span>
              </div>
              <div>
                <span className="text-xs">
                  {(orderDetails?.type?.includes("Yacht") || orderDetails?.type == "SpeedBoat") ? 'Hours' :
                    orderDetails?.type?.includes("Wheel") || orderDetails?.type?.includes("Vehicle")
                      || orderDetails?.type?.includes("Cycle") || orderDetails?.type?.includes("Car") ? 'Day(s)' : 'Guests'}
                </span><br />
                <span className="font-semibold">{3}</span>
              </div>
            </div>
            <div className="p-4 py-2 xl:p-5 webkitCenter border-b border-neutral-200 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800"
            >
              <img style={{ height: "20vh" }} src={constants.API_CONTEXT_URL + "/qrcode/getCode?assetId=" + orderDetails?.id + "&assetType=ORDER"}></img>
              {/* { <span className="text-[10px]">{data.orderId}</span> } */}
            </div>
            <div className="text-sm flex items-top justify-between p-4 xl:p-5 border-b border-neutral-200 bg-neutral-100">
              Venue:
              <span className="ml-2" style={{ textAlign: "left" }}>{productDetails?.firstLine} {productDetails?.secondLine}{", "}
                {productDetails?.stateCode} {productDetails?.countryCode}<br />
                Contact: {productDetails?.phoneNumber}</span>
            </div>
            <div className="py-0 p-4 xl:p-5 xl:py-2 webkitCenter border-b border-neutral-200 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800"
            >
              <span className="text-[10px]">Terms & Conditions apply, as per eSanchari policy.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingDetailsScreenshot;
