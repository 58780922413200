import NextPrev from "shared/NextPrev/NextPrev";
import React, { HTMLAttributes, ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  desc = "Discover the most outstanding articles in all topics of life. ",
  className = "mb-10 md:mb-12 text-neutral-900 dark:text-neutral-50",
  isCenter = false,
  hasNextPrev = false,
  ...args
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState("");
  

  useEffect(() => {
    let searchTextFromParams = searchParams.get("SearchText");
    setSearchText(searchTextFromParams ?? "");
  }, []);

  const handelClearSearch = () => {
    window.location.assign("#/AllListingsHome");
    window.location.reload();
  }

  return (
    <div
      className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
    >
      <div
        className={
          isCenter ? "text-center w-full max-w-2xl mx-auto mb-4" : "max-w-2xl"
        }
      >
        <h2 className={`text-3xl md:text-4xl font-semibold`} {...args}>
          {children || `Section Heading`}
        </h2>
        {desc && (
          <div className="flex">
            <span className="mt-2 md:mt-4 font-normal block text-base sm:text-lg text-neutral-500 dark:text-neutral-400">
              {desc}
            </span>
            {searchText != "" ?
            <span onClick={() => handelClearSearch()} className="clearSearchMargin text-primary-700 cursor-pointer">
              Clear search
            </span>
            : null}
          </div>
        )}
      </div>
      {hasNextPrev && !isCenter && (
        <div className="mt-4 flex justify-end sm:ml-2 sm:mt-0 flex-shrink-0">
          <NextPrev onClickNext={() => {}} onClickPrev={() => {}} />
        </div>
      )}
    </div>
  );
};

export default Heading;
