import React, { FC, useState } from "react";
import SessionManager from './../../common/SessionManager';
import constants from 'common/constants';

export interface BtnLikeIconProps {
  className?: string;
  colorClass?: string;
  isLiked?: boolean;
  productId?: string;
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = "",
  colorClass = "text-white bg-black bg-opacity-30 hover:bg-opacity-50",
  isLiked,
  productId
}) => {

  const [likedState, setLikedState] = useState(isLiked);
  const [isloggedin, setisloggedin] = React.useState(SessionManager.getUser());

  const SaveLike = () => {
    if (isloggedin != null) {
      SessionManager.getJWTToken((jwtToken: string) => {
        const requestOptions = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwtToken,
          }
        };
        const url = constants.API_CONTEXT_URL + '/product/updatePreference?assetId=' + productId + '&assetType=PRODUCT&enable=' + !likedState + '&userPreferenceType=Favourites';
        fetch(url, requestOptions)
          .then(async response => {
            likedState == false ? alert("Added to Favourites successfully!!") : alert("Removed from Favourites successfully!!");
            setLikedState(!likedState)
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
    else {
      alert("Please login!")
    }
  }

  return (
    <div
      className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${likedState ? "nc-BtnLikeIcon--liked" : ""
        }  ${colorClass} ${className}`}
      data-nc-id="BtnLikeIcon"
      title="Save"
      onClick={() => SaveLike()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill={likedState ? "red" : "currentColor"}
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    </div>
  );
};

export default BtnLikeIcon;
