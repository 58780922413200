import rightImg from "images/about-hero-right.png";
import React, { FC, Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";
import NcImage from "shared/NcImage/NcImage";
import SectionAboutTeam from "./SectionAboutTeam";
import "animate.css/animate.compat.css";
import ScrollAnimation from 'react-animate-on-scroll';
import aboutus1 from "images/aboutus/1.jpg";
import aboutus2 from "images/aboutus/2.jpg";
import aboutus3 from "images/aboutus/3.jpg";
import aboutus4 from "images/aboutus/4.jpg";
import aboutus5 from "images/aboutus/5.jpg";
import aboutus6 from "images/aboutus/6.jpg";
import banner from "images/aboutus/banner.jpg";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import anil from "images/avatars/anil1.jpg";
import kishor from "images/avatars/kishor.jpg";
import shridhar from "images/avatars/shridhar.jpg";
import abhit from "images/avatars/abhit.jpg";
import abhi from "images/avatars/abhi.jpg";
import subhasish from "images/avatars/subhasish.jpg";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";
import { InstagramEmbed } from 'react-social-media-embed';

export interface PageAboutProps {
  className?: string;
}
export interface People {
  id: string;
  name: string;
  job: string;
  insta: string;
  avatar: string;
  details: string;
}
export interface Statistic {
  id: string;
  url: string;
}
  export interface Articles {
    id: string;
    url: string;
    icon: string;
    heading: string;
    subHeading: string;
  }

const Team: People[] = [
  {
    id: "1",
    name: `Anil Kumar P`,
    job: "Founder and CEO",
    insta: "https://www.linkedin.com/in/anil-kumar-95176b2b/?originalSubdomain=in",
    avatar: anil,
    details: "A professional with a robust background encompassing two decades of diverse experience. With 10 years dedicated to the IT industry, Anil has honed his skills in various technological domains, ensuring efficient and innovative solutions for complex challenges. Transitioning from IT, Anil ventured into the world of entrepreneurship, where he has successfully spent the past 10 years building and managing businesses. His dual expertise in technology and business strategy makes him a versatile and insightful leader, capable of bridging the gap between technical solutions and entrepreneurial success which helps growing eSanchari."
  },
  {
    id: "2",
    name: `Kishor Kumar K`,
    job: "Co-founder and CFO",
    insta: "https://www.linkedin.com/in/kishor-kumar-naidu/",
    avatar: kishor,
    details: "A distinguished entrepreneur with over 20 years of expertise in the business world. Kishore's extensive experience is complemented by a strong proficiency in web-related technologies, making him a formidable force in the digital landscape. His deep understanding of both entrepreneurial strategies and cutting-edge web technologies has allowed him to successfully launch and grow multiple ventures. Kishore's ability to innovate and adapt in the ever-evolving tech industry, combined with his business acumen, positions him as a visionary leader and a driving force behind eSanchari."
  },
  {
    id: "3",
    name: `Shridhar Deshpande`,
    job: "Co-founder and CCO",
    insta: "https://www.linkedin.com/in/shridhar-deshpande-b71b60233/",
    avatar: shridhar,
    details: "A veteran entrepreneur with over 25 years of experience in the business realm. Throughout his illustrious career, Sridhar has demonstrated exceptional leadership, vision, and innovation, successfully steering multiple ventures to success. His extensive experience and deep understanding of the entrepreneurial landscape have equipped him with the skills to navigate and thrive in competitive markets. Sridhar's dedication, strategic thinking, and unwavering commitment to eSanchari has helped to grow business."
  },
  {
    id: "4",
    name: `Abhit Kamat`,
    job: "CMO",
    insta: "https://www.linkedin.com/in/abhit-kamat-934b90302/",
    avatar: abhit,
    details: "With over two decades of vast experience in Sales, Marketing & Business Development, have worked in various positions handling Client acquisition, Customer relationship, Vendor management, Determining SOW & SLA, Business development planning & its implementation, Adhering operational procedures and policies, Market research, Product analysis & Business growth. Overall 22+ years of experience in sales, marketing & business development. Has enormous knowledge of market and consumers."
  },
  {
    id: "5",
    name: `Abhinandan B`,
    job: "CTO",
    insta: "https://www.linkedin.com/in/abhinandan-b-6bb626307/",
    avatar: abhi,
    details: "Has 15 years of experience in the IT sectors. An accomplished innovator with over 5 patents across fields like HCI, BPM, Computer Vision, RPA. Co-innovator with teams across globe."
  },
  {
    id: "6",
    name: `Subhasish D`,
    job: "CPO",
    insta: "https://www.linkedin.com",
    avatar: subhasish,
    details: "Has 20+ years of experience, Lead Engineer. Has knowledge of different software systems, client/server architectures and various compatibility requirements."
  }
];

const Latest_Posts: Statistic[] = [
  {
    id: "1",
    url: "https://www.instagram.com/p/C9o3n1PoFQD/"
  },
  {
    id: "2",
    url: "https://www.instagram.com/p/C7MP4zhoxLX/"
  },
  {
    id: "3",
    url: "https://www.instagram.com/p/C0tiPZJt1Va/"
  },
];

const Latest_Articles: Articles[] = [
  {
    id: "1",
    url: "#/blogpost1",
    icon:"https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/Articles/article1.jpeg",
    heading:"Why one should consider taking a holiday?",
    subHeading:"Going on a holiday can offer numerous benefits, both for mental and physical well-being. Here are some compelling reasons why people should consider taking a holiday"
  },
  {
    id: "2",
    url: "#/blogpost2",
    icon:"https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/Articles/article2.jpeg",
     heading:"The travel industry in India",
    subHeading:"The travel industry in India is a significant and rapidly growing sector of the economy, influenced by various factors including domestic tourism, international tourism, cultural heritage, and adventure tourism. Here’s an overview of the industry"
  },
  {
    id: "3",
    url: "#/blogpost3",
    icon:"https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/Articles/article3.jpeg",
     heading:"Holiday spendings by an Indian",
    subHeading:"The Indian mentality on spending money for a holiday can vary significantly based on factors such as socio-economic status, regional culture, personal values, and individual preferences. Here are some common perspectives"
  },
];

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  function openlinktabs(val: any) {
    window.location.assign(val);
  }
  const [isAuthorModal, setAuthorOpenModal] = useState(false);
  const [authorDetails, setAuthorDetails] = useState<People>();

  const openAuthorModal = (item: People) => {
    setAuthorDetails(item);
    setAuthorOpenModal(true);
  }
  function closeAuthorModal() {
    setAuthorOpenModal(false);
  }
  const renderAuthorModal = () => {
    return (
      <Transition appear show={isAuthorModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeAuthorModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      {authorDetails?.name}
                    </h3>
                    <span className="absolute right-3 top-3">
                      <ButtonClose onClick={closeAuthorModal} />
                    </span>
                  </div>
                  <div className="overflow-auto text-neutral-700 dark:text-neutral-300">
                    <div className="flex flex-col pt-4 lg:flex-row space-y-14 lg:space-y-2 lg:space-x-15 px-10  relative text-center lg:text-left">
                      <div className="p-2">
                        <img className="rounded-2xl" src={authorDetails?.avatar} alt="" />
                        <span className="block text-sm pt-4 text-center text-neutral-900 sm:text-base dark:text-neutral-400">
                          {authorDetails?.job} &nbsp;
                          <a href={authorDetails?.insta} target="_blank" rel="noopener noreferrer" title={authorDetails?.name}>
                            <i className="lab la-linkedin text-blue-600 text-lg"></i>
                          </a>
                        </span>
                      </div>
                      <div className="w-screen px-5 max-w-full xl:max-w-3xl lg:space-y-7">
                        <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                          <p className=''>
                            {authorDetails?.details}
                          </p>
                        </span>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || eSanchari</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-10 lg:py-14 space-y-16 lg:space-y-5">
        <SectionHero
          rightImg={rightImg}
          heading="Elevating the Ticket Booking Experience"
          btnText=""
          subHeading="Innovative approach for fun activities & events"
        />
        <div className='mx-3 mb-4 text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 p-3' >
          <p className='py-2 font-semibold'>
            <a className="text-primary-500 ">eSanchari</a> aims to be a comprehensive digital platform that revolutionizes the travel experience by integrating advanced IT solutions, seamless ticket booking, and personalized travel planning.
          </p>
          <ScrollAnimation animateIn='fadeIn'>
            <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-2 lg:space-x-10 px-10 items-center relative text-center lg:text-left">
              <div className="w-screen max-w-full xl:max-w-3xl space-y-5 m-5 lg:space-y-7">
                <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                  <h2 className="text-xl pb-4 !leading-tight font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100">
                    Unified Booking Platform:
                  </h2>
                  <p className='px-1'>
                    ●	Single interface for booking for event tickets, self-driven vehicles, pilgrimages and casinos.
                    <br />●	Cross-platform accessibility (web, mobile app) for convenience.
                    <br />●	Smart Search and Recommendations: AI-driven search engine that provides personalized recommendations based on user preferences, travel history, and real-time data.
                    <br />●	Filters for price, time, duration, and amenities to help users find the best options.
                  </p>
                </span>
              </div>
              <div className="flex-grow">
                <img className="rounded-2xl" src={aboutus1} alt="" />
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'>
            <div className="flex flex-col pt-10 lg:flex-row space-y-14 lg:space-y-2 lg:space-x-15 px-10 items-center relative text-center lg:text-left">
              <div className="flex-grow px-4">
                <img className=" rounded-2xl" src={aboutus2} alt="" />
              </div>
              <div className="w-screen max-w-full xl:max-w-3xl space-y-5 m-5 lg:space-y-7">
                <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                  <h2 className="text-xl pb-4 !leading-tight font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100">
                    Flexible Payment Options:
                  </h2>
                  <p className='px-4'>
                    ●	Multiple payment methods including credit/debit cards, digital wallets, and UPI.
                    <br />●	Option for instalment payments or "Book Now, Pay Later" services.
                  </p>
                </span>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'>
            <div className="flex flex-col pt-10 lg:flex-row space-y-14 lg:space-y-2 lg:space-x-10 px-10 items-center relative text-center lg:text-left">
              <div className="w-screen max-w-full xl:max-w-3xl space-y-5 m-5 lg:space-y-7">
                <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                  <h2 className="text-xl pb-4 !leading-tight font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100">
                    Integrated Travel Planning:
                  </h2>
                  <p className='px-4'>
                    ●	Automatic itinerary generation, integrating booked tickets and activities.
                    <br />●	Synchronization with calendars and travel apps for seamless planning.
                  </p>
                </span>
              </div>
              <div className="flex-grow">
                <img className="rounded-2xl" src={aboutus3} alt="" />
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'>
            <div className="flex flex-col pt-10 lg:flex-row space-y-14 lg:space-y-2 lg:space-x-15 px-10 items-center relative text-center lg:text-left">
              <div className="flex-grow px-4">
                <img className=" rounded-2xl" src={aboutus4} alt="" />
              </div>
              <div className="w-screen max-w-full xl:max-w-3xl space-y-5 m-5 lg:space-y-7">
                <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                  <h2 className="text-xl pb-4 !leading-tight font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100">
                    Customer Support and Assistance:
                  </h2>
                  <p className='px-4'>
                    ●	24/7 customer support via chat and email.
                    <br />●	Virtual assistant for instant help with bookings and travel inquiries.
                  </p>
                </span>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'>
            <div className="flex flex-col py-10 lg:flex-row space-y-14 lg:space-y-2 lg:space-x-15 px-10 items-center relative text-center lg:text-left">
              <div className="flex-grow px-4">
                <img className="rounded-2xl" src={banner} alt="" />
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'>
            <div className="flex flex-col pt-10 lg:flex-row space-y-14 lg:space-y-2 lg:space-x-10 px-10 items-center relative text-center lg:text-left">
              <div className="w-screen max-w-full xl:max-w-3xl space-y-5 m-5 lg:space-y-7">
                <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                  <h2 className="text-xl pb-4 !leading-tight font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100">
                    User Experience Enhancements:
                  </h2>
                  <p className='px-4'>
                    ●	Clean, easy-to-navigate UI/UX design with clear instructions and minimal steps.
                    <br />●	Quick booking process with options to save traveller profiles for faster checkout.
                    <br />●	Selection of convenient time-slot.
                    <br />●	Secure payment gateways and data encryption.
                    <br />●	Two-factor authentication for account protection.
                  </p>
                </span>
              </div>
              <div className="flex-grow">
                <img className="rounded-2xl" src={aboutus5} alt="" />
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'>
            <div className="flex flex-col pt-10 lg:flex-row space-y-14 lg:space-y-2 lg:space-x-15 px-10 items-center relative text-center lg:text-left">
              <div className="flex-grow px-4">
                <img className=" rounded-2xl" src={aboutus6} alt="" />
              </div>
              <div className="w-screen max-w-full xl:max-w-3xl space-y-5 m-5 lg:space-y-7">
                <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                  <h2 className="text-2xl pt-6 !leading-tight font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
                    VISION
                  </h2>
                  <p className='px-4 py-4'>
                    Continuous innovation to stay ahead in the competitive travel tech industry.
                  </p>
                  <p className='px-4 pb-4'>
                    With these features, esanchari aims to offer a comprehensive, user-centric ticket booking experience that not only meets but exceeds the expectations of modern travellers.
                  </p>
                  <p className='px-4'>
                    Future plans to integrate emerging technologies like AI, machine learning, and blockchain for enhanced security and user experience.
                  </p>
                </span>
              </div>
            </div>
          </ScrollAnimation>
          <h2 className="text-2xl pt-14 pb-3 !leading-tight font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
            ⛱ Our Team
          </h2>
          <ScrollAnimation animateIn='fadeIn'>
            <div className="grid sm:grid-cols-2 py-9 gap-x-20 px-10 mx-6 gap-y-8 lg:grid-cols-3 xl:gap-x-20">
              {Team.map((item) => (
                <div key={item.id} className="max-w-sm">
                  <NcImage
                    containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                    className="absolute cursor-pointer inset-0 object-cover"
                    src={item.avatar}
                    onClick={() => openAuthorModal(item)}
                  />
                  <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
                    {item.name}
                  </h3>
                  <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                    {item.job} &nbsp;
                    <a href={item.insta} target="_blank" rel="noopener noreferrer" title={item.name}>
                      <i className="lab la-linkedin text-blue-600 text-lg"></i>
                    </a>
                  </span>

                </div>
              ))}
            </div>
          </ScrollAnimation>
          <SectionAboutTeam className="py-4" uniqueClassName="Pagecontact_" />
          <h2 className="text-2xl pt-9 pb-3 !leading-tight font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
            🚀 Latest Articles
          </h2>
          <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
            {Latest_Articles.map((item) => (
              <div
                key={item.id}
                onClick={() => openlinktabs(item.url)}
                className="p-2 cursor-pointer bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
              >
                <div className="relative h-0 aspect-h-5 aspect-w-12 rounded-xl overflow-hidden">
                  <img src={item.icon} className="absolute cursor-pointer inset-0 object-cover" />
                </div>
                <h3 className="text-xl pt-2 font-semibold leading-none text-neutral-900 md:text-xl dark:text-neutral-200">
                  {item.heading}
                </h3>
                <span className="block line-clamp-5 text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                  {item.subHeading}
                </span>
              </div>
            ))}
          </div>
          <h2 className="text-2xl pt-9 pb-3 !leading-tight font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
            🚀 Latest Posts
          </h2>
          <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
            {Latest_Posts.map((item) => (
              <div
                key={item.id}
                className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed url={item.url} width={328} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="relative py-8">
          <SectionHowItWork />
        </div>
        <div className="relative py-8">
          <SectionSubscribe2 />
        </div>
      </div>
      {renderAuthorModal()}
    </div>
  );
};

export default PageAbout;
