import React, { FC, Fragment, useEffect, useState } from "react";
import axios from 'axios';
import constants from 'common/constants';
import SessionManager from 'common/SessionManager';
import {CartOrders, Order, Review} from '../../data/types';
import  ButtonPrimary  from 'shared/Button/ButtonPrimary';
import RazorPay from "common/RazorPay";
import BookingDetailsTable from "./BookingDetailsTable";
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import CommentListing from 'components/CommentListing/CommentListing';
import {ArrowRightIcon} from '@heroicons/react/24/solid';
import ButtonCircle from 'shared/Button/ButtonCircle';
import Input from 'shared/Input/Input';
import FiveStartIconForRate from 'components/FiveStartIconForRate/FiveStartIconForRate';
import { useSearchParams } from 'react-router-dom';
import Textarea from 'shared/Textarea/Textarea';
import { StarIcon } from '@heroicons/react/24/solid';

export interface ProductReviewsProps {
  className?: string;
  sendReviewsToParent: any;
}

const ProductReviews: FC<ProductReviewsProps> = ({ className = "" ,sendReviewsToParent}) => {
  const [reviews, setReviews] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [comment, setComment] = useState<string>();
  const [isloggedin, setisloggedin] = React.useState(SessionManager.getUser());

  const [point, setPoint] = useState(5);
  const [currentHover, setCurrentHover] = useState(0);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews =() =>{
    let userId= SessionManager.getUserId();
    let productIdFromParams = searchParams.get("productId");
    let requestOptions:any = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    };
    axios.get(constants.API_CONTEXT_URL + '/review/listReview?assetId=' + productIdFromParams + '&assetType=Product')
			.then((response) => {
				setReviews(response.data.response);
        sendReviewsToParent(response.data.response)
			})
			.catch((error) => {
				console.log(error);
			});
  }  

  const handleSubmit =() =>{
    if(comment == "" || comment == null){
      return;
    }
    let productIdFromParams = searchParams.get("productId");
    if (isloggedin != null) {
      SessionManager.getJWTToken((jwtToken: string) => {
        const requestOptions = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwtToken,
          }
        };
      const url = constants.API_CONTEXT_URL + '/review/addReview?assetId='+productIdFromParams+'&assetType=Product&comment='+ comment+ '&rating='+point;
      fetch(url, requestOptions)
      .then(async response => {
          let reviewobj: any = await response;
          setPoint(5);
          setComment("");
          fetchReviews();
        })
        .catch((error) => {
          alert("Review not published" + error);
        });
      });
    }
    else {
      // Save in Local Storage to access after login
      window.location.assign("#/login")
    }
  }

  return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews ({reviews?.length} reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
        <div className={`nc-FiveStartIconForRate flex items-center text-neutral-300 space-x-0.5`}
          data-nc-id="FiveStartIconForRate">
          {[1, 2, 3, 4, 5].map((item) => {
            return (
              <StarIcon
                key={item}
                className={`${point >= item || currentHover >= item ? "text-yellow-500" : ""
                  } w-7 h-7`}
                onMouseEnter={() => setCurrentHover(() => item)}
                onMouseLeave={() => setCurrentHover(() => 0)}
                onClick={() => setPoint(() => item)}
              />
            );
          })}
        </div>
          {/* <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" /> */}
          <div className="relative">
            <Textarea
              placeholder="Share your thoughts ..."
              onChange={(e) => {
                setComment(e.currentTarget.value);
              }}
              value={comment}
            />
          </div>
          <ButtonPrimary className="float-right" onClick={() => handleSubmit()}>Submit</ButtonPrimary>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
        <>
          {reviews?.length! > 0 ?
          // <CommentListing review = {reviews[0]} className="py-8" />
            reviews?.map((review:Review) => (
              <CommentListing key={review.id} review = {review} className="py-8" />
            ))
            : null}
          <div className="pt-8">
            {/* <ButtonSecondary>View more 20 reviews</ButtonSecondary> */}
          </div>
        </>
        </div>
      </div>
  );
};

export default ProductReviews;
