import React, { FC, useEffect, Fragment, useMemo, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Dialog, Transition } from "@headlessui/react";
import { GeneralPackage, PriceRates } from "../../data/types";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment';
import constants from 'common/constants';

export interface ModalDatePriceProps {
  post: GeneralPackage;
  openModal: () => void;
  onPackageSelected: any;
  onRateSelected: any;
  setdateValue: any;
  closeModal: () => void;
  isOpenModal: boolean;
  productType: any;
}

const ModalDatePrice: FC<ModalDatePriceProps> = ({
  post,
  openModal,
  closeModal,
  isOpenModal,
  onPackageSelected,
  onRateSelected,
  setdateValue,
  productType
}) => {
  const [events, setevents] = useState<any>([])
  const [priceSet, setpriceSet] = useState<any>([]);
  const [mobileCheck, setmobileCheck] = useState<boolean>(window.innerWidth >= 768 ? false : true);

  useEffect(() => {
    viewPricePackages();
  }, []);

  async function viewPricePackages() {
    let packageStartDate = moment().startOf('day').format('yyyy-MM-DD');
    let packageEndDate = moment().endOf('month').format('yyyy-MM-DD');
    if(post?.packageMaxDays != null && post?.packageMaxDays != ""){
      packageEndDate = moment(packageStartDate).add(parseInt(post?.packageMaxDays.toString()), 'days').format('yyyy-MM-DD');
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        packageId: post?.id,
        productType: productType.includes('Yacht') ? "Yacht" : productType,
        endDate: packageEndDate,
        startDate: packageStartDate
      })
    };
    await fetch(constants.API_CONTEXT_URL + '/rate/viewRates', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setpriceSet(data.response);
        let eventobj: any = [];
        //var jsonObj = Array.from({ length: moment().daysInMonth() }, (x, i) => moment().startOf('day').add(i, 'days').format('yyyy-MM-DD'))
        data.response?.forEach((element: PriceRates) => {
          let day: number = moment(element.startDate).day();
          let item: any = { set: 1, post: post, selectedDate: element?.startDate, title: element?.basePrice, date: element?.startDate,selectedRate:element };
          let item1: any = { set: 2, post: post, title: element?.anchorPrice ? element?.anchorPrice : "", selectedDate: element?.startDate, date: element?.startDate,selectedRate:element };
          eventobj.push(item);
          eventobj.push(item1);
        });
        setevents(eventobj);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function setValues(eventInfo: any){
    onPackageSelected(eventInfo.event?.extendedProps?.post);
    setdateValue(moment(eventInfo.event?.extendedProps?.selectedDate));
    onRateSelected(eventInfo.event?.extendedProps?.selectedRate)
    closeModal()
  }

  function renderEventContent(eventInfo: any) {
    return (
      <>
        <div onClick={() => setValues(eventInfo)} className="text-center">
        {eventInfo.event.extendedProps.set == 1 ? <>Base: ₹ {eventInfo.event.title}</>: null}
        {eventInfo.event.extendedProps.set == 2 && eventInfo.event?.title != ""? 
        <>Anchoring: <br/> 
        ₹ {eventInfo.event?.title} / {eventInfo.event?.extendedProps?.post?.packageItems[0]?.unit}</> 
        : null}
           
          </div>
      </>
    )
  }
  function renderEventContentMobile(eventInfo: any) {
    return (
      <>
        <div onClick={() => setValues(eventInfo)} className="text-center">
        {eventInfo.event.extendedProps.set == 1 ? <>₹ {eventInfo.event.title}</>: null}
        {eventInfo.event.extendedProps.set == 2 && eventInfo.event?.title != ""? 
        <>Anchoring: <br/> 
        ₹ {eventInfo.event?.title} / {eventInfo.event?.extendedProps?.post?.packageItems[0]?.unit}</> 
        : null}
           
          </div>
      </>
    )
  }
  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 h-screen w-full max-w-4xl">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h2
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="headlessui-dialog-title-70"
                  >
                    Price Chart
                  </h2>
                  <span className="absolute right-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>
                {mobileCheck ?
                  <div className="px-2 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <div className="flow-root py-3">
                      <FullCalendar
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        selectable={true}
                        dayMaxEvents={1}
                        eventOrder={"-title"}
                        weekends={true}
                        events={events}
                        eventContent={renderEventContentMobile}
                      />
                    </div>
                  </div>
                  :
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <div className="flow-root py-3">
                      <FullCalendar
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        selectable={true}
                        dayMaxEvents={2}
                        eventOrder={"-title"}
                        weekends={true}
                        events={events}
                        eventContent={renderEventContent}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
};

export default ModalDatePrice;
