import { Popover, Transition } from "@headlessui/react";
import { FC, Fragment } from "react";
import {BsCart2} from 'react-icons/bs';
import React, { useEffect, useRef, useState } from "react";
import SessionManager from '../../common/SessionManager';
import ClientStore from "../../common/ClientStore";
import axios from 'axios';
import constants from 'common/constants';

interface Props {
  className?: string;
}

const NotifyDropdown: FC<Props> = ({ className = "" }) => {
  
  const [isloggedin, setisloggedin] = React.useState(SessionManager.getUser());
  const [cartCount, setcartCount] = React.useState<number>(ClientStore.get('localstorage', 'orders_count') ?? 0);

  useEffect(() => {
    if(isloggedin){
    let packagejson = {
      new: true,
      stage: "New",
      userId: SessionManager.getUserId()
    }
    const url = constants.API_CONTEXT_URL + '/order/listOrdersWithQuote';
    axios.post(url, packagejson)
      .then((response: any) => {
        if (response.data?.response != null) {
          ClientStore.set('localstorage', 'orders_count', response.data?.response?.orders?.length ?? 0);
          setcartCount(response.data?.response?.orders?.length ?? 0);
        }
        else {
          ClientStore.set('localstorage', 'orders_count', 0);
          setcartCount(0);
        }
      })
      .catch((error) => {
      });
    }
    else{
      ClientStore.set('localstorage', 'orders_count', 0);
    }

  }, []);

  const mycart=()=>{
    isloggedin ? window.location.assign("#/CheckSummary"):
    window.location.assign("#/login");
  }
  return (
    <div className={className}>
      <Popover className="relative">
          <>
            <div onClick={mycart}
              className={` ${"text-opacity-90"
              } group p-3 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
            >
            {cartCount != 0 ?
              <span className="cartIcon absolute top-1 right-1">{cartCount}</span>
              : null
            }
            <BsCart2 className="w-5 h-5 cursor-pointer"/>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
            </Transition>
          </>
      </Popover>
    </div>
  );
};

export default NotifyDropdown;
