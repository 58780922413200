import React, { Component } from 'react'
// import QrReader from 'react-qr-scanner'
import QrReader from 'modern-react-qr-reader'

class QrcodeScannerWeb extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 100,
      result: 'No result',
    }

    this.handleScan = this.handleScan.bind(this)
    
  }
  
  handleScan(data){
    if(data != null){
      this.props.handleScanResult(data);
    }
  }
  handleError(err){
    console.error(err)
  }
  render(){
    const previewStyle = {
      height: 350,
      width: 350,
    }

    return(
      <div>
        <div className='flex justify-center'>
        <QrReader
          delay={this.state.delay}
          facingMode={"environment"}
          style={previewStyle}
          onError={this.handleError}
          onScan={this.handleScan}
        />
          </div>
      </div>
    )
  }
}

export default QrcodeScannerWeb;