import React, { FC,useEffect,useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { ProductDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import constants from "common/constants";
import SessionManager from "common/SessionManager";
import { GeneralPackage, MediaAccess, PackageOrder } from './../../data/types';
import { StarIcon, FlagIcon } from "@heroicons/react/24/solid";

export interface ProductListingProps {
  className?: string;
  data?: ProductDataType;
  href?:string;
  size?: "default" | "small";
}

const ProductListing: FC<ProductListingProps> = ({
  size = "default",
  className = "",
  href="",
  data = {},
}) => {
  const {
    id,
    thumbnailUrl,
    companyId,
    distance,
    userId,
    type,
    stage,
    phoneNumber,
    email,
    title,
    description,
    agreementUrl,
    thumbnailDescription,
    rating,
    favourites
  } = data;

  const [product, setProduct] = useState<any>();
  const [media, setMedia] = useState<string[]>([]);
  const[lowestPackagePrice, setlowestPackagePrice]= useState<any>();
  const moment = require('moment');
  const [amount, setAmount] = useState<number>()
  const [dateValue, setdateValue] = useState<moment.Moment | null>(moment());
  const [reviews, setReviews] = useState<any>();

  useEffect(() => {
    SessionManager.getJWTToken((jwtToken:string) => {
        // POST request using fetch with error handling
    const requestOptions = {
      method: 'GET',

      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken,
      },
    };
    fetch(constants.API_CONTEXT_URL + '/product/seller/viewProduct?productId=' + id, requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setProduct(data.response)
        let picArray : string[] = []
        data.response?.mediaAccessList?.sort((a:any,b:any) => a.seqNo - b.seqNo).forEach(function (value:MediaAccess) {
          if(value.assetType == "PRODUCT_IMAGES"){
          picArray.push(value.url);
          }
        });
        if(picArray.length == 0){
          setMedia([thumbnailUrl+""])
        }
        else setMedia(picArray)  

        //set lowest price from Package
        let amountsArray : number[] = []
        var daynum = dateValue?.toDate().getDay();
        // setdateValue(moment(`${date}`).format('DD MMM YYYY'));
        data.response?.packages?.forEach(function (value:GeneralPackage) {
          var amountobj = daynum == 1? value.mon : daynum == 2 ? value.tue : daynum == 3 ? value.wed : 
           daynum == 4 ? value.thu : daynum == 5 ? value.fri : daynum == 6 ? value.sat : value.sun
          amountsArray.push(amountobj);
        });
        amountsArray.length > 0 ? setlowestPackagePrice(Math.min(...amountsArray)) : setlowestPackagePrice(0);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    })
  }, []);

  function getFormattedDistance(distance: any): any {
    if(distance < 1000){
      return distance + " m"
    }
    return Math.round(distance /1000) + " km";
  }
  const renderSliderGallery = () => {
    
    return (
      <div className="relative w-full">
        {media?.length > 0 ?
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={media}
          href={href}
        />
        : null}
        <BtnLikeIcon isLiked={(favourites != "" && favourites != null)} productId={id} className="absolute right-3 top-3 z-[1]" />
        {product?.weight >= 1 ?
        <FlagIcon className="absolute text-primary-500 right-12 h-8 top-3 z-[1] w-5 h-5 ml-2"/>
         :null}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-primary-500 dark:text-neutral-400">
            {type} · {getFormattedDistance(distance)}  away
          </span>
          <div className="flex items-center space-x-2">
            {/*{true && <Badge name={stage} color="green" />}*/}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{product?.businessDetails?.cityCode} {' '} 
            {product?.businessDetails?.stateCode} {' '} {product?.businessDetails?.countryCode}</span>
          </div>
        </div>
        <div hidden={type?.includes("PlacesToVisit")} className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span hidden={type?.includes("PlacesToVisit")} className="text-base text-primary-500 font-semibold">
            ₹ {lowestPackagePrice}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                onwards
              </span>
            )}
          </span>
          {(<>
            {rating != null ?
              <StartRating point={+rating!?.split("-")[0]} reviewCount={+rating!?.split("-")[1]} />
              :
              <StartRating point={0} reviewCount={0} />
            }
          </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent()}</Link>
    </div>
  );
};

export default ProductListing;


