import axios from 'axios';

import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const TermsPolicy: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-TermsPolicy overflow-hidden relative ${className}`}
      data-nc-id="TermsPolicy"
    >
      <Helmet>
        <title>Terms & Conditions|| eSanchari</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-10 lg:py-28 space-y-16 lg:space-y-5">
        <SectionHero
          rightImg={rightImg}
          heading="Terms & Conditions"
          btnText=""
          subHeading=""
        />
        <div className='mx-3 mb-4 aboutushead p-3' >

          eSanchari.com is an online service providing platform or a Market Place and our mission is to deepen the engagement with the customers, and provide them service(s) of Vendors, Service Providers or Business Owners, and make life easier for customers to get curated services at their doorstep.
          <br /><br /> This electronic record is generated by a computer system and does not require any physical or digital signatures. By clicking on the “ACCEPT & CONTINUE” button on this electronic contract, you are consenting to be bound by this User Agreement. Please ensure that you read and understand all the provisions of this document before you start providing your services on the eSanchari.com. If you do not agree or accept any of the Terms and Conditions contained herein, then please do not use the eSanchari.com’s digital platform.
          <br /><br /> If this document is not in a language that you understand, you shall contact eSanchari.com by writing us an email. Failure to do so within 24 (Twenty-Four) hours from the time of receipt of this document and your acceptance of this document by clicking “I ACCEPT” button shall be considered as your understanding of this document.
          <br />
          <br />          
          <br /> <b> TERMS AND CONDITIONS (For Users)</b>

          <br />Welcome to eSanchari.com. This App and Website is owned and operated by DIGIVERTI INFOTECH PRIVATE LIMITED, having its registered office in Bangalore, Karnataka, India. By signing up for a eSanchari.com account or by using services available on eSanchari.com, you are agreeing to be bound by our terms and conditions.
          <br /><br />By visiting our App or Website and accessing the information, services, we provide, you understand and agree to accept and adhere to the following terms and conditions as stated in this policy (hereafter referred to as 'User Agreement'), along with the terms and conditions as stated in our Privacy Policy (please refer to the Privacy Policy link of this App or Website for more information).
          <br /><br />We reserve the right to change this User Agreement from time to time without notice. You acknowledge and agree that it is your responsibility to review this User Agreement periodically to familiarize yourself with any modifications. Your continued use of this site after such modifications will constitute acknowledgment and agreement of the modified terms and conditions.
          <br />
          <br />RESPONSIBLE USE AND CONDUCT:
          In order to access our Services, you may be required to provide certain information about yourself (such as identification, email, phone number, contact details, etc.) as part of the registration process, or as part of your ability to use the Services available on the eSanchari.com App or Website. You agree that any information you provide will always be accurate, correct, and up to date.
          <br /><br />You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Services. Accordingly, you are responsible for all activities that occur under your accounts.
          <br /><br />Accessing (or attempting to access) any of our Services by any means other than through the means we provide, is strictly prohibited. You specifically agree not to access (or attempt to access) any of our Services through any automated, unethical or unconventional means.
          <br /><br />Engaging in any activity that disrupts or interferes with our Services, including the servers and/or networks to which our Services are located or connected, is strictly prohibited.
          <br /><br />You are solely responsible any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability.
          <br /><br />We may provide various open communication tools on eSanchari.com, such as public chat, forums, message boards, service ratings and reviews, various social media services, etc. You understand that generally we do not pre-screen or monitor the content posted by users of these various communication tools, which means that if you choose to use these tools to submit any type of content to our eSanchari.com, then it is your personal responsibility to use these tools in a responsible and ethical manner. By posting information or otherwise using any open communication tools as mentioned, you agree that you will not upload, post, share, or otherwise distribute any information that:
          <br /><br />Is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive, invasive, racist, or contains any type of suggestive, inappropriate, or explicit language;
          <br /><br />Infringes on any trademark, patent, trade secret, copyright, or other proprietary right of any party;
          <br /><br />Contains any type of unauthorized or unsolicited advertising;
          <br /><br />Impersonates any person or entity, including any eSanchari.com employees or representatives.
          <br /><br />We have the right at our sole discretion to remove any information that, we feel in our judgment does not comply with this User Agreement, along with any information that we feel is otherwise offensive, harmful, objectionable, inaccurate, or violates any eSanchari.com copyrights or trademarks. We are not responsible for any delay or failure in removing such information. If you post information that we choose to remove, you hereby consent to such removal, and consent to waive any claim against us.
          <br /><br />We do not assume any liability for any information posted by you on eSanchari.com. However, any information posted by you using any open communication tools on our website/app, provided that it doesn't violate or infringe on any eSanchari.com copyrights or trademarks, becomes the property of DIGIVERTI INFOTECH PVT LTD, and as such, gives us a perpetual, irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, publicly display and/or distribute as we see fit. All information provided as part of our registration process is covered by our Privacy Policy.
          <br /><br />You agree to indemnify and hold harmless eSanchari.com a digital property of DIGIVERTI INFOTECH PVT LTD., and their directors, officers, managers, employees, Investors, agents, and licensors, from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of this User Agreement or the failure to fulfill any obligations relating to your account incurred by you or any other person using your account. We reserve the right to take over the exclusive defense of any claim for which we are entitled to indemnification under this User Agreement. In such event, you shall provide us with such cooperation as is reasonably requested by us.
          <br />
          <br />
          <br />ACCOUNT TERMS:

          <br />To access and use the Services, you must register for a eSanchari.com (“Account”). To complete your Account registration, you must provide us with your full legal name, business address, phone number, a valid email address, and any other information indicated as required. eSanchari.com may reject your application for an Account, or cancel an existing Account, for any reason, at our sole discretion.

          <br /><br />“Age Restrictions”. You must be the older of: (i) 18 years, or (ii) at least the age of majority in the jurisdiction where you reside and from which you use the Services to open an Account.

          <br /><br />You acknowledge that eSanchari.com will use the email address/Phone Number you provide on opening an Account or as updated by you from time to time as the primary method for communication with you (“Primary Email Address”). You must monitor the Primary Email Address you provide to eSanchari.com and your Primary Email Address must be capable of both sending and receiving messages. Your email communications with eSanchari.com can only be authenticated if they come from your Primary Email Address.

          <br /><br />You are responsible for keeping your password secure. eSanchari.com cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account and password.

          <br /><br />You agree not to work around, bypass, or circumvent any of the technical limitations of the Services, use any tool to enable features or functionalities that are otherwise disabled in the Services, or decompile, disassemble or otherwise reverse engineer the Services.
          <br />
          <br />SELLING OF SERVICES:

          <br /><br />As a registered seller, the Vendors, Service Providers and Business Owners are allowed to launch their service(s) on the eSanchari.com App or Website. eSanchari.com helps them to list service(s), take appointment for service(s), helps the Vendors to find new customers to boost their sales and helps the store to facilitates the service request, and to facilitates the services ordered through eSanchari.com App or Website.

          <br /><br />Periodically, you (the “User”) may get Prompt Service, Customized experience and exclusive offers during the sale on the eSanchari.com App or Website. These offers are exclusively offered by the stores listed on the eSanchari.com app or Website.

          <br /><br />We reserve the right to accept or reject your offer in part or in full. Our acceptance of your order will take place upon verification of your request.

          <br /><br />Facilitating of all the service(s) ordered, may or may not happen at the same time, in such a scenario that portion of the service which has been facilitated will be deemed to have been accepted by us and the balance would continue to be on offer to us and we reserve the right to accept or reject such balance order.

          <br /><br />No act or omission of the company prior to the actual facilitating of the service(s) ordered will constitute acceptance of your offer. If you have supplied us with your email address or phone number, we will notify you by email and/or phone number, as the case may be, as soon as possible to confirm receipt of your order and email/contact you again to confirm dispatch and therefore acceptance of the order.

          <br /><br />“QUANTITY RESTRICTION”:
          <br />We reserve the right, at our sole discretion, to limit the quantity of tickets purchased per person, per household or per order. These restrictions may be applicable to orders placed by the same account, the same credit / debit card, and also to orders that use the same billing and/or shipping address. We will provide notification to the customer should such limits be applied. We also reserve the right, at our sole discretion, to prohibit sales to any one as we may deem fit.
          <br />
          <br /><br />SERVICES BOOKING AND FINANCIAL TERMS:

          <br />The eSanchari.com App or Website allows users to order a service, request for a service or schedule a service, upon acceptance of such orders, requests, services. the eSanchari.com, subject to the terms and conditions set out herein, facilitates services, or completion of tasks ordered through the eSanchari.com App or Website.

          <br /><br />eSanchari.com does not own, sell or resell on its own such services, these services are offered by the on-boarded Vendors, Service Providers and Business Owners only, eSanchari.com is an online service providing platform by engaging them. We reserve the right to refuse the services on the App or Website at our sole discretion to anyone for any reason at any time.

          <br /><br />This App or Website does not screen or censor the Users who register on and access the eSanchari.com platform. You assume all risks associated with dealing with other users with whom you come in contact through the App or Website. You agree to use the eSanchari.com only for the lawful purposes without infringing the rights or restricting the use of this platform by and third party.

          <br /><br />All payments made against the Orders, Services on the eSanchari.com by the Users shall be compulsory in Indian Rupees acceptable in the Republic of India. The eSanchari.com will not facilitate transactions with respect to any other form of currency with respect to the Services made on App or Website. You can pay by (i) credit card or debit card or net banking; (ii) any other RBI approved payment method at the time of ordering a service or scheduling a Service. You understand, accept and agree that the payment facility provided by eSanchari.com is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment on Service delivery and remittance facility for the transactions on the eSanchari.com using the existing authorized banking infrastructure and credit card payment gateway networks. Further, by providing payment facility, eSanchari.com is neither acting as trustees nor acting in a fiduciary capacity with respect to the transaction or the transaction price. Taxes on Certain services offered by the seller may be eligible for business purchases, upon your purchase of service(s) eligible for business purchases, you may be able to avail the benefits of the GST input tax credit.  Accordingly, at your request, an invoice containing the GSTIN as provided by you (“Tax Invoice”) shall be issued to you by the seller(s) selling such services through eSanchari.com.

          <br /><br />When you register to order a Services on eSanchari.com, you agree to pay us the specific monetary amounts required to facilitates those Services. The amount will be described to you during the booking of a Service. The final amount required for payment for availing the Services will be shown to you immediately prior to final booking of Services.

          <br /><br />While we strive to provide accurate pricing information, pricing or typographical errors may occur. We cannot confirm the price of a service until after you order. In the event that a service is listed at an incorrect price or with incorrect information due to an error in pricing or service information, we shall have the right, at our sole discretion, to refuse or cancel any orders placed for that service, unless the service has already been facilitated.

          <br /><br />In the event that an item is mispriced, we may, at our sole discretion, either contact you for instructions or cancel your order and notify you of such cancellation. Unless the service ordered by you has been facilitated, your offer will not be deemed accepted and we will have the right to modify the price of the service and contact you for further instructions using the e-mail address or the contact number provided by you during the time of registration, or cancel the order and notify you of such cancellation.

          <br /><br />In the event we accept your order the same shall be debited to your credit / debit card account and duly notified to you by email or the contact number, as the case may be, that the payment has been processed. The payment may be processed prior to facilitating of the service that you have ordered. If we have to cancel the order after we have processed the payment, the said amount will be reversed back to your credit / debit card account. We strive to provide you with best value, however prices and availability are subject to change without notice.

          <br /><br />Please note our promotional offers/discounts are not site wide and are limited to selected categories.
          <br />
          <br />
          PRIVACY:

          <br />Through the use of eSanchari.com App and Website, you may provide us with certain information. By using the App or the Website, you authorize the eSanchari.com to use your information in India and any other country where the eSanchari.com may operate. We view protection of Your Privacy as very important principal. We understand clearly that you and your personal information in one of our most important assets.

          <br /><br />We store or process your information including any sensitive financial information collected (as defined under the Information Technology Act, 2000), if any, on computers that may be protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act, 2000 and rules there under.

          <br /><br />Your privacy is very important to us, which is why we've created a separate Privacy Policy in order to explain in detail how we collect, manage, process, secure, and store your private information. Our privacy policy is included under the scope of this User Agreement. To read our privacy policy in its entirety, click on the Privacy Policy link.
          <br />
          <br /><br />LIMITATION OF WARRANTIES:

          <br />By using eSanchari.com App or Website, you understand and agree that all Services we provide, facilitates are "as is" and "as available" on eSanchari.com platform. This means that we do not represent or warrant to you that:
          <br /><br />eSanchari.com does not warrant that your use of Services will be always uninterrupted or error-free, that eSanchari.com will review the information and Services delivered through the App or Website in a timely manner for accuracy or that it will preserve or maintain any such information or Services without loss.

          <br />eSanchari.com shall not be liable for delays, interruptions, Service failure, or other problems inherent in use of the internet and electronic communications or other systems outside the reasonable control of eSanchari.com.
          <br />
          <br /><br /> LIMITATION OF LIABILITY AND INDEMNIFICATION:

          <br />In conjunction with the Limitation of Warranties as explained above, you expressly understand and agree that any claim against us shall be limited to the amount you paid, if any, for use of services. eSanchari.com will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you as a result of using our resources, or as a result of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws apply.

          <br /><br />You shall defend, indemnify and hold harmless eSanchari.com its owners, affiliates, independent partners (as applicable), and its respective officers, directors, agents, and employees (“Indemnified Parties”), from and against any claim, damages, demand or actions including attorney’s fees, made by any third party against the indemnified parties or penalty imposed against the indemnified parties, due to arising out of your breach of this Terms of Use, Privacy Policy and Other Policies or your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.
          <br />
          <br /><br />COPYRIGHTS / TRADEMARKS:

          <br />All content and materials available on eSanchari.com, including but not limited to text, graphics, app name, code, images and logos are the intellectual property of DIGIVERTI INFOTECH PVT LTD, and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this App or Website is strictly prohibited, unless specifically authorized by DIGIVERTI INFOTECH PVT LTD.

          <br /><br />You must not use any part of the materials on the eSanchari.com’s App or Website for commercial purposes without obtaining a license to do so from us or our licensors. If you print off, copy or download any part of the eSanchari.com in breach of these Terms of Use, your right to use the App or Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.

          <br /><br />In order to make the Orders or Services available to you, you hereby grant the eSanchari.com a royalty-free, non-exclusive, worldwide license to copy, display, use, broadcast, transmit and make derivate works of any information you upload or otherwise make available to the App or the Website. The eSanchari.com claims no further proprietary rights in your information. If you feel that any of your Intellectual Property rights have been infringed or otherwise violated by the posting of information or media by another of Our users, please contact Us or write an email.
          <br />
          <br />
          TERMINATION OF USE:

          <br />You agree that we may, at our sole discretion, suspend or terminate your access to all or part of our App or Website and Services with or without notice and for any reason, including, without limitation, breach of this User Agreement. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to use the services we provide on eSanchari.com will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information.

          <br /><br />The eSanchari.com specifically reserves the right to terminate these Terms of Services if You violate any of the terms outlined herein, including but not limited to, violating the Intellectual Property rights of the DIGIVERTI INFOTECH PVT LTD or a third party, failing to comply with the applicable laws or other legal obligations, and/or publishing or distributing illegal material. If you have registered for an account with us, you may also terminate these Terms of Services at any time by contacting us and request termination. At the termination of these Terms of Services, any provisions that would be expected to survive termination by their nature shall remain in full force and effect.
          <br />
          <br />
          GOVERNING LAW:

          <br />The eSanchari.com App or Website is a digital property of and controlled by DIGIVERTI INFOTECH PVT LTD from our office located in the state of Bengaluru, India. These terms and conditions shall be governed, interpreted, and construed in accordance with the laws of India, without regards to conflict of law provisions and for resolution of any dispute arising out of your use of services or in relation of these terms and conditions.
          <br /><br />
          Furthermore, any action to enforce this User Agreement shall be brought in the courts located in Bengaluru, India. You hereby agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.
          <br />
          <br />
          SPAM POLICY:

          <br />You are strictly prohibited from using the App or any of the owner’s Services from illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.
          <br />
          <br />
          <br />THIRD-PARTY LINKS & CONTENTS:

          <br />The eSanchari.com may occasionally post links of third-party websites or other services through Google ad-sense on its App or Website. You agree that the eSanchari.com is not responsible or liable for any loss or damage caused as a result of your clicking on the links of any third-party websites available on our App or Website eSanchari.com.

          <br />

          <br />CANCELLATION AND REFUND:

          <br />You agree to ensure payment for any services, you may order from the eSanchari.com’s App or Website and you acknowledge and affirm that prices are subject to change depending on the order value and quantity. We ship your order(s) by ground couriers. We deliver most of our orders through our courier partners such as Swiggy, Dunzo or FedEx. For a few remote locations where there is no other courier service available, we use India Post to deliver your orders.

          <br /><br />We affirm that any order(s) received before 12 noon will be delivered on the same day and any order(s) received after 12 noon will be delivered next day. As soon as we ship your order, you will receive an e-mail notification with a link to track your order.

          <br /><br />For any questions or concerns regarding the Cancellation and Refund kindly visit our detailed “Cancellation and Refund Policy” available on the eSanchari.com app or website.

          <br />
          <br />FEEDBACK AND REVIEWS:

          <br />eSanchari.com welcomes any ideas and/or suggestions regarding improvements or additions to the Services. Under no circumstances will any disclosure of any idea, suggestion or related material or any review of the Services, Vendor’s services (collectively, “Feedback") to eSanchari.com be subject to any obligation of confidentiality or expectation of compensation. By submitting Feedback to eSanchari.com (whether submitted directly to eSanchari.com or posted on any eSanchari.com hosted forum or page), you waive any and all rights in the Feedback and that eSanchari.com is free to implement and use the Feedback if desired, as provided by you or as modified by eSanchari.com, without obtaining permission or license from you or from any Independent Partner. Any reviews of a Services that you submit to eSanchari.com must be accurate to the best of your knowledge, and must not be illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to eSanchari.com or objectionable. eSanchari.com reserves the right (but not the obligation) to remove or edit Feedback of any Services, but does not regularly inspect posted Feedback.

          <br /><br />We welcome Users feedback but are under no obligation to ensure that ideas and suggestions regarding eSanchari.com Services, Services selling by Vendors remain confidential and we can use the feedback in any way we want.

          <br />
          <br />MODIFICATIONS AND VARIATIONS:

          <br />We reserve the right, in our sole and absolute discretion, to update or change any portion of the Terms of Service at any time. We will provide you with reasonable advance notice of changes to the Terms of Service that materially adversely affect your use of the Services or your rights under the Terms of Service by sending an email to the Primary Email Address, providing notice through the eSanchari.com administrative console, or by similar means. However, eSanchari.com may make changes that materially adversely affect your use of the Services or your rights under the Terms of Service at any time and with immediate effect (i) for legal, regulatory, fraud and abuse prevention, or security reasons; or (ii) to restrict services or activities that we deem unsafe, inappropriate, or offensive. Unless we indicate otherwise in our notice (if applicable), any changes to the Terms of Service will be effective immediately upon posting of such updated terms at this location. Your continued access to or use of the Services after we provide such notice, if applicable, or after we post such updated terms, constitutes your acceptance of the changes and consent to be bound by the Terms of Service as amended. If you do not agree to the amended Terms of Service, you must stop accessing and using the Services.

          <br /><br />eSanchari.com may change the Prices for the Services from time-to-time. We will provide you with a notice prior to any changes in Fees by sending an email to the Primary Email Account, providing notice through the eSanchari.com administrative console, or by similar means. eSanchari.com will not be liable to you or to any Independent Partner for any modification, price change, suspension or discontinuance of the Services (or any part thereof).

          <br /><br />If we make a change to the Terms of Service that adversely affects your rights under the Terms of Service or your use of our Services in a material way, we will notify you in advance (unless the change relates to legal requirements or to prevent abuse of our Services, among other things).

          <br />
          <br />ENTIRE AGREEMENT:

          <br />These Terms and Conditions, Privacy Policy and any other terms or policies as may be prescribed by eSanchari.com from time to time, constitute the entire agreement between you and the eSanchari.com, which will govern your use of or access to the Services and/or the platform, superseding any prior agreements between you and eSanchari.com regarding such use or access.

          <br />
          <br />CONTACT INFORMATION:

          <br />If you have any questions or comments about eSanchari.com Terms of Service as outlined above, you can write us on the address provided in “contact us” section of the eSanchari.com app or website.

          <br /><br />eSanchari.com,
          <br />A digital property of DIGIVERTI INFOTECH PVT LTD


          <br />
 
          <br />
          <br />
          <br /> <b> TERMS AND CONDITIONS (For Vendors)</b>
          <br />  Welcome to eSanchari.com. This App and Website is owned and operated by DIGIVERTI INFOTECH PRIVATE LIMITED, having its registered office in Bangalore, Karnataka, India. By entering into a contract with eSanchari.com, you are agreeing to be bound by our terms and conditions. In addition, when you use any current or future eSanchari.com service, you also will be subject to the guidelines, terms and agreements applicable to such service.
          <br /><br /> You understand and agree to accept and adhere to the following terms and conditions as stated in this policy (hereafter referred to as 'User Agreement'), along with the terms and conditions as stated in our Privacy Policy (please refer to the Privacy Policy link of this App or Website for more information).
          <br /> We reserve the right to change this User Agreement from time to time without notice. You acknowledge and agree that it is your responsibility to review this User Agreement periodically to familiarize yourself with any modifications. Your continued use of this site after such modifications will constitute acknowledgment and agreement of the modified terms and conditions.
          <br />
          <br /> CONDITIONS OF USE:
          In order to provide your Services, you may be required to provide certain information about yourself (such as identification, email, phone number, contact details, etc.) as part of the registration process, or as part of your ability to use the Services available on the eSanchari.com App or Website. You agree that any information you provide will always be accurate, correct, and up to date. You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Services. Accordingly, you are responsible for all activities that occur under your accounts.
          <br /> You are solely responsible any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you.
          <br />
          <br />
          <br />  ELECTRONIC COMMUNICATION:
          <br />When you visit the Site or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on this Site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
          <br /> We may provide various open communication tools on eSanchari.com, such as public chat, forums, message boards, Service ratings and reviews, various social media services, etc. You understand that generally we do not pre-screen or monitor the content posted by users of these various communication tools, which means that if you choose to use these tools to submit any type of content to our eSanchari.com, then it is your personal responsibility to use these tools in a responsible and ethical manner. By posting information or otherwise using any open communication tools as mentioned, you agree that you will not upload, post, share, or otherwise distribute any information that: Is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive, invasive, racist, or contains any type of suggestive, inappropriate, or explicit language.
          <br /><br /> We have the right at our sole discretion to remove any information that, we feel in our judgment does not comply with this User Agreement, along with any information that we feel is otherwise offensive, harmful, objectionable, inaccurate, or violates any eSanchari.com copyrights or trademarks. We do not assume any liability for any information posted by you on eSanchari.com.
          <br />

          <br />  ACCOUNT TERMS:
          <br />For your onboarding or providing the Vendor’s Services, you must register on eSanchari.com (“Account”). To complete your Account registration, you must provide us with your full legal name, business address, phone number, a valid email address, and other information indicated specification of Service(s) as required.
          <br /><br />  You must monitor the Primary Email Address you provide to eSanchari.com and your Primary Email Address must be capable of both sending and receiving messages. Your email communications with eSanchari.com can only be authenticated if they come from your Primary Email Address.
          <br /><br />  You are responsible for keeping your password secure. eSanchari.com cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account and password. You agree not to work around, bypass, or circumvent any of the technical limitations of the Services, use any tool to enable features or functionalities that are otherwise disabled in the Services, or decompile, disassemble or otherwise reverse engineer the Services.

          <br />

          <br />  VENDOR’S OBLIGATIONS:
          <br />Your activity on the eSanchari.com is not intended for use by children. If you are a minor i.e. under the age of 18 years, you may use the eSanchari.com only with involvement of a parent or guardian. eSanchari.com and its affiliates reserve the right to refuse service, terminate accounts, or remove or edit content in their sole discretion. You must not use the Site in any way that causes, or is likely to cause, the website or access to it to be interrupted, damaged or impaired in any way. You understand that you, and not eSanchari.com, are responsible for all electronic communications and content sent from your computer to us and you must use the Site for lawful purposes only and only in accordance with the applicable law.
          <br /><br />  You must not use the Site for any of the following: for fraudulent purposes, or in connection with a criminal offence or other unlawful activity; to send, use or reuse any material that is illegal, offensive, (including but not limited to material that is sexually explicit or which promotes racism, bigotry, hatred or physical harm), abusive, harassing, misleading, indecent, defamatory, disparaging, obscene or menacing; or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any “spam”; to cause annoyance, inconvenience or needless anxiety.

          <br />
          <br />  SELLING OF SERVICES:
          <br />You as a registered seller, or in the form of Vendors, Service Providers and Business Owners, are allowed to launch your service(s) on the eSanchari.com App or Website. eSanchari.com helps you to list service(s), and sell ticket(s), take appointment for service(s), facilitates the service request, ordered through eSanchari.com App or Website.
          <br /><br />  We reserve the right to accept or reject your offer in part or in full. Our acceptance of user’s orders will take place upon facilitating of service(s) ordered. Facilitating of all the service(s) ordered, may or may not happen at the same time, in such a scenario that portion of the service which has been facilitated will be deemed to have been accepted by us and the balance would continue to be on offer to us and we reserve the right to accept or reject such balance order.
          <br />
          <br />  QUANTITY RESTRICTION:
          <br />We reserve the right, at our sole discretion, to limit the quantity of tickets purchased for the vendor’s service by per person, per household or per order. These restrictions may be applicable to orders placed by the same account, the same credit / debit card, and also to orders that use the same billing and/or shipping address. We will provide notification to the customer should such limits be applied. We also reserve the right, at our sole discretion, to prohibit sales to any one as we may deem fit.
          <br />
          <br />
          SERVICE DESCRIPTION:
          <br />eSanchari.com attempt to be as accurate as possible. However, eSanchari.com does not warrant that descriptions of or other content of this site is accurate, complete, reliable, current, or error-free. Also, your access to the Site may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction. eSanchari.com provides links to other sites over which eSanchari.com has no control. eSanchari.com is not responsible for the availability of such external sites or resources and does not endorse and is not responsible or liable for any content, advertising, products or other material on or available from such sites or resources.
          <br /><br />  In the event that a Service is mispriced, we may, at our sole discretion, either contact users for instructions or cancel their order and notify them and you of such cancellation. Unless the service ordered by users has been facilitated, users offer will not be deemed accepted and we will have the right to modify the price of the service and contact users for further instructions using the e-mail address or the contact number provided by users during the time of registration, or cancel the order and notify users and you of such cancellation.

          <br />
          <br />
          GRANT OF LICENSE:
          <br />If you on boarded as a Vendor, Service Provider, and unless we indicate otherwise, you grant eSanchari.com and its affiliates a nonexclusive, royalty-free, irrevocable, perpetual and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the India in any media for as long as you are permitted to grant the said license under applicable law. You grant eSanchari.com and its affiliates and sub-licensees the right to use the name that you submit in connection with such content, if they choose. You represent and warrant that you own or otherwise control all of the rights to the content or material that you post or submit or that you otherwise provide on or through the eSanchari.com Website or App; that the content is accurate; that the content is lawful; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that you will indemnify eSanchari.com and its affiliates for all claims arising from content you supply. eSanchari.com has the right but not the obligation to monitor and edit or remove any activity or content. eSanchari.com takes no responsibility and assumes no liability for any content submitted by you or any third party.

          <br />
          <br />
          FINANCIAL TERMS:
          <br />We have multiple payment methods through reputed and highly secured payment gateways. Whatever user’s online mode of payment, we can assure users and you that our company has trusted payment gateway partners that use secure encryption technology to keep the transaction details confidential at all times. We are accepting payments made using Visa, MasterCard, Maestro and American Express credit/debit cards, UPI Payments, Net Banking, Wallets, in India.
          <br /><br />  All payments made against the Orders, Services on the eSanchari.com by the Users shall be compulsory in Indian Rupees acceptable in the Republic of India. The eSanchari.com will not facilitate transactions with respect to any other form of currency with respect to the Services made on App or Website.
          <br /><br />  You understand, accept and agree that the payment facility provided by eSanchari.com is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment on Service delivery and remittance facility for the transactions on the eSanchari.com using the existing authorized banking infrastructure and credit card payment gateway networks. Further, by providing payment facility, eSanchari.com is neither acting as trustees nor acting in a fiduciary capacity with respect to the transaction or the transaction price.
          <br /><br />  When users order a paid Services on eSanchari.com, they agree to pay us the specific monetary amounts required to facilitates those Services. The amount will be described to them during the booking of a Service. The final amount required for payment for availing the Services will be shown to them immediately prior to final booking of Services and same shall be transferred to you within the prescribed time limit after statutory deductions.

          <br />
          <br />
          COPYRIGHTS AND TRADEMARKS:
          <br />All content included on eSanchari.com, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of eSanchari.com or its affiliates or its content suppliers and protected by Indian copyright laws. The compilation of all content on this Site is the exclusive property of eSanchari.com or its affiliates and protected by Indian copyright laws. All software used on eSanchari.com website or app is the property of eSanchari.com, its affiliates or its software suppliers and protected by Indian copyright laws.
          <br /><br />  eSanchari.com, its logo and other marks indicated on our Site are trademarks of eSanchari.com or its affiliates in the India. Other eSanchari.com graphics, logos, page headers, button icons, scripts, and service names are trademarks or trade dress of eSanchari.com or its affiliates. eSanchari.com and its affiliates’ trademarks and trade dress may not be used in connection with any service that is not eSanchari.com or its affiliates’ as applicable, in any manner that is likely to cause confusion among users, or in any manner that disparages or discredits eSanchari.com or its affiliates. All other trademarks not owned by eSanchari.com or its affiliates that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by eSanchari.com or its affiliates.

          <br />
          <br />
          PRIVACY:
          <br />Through the launch of your services and use of eSanchari.com App and Website, you may provide us with certain information. By using the App or the Website, you authorize the eSanchari.com to use your information in India and any other country where the eSanchari.com may operate. We view protection of Your Privacy as very important principal. We understand clearly that you and your personal information in one of our most important assets, which is why we've created a separate Privacy Policy in order to explain in detail how we collect, manage, process, secure, and store your private information.
          <br /><br />  We store or process your information including any sensitive financial information collected (as defined under the Information Technology Act, 2000), if any, on computers that may be protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act, 2000 and rules there under.

          <br />
          <br />
          DISCLAIMER OF WARRANTIES:
          <br />This site is provided by eSanchari.com on an “as is” and “as available” basis. eSanchari.com makes no representations or warranties of any kind, express or implied, as to the operation of this site or the information, content, materials, included on this site. you expressly agree that your use of this site is at your sole risk. eSanchari.com reserves the right to withdraw or delete any information from this site at any time in its discretion.

          <br />
          <br />
          LIMITATION OF LIABILITY:
          <br />To the full extent permissible by applicable law or in conjunction with the Limitation of Warranties as explained above, eSanchari.com disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. eSanchari.com does not warrant that this site, its servers, or e-mail sent from eSanchari.com are free of viruses or other harmful components. eSanchari.com will not be liable for any damages of any kind arising from the use of this site, including, but not limited to direct, indirect, incidental, punitive and consequential damages.

          <br />
          <br />
          INDEMNIFICATION:
          <br />You shall defend, indemnify and hold harmless eSanchari.com its owners, affiliates and its respective officers, directors, agents, and employees (“Indemnified Parties”), from and against any claim, damages, demand or actions including attorney’s fees, made by any third party against the indemnified parties or penalty imposed against the indemnified parties, due to arising out of your breach of this Terms of Use, Privacy Policy and Other Policies or your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.

          <br />
          <br />
          TERMINATION OF USE:
          <br />You agree that we may, at our sole discretion, suspend or terminate your access to all or part of our App or Website and You as a Vendor with or without notice and for any reason, including, without limitation, breach of this User Agreement. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to as a Vendor on eSanchari.com will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information.

          <br />
          <br />
          APPLICABLE LAW & DISPUTES:
          <br />By visiting eSanchari.com platform, you agree with the terms & conditions of it. eSanchari.com App or Website is a digital property of and controlled by DIGIVERTI INFOTECH PVT LTD from our office located in the state of Bengaluru, India. These terms and conditions shall be governed, interpreted, and construed in accordance with the laws of India, without regards to conflict of law provisions and for resolution of any dispute arising out of your use of services or in relation of these terms and conditions.
          <br /><br />  Furthermore, any action to enforce this User Agreement shall be brought in the courts located in Bengaluru, India. You hereby agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.

          <br />
          <br />
          THIRD-PARTY LINKS & CONTENTS:
          <br />The eSanchari.com may occasionally post links of third-party websites or other services through Google ad-sense on its App or Website. You agree that the eSanchari.com is not responsible or liable for any loss or damage caused as a result of your clicking on the links of any third-party websites available on our App or Website eSanchari.com.

          <br />
          <br />
          MODIFICATIONS AND SEVERABILITY:
          <br />We reserve the right, in our sole and absolute discretion, to update or change any portion of the Terms of Service at any time. We will provide you with reasonable advance notice of changes to the Terms of Service that materially adversely affect your use of the Services or your rights under the Terms of Service by sending an email to the Primary Email Address, providing notice through the eSanchari.com administrative console, or by similar means. Your continued access to or use of the eSanchari.com after we provide such notice, if applicable, or after we post such updated terms, constitutes your acceptance of the changes and consent to be bound by the Terms of Service as amended. If you do not agree to the amended Terms of Service, you must stop accessing and using the Services.
          <br /><br />  If we make a change to the Terms of Service that adversely affects your rights under the Terms of Service or your use of eSanchari.com in a material way, we will notify you in advance (unless the change relates to legal requirements or to prevent abuse of our Services, among other things). If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.

          <br />
          <br />
          ENTIRE AGREEMENT:
          <br />These Terms and Conditions, Privacy Policy and any other terms or policies as may be prescribed by eSanchari.com from time to time, constitute the entire agreement between you and the eSanchari.com, which will govern your onboarding as a vendor to providing the Services and/or the platform, superseding any prior agreements between you and eSanchari.com regarding such services providing.

          <br />
          <br />

          CONTACT INFORMATION:
          <br />If you have any questions or comments about eSanchari.com Terms of Service as outlined above, you can write us on the address provided in “contact us” section of the eSanchari.com app or website.
          <br /><br />  eSanchari.com,
          <br />  A digital property of DIGIVERTI INFOTECH PVT LTD

          <br />


        </div>
      </div>
    </div>
  );
};

export default TermsPolicy;
