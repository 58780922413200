import React, { FC, useEffect, Fragment, useMemo, useState, useInsertionEffect } from "react";
import { Extras, GeneralPackage, PackageOrder, PriceRates, ProductDataType, ProductDetailsDataType, ticketDetails } from "../../data/types";
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import FormItem from "../PageAddListing1/FormItem";
import Input from 'shared/Input/Input';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { Form, useSearchParams } from "react-router-dom";

export interface AddRemoveMemberDetailsProps {
  selectedPackage: GeneralPackage | undefined;
  setticketDetail:any;
  setIsValidMemberDetails:any;
  defaultGuest:ticketDetails[] | undefined;
  setGuestInput:any;
  selectedRate:PriceRates | undefined;
}

const AddRemoveMemberDetails: FC<AddRemoveMemberDetailsProps> = ({
selectedPackage,setticketDetail,setIsValidMemberDetails,defaultGuest,setGuestInput,selectedRate
}) => {
  const [ticketDetails, setticketDetails] = useState<ticketDetails[]>();
  const [newTicketDetail, setnewTicketDetail] = useState<ticketDetails>();
  const [errorsavemessage, seterrorsavemessage] = useState<string>("");
  const [errormessage, seterrormessage] = useState<string>("");
  const [isEditModeOn, setIsEditModeOn] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    if(selectedPackage?.guestRequired != true){
      defaultGuest![0].pricePerUnit = (selectedRate?.basePrice != null && selectedRate?.basePrice != undefined) ? selectedRate?.basePrice?.toString() : "";
    }
    
    setticketDetails(defaultGuest!)
    setticketDetail(defaultGuest!)
    //setnewTicketDetail(defaultGuest)
  }, [defaultGuest]);

  useEffect(() => {
    let isValidMembers = errormessage == "" && ticketDetails != undefined && ticketDetails?.length != 0
    setIsValidMemberDetails(isValidMembers);
    if (ticketDetails != undefined) {
      setGuestInput({
        guestAdults: ticketDetails?.length,
        guestChildren: 0,
        guestInfants: 0,
      });
    }
  }, [errormessage, newTicketDetail, ticketDetails]);

  const handleAgeChange = (e: any) => {
    seterrormessage("");
    if(e.target.value < 1 || e.target.value > 120){
      seterrormessage("Age limit is 1-120");
      return;
    }
    let newmemberticket: ticketDetails = {
      age: e.target.value,
      firstName: newTicketDetail?.firstName ?? "",
      lastName: newTicketDetail?.lastName ?? "",
      phoneNumber: newTicketDetail?.phoneNumber ?? "",
      pricePerUnit: (selectedRate?.basePrice != null && selectedRate?.basePrice != undefined) ? selectedRate?.basePrice?.toString() : "",
      serialNumber: newTicketDetail?.serialNumber ?? "",
    };
    setnewTicketDetail(newmemberticket);
  }
  const handlePhoneChange = (e: any) => {
    seterrormessage("");
    let newmemberticket: ticketDetails = {
      age: newTicketDetail?.age ?? "",
      firstName: newTicketDetail?.firstName ?? "",
      lastName: newTicketDetail?.lastName ?? "",
      phoneNumber: e,
      pricePerUnit: (selectedRate?.basePrice != null && selectedRate?.basePrice != undefined) ? selectedRate?.basePrice?.toString() : "",
      serialNumber: newTicketDetail?.serialNumber ?? "",
    };
    setnewTicketDetail(newmemberticket);
  }
  const handleNameChange = (e: any) => {
    seterrormessage("");
    let newmemberticket: ticketDetails = {
      age: newTicketDetail?.age ?? "",
      firstName: e.target.value,
      lastName: newTicketDetail?.lastName ?? "",
      phoneNumber: newTicketDetail?.phoneNumber ?? "",
      pricePerUnit: (selectedRate?.basePrice != null && selectedRate?.basePrice != undefined) ? selectedRate?.basePrice?.toString() : "",
      serialNumber: newTicketDetail?.serialNumber ?? "",
    };
    setnewTicketDetail(newmemberticket);
  }
  const handleLastNameChange = (e: any) => {
    seterrormessage("");
    let newmemberticket: ticketDetails = {
      age: newTicketDetail?.age ?? "",
      firstName: newTicketDetail?.firstName ?? "",
      lastName: e.target.value,
      phoneNumber: newTicketDetail?.phoneNumber ?? "",
      pricePerUnit: (selectedRate?.basePrice != null && selectedRate?.basePrice != undefined) ? selectedRate?.basePrice?.toString() : "",
      serialNumber: newTicketDetail?.serialNumber ?? "",
    };
    setnewTicketDetail(newmemberticket);
  }
  const handleBlockRemove = (item: ticketDetails) => {
    if(ticketDetails?.length! > 0){
      let updatedTicketDetails = ticketDetails?.map(x => x).filter(val => val.firstName != item.firstName && val.phoneNumber != item.phoneNumber);
      setticketDetails(updatedTicketDetails);
      setticketDetail(updatedTicketDetails);
      let isValidMembers = errormessage == "" && newTicketDetail != undefined && updatedTicketDetails != undefined && updatedTicketDetails?.length != 0
      setIsValidMemberDetails(isValidMembers);
      setGuestInput({
        guestAdults: updatedTicketDetails?.length,
        guestChildren: 0,
        guestInfants: 0,
      });
    }
  }

  const handleSubmitUpdate = (e:any, val:ticketDetails, idx:any) => {
    e.preventDefault();
    seterrormessage("");
    if(e.target[3]?.value == "" || e.target[3]?.value?.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) === null){
      seterrormessage("Phone number not valid");
      return;
    }
    if(isValidPhoneNumber(e.target[3]?.value) === false){
      seterrormessage("Phone number is not valid");
      return
    }
    if(e.target[2]?.value == "" || e.target[2]?.value?.match('^[0-9]*$') === null){
      seterrormessage("Age not valid");
      return;
    }
    if(e.target[2]?.value < 1 || e.target[2]?.value > 120){
      seterrormessage("Age limit is 1-120");
      return;
    }
    if(e.target[0]?.value == "" || e.target[0]?.value?.match('^[a-zA-Z ]*$') === null){
      seterrormessage("Name not valid");
      return;
    }
    let updatedmemberticket: ticketDetails = {
      age: e.target[2].value,
      firstName: e.target[0].value,
      lastName: e.target[1].value,
      phoneNumber: e.target[3].value,
      pricePerUnit: val.pricePerUnit,
      serialNumber: val.serialNumber,
    };
    let updatedTicketDetails = ticketDetails?.map(x => x);
    updatedTicketDetails?.splice(idx,1);
    updatedTicketDetails?.push(updatedmemberticket);
    setticketDetails(updatedTicketDetails);
    setticketDetail(updatedTicketDetails);
  }
  const updateOrder=() => {
    //Validation
    seterrormessage("");
    seterrorsavemessage("");
    if(newTicketDetail?.phoneNumber.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) === null){
      seterrormessage("Phone number not valid");
      return;
    }
    if(isValidPhoneNumber(newTicketDetail?.phoneNumber!) === false){
      seterrormessage("Phone number is not valid");
      return
    }
    if(newTicketDetail?.age.match('^[0-9]*$') === null){
      seterrormessage("Age not valid");
      return;
    }
    if(newTicketDetail?.firstName.match('^[a-zA-Z ]*$') === null){
      seterrormessage("Name not valid");
      return;
    }
    let allTickets = ticketDetails;
    if(allTickets == null || allTickets == undefined){
      allTickets = [newTicketDetail!]
    }
    else allTickets?.push(newTicketDetail!);

    setticketDetails(allTickets!)
    setticketDetail(allTickets!)
    let newmemberticket:ticketDetails = {
      age: "",
      firstName:"",
      lastName: "",
      phoneNumber: "",
      pricePerUnit:(selectedRate?.basePrice != null && selectedRate?.basePrice != undefined) ? selectedRate?.basePrice?.toString() : "",
      serialNumber: "",
    };
    setnewTicketDetail(newmemberticket);
  }

  return (
    <div className="space-y-6">
      <h3 className="text-2xl font-semibold">Booking detail</h3>
      <div className="flow-root">
        {(ticketDetails != undefined && ticketDetails?.length! > 0) ?
          ticketDetails?.map((item,idx) => (
            <form onSubmit={(e:any) => handleSubmitUpdate(e, item, idx)}>
              <div key={item.firstName + Math.random()} className="flex sm:flex-row hidden lg:flex sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                <div className="grid pb-2 grid-cols-1 md:grid-cols-4 gap-8 md:gap-5">
                    <Input defaultValue={item?.firstName} placeholder="First Name" />
                    <Input defaultValue={item?.lastName} placeholder="Last Name" />
                    <Input defaultValue={item?.age}
                      maxLength={2} placeholder="Age" type="number"/>
                    <Input defaultValue={item?.phoneNumber}
                      placeholder="Phone" />
                </div>
                <button type="submit">
                    <i className="text-3xl pt-1 text-neutral-400 las la-save hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"></i>
                    </button>
                <i onClick={() => handleBlockRemove(item)} className="text-3xl pt-3 text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"></i>
              </div>
              {/* Mobile view */}
              <div key={item.firstName + Math.random()} className="lg:hidden justify-between border p-1 mb-2 bg-neutral-200 pt-2 space-y-2">
                <div className="grid pb-0 grid-cols-2 gap-2">
                    <Input defaultValue={item?.firstName} placeholder="First Name" />
                    <Input defaultValue={item?.lastName} placeholder="Last Name" />
                    <Input defaultValue={item?.age}
                      maxLength={2} placeholder="Age" type="number"/>
                    <Input defaultValue={item?.phoneNumber}
                      placeholder="Phone" />
                </div>
                <div className="flex justify-end" style={{alignItems:'baseline'}}>
                  <button type="submit">
                    <i className="text-3xl pt-1 text-neutral-400 las la-save hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"></i>
                  </button>&nbsp;
                  <i onClick={() => handleBlockRemove(item)} className="text-3xl pt-3 text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"></i>
                </div>
              </div>
            </form>
          ))
          :
          <span className="block pt-2 text-sm text-red-500 dark:text-neutral-400"> No Guest Added. Please add below!
          </span>
        }
            <div className="flex pt-4 flex-col pb-5 sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-5">
                <FormItem >
                  <Input value={newTicketDetail?.firstName}
                    onChange={(e: any) => handleNameChange(e)}
                    placeholder="First Name" />
                </FormItem>
                <FormItem >
                  <Input value={newTicketDetail?.lastName}
                    onChange={(e: any) => handleLastNameChange(e)}
                    placeholder="Last Name" />
                </FormItem>
                <FormItem>
                  <Input defaultValue={newTicketDetail?.age}
                    onChange={(e: any) => handleAgeChange(e)}
                    placeholder="Age" type="number"/>
                </FormItem>
                <FormItem>
                  <PhoneInput
                    className="h-11"
                    style={{ "--PhoneInputCountryFlag-height": "1rem", "width": "100%" }}
                    international
                    defaultCountry="IN"
                    value={newTicketDetail?.phoneNumber}
                    onChange={(e: any) => handlePhoneChange(e)}
                    placeholder="Phone"
                    required />
                </FormItem>
              </div>
              <ButtonPrimary onClick={() => updateOrder()}
                disabled={errormessage != "" || newTicketDetail?.age == ""
                  || newTicketDetail?.firstName == "" || newTicketDetail?.phoneNumber == ""
                  || newTicketDetail == null}
                className="flex-shrink-0">
                <i className="text-xl las la-plus" style={{ marginLeft: "-9px", marginRight: "-9px" }}></i>
              </ButtonPrimary>
            </div>
            {errormessage != "" ?
              <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                <span className="block pt-2 text-sm text-red-500 dark:text-neutral-400"> {errormessage}</span>
              </div>
              : null}

        {errorsavemessage != "" ?
          <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
            <span className="block pt-2 text-sm text-red-500 dark:text-neutral-400"> {errorsavemessage}</span>
          </div>
          : null}
      </div>
    </div>
  )
};

export default AddRemoveMemberDetails;
