import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import Otpinput from "common/OTPInput";

import { useSearchParams } from 'react-router-dom';
import SessionManager from './../../common/SessionManager';
import Constant from 'common/constants';


export interface MobileOTPVerificationProps {
  className?: string;
}



const MobileOTPVerification: FC<MobileOTPVerificationProps> = ({ className = "" }) => {
  const [errormessage, seterrormessage] = useState("");
  const [userCreated, setUserCreated] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isVendor, setIsVendor]  = useState(false);

  function onInputComplete(val:string) {
    let mobile = "+" + searchParams.get("mobile")?.trim();
    setIsVendor((searchParams.get("role")?.trim() == "Vendor")?true:false);
    confirmSignUp(mobile, val);
  }

  function confirmSignUp(user_name: string, code: string) {
    try {
      Auth.confirmSignUp(user_name, code)
        .then(resp => {
          var isVendorRole = (searchParams.get("role")?.trim() == "Vendor") ? true : false
          setIsVendor(isVendorRole);
          //adding vendor role
          if (isVendorRole) {
            // SessionManager.getJWTToken((jwtToken: any) => {
            //   const requestOptions = {
            //     method: 'POST',
            //     body: JSON.stringify({
            //       companyId: "",
            //       userId: user_name,
            //       firstName: user_name,
            //       productId: "",
            //       phoneNumber: user_name,
            //       role: "Vendor",
            //     }),
            //     headers: {
            //       'Content-Type': 'application/json',
            //       'Authorization': 'Bearer ' + jwtToken,
            //     },
            //   };
            //   fetch(Constant.API_CONTEXT_URL + '/user_role/addUserRole', requestOptions)
            //     .then(response => {
            //       const isJson = response.headers.get('content-type')?.includes('application/json');
            //       const data = isJson && response.json();
                  
                  window.location.assign("#/login?Vendor=true&mobile=" + user_name);
            //     })
            //     .catch(error => {
            //       console.log('There was an error!', error);
            //     });
            // });
          }
          else {
            window.location.assign("#/login?mobile=" + user_name);
          }
        })
        .catch(error => {
          alert('Error: ' + error);
        });

    } catch (error) {
      seterrormessage('error confirming sign up: ' + error)
    }
  }
 
  return (
    <div className={`nc-MobileOTPVerification  ${className}`} data-nc-id="MobileOTPVerification">
      <Helmet>
        <title>Sign up || eSanchari</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Verify your OTP
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          
          {/* FORM */}
          <div className="grid grid-cols-1 gap-6" >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
              </span>
              <Otpinput
                        onInputComplete={onInputComplete}
                      />
            </label>

            
            <label className="block">
              <span className="flex justify-between items-center text-red-800 ">
                {errormessage}
              </span>
              
            </label>
            
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="#/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default MobileOTPVerification;


