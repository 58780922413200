import React, { FC, useEffect, Fragment, useMemo, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Dialog, Transition } from "@headlessui/react";
import { CartOrders, Coupon, Order } from "../../data/types";
import constants from 'common/constants';
import { Auth } from "aws-amplify";
import moment from "moment";
import Badge from 'shared/Badge/Badge';
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import Button from "../../shared/Button/Button";
import SessionManager from './../../common/SessionManager';

export interface AccountEmailVerifyProps {
  openModal: () => void;
  closeModal: () => void;
  isOpenModal: boolean;
  setEmailVerified:any;
  saveToSystem: any;
}

const AccountEmailVerify: FC<AccountEmailVerifyProps> = ({
  openModal,
  closeModal,
  isOpenModal,
  setEmailVerified,
  saveToSystem
}) => {
  const [verificationCode, setverificationCode]: any = React.useState();
  const [isReadyForUpdate, setIsReadyForUpdate] = React.useState(false);

  const handleChangeverificationCode = (e: any) => {
    setIsReadyForUpdate(false);
    if (e.target.value != "" && e.target.value != null) {
      setverificationCode(e.target.value);
      setIsReadyForUpdate(true);
    }
  }

  const verifyEmail = () => {
    SessionManager.getJWTToken(async (jwtToken: any) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwtToken,
        },
      };
      const url = constants.API_CONTEXT_URL + '/account/verifyEmail?verifyCode=' + verificationCode;
      fetch(url, requestOptions)
        .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          if (data.response == true) {
            setEmailVerified(true);
            saveToSystem(true);
            closeModal();
            alert("Email verified successfully !!")
          }
          else {
            alert("Incorrect code !!")
          }
        })
        .catch((error) => {
          alert("Error sending email " + error);
        });
    })
  }

  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 h-screen w-full max-w-4xl">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h2
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="headlessui-dialog-title-70"
                  >
                    Verify Email
                  </h2>
                  <span className="absolute right-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>
                <div className="overflow-auto">
                  <div className="flex space-x-4 p-5" >
                    <div className="listingSection__wrap">
                      <div className="space-x-3">
                        <span className="text-lg text-green-500 mt-1">
                          Verification code sent to your registered email, Please check and verify here..
                        </span>
                        <div className="width-fill p-4">
                          <Label>Enter the verification code:</Label>
                          <Input className="mt-1.5" placeholder="6 digit verification code"
                            onChange={(e: any) => handleChangeverificationCode(e)}
                            defaultValue={verificationCode} />
                        </div>
                        <div className="flex justify-center items-center">
                          <Button className="mt-3 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50"
                            disabled={!isReadyForUpdate} onClick={() => verifyEmail()}>Verify</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
};

export default AccountEmailVerify;
