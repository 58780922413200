import React, { FC, useEffect, Fragment, useMemo, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Dialog, Transition } from "@headlessui/react";
import { Extras, GeneralPackage, Order, PackageOrder, PriceRates, ProductDataType, ProductDetailsDataType, ticketDetails } from "../../data/types";
import moment from 'moment';
import constants from 'common/constants';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ClientStore from "../../common/ClientStore";
import axios from 'axios';
import SessionManager from "../../common/SessionManager";
import AnchoringPackage from "./AnchoringPackage";
import GuestsPackage from "./GuestsPackage";
import { GuestsObject } from "../../components/HeroSearchForm2Mobile/GuestsInput";
import { useSearchParams } from "react-router-dom";
import ExperiencesDateSingleInput from 'components/HeroSearchForm/ExperiencesDateSingleInput';
import Badge from 'shared/Badge/Badge';
import 'react-phone-number-input/style.css'
import AddRemoveMemberDetails from "./AddRemoveMemberDetails";

export interface ModalEditBookingProps {
  closeBookingModal: () => void;
  isOpenModal: boolean;
  product: ProductDataType | undefined;
  selectedPackageId: string | undefined;
  selectedPackage: GeneralPackage | undefined;
  dateValue: moment.Moment | null;
  selectedAnchor: any;
  setdateValue: any;
  orderDetails: Order | undefined;
}

const ModalEditBooking: FC<ModalEditBookingProps> = ({
  closeBookingModal,
  isOpenModal,
  product,
  dateValue, selectedAnchor,
  setdateValue, selectedPackage,
  orderDetails
}) => {
  const [events, setevents] = useState<any>([])
  const [mobileCheck, setmobileCheck] = useState<boolean>(window.innerWidth >= 768 ? false : true);
  const [isloggedin, setisloggedin] = useState(SessionManager.getUser());
  const [anchorHoursInput, setAnchorHoursInput] = useState<number>(0);
  const [personCount, setpersonCount] = useState<number>(getTotalCount(orderDetails!));
  const [ticketDetails, setticketDetails] = useState<ticketDetails[]>();
  const [selectedRate, setselectedRate] = useState<PriceRates>();
  const [defaultGuest, setDefaultGuest] = useState<ticketDetails[] | null>();

  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: getTotalCount(orderDetails!),
    guestChildren: 0,
    guestInfants: 0,
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [guestListError, setGuestListError] = useState<string>('');
  const [isValidBookTime, setIsValidBookTime] = useState<boolean>(true);
  const [isValidMemberDetails, setIsValidMemberDetails] = useState<boolean>(false);

  useEffect(() => {
    if (isloggedin != null) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          packageId: orderDetails?.packageId,
          productType: orderDetails?.type?.includes('Yacht') ? "Yacht" : orderDetails?.type,
          endDate: moment(dateValue).format('yyyy-MM-DD'),
          startDate: moment(dateValue).format('yyyy-MM-DD')
        })
      };
      fetch(constants.API_CONTEXT_URL + '/rate/viewRates', requestOptions)
        .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          setselectedRate(data.response[0]);
          let selectedrate = data.response[0];
          let userIdTrimmed: string = SessionManager.getUserId();
          var userId = userIdTrimmed.split('+')
          let userid: any = { "userId": SessionManager.getUserId() }
          const url = constants.API_CONTEXT_URL + '/account/viewUser?userId=%2B' + userId[1];
          axios.get(url, userid)
            .then((response) => {
              let userDetails: any = response.data.response;
              let user: ticketDetails[] = [{
                age: calculate_age(userDetails?.dateOfBirth), // using last name to fill in age field for now dec 2022
                firstName: SessionManager.getUserName(),
                lastName: SessionManager.getUserLastName(),
                phoneNumber: SessionManager.getUserId(),
                pricePerUnit: (selectedrate?.basePrice != undefined && selectedrate?.basePrice != null) ? ("" + selectedrate?.basePrice.toString()) : "",
                serialNumber: ""
              }]
              if(orderDetails?.type?.includes("Yacht") || orderDetails?.type == "SpeedBoat"){
                const requestOptions:any = {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                };
                axios.get(constants.API_CONTEXT_URL + '/order/viewOrder?orderId=' + orderDetails.orderId, requestOptions)
                  .then((response) => {
                    const extras: Extras = response?.data?.response?.details?.extras;
                    if(extras != undefined && extras != null){
                      setAnchorHoursInput(+extras[selectedAnchor?.id]);
                    }
                    else{
                      setAnchorHoursInput(1);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if(!(data?.type?.includes("Yacht") || data?.type == "SpeedBoat")){
                setDefaultGuest(orderDetails?.guestListRequired == true ? orderDetails?.ticketDetails : user);
              }
            });
        })
        .catch(error => {
          console.error('There was an error!', error);
        });


    }
  }, []);

  useEffect(() => {
    if (guestInput != undefined && guestInput?.guestAdults != undefined) {
      let total1 = guestInput != undefined ? guestInput.guestAdults : 0
      let total2 = guestInput != undefined ? guestInput.guestChildren : 0
      let total3 = guestInput != undefined ? guestInput.guestInfants : 0
      let total = (total1 ?? 0) + (total2 ?? 0) + (total3 ?? 0)
      setpersonCount(total)
    }
    setGuestListError("");
  }, [guestInput]);


  const calculate_age = (dob1: any) => {
    var today = new Date();
    var birthDate = new Date(dob1);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log(age_now);
    return age_now.toString();
  }
  const CalculateTotalAmount = (basePrice: any, personCount: any) => {
    let totalBasePrice = basePrice == undefined ? 0 : basePrice * personCount;
    //Calculate Anchor Price
    //let totalAnchorAmount = selectedAnchor != null ? anchorHoursInput * selectedAnchor?.pricePerUnit : 0
    let totalAmount = totalBasePrice;
    return totalAmount;
  }
  const CalculateTotalAmountDisplay = (basePrice: any, personCount: any) => {
    let totalBasePrice = basePrice == undefined ? 0 : basePrice * personCount;
    //Calculate Anchor Price
    let totalAnchorAmount = selectedAnchor != null ? anchorHoursInput * selectedAnchor?.pricePerUnit : 0
    let totalAmount = totalBasePrice + totalAnchorAmount;
    return totalAmount;
  }
  function getTotalCount(data: Order) {
    let count = data.guestListRequired == true ? data.ticketDetails?.length ?? 0 : data.nonGuestCount;
    if(data?.type?.includes("Yacht")||data?.type == "SpeedBoat"){
      return data.nonGuestCount;
    }
    return count;
  }

  const closeButtonHandle = () =>{
    closeBookingModal();
    setticketDetails(orderDetails?.ticketDetails!)
  }

  const updateCart = () => {
    let PackageOrderobj: PackageOrder;
    let selectedAnchorId = selectedAnchor?.id;
    let ext: Extras = {};
    ext[selectedAnchorId] = anchorHoursInput?.toString() ?? "";

    if(!(product?.type?.includes("Yacht") || product?.type == "SpeedBoat")
        && selectedPackage?.guestRequired 
         && ticketDetails?.length != personCount){
      setGuestListError("Please update the Guest list!");
      return;
    }
    // if((product?.type?.includes("Yacht") || product?.type == "SpeedBoat") && anchorHoursInput < 1){
    //   setGuestListError("Minimum anchoring hours is 1!");
    //   return;
    // }
    PackageOrderobj = {
      details: {
        comments: orderDetails?.comments ?? "",
        couponCode: orderDetails?.couponCode ?? "",
        eventDate: orderDetails?.eventDate!,//'2022-11-18T23:12:03.000+00:00'
        extras: selectedAnchorId != undefined ? ext : null,
        id: orderDetails?.id!,
        new: orderDetails?.new!,
        orderId: orderDetails?.orderId!,
        paymentDetails: orderDetails?.paymentDetails!,
        price: (!(product?.type?.includes("Yacht") || product?.type == "SpeedBoat") && selectedPackage?.guestRequired) ? +selectedRate?.basePrice! :
          CalculateTotalAmount(selectedRate?.basePrice, guestInput?.guestAdults == undefined ? 1 : guestInput?.guestAdults),
        ticketDetails: ticketDetails != undefined ? ticketDetails : orderDetails?.ticketDetails,
        nonGuestCount: guestInput?.guestAdults ?? orderDetails?.nonGuestCount!,
        guestListRequired: selectedPackage?.guestRequired!,
      },
      id: orderDetails?.id!,
      new: true,
      packageId: selectedPackage?.id ?? orderDetails?.packageId,
      productId: product?.id ?? orderDetails?.productId!,
      stage: 'New',
      type: product?.type ?? orderDetails?.type!,
      userId: isloggedin ? SessionManager.getUserId() : orderDetails?.userId!,
    }

    if (isloggedin != null) {
      SessionManager.getJWTToken((jwtToken: any) => {
        const url = constants.API_CONTEXT_URL + '/order/saveOrder';
        axios.post(url, PackageOrderobj, {
          headers: {
            Authorization: 'Bearer ' + jwtToken
          }
        })
          .then((response) => {
            let orderobj: PackageOrder = response.data.response;
            alert("Order Updated Successfully !!");
            window.location.reload();
            closeBookingModal();
          })
          .catch((error) => {
            alert("Order cannot be processed" + error);
          });
      });
    }
    else {
      // Save in Local Storage to access after login
      ClientStore.set('localstorage', 'nonSessionOrder', JSON.stringify(PackageOrderobj))
      window.location.assign("#/login")
    }
  }

  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeBookingModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 h-screen w-full max-w-4xl">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h2
                    className="text-xl leading-6 text-gray-900"
                    id="headlessui-dialog-title-70"
                  >
                    Update Your Booking
                  </h2>
                  <span className="absolute right-3 top-3">
                    <ButtonClose onClick={closeBookingModal} />
                  </span>
                </div>
                <div className="px-2 text-neutral-700 overflow-scroll scrollHide dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="flow-root py-3">
                    <div className="listingSectionSidebar__wrap">
                      {/* PRICE */}
                      <div className="flex justify-between">
                        <span className="text-3xl font-semibold">
                          ₹{selectedRate?.basePrice}
                          <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                            {(product?.type?.includes("Yacht") || product?.type?.includes("SpeedBoat")) ? '/hr' :
                              product?.type?.includes("Wheel") || product?.type?.includes("Vehicle")
                                || product?.type?.includes("Cycle") || product?.type?.includes("Car") ? '/day' : '/person'}
                          </span>
                        </span>
                        <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                          Your booking with {selectedPackage?.title}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-base sm:text-lg font-medium mt-1 block">
                          <a href={`#/product?productId=${product?.id}&name=${product?.title}`}>
                            {product?.title}
                          </a>
                          &nbsp;<Badge name={product?.type} color="green" />
                        </span>
                      </div>

                      {/* FORM */}
                      <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
                        <ExperiencesDateSingleInput
                          defaultValue={dateValue}
                          onChange={(cdt: any) => setdateValue(cdt)}
                          className="flex-1"
                          disabled={true}
                        />
                        <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                        <GuestsPackage
                          className="nc-ProductDetailPage__guestsInput px-4 flex-1"
                          fieldClassName="p-3"
                          defaultValue={guestInput}
                          hasButtonSubmit={false}
                          selectedPackageMaxUnits={selectedRate?.availability != "" ? selectedRate?.availability : selectedPackage?.packageMaxUnits}
                          onChange={setGuestInput}
                          label={(product?.type?.includes("Yacht") || product?.type == "SpeedBoat") ? 'Riding Hours' :
                            product?.type?.includes("Wheel") || product?.type?.includes("Vehicle")
                              || product?.type?.includes("Cycle") || product?.type?.includes("Car") ? 'Number of Day(s)' : 'Number of Guests'}
                        />
                        {selectedAnchor != null && anchorHoursInput != 0 && (product?.type?.includes("Yacht") || product?.type == "SpeedBoat") ?
                          <AnchoringPackage
                            className="nc-ProductDetailPage__guestsInput px-4 flex-1"
                            fieldClassName="p-3"
                            defaultValue={anchorHoursInput}
                            hasButtonSubmit={false}
                            onChange={setAnchorHoursInput}
                            label={selectedAnchor?.title}
                            SelectedAnchor={selectedAnchor}
                          />
                          : null}
                      </form>
                      {selectedPackage?.guestRequired && defaultGuest != undefined ?
                        <AddRemoveMemberDetails
                          selectedPackage={selectedPackage} 
                          setticketDetail={setticketDetails}
                          setIsValidMemberDetails={setIsValidMemberDetails}
                          defaultGuest={defaultGuest!}
                          setGuestInput={setGuestInput}
                          selectedRate={selectedRate}
                        />
                        : null}
                      {/* SUM */}
                      <div className="flex flex-col space-y-4">
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                          <span>₹{selectedRate?.basePrice} x {personCount} {(product?.type?.includes("Yacht") ||
                            product?.type == "SpeedBoat") ? 'hr + Anchoring charges' :
                            product?.type?.includes("Wheel") ||
                              product?.type?.includes("Vehicle") ||
                              product?.type?.includes("Cycle") ||
                              product?.type?.includes("Car") ? 'day' : 'person'}
                            {/* {selectedAnchor != null ? ` for ${anchorHoursInput} hrs`
                : null} */}
                          </span>
                          <span>₹{CalculateTotalAmountDisplay(selectedRate?.basePrice, personCount)}</span>
                        </div>
                        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                        <div className="flex justify-between font-semibold">
                          <span>Total</span>
                          <span>₹{CalculateTotalAmountDisplay(selectedRate?.basePrice, personCount)}</span>
                        </div>
                      </div>
                      <div className="flex justify-space-evenly">
                        <ButtonPrimary onClick={updateCart}
                          disabled={!isValidBookTime
                            || (!isValidMemberDetails && selectedPackage?.guestRequired && !(product?.type?.includes("Yacht") || product?.type == "SpeedBoat"))
                            || ((selectedRate?.availability == "-1" ? 100 : +selectedRate?.availability!) < guestInput?.guestAdults!)
                            }>
                          Update Cart
                        </ButtonPrimary>
                        <ButtonPrimary onClick={() => closeButtonHandle()}>Close</ButtonPrimary>
                      </div>

                      {!(product?.type?.includes("Yacht") || product?.type == "SpeedBoat")? (selectedRate?.availability != null && selectedRate?.availability != "0" && selectedRate?.availability != "-1" ?
                        <span className="flex justify-between text-neutral-6000 dark:text-neutral-300">Total seats left: {selectedRate?.availability}</span>
                        :
                        null) : null
                      }
                      <label className="block">
                        <span className="flex justify-between items-center text-red-800 ">
                          {errorMessage}
                        </span>

                      </label>
                      {guestListError != "" ?
                        <label className="block">
                          <span className="flex justify-between items-center text-red-800 ">
                            {guestListError}
                          </span>

                        </label>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
};

export default ModalEditBooking;
