import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import kishor from "images/avatars/kishor.jpg";

export interface RelatedArticles {
  id: string;
  href: string;
  featuredImage: string;
  title: string;
  author: string;
  date: string;
}

const BlogPost3 = () => {

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Holiday spendings by an Indian
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
          The Indian mentality on spending money for a holiday can vary significantly based on factors such as socio-economic status, regional culture, personal values, and individual preferences. Here are some common perspectives.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11"
                imgUrl={kishor}
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    Kishor Kumar
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    Aug 12, 2024
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    5 min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <h3>Value for Money</h3>
        <p>
        Many Indians tend to seek value for their money. They often compare prices, look for discounts, and consider package deals. There is a focus on ensuring that the expenditure on a holiday is justified by the experiences gained.
        </p>
        <h3>Cost-Consciousness</h3>
        <p>
        Traditionally, there is a strong emphasis on saving and being thrifty. This can lead some individuals to prioritize budget travel options and avoid overspending, seeking affordable accommodations and transportation.
        </p>
        <h3>Familial Importance</h3>
        <p>
        Holidays are often viewed as family bonding experiences. Many people may choose to spend more to accommodate extended family members, leading to larger group travel and shared expenses.
        </p>
        <figure>
          <img
            src="https://images.pexels.com/photos/8241135/pexels-photo-8241135.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            alt="nc blog"
            className="rounded-2xl"
          />
          <figcaption>
          {/* Overall, taking a holiday can greatly enhance quality of life, and it’s often essential for maintaining balance in a busy world. */}
          </figcaption>
        </figure>
        <h3>Seeking Experiences</h3>
        <p>
        There is a growing trend, especially among younger people, to prioritize experiences over material possessions. This could lead to a willingness to spend on unique and memorable experiences during holidays.
        </p>
        <h3>Cultural and Religious Influences</h3>
        <p>
        Holidays often align with cultural or religious festivals, influencing travel patterns.
        Spending might increase during these times as people seek to visit family or pilgrimage sites.
        </p>
        <h3>Status and Social Influences</h3>
        <p>
        In some social circles, travel and spending on vacations can also be a status symbol. People may feel pressure to showcase travel experiences on social media, which can influence spending behavior.
        </p>
        <figure>
          <img
            src="https://images.pexels.com/photos/346885/pexels-photo-346885.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            alt="nc blog"
            className="rounded-2xl"
          />
          <figcaption>
          Overall, while budget considerations are crucial, there is a balancing act between spending wisely and seeking enjoyable, enriching experiences during holidays.
          </figcaption>
        </figure>
        <h3>Rise of Domestic Travel</h3>
        <p>
        With the increase in domestic tourism due to the pandemic, many Indians are more inclined to explore local destinations. This sometimes leads to more spending on experiences close to home.
        </p>
        <h3>Pragmatic Planning</h3>
        <p>
        Many individuals approach holiday planning pragmatically, often budgeting in advance and determining their travel itinerary to maximize enjoyment without overspending.
        </p>
      </div>
    );
  };

  const relatedposts: RelatedArticles[] = [
    {
      id: "1",
      href: "/blogpost1",
      featuredImage: "https://images.unsplash.com/photo-1501493870936-9c2e41625521?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1045&amp;q=80",
      title: "Why one should consider taking a holiday?",
      author: "Kishor Kumar",
      date: "July 22, 2024"
    },
    {
      id: "2",
      href: "/blogpost2",
      featuredImage: "https://images.pexels.com/photos/3155666/pexels-photo-3155666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
      title: "The travel industry in India is a significant and rapidly growing sector of the economy",
      author: "Kishor Kumar",
      date: "July 29, 2024"
    }
  ]

  const renderPostRelated = (post: RelatedArticles) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Travel" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Single Blog || eSanchari</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://images.pexels.com/photos/3935702/pexels-photo-3935702.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
      </div>
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-8 lg:py-14 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {/*  */}
            {relatedposts.map(renderPostRelated)}
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost3;
