import React from 'react'
import { Auth } from 'aws-amplify';
import ClientStore from './ClientStore'

export default class SessionManager  {
    static getSession(){
        return ClientStore.get('localstorage', 'session')
    }

    static setSession(session){

      ClientStore.set('localstorage', 'session', JSON.stringify(session))
    }


    static logout() {
      // ClientStore.remove('localstorage', 'session')
      Auth.signOut({ global: true });
      localStorage.clear();
      window.location = "/"

    }

    static async getJWTToken(callback) {
      Auth.currentSession()
      .then(res=>{
        let idToken = res.getIdToken()
        callback(idToken["jwtToken"]);
      })
      .catch(err =>
        {
            callback(null);
        } );
      
    }

    static async getUserEmailVerifiedStatus(callback) {
      Auth.currentUserInfo()
      .then(user=>{
        callback( user.attributes.email_verified);
      })
      .catch(err =>
        {
            callback(null);
        } );
      
    }

    static getUser(){
      if(this.getSession()){
        var session = JSON.parse(this.getSession());
        return session['user'];
      }
      else return null;
      
    }
    static setUser(user){
      if(this.getSession()){
        var session = JSON.parse(this.getSession());
        session['user'] = user;
        this.setSession(session);
      }
      else return null;
      
    }

    static getUserId(){
      if(this.getSession()){
        var session = JSON.parse(this.getSession());
        return session['user']['userId'];
      }
      else return null;
      
    }

    static getUserName(){
      if(this.getSession()){
        var session = JSON.parse(this.getSession());
        return session['user']['firstName'];
      }
      else return null;
      
    }
    static getUserLastName(){
      if(this.getSession()){
        var session = JSON.parse(this.getSession());
        return session['user']['lastName'];
      }
      else return null;
      
    }
    
    static getCompanies(){
      if(this.getSession()){
        var session = JSON.parse(this.getSession());
        return session['company'] ;
      }
      else return null;
      
    }

    static getAccess(){
      if(this.getSession()){
        var session = JSON.parse(this.getSession());
        return session['access'] ;
      }
      else return null;
      
    }

    static getRole(){
      if(this.getSession()){
        var session = JSON.parse(this.getSession());
        return session['role'] ? session['role'] :'Buyer';
      }
      else return "Buyer";
      
    }

    static getProduct(){
      if(this.getSession()){
        var session = JSON.parse(this.getSession());
        return session['product'];
      }
      else return "";
      
    }
    static setProduct(product){
      if(this.getSession()){
        var session = JSON.parse(this.getSession());
        session['product'] = JSON.parse(product);
      }
      else return "";
      
    }
  
    static getCurrentAuthenticationStep(){
      return ClientStore.get('localstorage', 'currentAuthenticationStep')
    }
    static setCurrentAuthenticationStep(val){
      ClientStore.set('localstorage', 'currentAuthenticationStep', JSON.stringify(val))
    }
    static removeCurrentAuthenticationStep(){
      ClientStore.remove('localstorage', 'currentAuthenticationStep');
    }
    
    static isEditable(){
      return this.getRole() != "Buyer"
    }
}

