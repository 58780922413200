import Label from "components/Label/Label";
import React, { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import SessionManager from "common/SessionManager";
import constants from "common/constants";
import { Auth } from "aws-amplify";
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Button from "../../shared/Button/Button";
import AccountEmailVerify from "./AccountEmailVerify";

export interface AccountPageProps {
  className?: string;
}


const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {

  const [user, setUser] = useState(SessionManager.getUser())
  const [media, setmedia] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [successmessge, setsuccessmessge] = useState('')
  const [selectedFile, setSelectedFile] = useState();
  const inputFileRef = React.useRef<any>(null);
  const [isloggedin, setisloggedin] = React.useState(SessionManager.getUser());
  // const [isEmailVerified, setisEmailVerified] = React.useState<string>();
  const moment = require('moment');

  const [anniversaryDateValue, setanniversaryDateValue] = useState(null);
  const [genderValue, setGenderValue]: any = React.useState();
  const [firstName, setfirstName]: any = React.useState();
  const [lastName, setlastName]: any = React.useState();
  const [dobDateValue, setdobDateValue] = useState(null);
  const [email, setemail]: any = React.useState();
  const [existingEmailId, setExistingEmailId]: any = React.useState();
  const [address, setaddress]: any = React.useState();
  const [pincode, setpincode]: any = React.useState();
  const [phn, setPhn]: any = React.useState();
  const [selectedCountry, setSelectedCountry]: any = React.useState();
  const [selectedState, setSelectedState]: any = React.useState();
  const [selectedCity, setSelectedCity]: any = React.useState();
  const [profileImage, setProfileImage]: any = React.useState();
  const [emailVerified, setEmailVerified]: any = React.useState();
  const [isReadyForUpdate, setIsReadyForUpdate] = React.useState(false);
  const [emailVerifyCode, setEmailVerifyCode] = React.useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);

  const data = {

    gender: [
      {
        name: "Male",
      },
      {
        name: "Female",
      }
    ],
    countries: [
      {
        name: "India",
        dial_code: "+91",
        country_code_ISO2: "IN",
        states: [
          {
            name: "Andaman and Nicobar Islands",
            cities: ["Port Blair"]
          },
          {
            name: "Andhra Pradesh",
            cities: ["Chittoor", "Amalapuram", "Anakapalle", "Anantapur", "Bapatla", "Bheemunipatnam", "Bhimavaram", "Bobbili", "Chilakaluripet", "Chirala", "Chittoor", "Dharmavaram", "Eluru", "Gooty", "Gudivada", "Gudur", "Guntakal", "Guntur", "Hindupur", "Jaggaiahpet", "Jammalamadugu", "Kadapa", "Kadiri", "Kakinada", "Kandukur", "Kavali", "Kovvur", "Kurnool", "Macherla", "Machilipatnam", "Madanapalle", "Mandapeta", "Markapur", "Nagari", "Naidupet", "Nandyal", "Narasapuram", "Narasaraopet", "Narsipatnam", "Nellore", "Nidadavole", "Nuzvid", "Ongole", "Palacole", "Palasa Kasibugga", "Parvathipuram", "Pedana", "Pithapuram", "Ponnur", "Proddatur", "Punganur", "Puttur", "Rajahmundry", "Rajam", "Rajampet", "Ramachandrapuram", "Rayachoti", "Rayadurg", "Renigunta", "Repalle", "Salur", "Samalkot", "Sattenapalle", "Srikakulam", "Srikalahasti", "Srisailam Project (Right Flank Colony) Township", "Sullurpeta", "Tadepalligudem", "Tadpatri", "Tanuku", "Tenali", "Tirupati", "Tiruvuru", "Tuni", "Uravakonda", "Venkatagiri", "Vijayawada", "Vinukonda", "Visakhapatnam", "Vizianagaram", "Yemmiganur", "Yerraguntla"]
          },
          {
            name: "Arunachal Pradesh",
            cities: ["Naharlagun", "Pasighat"]
          },
          {
            name: "Assam",
            cities: ["Barpeta", "Bongaigaon City", "Dhubri", "Dibrugarh", "Diphu", "Goalpara", "Guwahati", "Jorhat", "Karimganj", "Lanka", "Lumding", "Mangaldoi", "Mankachar", "Margherita", "Mariani", "Marigaon", "Nagaon", "Nalbari", "North Lakhimpur", "Rangia", "Sibsagar", "Silapathar", "Silchar", "Tezpur", "Tinsukia"]
          },
          {
            name: "Bihar",
            cities: ["Araria", "Arrah", "Arwal", "Asarganj", "Aurangabad", "Bagaha", "Barh", "Begusarai", "Bettiah", "BhabUrban Agglomeration", "Bhagalpur", "Buxar", "Chhapra", "Darbhanga", "Dehri-on-Sone", "Dumraon", "Forbesganj", "Gaya", "Gopalganj", "Hajipur", "Jamalpur", "Jamui", "Jehanabad", "Katihar", "Kishanganj", "Lakhisarai", "Lalganj", "Madhepura", "Madhubani", "Maharajganj", "Mahnar Bazar", "Makhdumpur", "Maner", "Manihari", "Marhaura", "Masaurhi", "Mirganj", "Mokameh", "Motihari", "Munger", "Murliganj", "Muzaffarpur", "Narkatiaganj", "Naugachhia", "Nawada", "Nokha", "Patna", "Piro", "Purnia", "Rafiganj", "Rajgir", "Ramnagar", "Raxaul Bazar", "Revelganj", "Rosera", "Saharsa", "Samastipur", "Sasaram", "Sheikhpura", "Sheohar", "Sherghati", "Silao", "Sitamarhi", "Siwan", "Sonepur", "Sugauli", "Sultanganj", "Supaul", "Warisaliganj"]
          },
          {
            name: "Chandigarh",
            cities: ["Chandigarh"]
          },
          {
            name: "Chhattisgarh",
            cities: ["Ambikapur", "Bhatapara", "Bhilai Nagar", "Bilaspur", "Chirmiri", "Dalli-Rajhara", "Dhamtari", "Durg", "Jagdalpur", "Korba", "Mahasamund", "Manendragarh", "Mungeli", "Naila Janjgir", "Raigarh", "Raipur", "Rajnandgaon", "Sakti", "Tilda Newra"]
          },
          {
            name: "Dadra and Nagar Haveli",
            cities: ["Silvassa"]
          },
          {
            name: "Delhi",
            cities: ["Delhi"]
          },
          {
            name: "Goa",
            cities: ["Marmagao", "Margao", "Mapusa", "Panaji"]
          },
          {
            name: "Gujarat",
            cities: ["Adalaj", "Ahmedabad", "Amreli", "Anand", "Anjar", "Ankleshwar", "Bharuch", "Bhavnagar", "Bhuj", "Chhapra", "Deesa", "Dhoraji", "Godhra", "Jamnagar", "Kadi", "Kapadvanj", "Keshod", "Khambhat", "Lathi", "Limbdi", "Lunawada", "Mahemdabad", "Mahesana", "Mahuva", "Manavadar", "Mandvi", "Mangrol", "Mansa", "Modasa", "Morvi", "Nadiad", "Navsari", "Padra", "Palanpur", "Palitana", "Pardi", "Patan", "Petlad", "Porbandar", "Radhanpur", "Rajkot", "Rajpipla", "Rajula", "Ranavav", "Rapar", "Salaya", "Sanand", "Savarkundla", "Sidhpur", "Sihor", "Songadh", "Surat", "Talaja", "Thangadh", "Tharad", "Umbergaon", "Umreth", "Una", "Unjha", "Upleta", "Vadnagar", "Vadodara", "Valsad", "Vapi", "Vapi", "Veraval", "Vijapur", "Viramgam", "Visnagar", "Wadhwan", "Wankaner"]
          },
          {
            name: "Haryana",
            cities: ["Bahadurgarh", "Bhiwani", "Charkhi Dadri", "Faridabad", "Fatehabad", "Gohana", "Gurgaon", "Hansi", "Hisar", "Jind", "Kaithal", "Karnal", "Ladwa", "Mahendragarh", "Mandi Dabwali", "Narnaul", "Narwana", "Palwal", "Panchkula", "Panipat", "Pehowa", "Pinjore", "Rania", "Ratia", "Rewari", "Rohtak", "Safidon", "Samalkha", "Sarsod", "Shahbad", "Sirsa", "Sohna", "Sonipat", "Taraori", "Thanesar", "Tohana", "Yamunanagar"]
          },
          {
            name: "Himachal Pradesh",
            cities: ["Shimla", "Mandi", "Solan", "Nahan", "Sundarnagar", "Palampur"]
          },
          {
            name: "Jammu & Kashmir",
            cities: ["Srinagar", "Jammu", "Baramula", "Anantnag", "Sopore", "KathUrban Agglomeration", "Rajauri", "Punch", "Udhampur"]
          },
          {
            name: "Jharkhand",
            cities: ["Dhanbad", "Ranchi", "Jamshedpur", "Bokaro Steel City", "Deoghar", "Phusro", "Adityapur", "Hazaribag", "Giridih", "Ramgarh", "Jhumri Tilaiya", "Saunda", "Sahibganj", "Medininagar (Daltonganj)", "Chaibasa", "Chatra", "Gumia", "Dumka", "Madhupur", "Chirkunda", "Pakaur", "Simdega", "Musabani", "Mihijam", "Patratu", "Lohardaga", "Tenu dam-cum-Kathhara"]
          },
          {
            name: "Karnataka",
            cities: ["Adyar", "Afzalpur", "Arsikere", "Athni", "Ballari", "Belagavi", "Bengaluru", "Chikkamagaluru", "Davanagere", "Gokak", "Hubli-Dharwad", "Karwar", "Kolar", "Lakshmeshwar", "Lingsugur", "Maddur", "Madhugiri", "Madikeri", "Magadi", "Mahalingapura", "Malavalli", "Malur", "Mandya", "Mangaluru", "Manvi", "Mudabidri", "Mudalagi", "Muddebihal", "Mudhol", "Mulbagal", "Mundargi", "Mysore", "Nanjangud", "Nargund", "Navalgund", "Nelamangala", "Pavagada", "Piriyapatna", "Puttur", "Raayachuru", "Rabkavi Banhatti", "Ramanagaram", "Ramdurg", "Ranebennuru", "Ranibennur", "Robertson Pet", "Ron", "Sadalagi", "Sagara", "Sakaleshapura", "Sanduru", "Sankeshwara", "Saundatti-Yellamma", "Savanur", "Sedam", "Shahabad", "Shahpur", "Shikaripur", "Shivamogga", "Shrirangapattana", "Sidlaghatta", "Sindagi", "Sindhagi", "Sindhnur", "Sira", "Sirsi", "Siruguppa", "Srinivaspur", "Surapura", "Talikota", "Tarikere", "Tekkalakote", "Terdal", "Tiptur", "Tumkur", "Udupi", "Vijayapura", "Wadi", "Yadgir"]
          },
          {
            name: "Kerala",
            cities: ["Adoor", "Alappuzha", "Attingal", "Chalakudy", "Changanassery", "Cherthala", "Chittur-Thathamangalam", "Guruvayoor", "Kanhangad", "Kannur", "Kasaragod", "Kayamkulam", "Kochi", "Kodungallur", "Kollam", "Kottayam", "Koyilandy", "Kozhikode", "Kunnamkulam", "Malappuram", "Mattannur", "Mavelikkara", "Mavoor", "Muvattupuzha", "Nedumangad", "Neyyattinkara", "Nilambur", "Ottappalam", "Palai", "Palakkad", "Panamattom", "Panniyannur", "Pappinisseri", "Paravoor", "Pathanamthitta", "Peringathur", "Perinthalmanna", "Perumbavoor", "Ponnani", "Punalur", "Puthuppally", "Shoranur", "Taliparamba", "Thiruvalla", "Thiruvananthapuram", "Thodupuzha", "Thrissur", "Tirur", "Vaikom", "Varkala", "Vatakara"]
          },
          ,
          {
            name: "Ladakh",
            cities: ["Kargil", "Leh"]
          },
          {
            name: "Madhya Pradesh",
            cities: ["Alirajpur", "Ashok Nagar", "Balaghat", "Bhopal", "Ganjbasoda", "Gwalior", "Indore", "Itarsi", "Jabalpur", "Lahar", "Maharajpur", "Mahidpur", "Maihar", "Malaj Khand", "Manasa", "Manawar", "Mandideep", "Mandla", "Mandsaur", "Mauganj", "Mhow Cantonment", "Mhowgaon", "Morena", "Multai", "Mundi", "Murwara (Katni)", "Nagda", "Nainpur", "Narsinghgarh", "Narsinghgarh", "Neemuch", "Nepanagar", "Niwari", "Nowgong", "Nowrozabad (Khodargama)", "Pachore", "Pali", "Panagar", "Pandhurna", "Panna", "Pasan", "Pipariya", "Pithampur", "Porsa", "Prithvipur", "Raghogarh-Vijaypur", "Rahatgarh", "Raisen", "Rajgarh", "Ratlam", "Rau", "Rehli", "Rewa", "Sabalgarh", "Sagar", "Sanawad", "Sarangpur", "Sarni", "Satna", "Sausar", "Sehore", "Sendhwa", "Seoni", "Shahdol", "Shajapur", "Shamgarh", "Sheopur", "Shivpuri", "Shujalpur", "Sidhi", "Sihora", "Singrauli", "Sironj", "Sohagpur", "Tarana", "Tikamgarh", "Ujjain", "Umaria", "Vidisha", "Vijaypur", "Wara Seoni"]
          },
          {
            name: "Maharastra",
            cities: ["Achalpur", "Ahmednagar", "Akola", "Akot", "Amalner", "Ambejogai", "Amravati", "Anjangaon", "Arvi", "Aurangabad", "Bhiwandi", "Dhule", "Ichalkaranji", "Kalyan-Dombivali", "Karjat", "Latur", "Loha", "Lonar", "Lonavla", "Mahad", "Malegaon", "Malkapur", "Mangalvedhe", "Mangrulpir", "Manjlegaon", "Manmad", "Manwath", "Mehkar", "Mhaswad", "Mira-Bhayandar", "Morshi", "Mukhed", "Mul", "Mumbai", "Murtijapur", "Nagpur", "Nanded-Waghala", "Nandgaon", "Nandura", "Nandurbar", "Narkhed", "Nashik", "Nawapur", "Nilanga", "Osmanabad", "Ozar", "Pachora", "Paithan", "Palghar", "Pandharkaoda", "Pandharpur", "Panvel", "Parbhani", "Parli", "Partur", "Pathardi", "Pathri", "Patur", "Pauni", "Pen", "Phaltan", "Pune", "Purna", "Pusad", "Rahuri", "Rajura", "Ramtek", "Ratnagiri", "Raver", "Risod", "Sailu", "Sangamner", "Sangli", "Sangole", "Sasvad", "Satana", "Satara", "Savner", "Sawantwadi", "Shahade", "Shegaon", "Shendurjana", "Shirdi", "Shirpur-Warwade", "Shirur", "Shrigonda", "Shrirampur", "Sillod", "Sinnar", "Solapur", "Soyagaon", "Talegaon Dabhade", "Talode", "Tasgaon", "Thane", "Tirora", "Tuljapur", "Tumsar", "Uchgaon", "Udgir", "Umarga", "Umarkhed", "Umred", "Uran", "Uran Islampur", "Vadgaon Kasba", "Vaijapur", "Vasai-Virar", "Vita", "Wadgaon Road", "Wai", "Wani", "Wardha", "Warora", "Warud", "Washim", "Yavatmal", "Yawal", "Yevla"]
          },
          {
            name: "Manipur",
            cities: ["Imphal", "Thoubal", "Lilong", "Mayang Imphal"]
          },
          {
            name: "Meghalaya",
            cities: ["Shillong", "Tura", "Nongstoin"]
          },
          {
            name: "Mizoram",
            cities: ["Aizawl", "Lunglei", "Saiha"]
          },
          {
            name: "Nagaland",
            cities: ["Dimapur", "Kohima", "Zunheboto", "Tuensang", "Wokha", "Mokokchung"]
          },
          {
            name: "Odisha",
            cities: ["Baleshwar Town", "Barbil", "Bargarh", "Baripada Town", "Bhadrak", "Bhawanipatna", "Bhubaneswar", "Brahmapur", "Byasanagar", "Cuttack", "Dhenkanal", "Jatani", "Jharsuguda", "Kendrapara", "Kendujhar", "Malkangiri", "Nabarangapur", "Paradip", "Parlakhemundi", "Pattamundai", "Phulabani", "Puri", "Rairangpur", "Rajagangapur", "Raurkela", "Rayagada", "Sambalpur", "Soro", "Sunabeda", "Sundargarh", "Talcher", "Tarbha", "Titlagarh"]
          },
          {
            name: "Puducherry",
            cities: ["Pondicherry", "Karaikal", "Yanam", "Mahe"]
          },
          {
            name: "Punjab",
            cities: ["Amritsar", "Barnala", "Batala", "Bathinda", "Dhuri", "Faridkot", "Fazilka", "Firozpur", "Firozpur Cantt.", "Gobindgarh", "Gurdaspur", "Hoshiarpur", "Jagraon", "Jalandhar", "Jalandhar Cantt.", "Kapurthala", "Khanna", "Kharar", "Kot Kapura", "Longowal", "Ludhiana", "Malerkotla", "Malout", "Mansa", "Moga", "Mohali", "Morinda", "Mukerian", "Muktsar", "Nabha", "Nakodar", "Nangal", "Nawanshahr", "Pathankot", "Patiala", "Patti", "Pattran", "Phagwara", "Phillaur", "Raikot", "Rajpura", "Rampura Phul", "Rupnagar", "Samana", "Sangrur", "Sirhind Fatehgarh Sahib", "Sujanpur", "Sunam", "Tarn Taran", "Urmar Tanda", "Zira", "Zirakpur"]
          },
          {
            name: "Rajasthan",
            cities: ["Ajmer", "Alwar", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Jaipur", "Jodhpur", "Lachhmangarh", "Ladnu", "Lakheri", "Lalsot", "Losal", "Makrana", "Malpura", "Mandalgarh", "Mandawa", "Mangrol", "Merta City", "Mount Abu", "Nadbai", "Nagar", "Nagaur", "Nasirabad", "Nathdwara", "Neem-Ka-Thana", "Nimbahera", "Nohar", "Nokha", "Pali", "Phalodi", "Phulera", "Pilani", "Pindwara", "Pipar City", "Prantij", "Pratapgarh", "Raisinghnagar", "Rajakhera", "Rajaldesar", "Rajgarh (Alwar)", "Rajgarh (Churu)", "Rajsamand", "Ramganj Mandi", "Ramngarh", "Ratangarh", "Rawatbhata", "Rawatsar", "Reengus", "Sadri", "Sadulpur", "Sadulshahar", "Sagwara", "Sambhar", "Sanchore", "Sangaria", "Sardarshahar", "Sawai Madhopur", "Shahpura", "Shahpura", "Sheoganj", "Sikar", "Sirohi", "Sojat", "Sri Madhopur", "Sujangarh", "Sumerpur", "Suratgarh", "Takhatgarh", "Taranagar", "Todabhim", "Todaraisingh", "Tonk", "Udaipur", "Udaipurwati", "Vijainagar, Ajmer"]
          },
          {
            name: "Tamilnadu",
            cities: ["Arakkonam", "Aruppukkottai", "Chennai", "Coimbatore", "Erode", "Gobichettipalayam", "Kancheepuram", "Karur", "Lalgudi", "Madurai", "Manachanallur", "Nagapattinam", "Nagercoil", "Namagiripettai", "Namakkal", "Nandivaram-Guduvancheri", "Nanjikottai", "Natham", "Nellikuppam", "Neyveli (TS)", "O' Valley", "Oddanchatram", "P.N.Patti", "Pacode", "Padmanabhapuram", "Palani", "Palladam", "Pallapatti", "Pallikonda", "Panagudi", "Panruti", "Paramakudi", "Parangipettai", "Pattukkottai", "Perambalur", "Peravurani", "Periyakulam", "Periyasemur", "Pernampattu", "Pollachi", "Polur", "Ponneri", "Pudukkottai", "Pudupattinam", "Puliyankudi", "Punjaipugalur", "Rajapalayam", "Ramanathapuram", "Rameshwaram", "Ranipet", "Rasipuram", "Salem", "Sankarankovil", "Sankari", "Sathyamangalam", "Sattur", "Shenkottai", "Sholavandan", "Sholingur", "Sirkali", "Sivaganga", "Sivagiri", "Sivakasi", "Srivilliputhur", "Surandai", "Suriyampalayam", "Tenkasi", "Thammampatti", "Thanjavur", "Tharamangalam", "Tharangambadi", "Theni Allinagaram", "Thirumangalam", "Thirupuvanam", "Thiruthuraipoondi", "Thiruvallur", "Thiruvarur", "Thuraiyur", "Tindivanam", "Tiruchendur", "Tiruchengode", "Tiruchirappalli", "Tirukalukundram", "Tirukkoyilur", "Tirunelveli", "Tirupathur", "Tirupathur", "Tiruppur", "Tiruttani", "Tiruvannamalai", "Tiruvethipuram", "Tittakudi", "Udhagamandalam", "Udumalaipettai", "Unnamalaikadai", "Usilampatti", "Uthamapalayam", "Uthiramerur", "Vadakkuvalliyur", "Vadalur", "Vadipatti", "Vandavasi", "Vaniyambadi", "Vedaranyam", "Vellakoil", "Vellore", "Vikramasingapuram", "Viluppuram", "Virudhachalam", "Virudhunagar", "Viswanatham"]
          },
          {
            name: "Telangana",
            cities: ["Adilabad", "Bellampalle", "Bhadrachalam", "Bhainsa", "Bhongir", "Bodhan", "Farooqnagar", "Gadwal", "Hyderabad", "Jagtial", "Jangaon", "Kagaznagar", "Kamareddy", "Karimnagar", "Khammam", "Koratla", "Kothagudem", "Kyathampalle", "Mahbubnagar", "Mancherial", "Mandamarri", "Manuguru", "Medak", "Miryalaguda", "Nagarkurnool", "Narayanpet", "Nirmal", "Nizamabad", "Palwancha", "Ramagundam", "Sadasivpet", "Sangareddy", "Siddipet", "Sircilla", "Suryapet", "Tandur", "Vikarabad", "Wanaparthy", "Warangal", "Yellandu"]
          },
          {
            name: "Tripura",
            cities: ["Agartala", "Udaipur", "Dharmanagar", "Pratapgarh", "Kailasahar", "Belonia", "Khowai"]
          },
          {
            name: "Uttar Pradesh",
            cities: ["Achhnera", "Agra", "Aligarh", "Allahabad", "Amroha", "Azamgarh", "Bahraich", "Chandausi", "Etawah", "Fatehpur Sikri", "Firozabad", "Hapur", "Hardoi", "Jhansi", "Kalpi", "Kanpur", "Khair", "Laharpur", "Lakhimpur", "Lal Gopalganj Nindaura", "Lalganj", "Lalitpur", "Lar", "Loni", "Lucknow", "Mathura", "Meerut", "Modinagar", "Moradabad", "Nagina", "Najibabad", "Nakur", "Nanpara", "Naraura", "Naugawan Sadat", "Nautanwa", "Nawabganj", "Nehtaur", "Niwai", "Noida", "Noorpur", "Obra", "Orai", "Padrauna", "Palia Kalan", "Parasi", "Phulpur", "Pihani", "Pilibhit", "Pilkhuwa", "Powayan", "Pukhrayan", "Puranpur", "PurqUrban Agglomerationzi", "Purwa", "Rae Bareli", "Rampur", "Rampur Maniharan", "Rasra", "Rath", "Renukoot", "Reoti", "Robertsganj", "Rudauli", "Rudrapur", "Sadabad", "Safipur", "Saharanpur", "Sahaswan", "Sahawar", "Sahjanwa", "Saidpur", "Sambhal", "Samdhan", "Samthar", "Sandi", "Sandila", "Sardhana", "Seohara", "Shahabad", "Hardoi", "Shahabad", "Rampur", "Shahganj", "Shahjahanpur", "Shamli", "Shamsabad", "Agra", "Shamsabad", "Farrukhabad", "Sherkot", "Shikarpur", "Bulandshahr", "Shikohabad", "Shishgarh", "Siana", "Sikanderpur", "Sikandra Rao", "Sikandrabad", "Sirsaganj", "Sirsi", "Sitapur", "Soron", "Sultanpur", "Sumerpur", "SUrban Agglomerationr", "Tanda", "Thakurdwara", "Thana Bhawan", "Tilhar", "Tirwaganj", "Tulsipur", "Tundla", "Ujhani", "Unnao", "Utraula", "Varanasi", "Vrindavan", "Warhapur", "Zaidpur", "Zamania"]
          },
          {
            name: "Uttarakhand",
            cities: ["Bageshwar", "Dehradun", "Haldwani-cum-Kathgodam", "Hardwar", "Kashipur", "Manglaur", "Mussoorie", "Nagla", "Nainital", "Pauri", "Pithoragarh", "Ramnagar", "Rishikesh", "Roorkee", "Rudrapur", "Sitarganj", "Srinagar", "Tehri"]
          },
          {
            name: "West Bengal",
            cities: ["Adra", "AlipurdUrban Agglomerationr", "Arambagh", "Asansol", "Baharampur", "Balurghat", "Bankura", "Darjiling", "English Bazar", "Gangarampur", "Habra", "Hugli-Chinsurah", "Jalpaiguri", "Jhargram", "Kalimpong", "Kharagpur", "Kolkata", "Mainaguri", "Malda", "Mathabhanga", "Medinipur", "Memari", "Monoharpur", "Murshidabad", "Nabadwip", "Naihati", "Panchla", "PandUrban Agglomeration", "Paschim Punropara", "Purulia", "Raghunathganj", "Raghunathpur", "Raiganj", "Rampurhat", "Ranaghat", "Sainthia", "Santipur", "Siliguri", "Sonamukhi", "Srirampore", "Suri", "Taki", "Tamluk", "Tarakeswar"]
          }
        ]
      },
      { name: " Afghanistan", country_code_ISO2: "AF", dial_code: "+93", states: [] },
      { name: " Albania", country_code_ISO2: "AL", dial_code: "+355", states: [] },
      { name: " Algeria", country_code_ISO2: "DZ", dial_code: "+213", states: [] },
      { name: " American Samoa", country_code_ISO2: "AS", dial_code: "+1-684", states: [] },
      { name: " Andorra, Principality of", country_code_ISO2: "AD", dial_code: "+376", states: [] },
      { name: " Angola", country_code_ISO2: "AO", dial_code: "+244", states: [] },
      { name: " Anguilla", country_code_ISO2: "AI", dial_code: "+1-264", states: [] },
      { name: " Antarctica", country_code_ISO2: "AQ", dial_code: "+672", states: [] },
      { name: " Antigua and Barbuda", country_code_ISO2: "AG", dial_code: "+1-268", states: [] },
      { name: " Argentina", country_code_ISO2: "AR", dial_code: "+54", states: [] },
      { name: " Armenia", country_code_ISO2: "AM", dial_code: "+374", states: [] },
      { name: " Aruba", country_code_ISO2: "AW", dial_code: "+297", states: [] },
      { name: " Australia", country_code_ISO2: "AU", dial_code: "+61", states: [] },
      { name: " Austria", country_code_ISO2: "AT", dial_code: "+43", states: [] },
      { name: " Azerbaijan or Azerbaidjan (Former Azerbaijan Soviet Socialist Republic)", country_code_ISO2: "AZ", dial_code: "+994", states: [] },
      { name: " Bahamas, Commonwealth of The", country_code_ISO2: "BS", dial_code: "+1-242", states: [] },
      { name: " Bahrain, Kingdom of (Former Dilmun)", country_code_ISO2: "BH", dial_code: "+973", states: [] },
      { name: " Bangladesh (Former East Pakistan)", country_code_ISO2: "BD", dial_code: "+880", states: [] },
      { name: " Barbados", country_code_ISO2: "BB", dial_code: "+1-246", states: [] },
      { name: " Belarus (Former Belorussian [Byelorussian] Soviet Socialist Republic)", country_code_ISO2: "BY", dial_code: "+375", states: [] },
      { name: " Belgium", country_code_ISO2: "BE", dial_code: "+32", states: [] },
      { name: " Belize (Former British Honduras)", country_code_ISO2: "BZ", dial_code: "+501", states: [] },
      { name: " Benin (Former Dahomey)", country_code_ISO2: "BJ", dial_code: "+229", states: [] },
      { name: " Bermuda", country_code_ISO2: "BM", dial_code: "+1-441", states: [] },
      { name: " Bhutan, Kingdom of", country_code_ISO2: "BT", dial_code: "+975", states: [] },
      { name: " Bolivia", country_code_ISO2: "BO", dial_code: "+591", states: [] },
      { name: " Bosnia and Herzegovina", country_code_ISO2: "BA", dial_code: "+387", states: [] },
      { name: " Botswana (Former Bechuanaland)", country_code_ISO2: "BW", dial_code: "+267", states: [] },
      { name: " Bouvet Island (Territory of Norway)", country_code_ISO2: "BV", dial_code: "", states: [] },
      { name: " Brazil", country_code_ISO2: "BR", dial_code: "+55", states: [] },
      { name: " British Indian Ocean Territory (BIOT)", country_code_ISO2: "IO", dial_code: "", states: [] },
      { name: " Brunei (Negara Brunei Darussalam)", country_code_ISO2: "BN", dial_code: "+673", states: [] },
      { name: " Bulgaria", country_code_ISO2: "BG", dial_code: "+359", states: [] },
      { name: " Burkina Faso (Former Upper Volta)", country_code_ISO2: "BF", dial_code: "+226", states: [] },
      { name: " Burundi (Former Urundi)", country_code_ISO2: "BI", dial_code: "+257", states: [] },
      { name: " Cambodia, Kingdom of (Former Khmer Republic, Kampuchea Republic)", country_code_ISO2: "KH", dial_code: "+855", states: [] },
      { name: " Cameroon (Former French Cameroon)", country_code_ISO2: "CM", dial_code: "+237", states: [] },
      { name: " Canada", country_code_ISO2: "CA", dial_code: "+1", states: [] },
      { name: " Cape Verde", country_code_ISO2: "CV", dial_code: "+238", states: [] },
      { name: " Cayman Islands", country_code_ISO2: "KY", dial_code: "+1-345", states: [] },
      { name: " Central African Republic", country_code_ISO2: "CF", dial_code: "+236", states: [] },
      { name: " Chad", country_code_ISO2: "TD", dial_code: "+235", states: [] },
      { name: " Chile", country_code_ISO2: "CL", dial_code: "+56", states: [] },
      { name: " China", country_code_ISO2: "CN", dial_code: "+86", states: [] },
      { name: " Christmas Island", country_code_ISO2: "CX", dial_code: "+53", states: [] },
      { name: " Cocos (Keeling) Islands", country_code_ISO2: "CC", dial_code: "+61", states: [] },
      { name: " Colombia", country_code_ISO2: "CO", dial_code: "+57", states: [] },
      { name: " Comoros, Union of the", country_code_ISO2: "KM", dial_code: "+269", states: [] },
      { name: " Congo, Democratic Republic of the (Former Zaire)", country_code_ISO2: "CD", dial_code: "+243", states: [] },
      { name: " Congo, Republic of the", country_code_ISO2: "CG", dial_code: "+242", states: [] },
      { name: " Cook Islands (Former Harvey Islands)", country_code_ISO2: "CK", dial_code: "+682", states: [] },
      { name: " Costa Rica", country_code_ISO2: "CR", dial_code: "+506", states: [] },
      { name: " Cote D'Ivoire (Former Ivory Coast)", country_code_ISO2: "CI", dial_code: "+225", states: [] },
      { name: " Croatia (Hrvatska)", country_code_ISO2: "HR", dial_code: "+385", states: [] },
      { name: " Cuba", country_code_ISO2: "CU", dial_code: "+53", states: [] },
      { name: " Cyprus", country_code_ISO2: "CY", dial_code: "+357", states: [] },
      { name: " Czech Republic", country_code_ISO2: "CZ", dial_code: "+420", states: [] },
      { name: " Czechoslavakia (Former) See CZ Czech Republic or Slovakia", country_code_ISO2: "CS", dial_code: "", states: [] },
      { name: " Denmark", country_code_ISO2: "DK", dial_code: "+45", states: [] },
      { name: " Djibouti (Former French Territory of the Afars and Issas, French Somaliland)", country_code_ISO2: "DJ", dial_code: "+253", states: [] },
      { name: " Dominica", country_code_ISO2: "DM", dial_code: "+1-767", states: [] },
      { name: " Dominican Republic", country_code_ISO2: "DO", dial_code: "+1-809 and '+1-829", states: [] },
      { name: " East Timor (Former Portuguese Timor)", country_code_ISO2: "TP", dial_code: "+670", states: [] },
      { name: " Ecuador", country_code_ISO2: "EC", dial_code: "+593", states: [] },
      { name: " Egypt (Former United Arab Republic - with Syria)", country_code_ISO2: "EG", dial_code: "+20", states: [] },
      { name: " El Salvador", country_code_ISO2: "SV", dial_code: "+503", states: [] },
      { name: " Equatorial Guinea (Former Spanish Guinea)", country_code_ISO2: "GQ", dial_code: "+240", states: [] },
      { name: " Eritrea (Former Eritrea Autonomous Region in Ethiopia)", country_code_ISO2: "ER", dial_code: "+291", states: [] },
      { name: " Estonia (Former Estonian Soviet Socialist Republic)", country_code_ISO2: "EE", dial_code: "+372", states: [] },
      { name: " Ethiopia (Former Abyssinia, Italian East Africa)", country_code_ISO2: "ET", dial_code: "+251", states: [] },
      { name: " Falkland Islands (Islas Malvinas)", country_code_ISO2: "FK", dial_code: "+500", states: [] },
      { name: " Faroe Islands", country_code_ISO2: "FO", dial_code: "+298", states: [] },
      { name: " Fiji", country_code_ISO2: "FJ", dial_code: "+679", states: [] },
      { name: " Finland", country_code_ISO2: "FI", dial_code: "+358", states: [] },
      { name: " France", country_code_ISO2: "FR", dial_code: "+33", states: [] },
      { name: " French Guiana or French Guyana", country_code_ISO2: "GF", dial_code: "+594", states: [] },
      { name: " French Polynesia (Former French Colony of Oceania)", country_code_ISO2: "PF", dial_code: "+689", states: [] },
      { name: " French Southern Territories and Antarctic Lands", country_code_ISO2: "TF", dial_code: "", states: [] },
      { name: " Gabon (Gabonese Republic)", country_code_ISO2: "GA", dial_code: "+241", states: [] },
      { name: " Gambia, The", country_code_ISO2: "GM", dial_code: "+220", states: [] },
      { name: " Georgia (Former Georgian Soviet Socialist Republic)", country_code_ISO2: "GE", dial_code: "+995", states: [] },
      { name: " Germany", country_code_ISO2: "DE", dial_code: "+49", states: [] },
      { name: " Ghana (Former Gold Coast)", country_code_ISO2: "GH", dial_code: "+233", states: [] },
      { name: " Gibraltar", country_code_ISO2: "GI", dial_code: "+350", states: [] },
      { name: " Great Britain (United Kingdom)", country_code_ISO2: "GB", dial_code: "", states: [] },
      { name: " Greece", country_code_ISO2: "GR", dial_code: "+30", states: [] },
      { name: " Greenland", country_code_ISO2: "GL", dial_code: "+299", states: [] },
      { name: " Grenada", country_code_ISO2: "GD", dial_code: "+1-473", states: [] },
      { name: " Guadeloupe", country_code_ISO2: "GP", dial_code: "+590", states: [] },
      { name: " Guam", country_code_ISO2: "GU", dial_code: "+1-671", states: [] },
      { name: " Guatemala", country_code_ISO2: "GT", dial_code: "+502", states: [] },
      { name: " Guinea (Former French Guinea)", country_code_ISO2: "GN", dial_code: "+224", states: [] },
      { name: " Guinea-Bissau (Former Portuguese Guinea)", country_code_ISO2: "GW", dial_code: "+245", states: [] },
      { name: " Guyana (Former British Guiana)", country_code_ISO2: "GY", dial_code: "+592", states: [] },
      { name: " Haiti", country_code_ISO2: "HT", dial_code: "+509", states: [] },
      { name: " Heard Island and McDonald Islands (Territory of Australia)", country_code_ISO2: "HM", dial_code: "", states: [] },
      { name: " Holy See (Vatican City State)", country_code_ISO2: "VA", dial_code: "", states: [] },
      { name: " Honduras", country_code_ISO2: "HN", dial_code: "+504", states: [] },
      { name: " Hong Kong", country_code_ISO2: "HK", dial_code: "+852", states: [] },
      { name: " Hungary", country_code_ISO2: "HU", dial_code: "+36", states: [] },
      { name: " Iceland", country_code_ISO2: "IS", dial_code: "+354", states: [] },
      { name: " Indonesia (Former Netherlands East Indies; Dutch East Indies)", country_code_ISO2: "ID", dial_code: "+62", states: [] },
      { name: " Iran, Islamic Republic of", country_code_ISO2: "IR", dial_code: "+98", states: [] },
      { name: " Iraq", country_code_ISO2: "IQ", dial_code: "+964", states: [] },
      { name: " Ireland", country_code_ISO2: "IE", dial_code: "+353", states: [] },
      { name: " Israel", country_code_ISO2: "IL", dial_code: "+972", states: [] },
      { name: " Italy", country_code_ISO2: "IT", dial_code: "+39", states: [] },
      { name: " Jamaica", country_code_ISO2: "JM", dial_code: "+1-876", states: [] },
      { name: " Japan", country_code_ISO2: "JP", dial_code: "+81", states: [] },
      { name: " Jordan (Former Transjordan)", country_code_ISO2: "JO", dial_code: "+962", states: [] },
      { name: " Kazakstan or Kazakhstan (Former Kazakh Soviet Socialist Republic)", country_code_ISO2: "KZ", dial_code: "+7", states: [] },
      { name: " Kenya (Former British East Africa)", country_code_ISO2: "KE", dial_code: "+254", states: [] },
      { name: " Kiribati (Pronounced keer-ree-bahss) (Former Gilbert Islands)", country_code_ISO2: "KI", dial_code: "+686", states: [] },
      { name: " Korea, Democratic People's Republic of (North Korea)", country_code_ISO2: "KP", dial_code: "+850", states: [] },
      { name: " Korea, Republic of (South Korea)", country_code_ISO2: "KR", dial_code: "+82", states: [] },
      { name: " Kuwait", country_code_ISO2: "KW", dial_code: "+965", states: [] },
      { name: " Kyrgyzstan (Kyrgyz Republic) (Former Kirghiz Soviet Socialist Republic)", country_code_ISO2: "KG", dial_code: "+996", states: [] },
      { name: " Lao People's Democratic Republic (Laos)", country_code_ISO2: "LA", dial_code: "+856", states: [] },
      { name: " Latvia (Former Latvian Soviet Socialist Republic)", country_code_ISO2: "LV", dial_code: "+371", states: [] },
      { name: " Lebanon", country_code_ISO2: "LB", dial_code: "+961", states: [] },
      { name: " Lesotho (Former Basutoland)", country_code_ISO2: "LS", dial_code: "+266", states: [] },
      { name: " Liberia", country_code_ISO2: "LR", dial_code: "+231", states: [] },
      { name: " Libya (Libyan Arab Jamahiriya)", country_code_ISO2: "LY", dial_code: "+218", states: [] },
      { name: " Liechtenstein", country_code_ISO2: "LI", dial_code: "+423", states: [] },
      { name: " Lithuania (Former Lithuanian Soviet Socialist Republic)", country_code_ISO2: "LT", dial_code: "+370", states: [] },
      { name: " Luxembourg", country_code_ISO2: "LU", dial_code: "+352", states: [] },
      { name: " Macau", country_code_ISO2: "MO", dial_code: "+853", states: [] },
      { name: " Macedonia, The Former Yugoslav Republic of", country_code_ISO2: "MK", dial_code: "+389", states: [] },
      { name: " Madagascar (Former Malagasy Republic)", country_code_ISO2: "MG", dial_code: "+261", states: [] },
      { name: " Malawi (Former British Central African Protectorate, Nyasaland)", country_code_ISO2: "MW", dial_code: "+265", states: [] },
      { name: " Malaysia", country_code_ISO2: "MY", dial_code: "+60", states: [] },
      { name: " Maldives", country_code_ISO2: "MV", dial_code: "+960", states: [] },
      { name: " Mali (Former French Sudan and Sudanese Republic)", country_code_ISO2: "ML", dial_code: "+223", states: [] },
      { name: " Malta", country_code_ISO2: "MT", dial_code: "+356", states: [] },
      { name: " Marshall Islands (Former Marshall Islands District - Trust Territory of the Pacific Islands)", country_code_ISO2: "MH", dial_code: "+692", states: [] },
      { name: " Martinique (French)", country_code_ISO2: "MQ", dial_code: "+596", states: [] },
      { name: " Mauritania", country_code_ISO2: "MR", dial_code: "+222", states: [] },
      { name: " Mauritius", country_code_ISO2: "MU", dial_code: "+230", states: [] },
      { name: " Mayotte (Territorial Collectivity of Mayotte)", country_code_ISO2: "YT", dial_code: "+269", states: [] },
      { name: " Mexico", country_code_ISO2: "MX", dial_code: "+52", states: [] },
      { name: " Micronesia, Federated States of (Former Ponape, Truk, and Yap Districts - Trust Territory of the Pacific Islands)", country_code_ISO2: "FM", dial_code: "+691", states: [] },
      { name: " Moldova, Republic of", country_code_ISO2: "MD", dial_code: "+373", states: [] },
      { name: " Monaco, Principality of", country_code_ISO2: "MC", dial_code: "+377", states: [] },
      { name: " Mongolia (Former Outer Mongolia)", country_code_ISO2: "MN", dial_code: "+976", states: [] },
      { name: " Montserrat", country_code_ISO2: "MS", dial_code: "+1-664", states: [] },
      { name: " Morocco", country_code_ISO2: "MA", dial_code: "+212", states: [] },
      { name: " Mozambique (Former Portuguese East Africa)", country_code_ISO2: "MZ", dial_code: "+258", states: [] },
      { name: " Myanmar, Union of (Former Burma)", country_code_ISO2: "MM", dial_code: "+95", states: [] },
      { name: " Namibia (Former German Southwest Africa, South-West Africa)", country_code_ISO2: "NA", dial_code: "+264", states: [] },
      { name: " Nauru (Former Pleasant Island)", country_code_ISO2: "NR", dial_code: "+674", states: [] },
      { name: " Nepal", country_code_ISO2: "NP", dial_code: "+977", states: [] },
      { name: " Netherlands", country_code_ISO2: "NL", dial_code: "+31", states: [] },
      { name: " Netherlands Antilles (Former Curacao and Dependencies)", country_code_ISO2: "AN", dial_code: "+599", states: [] },
      { name: " New Caledonia", country_code_ISO2: "NC", dial_code: "+687", states: [] },
      { name: " New Zealand (Aotearoa)", country_code_ISO2: "NZ", dial_code: "+64", states: [] },
      { name: " Nicaragua", country_code_ISO2: "NI", dial_code: "+505", states: [] },
      { name: " Niger", country_code_ISO2: "NE", dial_code: "+227", states: [] },
      { name: " Nigeria", country_code_ISO2: "NG", dial_code: "+234", states: [] },
      { name: " Niue (Former Savage Island)", country_code_ISO2: "NU", dial_code: "+683", states: [] },
      { name: " Norfolk Island", country_code_ISO2: "NF", dial_code: "+672", states: [] },
      { name: " Northern Mariana Islands (Former Mariana Islands District - Trust Territory of the Pacific Islands)", country_code_ISO2: "MP", dial_code: "+1-670", states: [] },
      { name: " Norway", country_code_ISO2: "NO", dial_code: "+47", states: [] },
      { name: " Oman, Sultanate of (Former Muscat and Oman)", country_code_ISO2: "OM", dial_code: "+968", states: [] },
      { name: " Pakistan (Former West Pakistan)", country_code_ISO2: "PK", dial_code: "+92", states: [] },
      { name: " Palau (Former Palau District - Trust Terriroty of the Pacific Islands)", country_code_ISO2: "PW", dial_code: "+680", states: [] },
      { name: " Palestinian State (Proposed)", country_code_ISO2: "PS", dial_code: "+970", states: [] },
      { name: " Panama", country_code_ISO2: "PA", dial_code: "+507", states: [] },
      { name: " Papua New Guinea (Former Territory of Papua and New Guinea)", country_code_ISO2: "PG", dial_code: "+675", states: [] },
      { name: " Paraguay", country_code_ISO2: "PY", dial_code: "+595", states: [] },
      { name: " Peru", country_code_ISO2: "PE", dial_code: "+51", states: [] },
      { name: " Philippines", country_code_ISO2: "PH", dial_code: "+63", states: [] },
      { name: " Pitcairn Island", country_code_ISO2: "PN", dial_code: "", states: [] },
      { name: " Poland", country_code_ISO2: "PL", dial_code: "+48", states: [] },
      { name: " Portugal", country_code_ISO2: "PT", dial_code: "+351", states: [] },
      { name: " Puerto Rico", country_code_ISO2: "PR", dial_code: "+1-787 or '+1-939", states: [] },
      { name: " Qatar, State of", country_code_ISO2: "QA", dial_code: "+974", states: [] },
      { name: " Reunion (French) (Former Bourbon Island)", country_code_ISO2: "RE", dial_code: "+262", states: [] },
      { name: " Romania", country_code_ISO2: "RO", dial_code: "+40", states: [] },
      { name: " Russia - USSR (Former Russian Empire, Union of Soviet Socialist Republics, Russian Soviet Federative Socialist Republic) Now RU - Russian Federation", country_code_ISO2: "SU", dial_code: "", states: [] },
      { name: " Russian Federation", country_code_ISO2: "RU", dial_code: "+7", states: [] },
      { name: " Rwanda (Rwandese Republic) (Former Ruanda)", country_code_ISO2: "RW", dial_code: "+250", states: [] },
      { name: " Saint Helena", country_code_ISO2: "SH", dial_code: "+290", states: [] },
      { name: " Saint Kitts and Nevis (Former Federation of Saint Christopher and Nevis)", country_code_ISO2: "KN", dial_code: "+1-869", states: [] },
      { name: " Saint Lucia", country_code_ISO2: "LC", dial_code: "+1-758", states: [] },
      { name: " Saint Pierre and Miquelon", country_code_ISO2: "PM", dial_code: "+508", states: [] },
      { name: " Saint Vincent and the Grenadines", country_code_ISO2: "VC", dial_code: "+1-784", states: [] },
      { name: " Samoa (Former Western Samoa)", country_code_ISO2: "WS", dial_code: "+685", states: [] },
      { name: " San Marino", country_code_ISO2: "SM", dial_code: "+378", states: [] },
      { name: " Sao Tome and Principe", country_code_ISO2: "ST", dial_code: "+239", states: [] },
      { name: " Saudi Arabia", country_code_ISO2: "SA", dial_code: "+966", states: [] },
      { name: " Serbia, Republic of", country_code_ISO2: "RS", dial_code: "", states: [] },
      { name: " Senegal", country_code_ISO2: "SN", dial_code: "+221", states: [] },
      { name: " Seychelles", country_code_ISO2: "SC", dial_code: "+248", states: [] },
      { name: " Sierra Leone", country_code_ISO2: "SL", dial_code: "+232", states: [] },
      { name: " Singapore", country_code_ISO2: "SG", dial_code: "+65", states: [] },
      { name: " Slovakia", country_code_ISO2: "SK", dial_code: "+421", states: [] },
      { name: " Slovenia", country_code_ISO2: "SI", dial_code: "+386", states: [] },
      { name: " Solomon Islands (Former British Solomon Islands)", country_code_ISO2: "SB", dial_code: "+677", states: [] },
      { name: " Somalia (Former Somali Republic, Somali Democratic Republic)", country_code_ISO2: "SO", dial_code: "+252", states: [] },
      { name: " South Africa (Former Union of South Africa)", country_code_ISO2: "ZA", dial_code: "+27", states: [] },
      { name: " South Georgia and the South Sandwich Islands", country_code_ISO2: "GS", dial_code: "", states: [] },
      { name: " Spain", country_code_ISO2: "ES", dial_code: "+34", states: [] },
      { name: " Sri Lanka (Former Serendib, Ceylon)", country_code_ISO2: "LK", dial_code: "+94", states: [] },
      { name: " Sudan (Former Anglo-Egyptian Sudan)", country_code_ISO2: "SD", dial_code: "+249", states: [] },
      { name: " Suriname (Former Netherlands Guiana, Dutch Guiana)", country_code_ISO2: "SR", dial_code: "+597", states: [] },
      { name: " Svalbard (Spitzbergen) and Jan Mayen Islands", country_code_ISO2: "SJ", dial_code: "", states: [] },
      { name: " Swaziland, Kingdom of", country_code_ISO2: "SZ", dial_code: "+268", states: [] },
      { name: " Sweden", country_code_ISO2: "SE", dial_code: "+46", states: [] },
      { name: " Switzerland", country_code_ISO2: "CH", dial_code: "+41", states: [] },
      { name: " Syria (Syrian Arab Republic) (Former United Arab Republic - with Egypt)", country_code_ISO2: "SY", dial_code: "+963", states: [] },
      { name: " Taiwan (Former Formosa)", country_code_ISO2: "TW", dial_code: "+886", states: [] },
      { name: " Tajikistan (Former Tajik Soviet Socialist Republic)", country_code_ISO2: "TJ", dial_code: "+992", states: [] },
      { name: " Tanzania, United Republic of (Former United Republic of Tanganyika and Zanzibar)", country_code_ISO2: "TZ", dial_code: "+255", states: [] },
      { name: " Thailand (Former Siam)", country_code_ISO2: "TH", dial_code: "+66", states: [] },
      { name: " Togo (Former French Togoland)", country_code_ISO2: "TG", dial_code: "", states: [] },
      { name: " Tokelau", country_code_ISO2: "TK", dial_code: "+690", states: [] },
      { name: " Tonga, Kingdom of (Former Friendly Islands)", country_code_ISO2: "TO", dial_code: "+676", states: [] },
      { name: " Trinidad and Tobago", country_code_ISO2: "TT", dial_code: "+1-868", states: [] },
      { name: " Tromelin Island", country_code_ISO2: "TE", dial_code: "", states: [] },
      { name: " Tunisia", country_code_ISO2: "TN", dial_code: "+216", states: [] },
      { name: " Turkey", country_code_ISO2: "TR", dial_code: "+90", states: [] },
      { name: " Turkmenistan (Former Turkmen Soviet Socialist Republic)", country_code_ISO2: "TM", dial_code: "+993", states: [] },
      { name: " Turks and Caicos Islands", country_code_ISO2: "TC", dial_code: "+1-649", states: [] },
      { name: " Tuvalu (Former Ellice Islands)", country_code_ISO2: "TV", dial_code: "+688", states: [] },
      { name: " Uganda, Republic of", country_code_ISO2: "UG", dial_code: "+256", states: [] },
      { name: " Ukraine (Former Ukrainian National Republic, Ukrainian State, Ukrainian Soviet Socialist Republic)", country_code_ISO2: "UA", dial_code: "+380", states: [] },
      { name: " United Arab Emirates (UAE) (Former Trucial Oman, Trucial States)", country_code_ISO2: "AE", dial_code: "+971", states: [] },
      { name: " United Kingdom (Great Britain / UK)", country_code_ISO2: "GB", dial_code: "+44", states: [] },
      { name: " United States", country_code_ISO2: "US", dial_code: "+1", states: [] },
      { name: " United States Minor Outlying Islands", country_code_ISO2: "UM", dial_code: "", states: [] },
      { name: " Uruguay, Oriental Republic of (Former Banda Oriental, Cisplatine Province)", country_code_ISO2: "UY", dial_code: "+598", states: [] },
      { name: " Uzbekistan (Former UZbek Soviet Socialist Republic)", country_code_ISO2: "UZ", dial_code: "+998", states: [] },
      { name: " Vanuatu (Former New Hebrides)", country_code_ISO2: "VU", dial_code: "+678", states: [] },
      { name: " Vatican City State (Holy See)", country_code_ISO2: "VA", dial_code: "+418", states: [] },
      { name: " Venezuela", country_code_ISO2: "VE", dial_code: "+58", states: [] },
      { name: " Vietnam", country_code_ISO2: "VN", dial_code: "+84", states: [] },
      { name: " Virgin Islands, British", country_code_ISO2: "VI", dial_code: "+1-284", states: [] },
      { name: " Virgin Islands, United States (Former Danish West Indies)", country_code_ISO2: "VQ", dial_code: "+1-340", states: [] },
      { name: " Wallis and Futuna Islands", country_code_ISO2: "WF", dial_code: "+681", states: [] },
      { name: " Western Sahara (Former Spanish Sahara)", country_code_ISO2: "EH", dial_code: "", states: [] },
      { name: " Yemen", country_code_ISO2: "YE", dial_code: "+967", states: [] },
      { name: " Yugoslavia", country_code_ISO2: "YU", dial_code: "", states: [] },
      { name: " Zaire (Former Congo Free State, Belgian Congo, Congo/Leopoldville, Congo/Kinshasa, Zaire) Now CD - Congo, Democratic Republic of the", country_code_ISO2: "ZR", dial_code: "", states: [] },
      { name: " Zambia, Republic of (Former Northern Rhodesia)", country_code_ISO2: "ZM", dial_code: "+260", states: [] },
      { name: " Zimbabwe, Republic of (Former Southern Rhodesia, Rhodesia)", country_code_ISO2: "ZW", dial_code: "+263", states: [] },
    ]
  };

  const availableState: any = data.countries.find((c) => c.name === selectedCountry);
  const availableCities: any = availableState?.states?.find((s: any) => s?.name === selectedState);
  const [isOpenConfirmModal, setisOpenConfirmModal] = useState(false);
  const handleChangeFirstName = (e: any) => {
    if (e.target.value != "" && e.target.value != null) {
      setfirstName(e.target.value);
      setIsReadyForUpdate(true);
    }
  }
  const handleChangeLastName = (e: any) => {
    if (e.target.value != "" && e.target.value != null) {
      setlastName(e.target.value);
      setIsReadyForUpdate(true);
    }
  }
  const handleChangeEmail = (e: any) => {
    if (e.target.value != "") {
      user.emailAddress = e.target.value;
      setemail(e.target.value);
      setIsReadyForUpdate(true);
    }
  }
  const handleChangePhone = (e: any) => {
  }

  const handleAnniversaryDateUpdate = (e: any) => {
    if (e.target.value != "") {
      const dateValue = e.target.value;
      setanniversaryDateValue(dateValue);
      setIsReadyForUpdate(true);
    }
  }

  const handledobDateUpdate = (e: any) => {
    const today = new Date();
    const dobDate = new Date(e.target.value);
    
    const minAge = 10;
    const maxAge = 100;
    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();
    const dayDiff = today.getDate() - dobDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    if (dobDate > today) {
      alert('Date of birth cannot be in the future.');
    }
    else if (age < minAge) {
      alert('You must be at least 10 years old.');
    }
    else if (age > maxAge) {
      alert('Age must be less than 100 years.');
    }
    if (e.target.value != "" && dobDate < today && age > minAge && age < maxAge) {
      const dateValue = e.target.value;
      setdobDateValue(dateValue);
      setIsReadyForUpdate(true);
    }
  }

  const handleAddress = (e: any) => {
    if (e.target.value != "") {
      setaddress(e.target.value);
      setIsReadyForUpdate(true);
    }
  }

  const handlePincode = (e: any) => {
    if (e.target.value != "" && e.target.value.match('^[0-9]*$') !== null && e.target.value != '0') {
      setpincode(e.target.value);
      setIsReadyForUpdate(true);
    }
  }
  const onBtnClick = () => {
    inputFileRef.current?.click();
  };

  async function deleteUser() {
    try {
      const result = await Auth.deleteUser();
      window.alert("User successfully deleted")
      console.log(result);
      SessionManager.logout()
      window.location.assign("/#");
    } catch (error) {
      console.log('Error deleting user', error);
    }
  }

  const deleteAccount = () => {
    if (window.confirm("Are you sure, you want to delete your account?")) {
      if (SessionManager.getUser() != null) {
        SessionManager.getJWTToken((jwtToken: any) => {
          deleteUser()
        });
      }
      else {
        window.location.assign("/login");
      }
    }
  }
  function closeConfirmModal() {
    setisOpenConfirmModal(false);
  }

  function openConfirmModal() {
    setisOpenConfirmModal(true);
  }

  const saveAddress = () => {
    if (isloggedin != null) {
      SessionManager.getJWTToken((jwtToken: string) => {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwtToken,
          },
          body: JSON.stringify({
            "country": selectedCountry,
            "detectedLocation": "",
            "district": "",
            "email": email,
            "houseNoStreet": address,
            "id": "",
            "isPrimary": true,
            "firstName": firstName,
            "lastName": lastName,
            "new": false,
            "parentUserId": "",
            "phoneNumber": phn,
            "phoneNumberVerified": true,
            "pincode": pincode,
            "state": selectedState,
            "tehsil": "",
            "userId": user.userId,
            "villageCity": selectedCity
          })
        };

        const url = constants.API_CONTEXT_URL + '/account/addAddress';
        fetch(url, requestOptions)
          .then(async response => {
            updateUserdetails()
            //setsuccessmessge("Updated successfully!!");
          })
          .catch((error) => {
            alert("Review not published" + error);
          });
      });
    }
    else {
      // Save in Local Storage to access after login
      window.location.assign("#/login")
    }

  }

  const updateUserdetails = () => {
    if (isloggedin != null) {
      SessionManager.getJWTToken((jwtToken: string) => {
        if(existingEmailId != email){
          setEmailVerified(false);
        }
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwtToken,
          },
          body: JSON.stringify({

            "dateOfAniversary": anniversaryDateValue,
            "dateOfBirth": dobDateValue,
            "emailAddress": email,
            "firstName": firstName,
            "gender": genderValue,
            "id": user.id,
            "lastName": lastName,
            "new": user.new,
            "phoneNumber": phn,
            "role": SessionManager.getRole(),
            "status": user.status,
            "userId": user.userId,
            "profileImage": profileImage,
            "emailAddressVerified": existingEmailId != email ? false : emailVerified
          })
        };


        const url = constants.API_CONTEXT_URL + '/account/saveUser';
        fetch(url, requestOptions)
          .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            SessionManager.setUser(data.response);
            setsuccessmessge("Updated successfully!!");
            if(existingEmailId != email){
              const userFromCognito = await Auth.currentAuthenticatedUser();
              userFromCognito.Session = userFromCognito.signInUserSession;
              const userAttributes = { email: email };
              Auth.updateUserAttributes(userFromCognito, userAttributes).then((res) => {
                console.log("updating user attributes: Email -> "+ res);
              }).catch(e => {
                console.log("Error in updating user attributes", e);
              });
            }
          })
          .catch((error) => {
            alert("Review not published" + error);
          });
      });
    }
    else {
      // Save in Local Storage to access after login
      window.location.assign("#/login")
    }

  }

  const saveToSystem = (val:any) => {
    SessionManager.getJWTToken((jwtToken: string) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwtToken
        },
        body: JSON.stringify({
          userId: user.userId,
          firstName: firstName,
          lastName: lastName,
          emailAddress: email,
          phoneNumber: phn,
          gender: genderValue,
          pincode: pincode,
          houseNoStreet: address,
          profileImage: (val == true ? profileImage : val) ?? profileImage,
          dateOfAniversary: anniversaryDateValue,
          dateOfBirth: dobDateValue,
          emailAddressVerified: val == true ? true : (existingEmailId != email ? false : emailVerified)
        })
      };

      fetch(constants.API_CONTEXT_URL + '/account/saveUser', requestOptions)
        .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          SessionManager.setUser(data.response);
          setsuccessmessge("Updated successfully!!");
          setTimeout(() => { setsuccessmessge(""); }, 3000);
          //window.location.reload();
        })
        .catch(error => {
          setErrorMessage(error.toString())
          console.error('There was an error!', error);
        });
    });
  }

  function SetUserAddress(response: any) {
    response.map((entry: any) => {
      //console.log(entry)
      if (entry.isPrimary) {
        setSelectedCity(entry.villageCity);
        setSelectedCountry(entry.country);
        setSelectedState(entry.state);
        setemail(entry.email);
        setaddress(entry.houseNoStreet);
        setpincode(entry.pincode);
        setExistingEmailId(entry.email);
      }
      return true;
    });
  }

  function SetUserdata(response: any) {
    let data: any = response ? response : "";
    if (data != null) {
      setanniversaryDateValue(data.dateOfAniversary?.split('T')?.[0]);
      setdobDateValue(data.dateOfBirth?.split('T')?.[0]);
      setGenderValue(data.gender);
      setfirstName(data.firstName);
      setlastName(data.lastName);
      setPhn(data.phoneNumber ?? user.userId);
      setProfileImage(data.profileImage);
      setemail(data.emailAddress);
      setEmailVerified(data.emailAddressVerified ?? false);
    }
  }

  useEffect(() => {
    if (isloggedin != null) {
      // SessionManager.getUserEmailVerifiedStatus((isUserEmailVerified: string) => {
      //   setisEmailVerified(isUserEmailVerified);
      // });
      SessionManager.getJWTToken((jwtToken: string) => {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwtToken,
          },
        };
        const url = constants.API_CONTEXT_URL + '/account/listAddress';
        fetch(url, requestOptions)
          .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            // check for error response
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            SetUserAddress(data.response);
            //console.log("phoneNumber"+ user.phoneNumber)      
            let userIdTrimmed: string = SessionManager.getUserId();
            var userId = userIdTrimmed?.split('+')
            if (isloggedin != null) {
              const url = constants.API_CONTEXT_URL + '/account/viewUser?userId=%2B' + userId[1];
              fetch(url, requestOptions)
                .then(async response => {
                  const isJson = response.headers.get('content-type')?.includes('application/json');
                  const data = isJson && await response.json();
                  SetUserdata(data.response);
                  //SessionManager.setUser(data.response);
                })
            }
          })
          .catch((error) => {
            alert("Review not published" + error);
          });
      });
    }
    else {
      // Save in Local Storage to access after login
      window.location.assign("#/login")
    }
  }, []);


  function compressImage(file: File, maxWidth: number, maxHeight: number, quality: number): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        // create an image element from the file data
        const image = new Image();
        image.onload = () => {
          // calculate the new dimensions based on the maximum dimensions
          let newWidth = image.width;
          let newHeight = image.height;
          if (newWidth > maxWidth) {
            newHeight *= maxWidth / newWidth;
            newWidth = maxWidth;
          }
          if (newHeight > maxHeight) {
            newWidth *= maxHeight / newHeight;
            newHeight = maxHeight;
          }
          
          // create a canvas element
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          // set the canvas dimensions to the new dimensions
          canvas.width = newWidth;
          canvas.height = newHeight;
          
          // draw the image on the canvas
          ctx?.drawImage(image, 0, 0, newWidth, newHeight);
          
          // convert the canvas to a data URL
          const dataUrl = canvas.toDataURL(file.type, quality);
          
          // convert the data URL to a Blob object
          const byteString = atob(dataUrl.split(',')[1]);
          const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([arrayBuffer], { type: mimeString });
          
          // create a new file from the compressed data
          const compressedFile = new File([blob], file.name, { type: blob.type });
          
          // resolve the promise with the compressed file
          resolve(compressedFile);
        };
        image.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    });
  }

  
  async function handleUpload(event: any)  {
    event.preventDefault();
    const url = constants.API_CONTEXT_URL + '/mediaAccess/upload?assetId=' + user.id + '&assetType=PROFILE&mediaType=IMAGE&metadata=a&sequence=0';
    const formData = new FormData();
    const compressedFile = event.target.files[0];
    compressedFile.width = compressedFile.width * 0.45;
    compressedFile.height = compressedFile.height * 0.45;
    
    formData.append('file', compressedFile);
    formData.append('fileName', event.target.files[0].name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    try {
      const response = await axios.post(url, formData, config)
        .then((response) => {
          if (response.status)
            setmedia({
              "id": "1",
              "url": response.data.response.url
            });
          setProfileImage(response.data.response.url);
          saveToSystem(response.data.response.url);
        });
    } catch {
      const formData = new FormData();
      const compressedFile = await compressImage(event.target.files[0], 500, 300, 0.6);
      formData.append('file', compressedFile);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios.post(url, formData, config)
        .then((response) => {
          setmedia({
            "id": "1",
            "url": response.data.response.url
          });
          setProfileImage(response.data.response.url);
          saveToSystem(response.data.response.url);
        });
    }
  }

  async function handleDeleteImage()  {
    setProfileImage("");
    saveToSystem("");
    closeConfirmModal();
  }
  const confirmDelete = () => {
    return (
      <Transition appear show={isOpenConfirmModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeConfirmModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-xl">
                <div className="inline-flex p-4 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 text-center">
                    <h2
                      className="text-xl leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Confirm Action
                    </h2>
                    <span className="absolute right-3 top-3">
                      <ButtonClose onClick={() => closeConfirmModal()} />
                    </span>
                  </div>
                  <div className="px-2 overflow-auto text-neutral-700 divide-y divide-neutral-200">
                    <div className="flow-root py-3">
                      Are you sure, you want to remove the account picture?
                    </div>
                    <div className="flex p-3 justify-space-evenly">
                      <ButtonPrimary onClick={() => handleDeleteImage()}>Ok</ButtonPrimary>
                      <ButtonPrimary onClick={() => closeConfirmModal()}>Cancel</ButtonPrimary>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    )
  }
  function closeModal() {
    setIsOpenModal(false);
  }

  function openModal() {
    setIsOpenModal(true);
  }
  const sendVerificationEmail = () => {
    setEmailVerifyCode("");
    SessionManager.getJWTToken((jwtToken: string) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwtToken,
        },
      };
      const url = constants.API_CONTEXT_URL + '/account/sendEmailVerifyOtp';
      fetch(url, requestOptions)
        .then(response => {
          openModal();
        })
        .catch((error) => {
          alert("Error sending email " + error);
        });
    });
  }
 
  const renderModal = () => {
    return (
      <AccountEmailVerify isOpenModal={isOpenModal} closeModal={closeModal} openModal={openModal}
       setEmailVerified ={setEmailVerified} saveToSystem={saveToSystem}/>
    );
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account Information</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 px-4 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account information</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative flex-col rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-40 h-40" imgUrl={profileImage} userName={firstName} isAccountPage={true}/>
              </div>
              {(profileImage != "" && profileImage != null) ?
              <TrashIcon onClick={() => openConfirmModal()} color="red" style={{height:"28px"}}></TrashIcon>
            : null}
              </div>&nbsp;
            <div>
              <svg onClick={onBtnClick}
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                  stroke="currentColor"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <input
              type="file"
              ref={inputFileRef}
              accept="image/*"
              onChange={handleUpload}
              className="absolute hidden inset-0 opacity-0 cursor-pointer"
            /> 
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div className="flex justify-space-between">
                <div className="width-fill">
                  <Label>First Name</Label>
                  <Input className="mt-1.5" placeholder="Enter first name"
                    onChange={(e: any) => handleChangeFirstName(e)}
                    defaultValue={firstName} />
                </div>&nbsp;
                <div className="width-fill">
                  <Label>Last Name</Label>
                  <Input className="mt-1.5" placeholder="Enter last name"
                    onChange={(e: any) => handleChangeLastName(e)}
                    defaultValue={lastName} />
                </div>
              </div>
              <div className="flex justify-space-between">
                <div className="mt-1.5 width-fill">
                  <Label>Date of birth</Label>
                  <Input
                    className="mt-1.5"
                    type="date"
                    defaultValue={dobDateValue == null ? "" : dobDateValue}
                    onChange={(e: any) => handledobDateUpdate(e)}
                  />
                </div>&nbsp;
                <div className="mt-1.5 width-fill">
                  <Label>Date of Anniversary</Label>
                  <Input
                    className="mt-1.5"
                    type="date"
                    defaultValue={anniversaryDateValue == null ? "" : anniversaryDateValue}
                    onChange={(e: any) => handleAnniversaryDateUpdate(e)}
                  />
                </div>
              </div>
              <div className="flex justify-space-between">
                <div className="width-fill">
                  <Label>Gender</Label>
                  <select className="mt-1.5"
                    value={genderValue}
                    onChange={(e: any) => setGenderValue(e.target.value)}
                  >
                    <option>--Choose Gender--</option>
                    {data.gender.map((value, key) => {
                      return (
                        <option value={value.name} key={key}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </div>&nbsp;
                <div className="width-fill">
                  <Label>Phone Number</Label>
                  <PhoneInput
                    className="h-11 mt-1.5"
                    style={{ "--PhoneInputCountryFlag-height": "1.3rem", "width": "100%" }}
                    international
                    defaultCountry="IN"
                    value={phn}
                    onChange={handleChangePhone}
                    required disabled />
                </div>
              </div>
              <div className="flex justify-space-between">
                <div className="width-fill">
                  <div className="flex mt-1">
                  <Label>Email</Label>
                  {emailVerified == true ?
                    <span className="px-3 flex justify-between text-sm items-center text-green-800 ">
                      (Verified)
                    </span>
                    :
                    <div className="flex">
                      <span className="px-3 flex justify-between text-sm items-center text-red-800 ">
                        (Not Verified)
                      </span>
                        {existingEmailId != email ?
                          <a className="px-3 flex justify-between text-sm items-center">
                            Verify now
                          </a>
                          :
                          <a href="javascript:void();" onClick={() => sendVerificationEmail()} className="px-3 flex justify-between text-sm items-center text-green-800 hover:text-green-300 ">
                            Verify now
                          </a>
                        }
                    </div>
                  }
                  </div>
                  <Input className="mt-1.5"
                    onChange={(e: any) => handleChangeEmail(e)}
                    defaultValue={email} />
                </div>&nbsp;
                <div className="width-fill">
                  <Label>Address</Label>
                  <Input className="mt-1.5"
                    onChange={(e: any) => handleAddress(e)}
                    defaultValue={address} />
                </div>
              </div>
              <div className="flex justify-space-between">
                <div className="width-fill">
                  <Label>Country</Label>
                  <select className="mt-1.5"
                    value={selectedCountry}
                    onChange={(e: any) => setSelectedCountry(e.target.value)
                    }
                  >
                    <option>--Choose Country--</option>
                    {data.countries.map((value, key) => {
                      return (
                        <option value={value.name} key={key}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </div>&nbsp;
                <div className="width-fill">
                  <Label>State</Label>
                  <select className="mt-1.5"
                    value={selectedState}
                    onChange={(e: any) => setSelectedState(e.target.value)}
                  >
                    <option>--Choose State--</option>
                    {availableState?.states.map((e: any, key: any) => {
                      return (
                        <option value={e.name} key={key}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="flex justify-space-between">
                <div className="width-fill">
                  <Label>City</Label>
                  <select className="mt-1.5"
                    value={selectedCity}
                    onChange={(e: any) => setSelectedCity(e.target.value)}
                  >
                    <option>--Choose City--</option>
                    {availableCities?.cities.map((e: any, key: any) => {
                      return (
                        <option value={e.name} key={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>&nbsp;
                <div className="width-fill">
                  <Label>Pincode</Label>
                  <Input className="mt-1.5"
                    onChange={(e: any) => handlePincode(e)}
                    value={pincode} />
                </div>
              </div>
              <span className="px-3 flex justify-between items-center text-green-800 ">
                  {successmessge}
                </span>
              <div className="flex justify-between items-center">
                <Button className="ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50"
                 disabled={!isReadyForUpdate} onClick={() => saveAddress()}>Update info</Button>
                <ButtonSecondary className="right" onClick={() => deleteAccount()}>Delete Account</ButtonSecondary>
              </div>           
                
            </div>
          </div>
        </div>
        {confirmDelete()}
        {isOpenModal == true ?
        renderModal()
        : null}
      </CommonLayout>
      
    </div>
  );
};

export default AccountPage;
