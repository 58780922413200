import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';

export interface MobileOTPInitiateProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const MobileOTPInitiate: FC<MobileOTPInitiateProps> = ({ className = "" }) => {
  const [errormessage, seterrormessage] = useState("");
  const [userCreated, setUserCreated] = useState("");
  const [loading, setLoading] = useState(false);
  
  function handleChangeUserName() {
    var usrNameValue = (document.getElementById("register_name") as HTMLInputElement).value;

    return usrNameValue;
  }

  function handleChangeEmail() {
    var register_email = (document.getElementById("register_email") as HTMLInputElement).value;
    
    return register_email;
  }
  function handleChangePassword() {
    var register_password = (document.getElementById("register_password") as HTMLInputElement).value;
    
    return register_password;
  }
  function handleChangePhone() {
   //this.phone_number = e
   var register_mobile = (document.getElementById("register_mobile") as HTMLInputElement).value;
    if (register_mobile != "" ) { //&& register_mobile.match('^([+]\d{2})?\d{10}$') 
      return register_mobile;
    }
    else return ''

    
   }
  
   function handleChangeRepeatPassword() {
    var register_repeat_password = (document.getElementById("register_repeat_password") as HTMLInputElement).value;
    if (register_repeat_password != "") {
      
    }
    return register_repeat_password;
  }


  // handleTermsCheckbox = (e) => {
  //   this.setState({
  //     errormessage : ""
  //   })
  //   this.setState({
  //     showResults: true
  //   })
  // }

  function handleSubmit() {
   
    seterrormessage("");

    var usrNameValue = handleChangeUserName();
    if(usrNameValue == '' || usrNameValue == null){
      seterrormessage("Please provide your name");
      return
    }

    var mobile = handleChangePhone();
    if(mobile == '' || mobile == null){
      seterrormessage("Please provide a valid mobile number in format +919999999999");
      return
    }

    var email = handleChangeEmail();
    if(email == '' || email == null){
      seterrormessage("Please provide your email");
      return
    }
    if(email?.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === null){
      seterrormessage("Email is not in correct format");
      return
    }

    var password = handleChangePassword();
    var repeatPassword = handleChangeRepeatPassword();

    if(password != repeatPassword){
      seterrormessage("Password and Confirm password should be same");
      return
    }

    signUp(usrNameValue, password, mobile, email);
  }

  function signUp(name:string, password:string, phone_number:string, email:string) {
    try {
      Auth.signUp({
        username: phone_number, //username,
        password: password,
        attributes: {
          name: name,
          email: email,          // optional
          phone_number: phone_number,   // optional - E.164 number convention
          // other custom attributes 
        }
      }).then(user => {
        // at this time the user is logged in if no MFA required
        // if (this.userCreated != null) {
        //   this.userCreated(true, user.user, password);
        // }
        // else {
        //   alert("this.userCreated " + this.userCreated);
        // }
      }).catch(e => {
        if(e.code === 'InvalidPasswordException') {
          seterrormessage("Password should be 8  characters long, containing numbers, special characters, uppercase letters and lowercase letters.");
           
        }else {
          seterrormessage(e.message)
        }
        
        //console.log(e);
        // if (this.userCreated != null) this.userCreated(false, e);
      });


    } catch (error) {
      seterrormessage(error + "");
      console.log('error signing up:', error);
      // if (this.userCreated != null) this.userCreated(false, error);
      return;
    }
    finally {
      setLoading(false)
    }
  }
  return (
    <div className={`nc-MobileOTPInitiate  ${className}`} data-nc-id="MobileOTPInitiate">
      <Helmet>
        <title>Sign up || eSanchari</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Enter OTP
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          
          {/* FORM */}
          <div className="grid grid-cols-1 gap-6" >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Name
              </span>
              <Input
                type="text"
                id="register_name"
                placeholder="Enter your name"
                
                className="mt-1"
              />
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Mobile Number
              </span>
              <Input
                type="text"
                id="register_mobile"
                placeholder="+919999999999"
                className="mt-1"
                onChange={() => handleChangePhone}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email
              </span>
              <Input
                type="email"
                id="register_email"
                placeholder="abc@gmail.com"
                className="mt-1"
                onChange={() => handleChangeEmail}
              />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input id="register_password" type="password" 
              onChange={() => handleChangePassword}
              className="mt-1" />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input id="register_repeat_password" type="password"
               onChange={() => handleChangeRepeatPassword}
                className="mt-1" />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-red-800 ">
                {errormessage}
              </span>
              
            </label>
            <ButtonPrimary onClick={()=> handleSubmit()}>Continue</ButtonPrimary>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="#/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default MobileOTPInitiate;
