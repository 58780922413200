import Util from "./util";
import BrandLogo from "./../../src/images/eSanchari_logo.png"



export default class PayuMoney {
    static async handlePayment(companyName, paymentDetail, callback) {

        // const res = await Util.loadScript(
        //     // "https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js"
        //     // "https://api.payu.in/js-sdks/bolt.min.js"
        // );

        // if (!res) {
        //     alert("Payu SDK failed to load. Are you online?");
        //     return;
        // }

        var pd = {
            key: paymentDetail.key,
            txnid: paymentDetail.txnId,
            amount: paymentDetail.amount,
            firstname: paymentDetail.name,
            email: paymentDetail.email,
            phone: paymentDetail.phone,
            productinfo: paymentDetail.productInfo,
            surl: paymentDetail.surl,
            furl: paymentDetail.furl,
            hash: paymentDetail.hash
        }

        window.bolt.launch(pd, {
            responseHandler: function (response) {
                if(response.response.txnStatus == "SUCCESS"){
                    console.log('Your payment has been successful');
                    document.body.style.removeProperty('overflow');
                    callback(response.response,"SUCCESS");
                  }
                  else if (response.response.txnStatus == "FAILED") {
                     console.log('Payment failed. Please try again.');
                     document.body.style.removeProperty('overflow');
                     callback(response.response,"FAILED");
                  }
                  else if(response.response.txnStatus == "CANCEL"){
                     console.log('Payment failed. Please try again.');
                     document.body.style.removeProperty('overflow');
                     callback(response.response,"CANCEL");
                  }
                
            },
            catchException: function (response) {
                // the code you use to handle the integration errors goes here
                // Make any UI changes to convey the error to the user
                console.log(response);
            }
        });
    }

}

