import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng1 from "images/travelhero2.png";
import imagePng from "images/travelmain.jpg";
import esanchariLogo from "images/eSanchari_logo_1024.png";
import esanchariNameLogo from "images/eSanchari_logo.png";
import SectionHero from "./SectionHero";
import SectionHomeBars from "./SectionHomeBars";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  return (
    <div>
      <div
        className={`nc-SectionHero3 hidden lg:block relative ${className} `}
        data-nc-id="SectionHero3"
      >
        
        {/* <div className="  absolute z-10 inset-x-0 top-[10%] sm:top-[10%] text-center flex flex-col items-center max-w-4xl mx-auto space-y-4 lg:space-y-5 xl:space-y-4">
          <span className="sm:text-lg md:text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-white">
            let's experience the
          </span>
          <h2 className="font-bold text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%] ">
            Life
          </h2>
          <SectionHomeBars className="pt-10 lg:pt-16 lg:pb-16 min-w-avabl"/>
        </div> */}
        <div className=" relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
          <img
            className="absolute inset-0 object-cover rounded-xl"
            src={'https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/BANNER/webBanner.jpg'}
            alt="hero"
          />
        </div>
      
      </div>

      <div
        className={`nc-SectionHero3  lg:hidden relative ${className} `}
        data-nc-id="SectionHero3"
      >
        <div className="  absolute z-20 inset-x-5 top-[5%] sm:top-[10%] text-center">
          <div className="w-24 ">
            <img src={esanchariNameLogo} className="w-20" alt="" />
          </div>
        </div>
        {/* <div className="  absolute z-20 inset-x-5 top-[30%] sm:top-[10%] text-center flex flex-col items-center max-w-4xl mx-auto space-y-4 lg:space-y-5 xl:space-y-5">

          <span className="sm:text-lg md:text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-white">
            let's experience the
          </span>
          <h2 className="font-bold text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%] ">
            Life
          </h2>
          <SectionHero className="pt-10 lg:pt-16 lg:pb-16 min-w-avabl" />
        </div> */}

        <div className=" relative aspect-w-2 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-4 lg:aspect-h-3 xl:aspect-h-8 ">
          <img
            className="absolute inset-0 object-cover rounded-xl"
            src={'https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/HOMEPAGE/BANNER/mobileBanner.jpg'}
            alt="hero"
          />
        </div>
      </div>

    </div>

    
  );
};

export default SectionHero3;
