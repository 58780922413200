export default class Util {
    static loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    static goBack = () => {
        window.history.back();
        window.scrollTo(0, 0);
    }
}

export class AgeGroupValidation {
    static isValidAgeGroup(age, type) {
        return new Promise((resolve, reject) => {
            switch (type) {
                case 'Casino':
                    if(age >= 18){
                        resolve(true);
                    }
                    else{
                        reject(false);
                    }
                    case 'zoo':
                        if(age >= 5){
                            resolve(true);
                        }
                        else{
                            reject(false);
                        } 
                default:
                    reject(false);
            }
        })
    }
}

export class CommissionService {
    static isValidAgeGroup(productid, days) {
        return new Promise((resolve, reject) => {
            
        })
    }
}


export class SearchService {
    static route(item) {
        var url = "#/"
        // if(item.id.startsWith("Event")) {
        // // productDataType.type = "Event";
        // url = url +"AllListingsHome?SelectedTab=Ticketing";
        // }
        // else if(item.id.startsWith("Vehicle")) {
        // url = url +"AllListingsHome?SelectedTab=Rides";
        // }
        // else if(item.id.startsWith("Yacht")) {
        // url = url +"AllListingsHome?SelectedTab=Yachts";
        // }
        // else if(item.id.startsWith("WaterSport")) {
        // url = url +"AllListingsHome?SelectedTab=Watersport";
        // }
        if("PRODUCT" == item.assetType) {
        url = url + "product?productId=" + item.id;
        }

        url = url + "&title=" + item.display;
        url = url + "&description=" + item.display;
        if(item.searchType.startsWith("Title")) {
            url = url + "&title=" + item.display;
        }
        else if(item.searchType.startsWith("Description")) {
            
        }

        window.location.href = url;
        return;
    }
}