import axios from 'axios';

import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";


export interface PageAboutProps {
  className?: string;
}

const PrivacyPolicy: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PrivacyPolicy overflow-hidden relative ${className}`}
      data-nc-id="PrivacyPolicy"
    >
      <Helmet>
        <title>Privacy Policy || eSanchari</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-10 lg:py-28 space-y-16 lg:space-y-5">
        <SectionHero
          rightImg={rightImg}
          heading="Privacy Policy"
          btnText=""
          subHeading=""
        />
        <div className='mx-3 mb-4 aboutushead p-3' >
        At eSanchari.com, a digital property of DIGIVERTI INFOTECH PRIVATE LIMITED, we value and respect the privacy of our Users. This Privacy Policy explains how we collect, use and share your personal information through our website eSanchari.com.com and its mobile application. Please read this Privacy Policy carefully. By clicking on the “ACCEPT & CONTINUE” button on this electronic document, you are consenting to be bound by this Privacy Policy. If we change our Privacy Policy/Practices, we may update this privacy policy. If any changes are significant, we will let you know through [Mail, Notifications]. 
        <br /> IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT USE OR ACCESS THIS WEBSITE OR APPLICATION.
        <br /> This Privacy Policy governs the manner in which eSanchari.com.com collects, uses, maintains and discloses information collected from users (each, a "User") of the eSanchari.com.com. This privacy policy applies to the App or to the Website of eSanchari.com and all products and services offered by eSanchari.com. However, you may be able to browse a certain section of this Website or App without registering with us.
        
           <br /> <b>SUMMARY OF KEY POINTS </b>
          <p className='px-4'>
            ●	<b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with eSanchari.com.com and the Services, the choices you make, and the products, services and features you use.
            <br />●	<b>Do we process any sensitive personal information?</b> We may process sensitive personal information when necessary only with your consent or as otherwise permitted by applicable law.
            <br />●	<b>Do you receive any information from third parties?</b> We may receive information from public databases, marketing partners, social media platforms, and other outside sources.
            <br />●	<b>How do you process my information?</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.
            <br />●	<b>In what situations and with which parties do we share personal information?</b> We may share your information with Vendor, Service Provider or Business Owners in specific situations and with specific third parties.

            <br />●	<b>How do we keep your information safe?</b> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
            <br />●	<b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. You have option to withdraw your consent that you have already provided by writing to us at the contact information provided below.
            <br />●	<b>How do I exercise my rights?</b> The easiest way to exercise your rights is by sending an email to us or by contacting us through the information provided under “Contact Us” section on the Sachari.com and its app. We will consider and act upon any request in accordance with applicable data protection laws.
            <br />
          </p>

          <br /> <b>OVERVIEW </b>          
          This Privacy Policy applies to the Users of eSanchari.com Services anywhere in the India, including the Users of its features and other services. This Privacy Policy describes how eSanchari.com and its affiliates collects and use your personal data. 
          <br /> The eSanchari.com are committed to protecting and respecting your Privacy. We do collect your personal information and process your personal data in accordance with the Information Technology Act, 2000 (also known as ITA-2000, or the IT Act) and other national and state laws which relate the processing of your personal data. 
          <br /> We collect your information in order to provide and continually improve our services. Our Privacy Policy is subject to change at any time without a notice, to make sure you are aware of any changes, please review this Policy periodically. All the Partners and Independent Partners who are working with or for us, or who have access to personal information, will be expected to read and comply with this Policy. 


          <br /> <b>COLLECTION OF YOUR INFORMATION </b>          
          When you use our App or Website, you required to create an account. For that we may collect personal identification information from you in a variety of ways, including, but not limited to, when you visit our App or Website, register there, place an order, and in connection with other activities, services, features or resources we make available on our App or Website. Users may be asked for, as appropriate, name, email address, mailing address, phone number, business category, product/service information or price. Users may, however, visit our App or Website. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain App or Website related activities.
          <br /> The information we may collect via the Application or the Website depends on the content and Services You Use, and includes. You always have the option to not provide information by choosing not to use a particular service, product or feature on our Platform.
          <br /> eSanchari.com has on boarded certain Independent Business Partners, Vendor, Service Provider and Business Owners. If you avail the services of these Independent Business Partners, Vendor, Service Provider and Business Owners, you agree to share some additional information based on your login credentials after seeking your permission to share the data further. 
          <br />
          <br /> <b>Your Personal Data </b>          
          Demographic and other personally identifiable information (Such as your name and email address) that you voluntarily give while registering on Our App or when choosing to avail services of various Independent Business Partners, Vendor, Service Provider and Business Owners. If you choose to share data about yourself via your profile, online chat, or other interactive areas of the Application, please be advised that all data you disclose in these areas is public and your data will be accessible to anyone who access the Application. We use your personal information to assist Independent Business Partners, Vendor, Service Provider and Business Owners in handling and fulfilling orders; enhancing customer experience; help promote a safe service; customize and enhance your experience; enforce our terms and conditions.
          <br /> In our efforts to continually improve the product and service offerings through our platform, we identify and use your IP address to help diagnose problems with our server, and to administer our Platform. Your IP address is also used to help identify you and to gather broad demographic information.
          <br />
          <br /> <b>Derivative Data </b>          
          Information our App or Servers automatically collect when you access the Application, such as your native actions that are integral to the Application, including liking, replying to a message, as well as other interactions with the Application and other Users via server log files.  

          <br />
          <br /> <b>Non-Personal Identification Information</b>          
          We may collect non-personal identification information about Users whenever they interact with our App. Non-personal identification information may include the browser name, the type of computer and technical information about Users' means of connection to our Site, such as the operating system and the Internet service providers' utilized and other similar information.
          <br />

          <br />
          <br /> <b>Data from Social Networks</b>          
          Users information from the Social Networking sites, such as Facebook, Google+, Instagram, YouTube, Pinterest, Twitter, including your name, your social network user name, location, gender, birthdate, email address, profile picture, and public data for contacts, if you connect your account to such social networks. This information may also include the contact information of anyone you invite to use and/or join the Application. 
          <br />

          <br /> <b>Geo-Location Information</b>          
          We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our Access or Permissions, you may do so in your device’s settings. 
          <br />

          <br /> <b>Push Notifications</b>          
          We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these type of communications, you may turn off in your Device’s settings.
          <br />

          <br /> <b>Mobile Device Access & Data</b>          
          We may request access or permission to certain features from your mobile device, including your mobile device’s storage. If you wish to change our access or permission, you may do so in your Device’s settings.
          <br /> Device information such as your mobile device ID number, model, and manufacturer, version of your operation system, phone number, country, location, and any other data you choose to provide. 
          <br />

          <br /> <b>Information you Submit</b>          
          If you voluntarily submit information to us, e.g., if you participate in a survey, provide us feedback, or respond to our request for information - we will collect the information you submit. In each such instance, you will know what data we collect, because you actively submit it. We shall use the data you voluntarily submit to us for the purposes we describe in this Privacy Policy, as well as any purposes that we describe to you when we collect such information.
          <br />



          <br /> <b>HOW WE USE COLLECTED INFORMATION</b>          
          Having accurate information about you permits us to provide you with a smooth, efficient, and customized Service experience. Specifically, the eSanchari.com may collect and use Users personal information for the following purposes:
          <br />
          <br /> <b>To improve customer Service </b>          
          Information you provide helps us respond to your customer service requests and support needs more efficiently. The collection of your information unable us to perform necessary actions to maintain our services, including the troubleshoot software bugs and operational problems; to conduct data analysis, testing, and research; and to monitor and analyze usages and activity trends.   
          <br />

          <br /> <b>To Personalize User Experience </b>          
          We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our App. It helps us to personalize the user’s experience or personalize Users’ account. 
          <br />

          <br /> <b>To improve our Working </b>          
          We may use feedback you provide to improve our products and services. It enhances the safety and security of our users and services. We use personal data to help maintain the safety, security, and integrity of our services and users. eSanchari.com uses the information we collect (which may include call recordings) to provide customer support, including to investigate and address user concerns and to monitor and improve our customer support responses and processes.
          <br />

          <br /> <b>To Process Payments </b>          
          We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service. 
          <br />

          <br /> <b>To run a promotion, contest, survey or other Site feature </b>          
          To send Users information they agreed to receive about topics we think will be of interest to them. eSanchari.com may use personal data to market our services to our users. This includes sending users communications about eSanchari.com’s Services, features, promotions, studies, surveys, news, updates, and events. We may do so through various methods, including email, text messages, push notifications, in app communications and ads, and ads on third party platforms.
          <br />

          <br /> <b>To send periodic emails </b>          
          We may use the email address to send User information and updates pertaining to their Service order. It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or User may contact us via our App.
          <br />

          <br /> <b>Research and Development </b>          
          We may use personal data for testing, research, analysis, Service development, and machine learning to improve the user experience. This helps us make our services more convenient and easy-to-use, enhance the safety and security of our services, and develop new services and features.
          <br /> eSanchari.com performs the above activities on the grounds that they are necessary to fulfil our obligations to users under our Terms of Use or other agreements with users in improving our existing services and features, or for purposes of eSanchari.com’s legitimate interests developing new services and features.

          <br /> <b>For Legal Requirements </b>          
          We may use personal data to investigate or address claims or disputes relating to use of eSanchari.com’s services, to satisfy requirements under applicable laws, regulations, or operating licenses or agreements, or pursuant to legal process or governmental request, including from law enforcement.
          <br /> eSanchari.com performs the above activities on the grounds that they are necessary for purposes of eSanchari.com’s legitimate interests in investigating and responding to claims and disputes relating to use of eSanchari.com’s services and features, and/or necessary for compliance with applicable legal requirements.
          



          <br /> <b>HOW WE PROTECT YOUR INFORMATION</b>          
          We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our App.
          <br /> Our team work tirelessly to protect your information, and to ensure the security and integrity of our platform. We also timely audit, assess the security of our data storage and systems that process financial information by the experts.
          <br /> We take due care to protect Users data. Technical measures are in place to prevent unauthorized access to data and against accidental loss or destruction of, or damage to, data. The employees who are dealing with the data have been trained to protect the data form any illegal or unauthorized usage. We work to protect the security of your information during the transmission, this allows sensitive information such as credit card and numbers, UID’s and login credentials to be transmitted securely. 
          <br /> We follow the Payment Card Industry Data Security Standard (PCI DSS) when handling branded credit cards from the major card schemes. We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of personal Users information. 
          <br /> We take reasonable steps to help protect your personal information in an effort to prevent the loss, misuse, and unauthorized access, disclosure alteration and destruction. It is your responsibility to protect your usernames and password to help prevent anyone from accessing or abusing your accounts and services. You should not use or reuse the same passwords you use with other accounts as your password for our Services. 
       
          
          
          <br /> <b>SHARING YOUR PERSONAL INFORMATION</b>          
          We are an Indian company, but we work with and process data about individuals across the world. To operate our business, we may send your personal information outside your states, province and country. This data may be subject to the laws of the countries where we send it. When we send your information across the borders, we take steps to protect your information, and we try to only send your information to countries that have strong data protection laws. 
          <br /> We do not share your personally identifiable information with others, except as follows: We may share your information with agents, service providers, vendors, contractors, or affiliates who process the data only on our behalf for the purposes set forth in this Policy and under terms of confidentiality. We may also share your information as required by law or in the interest of protecting or exercising our or others' legal rights, e.g., without limitation, in connection with requests from law enforcement officials and in connection with court proceedings. We may share or transfer your information in connection with a prospective or actual sale, merger, transfer or other reorganization of all or parts of our business. Finally, we may also share your personal information where you have granted us permission.
          <br /> We may use third party service providers to help us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us your permission.
          <br /> Some of eSanchari.com’s services and features require that we share personal data with other users or at a user’s request. We may also share such data with our affiliates, subsidiaries, and partners, for legal reasons or in connection with claims or disputes. eSanchari.com may share users’ personal data if we believe it’s required by applicable law, regulation, operating license or agreement, legal process or governmental request, or where the disclosure is otherwise appropriate due to safety or similar concerns.
          <br /> This includes sharing personal data with law enforcement officials, public health officials, other government authorities, airports (if required by the airport authorities as a condition of operating on airport property), or other third parties as necessary to enforce our Terms of Service, user agreements, or other policies; to protect eSanchari.com’s rights or property or the rights, safety, or property of others; or in the event of a claim or dispute relating to the use of our services. In the event of a dispute relating to use of another person’s credit card, we may be required by law to share your personal data, including trip or order information, with the owner of that credit card.
       
          


          <br /> <b>COOKIES </b>
          Cookies are small text files that are stored on browsers or devices by websites, apps, online media, and advertisements. eSanchari.com uses cookies and similar technologies for purposes such as:
          <p className='px-4'>
            ●	authenticating users
            <br />●	remembering user preferences and settings
            <br />●	determining the popularity of content
            <br />●	delivering and measuring the effectiveness of advertising campaigns
            <br />●	analyzing site traffic and trends, and generally understanding the online behaviors and interests of people who interact with our services
          </p>
          <br /> Our App may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the App may not function properly.
          <br /> Additionally, we may use third-party software to serve ads on the Application, implement email marketing campaigns, and manage other interactive marketing initiatives. This their-party software may use cookies or similar tracking technology to help manage and optimize your online experience with us. You can prevent the storage of cookies by choosing a “disable cookies” option in your browser settings. But this can limit the functionality of our Services. 


          <br /> <b>COOKIES </b>
          eSanchari.com retains your data, personal information as per applicable laws for as long as necessary for the purposes described above. This means that we retain different categories of data for different periods of time depending on the type of data, the category of user to whom the data relates, and the purposes for which we collected the data.
          <br /> However, users may request deletion of their account at any time through the help section or mail option in the eSanchari.com App, or through its website.
          <br /> Following an account deletion request, eSanchari.com deletes the user’s account and data, unless they must be retained due to legal or regulatory requirements, for purposes of safety, security, and fraud prevention, or because of an issue relating to the user’s account such as an outstanding credit or an unresolved claim or dispute. Because we are subject to legal and regulatory requirements relating to Independent Partners, Vendor, Service Provider or Business Owner. this generally means that we retain their account and data for a minimum of 5 years after a deletion request. 


          <br /> <b>OPTIONS REGARDING YOUR INFORMATION </b>
          You may at any time review or change the information in your account or terminate your account by:
          <p className='px-4'>
            ●	authenticating users
            <br />●	Logging into your account settings and updating your account
            <br />●	Contacting us using the contact information provided below
            <br />●	By any other means, if provided

          </p>
          <br />Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigation, enforce our Terms of Use and/or to comply with legal requirements. 

          <br /> <b>Emails and Communications </b>          
          If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out any time by:
          <p className='px-4'>
            ●	authenticating users
            <br />●	Noting your preference at the time you register your account with the Application
            <br />●	Login into your account setting and updating your preferences
            <br />●	Contacting us using the contact information provided below
          </p>

          <br /> And if you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.



          <br /> <b>COMPLIANCE WITH CHILDREN'S ONLINE PRIVACY PROTECTION ACT </b>
          Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our App from those we actually know are under 18 years, and if you have shared any personal information of children under the age of 18 years, you represent that you have the authority to do so and permit us to use the information accordance with this Privacy Policy.


          <br /> <b>CHOICE AND TRANSPARENCY </b>
          eSanchari.com enables users to access and/or control data that eSanchari.com collects, including through:
          <br /> <b>Privacy Settings</b>          
          The Settings or Privacy menu in the eSanchari.com allows Users to set or update their preferences regarding location data collection and sharing, emergency data sharing, and notifications.
          <br /> Users can enable/disable eSanchari.com to collect location data from their mobile devices through their device settings, which can be accessed via the Settings - Privacy - Location menu.
          <br /> Users can enable/disable eSanchari.com to share their real-time location data from their mobile devices with their drivers through their device settings, which can be accessed via the Settings - Privacy - Location menu.

          <br /> <b>Device Permissions</b>          
          Most mobile device platforms (iOS, Android, etc.) have defined certain types of device data that apps cannot access without the device owner’s permission, and these platforms have different methods for how that permission can be obtained. Please check the available settings on your device or check with your provider.
          <br /> Users may opt in/opt out from these communications, marketing notifications, Live location sharing, updates and news received from the eSanchari.com by checking their device settings at any time.
          
          <br /> <b>Your Consent</b>          
          By visiting our App or Website, you agree to provide your information, you consent to the collection, use, storage, disclosure, and otherwise processing of your information (including sensitive personal information) on our App or Website in accordance with this Privacy Policy. If you disclose to us any personal information relating to other people, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.
          

          <br /> <b>CHANGES TO THIS PRIVACY POLICY </b>
          <br /> WE MAY OCCASIONALLY UPDATE THIS PRIVACY POLICY.
          <br /> eSanchari.com have the discretion to update this privacy policy at any time. When we do, we will send you an email. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
          <br /> Use of our services after an update constitutes consent to the updated Privacy Policy to the extent permitted by law.

          <br /> <b>HOW YOU CAN REACH US </b>
          <br /> f you have any questions about this Privacy Policy, the practices of this App, your dealings with this eSanchari.com and you would like to ask about or complain about how we process your personal information, you may contact us by writing to us or call us using contact information provided on the Contact Us page on the portal www.eSanchari.com.
          <br /> eSanchari.com 
          <br /> A digital property of DIGIVERTI INFOTECH PVT LTD.

        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
