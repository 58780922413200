import React, { FC,useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WidgetTags from "../BlogPage/WidgetTags";
import WidgetCategories from "../BlogPage/WidgetCategories";
import WidgetPosts from "../BlogPage/WidgetPosts";
import Card3 from "../BlogPage/Card3";
import { useSearchParams } from 'react-router-dom';
import { GeneralPackage, PriceRates, ProductDataType, ProductDetailsDataType } from "../../data/types";
import PackageCard from "./PackageCard";

// THIS IS DEMO FOR MAIN DEMO
// OTHER DEMO WILL PASS PROPS
const postsDemo: PostDataType[] = DEMO_POSTS.filter((_, i) => i > 7 && i < 14);
//
export interface SectionLatestPostsProps {
  posts?: PostDataType[];
  className?: string;
  postCardName?: "card3";
  Packages:GeneralPackage[];
  onRateSelected:any;
  dateValue:moment.Moment | null;
  setdateValue: any;
  productType:any;
  product:ProductDataType| undefined;
  selectedAnchor:any;
  packageAmount: number | undefined;
  productDetails: ProductDetailsDataType | undefined;
}

const SectionPackages: FC<SectionLatestPostsProps> = ({
  posts = postsDemo,
  postCardName = "card3",
  className = "",
  Packages,
  dateValue,
  onRateSelected,
  setdateValue,
  productType,
  product,
  selectedAnchor,
  productDetails
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [packages, setPackages] = useState<GeneralPackage>();

  const renderCard = (pack: GeneralPackage) => {
    return <PackageCard setdateValue={setdateValue} 
    dateValue={dateValue} key={pack.id} className="" 
    post={pack}  productType= {productType} onRateSelected={onRateSelected} product={product}
    selectedAnchor={selectedAnchor} productDetails={productDetails}/>;
  };

  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full">
          <div className={`grid gap-6 md:gap-8`}>
            {Packages.map((post) => renderCard(post))}
          </div>
          
          {/* <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SectionPackages;
