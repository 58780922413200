import React, { FC, useEffect, useState } from "react";
import StartRating from "components/StartRating/StartRating";
import moment from "moment";
import axios from 'axios';
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import constants from "common/constants";
import SessionManager from "common/SessionManager";
import { useSearchParams } from "react-router-dom";
import { ProductDataType, ProductDetailsDataType } from "data/types";
import { MediaAccess, PriceRates } from './../../data/types';
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import SectionPackages from "./SectionPackages";
import ExperiencesDateSingleInput from 'components/HeroSearchForm/ExperiencesDateSingleInput';
import ProductReviews from "./ProductReviews";

export interface ProductDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}
const ProductDetailPage: FC<ProductDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [trmstext, settrmstext] = useState("");
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [reviews, setReviews] = useState<any>();
  const [isloggedin, setisloggedin] = useState(SessionManager.getUser());
  const [media, setMedia] = useState<string[]>([]);
  const [videoMedia, setVideoMedia] = useState<string[]>([]);
  const [selectedRate, setselectedRate] = useState<PriceRates>();
  const [isValidBookTime, setIsValidBookTime] = useState<boolean>(true);
  const [selectedAnchor, setSelectedAnchor] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const moment = require('moment');
  const [dateValue, setdateValue] = useState<moment.Moment | null>(moment());
  const [packageAmount, setpackageAmount] = useState<number>()
  const [searchParams, setSearchParams] = useSearchParams();
  const [product, setProduct] = useState<ProductDataType>();
  const [productDetails, setProductDetails] = useState<ProductDetailsDataType>();
  const [editable, setEditable] = useState(false);
  const [avgrating, setAvgrating] = useState<number[]>();
  const [isSavedList, setisSavedList] = useState<boolean>(false);
  const sendReviewsToParent = (index: any) => {
    setReviews(index);
    let d: number[] = [];
    index?.forEach((ele: any) => {
      d.push(ele.rating);
      setAvgrating(d);
    })
  };
  
  useEffect(() => {
    SessionManager.getJWTToken((jwtToken: string) => {
      let productIdFromParams = searchParams.get("productId");
      if (productIdFromParams != null) {
        viewProduct(productIdFromParams, jwtToken);
        getTermsConditionsData();
        
      }
      if (isloggedin != null) {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwtToken,
          },
        };
        const url = constants.API_CONTEXT_URL + '/account/listUserPreferences';
        fetch(url, requestOptions)
          .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            if (data?.response?.some((x: any) => x.assetId == productIdFromParams)) {
              setisSavedList(true);
            }

            // check for error response
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
          })
          .catch((error) => {
            console.log("Error while getting user pref: " + error);
          });
      }
    })
  }, [searchParams.get("productId")]);


  async function viewProduct(productId: string, jwtToken: string) {
    // POST request using fetch with error handling
    const requestOptions = {
      method: 'GET',

      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken,
      },
    };
    await fetch(constants.API_CONTEXT_URL + '/product/seller/viewProduct?productId=' + productId, requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setProduct(data.response)
        setProductDetails(data.response)
        let picArray: string[] = []
        let videosArray: string[] = []
        data.response?.mediaAccessList?.sort((a: any, b: any) => a.seqNo - b.seqNo).forEach(function (value: MediaAccess) {
          if (value.assetType == "PRODUCT_IMAGES" && value.url != "") {
            picArray.push(value.url);
          }
          if (value.assetType == "PRODUCT_VIDEOS" && value.url != "") {
            videosArray.push(youtube_parser(value.url));
          }
        });
        setMedia(picArray);
        setVideoMedia(videosArray.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        }));
        if (data.response.privileges['PROJECT_EDIT']) {
          setEditable(true);
        }
        else {
          setEditable(false);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function youtube_parser(url: any) {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      //error
    }
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  function locateUs(latitude:any, longitude:any){
    window.open('http://maps.google.com/maps?q=' + latitude + "," + longitude, '_system');
  }

  const handleCloseModal = () => setIsOpen(false);

  const info = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={product?.type} />
          {product != undefined ?
            <LikeSaveBtns isLiked={isSavedList} productId={product?.id} />
            : null}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {product?.title}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {productDetails?.businessDetails?.latitude != null && productDetails?.businessDetails?.latitude != undefined ?
            <div className="flex" onClick={() => locateUs(productDetails?.businessDetails?.latitude!, productDetails?.businessDetails?.longitude!)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer text-primary-6000">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
              </svg>
              <span className="ml-1 text-primary-6000 cursor-pointer"> {productDetails?.businessDetails?.firstLine} {productDetails?.businessDetails?.secondLine} {productDetails?.businessDetails?.cityCode} {productDetails?.businessDetails?.stateCode} {productDetails?.businessDetails?.countryCode} - {productDetails?.businessDetails?.pinCode}</span>
            </div>
            :
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
              </svg>
              <span className="ml-1"> {productDetails?.businessDetails?.firstLine} {productDetails?.businessDetails?.secondLine} {productDetails?.businessDetails?.cityCode} {productDetails?.businessDetails?.stateCode} {productDetails?.businessDetails?.countryCode} - {productDetails?.businessDetails?.pinCode}</span>
            </span>
          }
        
        </div>

        <StartRating point={avgrating!?.reduce((sum, curr) => sum + Number(curr), 0) / avgrating!?.length} reviewCount={reviews?.length} />
      </div>
    );
  };
  const loginSocials = [
    {
      href: productDetails?.businessDetails?.facebookUrl,
      icon: facebookSvg,
    },
    {
      href: productDetails?.businessDetails?.twitterUrl,
      icon: twitterSvg,
    }
  ];

  const description = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Product information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <div className="p-3" dangerouslySetInnerHTML={{ __html: (product == undefined ? "" : product?.description == undefined ? "" : product.description) }} />
          {/* <span>
           {product?.description?.split('\n').map( (it, i) => {
                   return <> 
                   <span key={'x'+i}>{it} dangerouslySetInnerHTML={{__html:('x'+i}>{it})}
                    </span> 
                    <br/>
                    </>
                  }) }
          </span>*/}
        </div>
      </div>
    );
  };

  const Packages = () => {

    return (
      <div>
        <div className="px-7 mb-2 mx-2">
          <h2 className="text-2xl font-semibold">Packages </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the product's Packages and details
          </span>
          <div className="lg:hidden flex-grow mt-1">
            <ExperiencesDateSingleInput
              defaultValue={dateValue}
              onChange={(cdt) => setdateValue(cdt)}
              className="flex-1" />
          </div>
        </div>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}
        <SectionPackages setdateValue={setdateValue} 
          dateValue={dateValue}
          Packages={productDetails?.packages.filter((items: any) => items?.visible == true && items?.stage == 'Active') || []} productType={product?.type}
          onRateSelected={onRateSelected} product={product}
          selectedAnchor={selectedAnchor} packageAmount={packageAmount}
          productDetails={productDetails}/>
      </div>
    );
  };

  const getTermsConditionsData = () => {
    axios
      .get('https://sanchari-dev-resources.s3.ap-south-1.amazonaws.com/TermsAndConditions/TermsandConditions.html')
      .then((response) => {
        settrmstext(response.data)
      })
  };

const onRateSelected = (rates:PriceRates) => {
  setselectedRate(rates)
}

  return (
    <div
      className={`ListingDetailPage nc-ProductDetailPage ${className}`}
      data-nc-id="ProductDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0 sm:aspect-w-6 sm:aspect-h-5"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={productDetails?.mediaAccessList.sort((a: any, b: any) => a.seqNo - b.seqNo).filter((x: any) => x.assetType == "PRODUCT_IMAGES")[0]?.url}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {productDetails?.mediaAccessList.filter((x: any) => x.assetType == "PRODUCT_IMAGES").filter((_, i) => i >= 1 && i < 5).sort((a: any, b: any) => a.seqNo - b.seqNo).map((item, index: number) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= (4 - videoMedia?.length) ? "hidden" : ""
                  }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item.url || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}
            {videoMedia!.map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5 ${index >= 3 ? "hidden sm:block" : ""
                  }`}
              >
                <iframe
                  src={`https://www.youtube.com/embed/${item}?autoplay=0`}
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ))}
            {media?.length > 0 || videoMedia?.length > 0 ?
              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 top-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10">
                {/* <span className="ml-2 text-neutral-800 text-sm font-medium ">
                Default photo
              </span> */}
              </div>
              : null}
            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={media}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ProductDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className={product?.type?.includes("PlacesToVisit") ? "w-full" : "w-full space-y-8 lg:space-y-10 lg:pr-10"}>
          {info()}
          {product?.type?.includes("PlacesToVisit") ? "" : Packages()}
          {description()}
          {/* {roomrates()} */}
          {/* {hostinfo()} */}
          {<ProductReviews sendReviewsToParent={sendReviewsToParent} />}
          {/* {location()} */}
          {/* moved t&c beside price chart {thingstoknow()} */}
        </div>

        {/* SIDEBAR */}
        {/* <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{product?.type?.includes("PlacesToVisit") ? "" : renderSidebar()}</div>
        </div> */}
      </main>
      <br />

      {/* STICKY FOOTER MOBILE */}
      {/* {product?.type?.includes("PlacesToVisit") ? "" : !isPreviewMode && <MobileFooterProduct createOrder={addToCart} isValidBookTime={isValidBookTime} selectedPackageId={selectedPackageId} addToCart={addToCart} amount={amount}
        dateValue={dateValue} setdateValue={setdateValue} label={(product?.type?.includes("Yacht") || product?.type?.includes("SpeedBoat")) ? 'hr(s)' :
          product?.type?.includes("Wheel") || product?.type?.includes("Vehicle")
            || product?.type?.includes("Cycle") || product?.type?.includes("Car") ? 'day(s)' : 'person(s)'}
        onPersonsChanges={setpersonCount}
        setGuestInput={setGuestInput} />} */}
    </div>

  );
};

export default ProductDetailPage;
