import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useSearchParams } from "react-router-dom";
import { Auth } from 'aws-amplify';
import Otpinput from "common/OTPInput";
import SessionManager from "common/SessionManager";
import constants from "common/constants";

export interface ResetPasswordProps {
  className?: string;
}


const ResetPassword: FC<ResetPasswordProps> = ({ className = "" }) => {
  const [errormessage, seterrormessage] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    
    
    let mobile = "+" + searchParams.get("mobile")?.trim();
    (document.getElementById("register_mobile") as HTMLInputElement).value = mobile;

  }, []);
  
  function onInputComplete(val:any) {
    setCode(val)
 
  }

  function handleChangePassword() {
    var register_password = (document.getElementById("register_password") as HTMLInputElement).value;
    
    return register_password;
  }
  function handleChangePhone() {
   //this.phone_number = e
   var register_mobile = (document.getElementById("register_mobile") as HTMLInputElement).value;
    if (register_mobile != "" ) { //&& register_mobile.match('^([+]\d{2})?\d{10}$') 
      return register_mobile;
    }
    else return ''

    
   }
  
   function handleChangeRepeatPassword() {
    var register_repeat_password = (document.getElementById("register_repeat_password") as HTMLInputElement).value;
    if (register_repeat_password != "") {
      
    }
    return register_repeat_password;
  }

  function handleSubmit() {
   
    seterrormessage("");
    var register_password = (document.getElementById("register_password") as HTMLInputElement).value;
    let mobile = "+" + searchParams.get("mobile")?.trim();
    try{
      Auth.forgotPasswordSubmit(mobile, code, register_password)
      .then(data => {
        console.log(data);
        var CryptoJS = require("crypto-js");
        var pass = CryptoJS.AES.encrypt(register_password, mobile);
        sessionStorage.setItem("inCode", pass);
        window.location.assign("#/login?notifyPasswordChanged=true&mobile=" + encodeURI(mobile));
       
      })
      .catch(err => {
        seterrormessage(err.message);
      });
    }
    catch(err){
      console.log(err);
    }
    
  }

  function resendConfirmtnCode() {
		try {
      let mobile = "+" + searchParams.get("mobile")?.trim();
			Auth.resendSignUp(mobile);
			alert("Otp sent successfully!!");
		} catch (err) {
			console.log('error resending code: ', err);
		}
	}

  
  return (
    <div className={`nc-ResetPassword  ${className}`} data-nc-id="ResetPassword">
      <Helmet>
        <title>Sign up || eSanchari</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Reset Password
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          
          {/* FORM */}
          <div className="grid grid-cols-1 gap-6" >
            

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Mobile Number
              </span>
              <Input
                type="text"
                id="register_mobile"
                placeholder="+919999999999"
                className="mt-1"
                disabled
                value={searchParams.get("mobile") ? '+' + searchParams.get("mobile") : ''}
              />
            </label>
            
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input id="register_password" type="password" 
              onChange={() => handleChangePassword}
              className="mt-1" />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input id="register_password_repeat" type="password" 
              onChange={() => handleChangePassword}
              className="mt-1" />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                OTP
              </span>
              <br></br>
              <Otpinput
                        onInputComplete={onInputComplete}
                      />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-red-800 ">
                {errormessage}
              </span>
              
            </label>
            <ButtonPrimary onClick={()=> handleSubmit()}>Reset Password</ButtonPrimary>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="#/login">Sign in</Link>
          </span>

           {/* ==== */}
           <span className="block text-center text-neutral-700 dark:text-neutral-300" onClick={()=> resendConfirmtnCode()}>
            Request a code Again {` `}
            
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
