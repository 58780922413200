import React, { FC, Fragment, useEffect, useState } from "react";
import axios from 'axios';
import constants from 'common/constants';
import SessionManager from 'common/SessionManager';
import {CartOrders, Order} from '../../data/types';
import  ButtonPrimary  from 'shared/Button/ButtonPrimary';
import RazorPay from "common/RazorPay";
import BookingDetailsTable from "./BookingDetailsTable";
import ButtonSecondary from 'shared/Button/ButtonSecondary';

export interface MyBookingsProps {
  className?: string;
}

const MyBookings: FC<MyBookingsProps> = ({ className = "" }) => {
  const [orders, setOrders] = useState<Order[]>();

  const goback =() =>{
    window.history.back();
  }

  useEffect(() => {
    let packagejson = {
      userId: SessionManager.getUserId(),
      removeStages: ["New","Deleted","InActive","PaymentInitiate","Unchanged"]
    }
    const url = constants.API_CONTEXT_URL + '/order/listOrders';
    axios.post(url, packagejson)
      .then((response) => {
        setOrders(response.data?.response);
      })
      .catch((error) => {
      });
  }, []);
  
  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-4 px-0 sm:p-6 xl:p-8">
        <div className="flex justify-between">
          <h2 className="text-3xl lg:text-4xl font-semibold">
            My Bookings
          </h2>
          <ButtonPrimary className="align-center" onClick={goback}>Go back</ButtonPrimary>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div className="border border-neutral-200 dark:border-neutral-700 rounded-3xl flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
          {orders && orders?.length! > 0 ?
            orders?.map((item) => (
              <BookingDetailsTable key={item.id} className="pb-15 lg:pb-15" data={item}/>
            ))
            : null
          }
           </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="w-full lg:pr-10 ">{renderMain()}</div>
      </main>
    </div>
  );
};

export default MyBookings;
