import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import kishor from "images/avatars/kishor.jpg";

export interface RelatedArticles {
  id: string;
  href: string;
  featuredImage: string;
  title: string;
  author: string;
  date: string;
}

const BlogPost2 = () => {

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            The travel industry in India
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
          The travel industry in India is a significant and rapidly growing sector of the economy, influenced by various factors including domestic tourism, international tourism, cultural heritage, and adventure tourism. Here’s an overview of the industry.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11"
                imgUrl={kishor}
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    Kishor Kumar
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    July 29, 2024
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    8 min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <h3>Segments of the Travel Industry</h3>
        <ul>
          <li><strong>Domestic Tourism:</strong> India has a vast domestic tourist base, with millions traveling for leisure, pilgrimage, business, and adventure. Popular destinations include historical cities, hill stations, and spiritual sites.</li>
          <li><strong>Inbound Tourism:</strong> India attracts foreign tourists interested in its rich culture, history, and natural diversity. Key attractions include the Taj Mahal, Jaipur's palaces, Kerala's backwaters, and the northern mountain ranges.</li>
          <li><strong>Outbound Tourism:</strong> Increasingly more Indians are traveling abroad for leisure, business, and educational purposes. Popular destinations include Southeast Asia, Europe, and the Middle East.</li>
        </ul>
        <figure>
          <img
            src="https://images.pexels.com/photos/4226100/pexels-photo-4226100.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            alt="nc blog"
            className="rounded-2xl"
          />
          <figcaption>
          {/* Overall, taking a holiday can greatly enhance quality of life, and it’s often essential for maintaining balance in a busy world. */}
          </figcaption>
        </figure>
        <h3>Types of Travel</h3>
        <ul>
          <li><strong>Ecotourism:</strong> India has many national parks and wildlife sanctuaries, attracting nature lovers and ecotourists.</li>
          <li><strong>Adventure Tourism:</strong> Activities like trekking in the Himalayas, river rafting, and desert safaris are popular among adventure enthusiasts.</li>
          <li><strong>Cultural and Heritage Tourism:</strong> Historical sites, festivals, and local traditions draw many tourists.</li>
          <li><strong>Wellness and Medical Tourism:</strong> India is a hub for alternative medicine practices like Ayurveda, and it also offers quality healthcare services at affordable prices.</li>
        </ul>
        <h3>Government Initiatives</h3>
        <p>
        The Indian government has initiated various programs to boost the travel industry, including:
        </p>
        <ul>
          <li><strong>Incredible India Campaign:</strong> Aimed at promoting India as a tourist destination.</li>
          <li><strong>E-Visa Services:</strong> Facilitating easier entry for foreign tourists.</li>
          <li><strong>Swadesh Darshan Scheme:</strong> Focused on developing theme-based tourist circuits.</li>
          <li><strong>Atmanirbhar Bharat:</strong> Promoting local tourism and self-sufficiency in the hospitality sector.</li>
        </ul>
        <h3>Technological Integration</h3>
        <p>
        The industry is increasingly adopting technology, with online booking platforms, travel apps, and digital payments becoming the norm. Technology is also being leveraged for virtual tours and experiences.
        </p>
        <figure>
          <img
            src="https://images.pexels.com/photos/3155666/pexels-photo-3155666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            alt="nc blog"
            className="rounded-2xl"
          />
          <figcaption>
          Overall, the travel industry in India is poised for growth, supported by a diverse array of attractions and a strong push from both the government and private sectors.
          </figcaption>
        </figure>
        <h3>Future Trends</h3>
        <ul>
          <li><strong>Sustainable Tourism:</strong> Growing awareness about environmental issues is pushing for more sustainable practices in tourism.</li>
          <li><strong>Personalization:</strong> Increased demand for tailored travel experiences is prompting travel agencies and hotels to offer personalized services.</li>
          <li><strong>Health and Safety Practices:</strong> Post-pandemic, travellers are prioritizing hygiene and safety, leading to enhanced health protocols in the hospitality industry.</li>
        </ul>
      </div>
    );
  };

  const relatedposts: RelatedArticles[] = [
    {
      id: "1",
      href: "/blogpost1",
      featuredImage: "https://images.unsplash.com/photo-1501493870936-9c2e41625521?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1045&amp;q=80",
      title: "Why one should consider taking a holiday?",
      author: "Kishor Kumar",
      date: "July 22, 2024"
    },
    {
      id: "2",
      href: "/blogpost3",
      featuredImage: "https://images.pexels.com/photos/8241135/pexels-photo-8241135.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
      title: "Holiday spendings by an Indian",
      author: "Kishor Kumar",
      date: "Aug 12, 2024"
    }
  ]

  const renderPostRelated = (post: RelatedArticles) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Travel" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Single Blog || eSanchari</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://images.pexels.com/photos/2507007/pexels-photo-2507007.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
      </div>
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-8 lg:py-14 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {/*  */}
            {relatedposts.map(renderPostRelated)}
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost2;
