import { Auth } from "aws-amplify";
import Label from "components/Label/Label";
import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import SessionManager from "common/SessionManager";
import constants from "common/constants";

const AccountPass = () => {
  const [errormessage, setErrorMessage] = useState('')

  const updatePassword = () => {
    setErrorMessage("")
    var old_password = (document.getElementById("old_password") as HTMLInputElement).value;
    var new_password = (document.getElementById("new_password") as HTMLInputElement).value;
    var new_repeat_password = (document.getElementById("new_repeat_password") as HTMLInputElement).value;

    if (new_password != new_repeat_password) {
      setErrorMessage("The new passwords dont match.")
      return;
    }
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, old_password, new_password)
          .then(data => {
            notifyPasswordChanged();
            alert("Password changed successfully.");
          })
          .catch(err => {
            setErrorMessage(err.message)
          });
      })
      .then(data => {
        console.log(data)
      })
      .catch(err => {
        setErrorMessage(err)
      });
  }

  const notifyPasswordChanged = () => {
    SessionManager.getJWTToken(async (jwtToken: any) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwtToken,
        },
      };
      const url = constants.API_CONTEXT_URL + '/account/passwordUpdatedNotify';
      fetch(url, requestOptions)
        .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
        })
        .catch((error) => {
          alert("Error sending email " + error);
        });
    })
  }

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Current password</Label>
              <Input type="password" id="old_password" className="mt-1.5" />
            </div>
            <div>
              <Label>New password</Label>
              <Input type="password" id="new_password" className="mt-1.5" />
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input type="password"  id="new_repeat_password" className="mt-1.5" />
            </div>
            <label className="block">
              <span className="flex justify-between items-center text-red-800 ">
                {errormessage}
              </span>
              
            </label>
            <div className="pt-2">
              <ButtonPrimary onClick={() => updatePassword()}>Update password</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
