import React, { Component } from 'react'
// import QrReader from 'react-qr-scanner'
import QrReader from 'modern-react-qr-reader'

class QrcodeScannerCordova extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 100,
      result: 'No result',
    }

    console.log(window.QRScanner)
  window.QRScanner.prepare(onDone);

  function onDone(err, status){
      if (err) {
          // here we can handle errors and clean up any loose ends.
          console.error(err);
      }
      if (status.authorized) {
          // W00t, you have camera access and the scanner is initialized.
    
          // document.getElementsByTagName("HTML")[0].setAttribute('style','opacity: 0');
          window.QRScanner.show(function(status){
              
              var x = document.getElementById("cordova-plugin-qrscanner-video-preview")
              if(x != null) {
                x.style.zIndex = 100;
                
              }
              window.QRScanner.scan(displayContents);
          });
      } else if (status.denied) {
          // The video preview will remain black, and scanning is disabled. We can
          // try to ask the user to change their mind, but we'll have to send them
          // to their device settings with `QRScanner.openSettings()`.
      } else {
          // we didn't get permission, but we didn't get permanently denied. (On
          // Android, a denial isn't permanent unless the user checks the "Don't
          // ask again" box.) We can ask again at the next relevant opportunity.
      }
  }

  function displayContents(err, text){
      window.QRScanner.hide(function(status){
        
      });
      if(err){
        alert("Error scanning :" + err);
          // an error occurred, or the scan was canceled (error code `6`)
      } else {
          // The scan completed, display the contents of the QR code:
          var data = "";
          if(text.result != null) {
            data = text.result;
          }
          else {
            data = text;
          }
          //alert(data);
          handleScan(data);
      }
  }

  function handleScan(data){
    if(data != null){
      props.handleScanResult(data);
    }
      
  }
    
  }
  
  
  handleError(err){
    console.error(err)
  }
  render(){
    const previewStyle = {
      height: 350,
      width: 350,
    }

    return(
      <div>
        <div className='flex justify-center'>
        
          </div>
        
      </div>
    )
  }
}

export default QrcodeScannerCordova;