import axios from 'axios';

import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PaymentPolicy: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PaymentPolicy overflow-hidden relative ${className}`}
      data-nc-id="PaymentPolicy"
    >
      <Helmet>
        <title>Payment Policy || eSanchari</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-10 lg:py-28 space-y-16 lg:space-y-5">
        <SectionHero
          rightImg={rightImg}
          heading="Payment Policy"
          btnText=""
          subHeading=""
        />
        <div className='mx-3 mb-4 aboutushead p-3' >

          <br /> <b>Terminology: </b>
          <p className='px-4'>
            ●	<b>Portal admin / facilitator / marketplace :</b> This user is the portal administrator at https://esanchari.com. Ownership of this user lies with the primary company <b>M/s. Digiverti Infotech Private Limited.</b>
            <br />●	<b>Vendor / Service Provider / Business Owner :</b> User who sells their product or services on the portal https://esanchari.com
            <br />●	<b>Customer :</b> User who buys product or services from the portal https://esanchari.com
            <br />
          </p>
          <br /> <b>How do I pay for the purchase of a product / service  that I buy at https://esanchari.com (portal)?</b>
          <br />
          <p className='px-4'>
            ●	We will have multiple payment methods through reputed and highly secured payment gateways. Whatever your online mode of payment, you can rest assured that our company has trusted payment gateway partners that use secure encryption technology to keep your transaction details confidential at all times.
            ●	We are accepting payments made using Visa, MasterCard, Maestro and American Express credit/debit cards, UPI Payments, Net Banking, Wallets, in India.
          </p>

          <br />
          <b>Are there any hidden charges when I make a purchase? </b>
          <br />
          <p className='px-4'>
            ●	There are NO hidden charges when you make a purchase on our company site. The prices listed for all the items are final and all-inclusive. The price you see on the product page is exactly what you pay.
          </p>

          <br />
          <b>What are the delivery charges? </b>
          <br />
          <p className='px-4'>
            ●	Delivery charges vary depending on the goods, their size & weight. Please check individual items for the shipping charges on the portal. There will not be delivery charges in case of e-goods.
          </p>

          <br />

          <br /><b>How do I pay using a credit/debit card?</b>
          <br />
          <p className='px-4'>
            ●	<b>Credit cards:</b> We will be accepting payments made using Visa, MasterCard and American Express credit cards. To pay using your credit card at checkout, you will need your card number, expiry date, three-digit CVV number (found on the backside of your card). After entering these details, you will be redirected to the bank's page for entering the online 3D Secure password.
            <br /> ●	<b>Debit cards: </b> We will be accepting payments made using Visa, MasterCard and Maestro debit cards. To pay using your debit card at checkout, you will need your card number, expiry date (optional for Maestro cards), three-digit CVV number (optional for Maestro cards). You will then be redirected to your bank's secure page for entering your online password (issued by your bank) to complete the payment.
          </p>

          <br />
          <br />
          <b>Is it safe to use my credit/debit card?  </b>
          <br />
          Your online transaction on our company site is secure with the highest levels of transaction security currently available on the Internet. Our company uses 256-bit encryption technology to protect your card information while securely transmitting it to the respective banks for payment processing.
          <br />
          <br /> <b>What steps does our company take to prevent card fraud?</b>
          <br />
          Our company realizes the importance of a strong fraud detection and resolution capability. We and our online payments partners monitor transactions continuously for suspicious activity and flag potentially fraudulent transactions for manual verification by our team.
          In the rarest of rare cases, when our team is unable to rule out the possibility of fraud categorically, the transaction is kept on hold, and the customer is requested to provide identity documents. The ID documents help us ensure that the purchases were indeed made by a genuine card holder. We apologise for any inconvenience that may be caused to customers and request them to bear with us in the larger interest of ensuring a safe and secure environment for online transactions.
          <br />
          <br /> <b>What is a 3D Secure password?</b>
          <br />
          The 3D Secure password is implemented by VISA and MasterCard in partnership with card issuing banks under the "Verified by VISA" and "Mastercard Secure Code" services, respectively.
          <br />
          <br />
          The 3D Secure password adds an additional layer of security through identity verification for your online credit/debit card transactions. This password, which is created by you, is known only to you. This ensures that only you can use your card for online purchases.
          <br />
          <br /> <b>How can I get the 3D Secure password for my credit/debit card?</b>
          <br />
          You can register for the 3D Secure password for your credit/debit card by visiting your bank's website.

          <br />

          <br /> <b>Can I use my bank's Internet Banking feature to make a payment?</b>
          <br />
          Yes. Our company offers you the convenience of using your bank's Internet Banking service to make a payment towards your order using the payment gateway. With this you can directly transfer funds from your bank account, while conducting a highly secure transaction.

          <br />

          <br /> <b>Can I make a credit/debit card or Internet Banking payment through my mobile?</b>
          <br />
          Yes, you can make credit card payments through our company mobile site and application. Our Company uses 256-bit encryption technology to protect your card information while securely transmitting it to the secure and trusted payment gateways managed by leading banks.

          <br />
        </div>
      </div>
    </div>
  );
};

export default PaymentPolicy;
