import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import { useEffect } from "react";
import clientSayMain from "images/clientSayMain.png";
import clientSay1 from "images/clientSay1.png";
import clientSay2 from "images/clientSay2.png";
import clientSay3 from "images/clientSay3.png";
import clientSay4 from "images/clientSay4.png";
import clientSay5 from "images/clientSay5.png";
import clientSay6 from "images/clientSay6.png";
import quotationImg from "images/quotation.png";
import quotationImg2 from "images/quotation2.png";
import useNcId from "hooks/useNcId";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

export interface SectionAboutTeamProps {
  className?: string;
  uniqueClassName: string;
}

const DEMO_DATA = [
  {
    id: 1,
    clientName: "May 2022",
    clientAddress: "India",
    content:
      "Ideation : eSanchari's inception is rooted in Anil's extensive experience in IT and entrepreneurship, combined with his passion for travel and technology. Anil Kumar, along with his friends Kishor Kumar and Shridhar Deshpande, has co-founded eSanchari.com with the aim of creating a platform for selling entry tickets to various events, catering to both domestic and international travellers looking for fun activities during their holidays. Realized the need for a unified platform that could offer a consistent and high-quality ticket booking experience. This initiative aims to centralize ticket sales for a wide range of events, from small local activities to larger events, making it convenient for travellers to plan and participate in different experiences while on vacation.",
  },
  {
    id: 2,
    clientName: "Jun 22, 2022",
    clientAddress: "India",
    content:
      "Incepted the company - Digiverti Infotech Private Limited and the brand eSanchari evolved.",
  },
  {
    id: 3,
    clientName: "Oct 28, 2022",
    clientAddress: "India",
    content:
      "First office branch opened in Goa, reason being vast opportunities to study various kinds of ticketing service providers. Partner’s acquisition started with Goa and extended to other states like Gujarat, Rajasthan, Karnataka, Kashmir, Maharashtra, UP, and more. With 200+ Partners and more than 5000+ activities.",
  },
  {
    id: 4,
    clientName: "June 2024",
    clientAddress: "India",
    content:
      "Go to Market! With amazing response, we are now looking at PAN India market with the vision of going international by 2026.",
  }
];

const SectionAboutTeam: FC<SectionAboutTeamProps> = ({
  className = "",
  uniqueClassName = "",
}) => {
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useNcId();

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      setTimeout(() => {
        new Glide(`.${UNIQUE_CLASS}`, {
          perView: 1,
        }).mount();
      }, 10);
    }
  }, []);

  const renderBg = () => {
    return (
      <div className="hidden md:block">
        <img className="absolute top-9 -left-20" src={clientSay1} alt="" />
        <img
          className="absolute bottom-[100px] right-full mr-40"
          src={clientSay2}
          alt=""
        />
        <img
          className="absolute top-full left-[140px]"
          src={clientSay3}
          alt=""
        />
        <img
          className="absolute -bottom-10 right-[140px]"
          src={clientSay4}
          alt=""
        />
        <img
          className="absolute left-full ml-32 bottom-[80px]"
          src={clientSay5}
          alt=""
        />
        <img className="absolute -right-10 top-10 " src={clientSay6} alt="" />
      </div>
    );
  };

  return (
    <div
      className={`nc-SectionClientSay relative ${className} `}
      data-nc-id="SectionClientSay"
    >
      <Heading desc="Here is an ideation journey to develop and enhance eSanchari" isCenter>
        Our Journey
      </Heading>
      <div className="relative px-10 mx-auto">
        <ScrollAnimation animateIn='fadeIn' animateOut="fadeOut">
          <div className="relative pb-10 p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <img
              className="opacity-50 md:opacity-100 absolute lg:mr-3 right-full top-1"
              src={quotationImg}
              alt=""
            />
            <img
              className="opacity-50 md:opacity-100 absolute lg:ml-3 left-full top-1"
              src={quotationImg2}
              alt=""
            />
            <div className="glide__track" data-glide-el="track">
              <span className="block text-center text-xl">
              <b>May 2022: </b>Ideation - eSanchari's inception is rooted in Anil's extensive experience in IT and entrepreneurship, combined with his passion for travel and technology. Anil Kumar, along with his friends Kishor Kumar and Shridhar Deshpande, has co-founded eSanchari.com with the aim of creating a platform for selling entry tickets to various events, catering to both domestic and international travellers looking for fun activities during their holidays. Realized the need for a unified platform that could offer a consistent and high-quality ticket booking experience. This initiative aims to centralize ticket sales for a wide range of events, from small local activities to larger events, making it convenient for travellers to plan and participate in different experiences while on vacation.
              </span>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeIn' animateOut="fadeOut">
          <div className="relative pb-10 p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <img
              className="opacity-50 md:opacity-100 absolute lg:mr-3 right-full top-1"
              src={quotationImg}
              alt=""
            />
            <img
              className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1"
              src={quotationImg2}
              alt=""
            />
            <div className="glide__track pt-3" data-glide-el="track">
              <span className="block text-xl text-center">
                <b>22 June 2022: </b>Incepted the company - Digiverti Infotech Private Limited and the brand eSanchari evolved.
              </span>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeIn' animateOut="fadeOut">
          <div className="relative pb-10 p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <img
              className="opacity-50 md:opacity-100 absolute lg:mr-3 right-full top-1"
              src={quotationImg}
              alt=""
            />
            <img
              className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1"
              src={quotationImg2}
              alt=""
            />
            <div className="glide__track pt-3" data-glide-el="track">
              <span className="block text-xl text-center">
                <b>28 Oct 2022: </b>First office branch opened in Goa, reason being vast opportunities to study various kinds of ticketing service providers. Partner’s acquisition started with Goa and extended to other states like Gujarat, Rajasthan, Karnataka, Kashmir, Maharashtra, UP, and more. With 200+ Partners and more than 5000+ activities.
              </span>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeIn' animateOut="fadeOut">
          <div className="relative pb-10 p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <img
              className="opacity-50 md:opacity-100 absolute lg:mr-3 right-full top-1"
              src={quotationImg}
              alt=""
            />
            <img
              className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1"
              src={quotationImg2}
              alt=""
            />
            <div className="glide__track pt-3" data-glide-el="track">
              <span className="block text-xl text-center">
                <b>June 2024: </b>Go to Market! With amazing response, we are now looking at PAN India market with the vision of going international by 2026.
              </span>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default SectionAboutTeam;
