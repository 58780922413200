import { Tab } from "@headlessui/react";
import React, { FC, ReactNode, Fragment, useState, useEffect } from "react";
import CarCard from "components/CarCard/CarCard";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StayCard from "components/StayCard/StayCard";
import { fetchAll, fetchThingsToDo, fetchRides, fetchYachts, fetchMuseum, fetchZoo, fetchWaterSport, fetchEvent, fetchCasino,fetchproductTypes,fetchRidesType,fetchYachtsTypes, fetchPlacesToVisit } from "data/listings";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "../AccountPage/CommonLayout";
import HeaderFilter from "../PageHome/HeaderFilter";
import { StayDataType } from 'data/types';
import ProductListing from 'components/StayCard/ProductListing';
import { useSearchParams } from "react-router-dom";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { appendFile } from "fs";
import SessionManager from "common/SessionManager";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export interface AllListingsHomeProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const AllListingsHome: FC<AllListingsHomeProps> = ({
  gridClass = "",
  heading = "All Featured things to do",
  subHeading = "All things to do that eSanchari recommends for you",
  headingIsCenter,
  tabs = ["All", "Ticketing", "Rides", "Yachts", "Places To Visit"]
}) => {

  const [listings, setListings] = React.useState([]);
  const [productTypes, setProductTypes] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabActive, settabActive] = useState<string>("");  
  const [subHeadings, setsubHeadings] = useState<string>("All things to do that eSanchari recommends for you");  
  const [slectedTicketingTypes, setslectedTicketingTypes] = React.useState<any[]>([]);
  const [slectedVehicleTypes, setSlectedVehicleTypes] = React.useState<any[]>([]);
  const [slectedYachtTypes, setSlectedYachtTypes] = React.useState<any[]>([]);

  const[nextval, setNextVal] = React.useState(1);
  const[hasmore, setHasmore] = React.useState(true);
  const [priceSort, setPriceSort1] = useState<string>("Featured");
  const [isDesc, setisDesc] = useState<boolean>(false);
  function setPriceSort(val:any,type:string){
    if(val == "Featured"){
    setPriceSort1(val);
    selectTab1(type, "", "",val)
    }
    else if(val == "Price High to Low"){
      setPriceSort1(val);
      selectTab1(type, "", "","Price",true)
    }
    else if(val == "Price Low to High"){
      setPriceSort1(val);
      selectTab1(type, "", "","Price",false)
    }
  }

  let pageSize:number = 12


  function wait(ms:number){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }

  //"LiveEvents","Concerts","Parties","TouristPlaces",
  useEffect(() => {
    let orderIdFromParams = searchParams.get("SelectedTab");
    let searchTextFromParams = searchParams.get("SearchText");
    if(searchTextFromParams != "" && searchTextFromParams != undefined){
      setsubHeadings("Your search results with " + searchTextFromParams + "...");
    }
    if (orderIdFromParams == "Casino") {
      appendTicketingType({ name: "Casino" })
      apply()
    }
    else if (orderIdFromParams == "WaterSport") {
      appendTicketingType({ name: "WaterSport" })
      apply()
    }
    else if (orderIdFromParams == "Event") {
      appendTicketingType({ name: "Event" })
      apply()
    }

    else if (orderIdFromParams == "LiveEvents") {
      appendTicketingType({ name: "LiveEvents" })
      apply()
    }
    else if (orderIdFromParams == "Concerts") {
      appendTicketingType({ name: "Concerts" })
      apply()
    }
    else if (orderIdFromParams == "Parties") {
      appendTicketingType({ name: "Parties" })
      apply()
    }
    else if (orderIdFromParams == "AmusementPark") {
      appendTicketingType({ name: "AmusementPark" })
      apply()
    }
    else if (orderIdFromParams == "Adventure") {
      appendTicketingType({ name: "Adventure" })
      apply()
    }
    let titleFromParams = searchParams.get("title");
    let descriptionFromParams = searchParams.get("description");
    //console.log(searchParams.get("Name"));

    if (orderIdFromParams && orderIdFromParams != "") {
      if (orderIdFromParams == "Casino"
        || orderIdFromParams == "WaterSport"
        || orderIdFromParams == "Event"
        || orderIdFromParams == "AmusementPark"
        || orderIdFromParams == "LiveEvents"
        || orderIdFromParams == "Concerts"
        || orderIdFromParams == "Adventure"
        || orderIdFromParams == "Parties") {
        settabActive("Ticketing");
      } else {
        settabActive(orderIdFromParams);
      }
    }

    selectTab1(orderIdFromParams ? orderIdFromParams : "All", titleFromParams ? titleFromParams : "", descriptionFromParams ? descriptionFromParams : "");

  }, []);

  // interface ticketingItem {
  //   name: String,
  //   label: String,
  //   isChecked: String
  // }
  
  let typeOfTicketing: any[] = [
    {
      name: "Adventure",
      label: "Adventure",   
      isChecked:'false'
    },
    {
      name: "AmusementPark",
      label:  "Amusement Park", 
      isChecked:'false' 
    },    
    {
      name: "Boating",  
      label: "Boating", 
      isChecked:'false'  
    },
    {
      name: "Casino",
      label: "Casino",
      // description: "Have a place to yourself",
      isChecked:'false'
    },
    {
      name: "Concerts",
      label: "Concerts",   
      isChecked:'false'
    },
    {
      name: "Crusing",
      label: "Crusing",
      isChecked:'false'
    },
    
    {
      name: "Dine", 
      label:  "Dine",  
      isChecked:'false'
    },    
    {
      name: "Event", 
      label: "Events",
      isChecked:'false'   
    },
    {
      name: "EcoTourism",
      label: "Eco Tourism",
      isChecked:'false'
    },
    {
      name: "LiveEvents",
      label: "Live Events",   
      isChecked:'false'
    },
    {
      name: "Museum",
      label: "Museum",
      isChecked:'false'
    },
    {
      name: "NightClubs",
      label: "Night Clubs",
      isChecked:'false'
    },
    {
      name: "Parties",
      label: "Parties",   
      isChecked:'false'
    },
    {
      name: "PhotoShoots",
      label: "Photo Shoots",    
      isChecked:'false'
    },
    {
      name: "Sightseeing",
      label: "Sightseeing",
      isChecked:'false'
    },
    {
      name: "WaterSport",
      label: "Water Sports",
      isChecked:'false'
    },
    {
      name: "Zoo",
      label: "Zoo & Biodiversity Park", 
      isChecked:'false'  
    }    
  ]


  let typeOfVehicle = [
    {
      name: "ElectricCycle",    
      label:"Electric Cycle",
      isChecked:'false'
    },
    {
      name: "FourWheeler",
      label:"Four Wheeler",
      isChecked:'false'
    },
    {
      name: "GearedCycle",
      label:"Geared Cycle",
      isChecked:'false'
    },
    {
      name: "TwoWheeler",
      label:"Two Wheeler",
      // description: "Have a place to yourself",
      isChecked:'false'
    },    
    {
      name: "VintageCars",
      label:"VintageCars",
      isChecked:'false'
    },
  ];

  let typeOfYacht = [
    {
      name: "ClassicYacht",
      label:"Classic Yacht",
      isChecked:'false'
    },
    {
      name: "DisplacementYacht",
      label:"Displacement Yacht",
      isChecked:'false'
    },    
    {
      name: "ExplorerYacht",
      label:" Explorer Yacht",
      isChecked:'false'
    },
    {
      name: "FishingYacht",
      label:"Fishing Yacht",
      isChecked:'false'
    },
    {
      name: "HybridYacht",
      label:"Hybrid Yacht",
      isChecked:'false'
    },    
    {
      name: "MotorYacht",
      label:"Motor Yacht",
      // description: "Have a place to yourself",
      isChecked:'false'
    },
    {
      name: "OpenYacht",
      label:"Open Yacht",
      isChecked:'false'
    },  
    {
      name: "SailingYacht",
      label:"Sailing Yacht",
      isChecked:'false'
    },    
    {
      name: "SemiDisplacementYacht",    
      label:"SemiDisplacement Yacht",
      isChecked:'false'
    },
    {
      name: "SpeedBoat",
      label:"Speed Boat",
      isChecked:'false'
    },
  ];

  function appendTicketingType(data: any) {

    if (!(data.name in slectedTicketingTypes)) {
      
      let type:any[] = slectedTicketingTypes     
      type.push(data.name) 
      setslectedTicketingTypes(type)
      
    } 

    console.log("typeOfTicketing before push update: ", typeOfTicketing)
    let item = typeOfTicketing.find(item => item.name === data.name);

    if(item)
    {
      item.isChecked = 'true';
    }
    console.log(" typeOfTicketing after push update: ", typeOfTicketing)
    

  };

  function removeTicketingType(data: any) {
    var index = slectedTicketingTypes.indexOf(data.name);
    if (index !== -1) {
      console.log("before Splice update: ", slectedTicketingTypes)
      slectedTicketingTypes.splice(index, 1);
      
      console.log("after splice update: ", slectedTicketingTypes)
    }
  
     let item = typeOfTicketing.find(item => item.name === data.name);

    if(item)
    {
      item.isChecked = 'false';
    }
  }

  function apply() {
    setListings([])
    setNextVal(1)
    selectTab("Ticketing")
    // fetchproductTypes((data: any) => {        
    //     setListings(data);
    //     settabActive("Ticketing")
    //   }, slectedTicketingTypes);
      
  }

  function clear() {
    let type:any[] = []
    setslectedTicketingTypes([])
    slectedTicketingTypes.splice(0, slectedTicketingTypes.length);
    apply()    
  }

  const renderTabsTypeOfTicketing = () => {
    return (
      <>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Type of Ticketings</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">                  
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={() => { clear(); close() }} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => { apply(); close() }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfTicketing.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.label}
                          defaultChecked={slectedTicketingTypes.indexOf(item.name) > -1 ? true : false}
                          // subLabel={item.description}   
                          onChange={(flag: boolean) => flag ? appendTicketingType(item) : removeTicketingType(item)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover> &nbsp;&nbsp;
      {renderTabsPriceSort("Ticketing")}
      </>
    );
  };
  const priceSortTypes = [
    {
      id: "Featured",
      name: "Featured",
      href: "javascript:void();",
      active: true,
    },
    {
      id: "ascn",
      name: "Price High to Low",
      href: "javascript:void();",
      active: true,
    },
    {
      id: "desc",
      name: "Price Low to High",
      href: "javascript:void();",
      active: true,
    }
  ]
  
  const renderTabsPriceSort = (type:string) => {
    return(
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>{priceSort}</span>
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[140px] px-4 mt-4 right-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-7 bg-white dark:bg-neutral-800 p-7">
                    {priceSortTypes.map((item, index) => (
                      <a
                        key={index}
                        href={item.href}
                        onClick={() => { setPriceSort(item?.name,type); close(); }}
                        className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                          item.active
                            ? "bg-gray-100 dark:bg-neutral-700"
                            : "opacity-80"
                        }`}
                      >
                        <p className="ml-2 text-sm font-medium ">{item.name}</p>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    )
  }

  function appendVehicleType(data: any) {

    if (!(data.name in slectedVehicleTypes)) {
      
      let type:any[] = slectedVehicleTypes     
      type.push(data.name) 
      setSlectedVehicleTypes(type)
      
    } 

    //console.log("typeOfTicketing before push update: ", typeOfTicketing)
    let item = typeOfVehicle.find(item => item.name === data.name);

    if(item)
    {
      item.isChecked = 'true';
    }
    //console.log(" typeOfTicketing after push update: ", typeOfTicketing)
    

  };

  function removeVehicleType(data: any) {
   
    var index = slectedVehicleTypes.indexOf(data.name);
    if (index !== -1) {
      console.log("before Splice update: ", slectedVehicleTypes)
      slectedVehicleTypes.splice(index, 1);
      
      console.log("after splice update: ", slectedVehicleTypes)
    }
  
     let item = typeOfVehicle.find(item => item.name === data.name);

    if(item)
    {
      item.isChecked = 'false';
    }
  }

function applyVehicle() {  
  setListings([])
  setNextVal(1)
  selectTab("Rides")
  // let lat: string = ""
  // let longi: string = ""
  // const location = navigator && navigator.geolocation.getCurrentPosition((position: any) => {
  //   lat = position.coords.latitude.toString();
  //   longi = position.coords.longitude.toString();
  //   fetchRidesType((data: any) => {
  //     setListings(data);
  //     settabActive("Rides")
  //   }, lat, longi, "", "", slectedVehicleTypes);
  // })
    
}

function clearVehicle() {      
  slectedVehicleTypes.splice(0, slectedVehicleTypes.length);  
  applyVehicle()
}

  const renderTabsTypeOfVehicle = () => {
    return (
      <>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Type of Rides</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={() => { clearVehicle(); close() }} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => { applyVehicle(); close() }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfVehicle.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          //name={item.name}
                          //label={item.name}
                          // subLabel={item.description}
                          name={item.name}
                          label={item.label}
                          defaultChecked={slectedVehicleTypes.indexOf(item.name) > -1 ? true : false}
                          // subLabel={item.description}   
                          onChange={(flag: boolean) => flag ? appendVehicleType(item) : removeVehicleType(item)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>&nbsp;&nbsp;
      {renderTabsPriceSort("Rides")}
      </>
    );
  };

   function appendYachtType(data: any) {

    if (!(data.name in slectedYachtTypes)) {
      
      let type:any[] = slectedYachtTypes     
      type.push(data.name) 
      setSlectedYachtTypes(type)
      
    } 

    //console.log("typeOfTicketing before push update: ", typeOfTicketing)
    let item = typeOfYacht.find(item => item.name === data.name);

    if(item)
    {
      item.isChecked = 'true';
    }
    //console.log(" typeOfTicketing after push update: ", typeOfTicketing)
    

  };

  function removeYachtType(data: any) {
   
    var index = slectedYachtTypes.indexOf(data.name);
    if (index !== -1) {
      console.log("before Splice update: ", slectedYachtTypes)
      slectedYachtTypes.splice(index, 1);
      
      console.log("after splice update: ", slectedYachtTypes)
    }
  
     let item = typeOfYacht.find(item => item.name === data.name);

    if(item)
    {
      item.isChecked = 'false';
    }
  }

  function applyYacht() {
    setListings([])
    setNextVal(1)
    selectTab("Yachts")
    // fetchYachtsTypes((data: any) => {
    //   setListings(data);
    //   settabActive("Yachts")
    // }, slectedYachtTypes);
    
}

function clearYacht() {    
  slectedYachtTypes.splice(0, slectedYachtTypes.length);
  applyYacht()
}

const renderTabsTypeOfYacht = () => {
  return (
    <>
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
              open ? "!border-primary-500 " : ""
            }`}
          >
            <span>Type of Yachts</span>
            <i className="las la-angle-down ml-2"></i>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <ButtonThird onClick={() => { clearYacht(); close() }} sizeClass="px-4 py-2 sm:px-5">
                    Clear
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => { applyYacht(); close() }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    Apply
                  </ButtonPrimary>
                </div>
                <div className="relative flex flex-col px-5 py-6 space-y-5">
                  {typeOfYacht.map((item) => (
                    <div key={item.name} className="">
                      <Checkbox
                        name={item.name}
                        label={item.label}
                        defaultChecked={slectedYachtTypes.indexOf(item.name) > -1 ? true : false}
                        // subLabel={item.description}   
                        onChange={(flag: boolean) => flag ? appendYachtType(item) : removeYachtType(item)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>&nbsp;&nbsp;
      {renderTabsPriceSort("Yachts")}
      </>
  );
};

//*********************************Start: This is for radio buttons, let code stay we can use later *******************************************

const [isCasino, setIsCasino] = useState(true);
  const [isWaterSport, setIsWaterSport] = useState(true);
  const [isSightSeeing, setIsSightSeeing] = useState(true);
  const [isBoating, setIsBoating] = useState(true);
  const [isCrusing, setIsCrusing] = useState(true);
  const [isZoo, setIsZoo] = useState(true);
  const [isMuseum, setIsMuseum] = useState(true);
  const [isPhotoShoot, setIsPhotoShoot] = useState(true);
  const [isNightClubs, setIsNightClubs] = useState(true);
  const [isEvents, setIsEvents] = useState(true);
  const [isEcoTourism, setIsEcoTourism] = useState(true);
  const [isAmusementPark, setIsAmusementPark] = useState(true);
  const [isDine, setIsDine] = useState(true);
  const [isAdventure, setIsAdventure] = useState(true);


  const [ticketingType, setTicketingType] = useState<
    "Casino" | "Event" | "">("Casino");

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTicketingSubCategoryBtn = () => {
    if (tabActive === "Ticketing") {

      return (


        <div className="flex lg-hidden">
          <div className="flex justify-left space-x-3">

            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isCasino
                ? "border-primary-500  text-primary-400"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsCasino(!isCasino); !isCasino ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Casino</span>
              {isCasino && renderXClear()}
            </div>

            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isWaterSport
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsWaterSport(!isWaterSport); !isWaterSport ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Water Sport</span>
              {isWaterSport && renderXClear()}
            </div>

            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isSightSeeing
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsSightSeeing(!isSightSeeing); !isSightSeeing ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Sightseeing</span>
              {isSightSeeing && renderXClear()}
            </div>


            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isBoating
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsBoating(!isBoating); !isBoating ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Boating</span>
              {isBoating && renderXClear()}
            </div>


            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isCrusing
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsCrusing(!isCrusing); !isCrusing ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Crusing</span>
              {isCrusing && renderXClear()}
            </div>

            <div
              className={`flex items-center justify-center px-3 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isZoo
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsZoo(!isZoo); !isZoo ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Zoo & Biodiversity park</span>
              {isZoo && renderXClear()}
            </div>

            
            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isMuseum
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsMuseum(!isMuseum); !isMuseum ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Museum</span>
              {isMuseum && renderXClear()}
            </div>
                        {/* </div>

            <br />

            <div className="flex justify-left space-x-3"> */}
            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isPhotoShoot
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsPhotoShoot(!isPhotoShoot); !isPhotoShoot ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Photo Shoots</span>
              {isPhotoShoot && renderXClear()}
            </div>

            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isNightClubs
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsNightClubs(!isNightClubs); !isNightClubs ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Night Clubs</span>
              {isNightClubs && renderXClear()}
            </div>

            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isEvents
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsEvents(!isEvents); !isEvents ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Events</span>
              {isEvents && renderXClear()}
            </div>

            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isEcoTourism
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsEcoTourism(!isEcoTourism); !isEcoTourism ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Eco Tourism</span>
              {isEcoTourism && renderXClear()}
            </div>
            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isAmusementPark
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsAmusementPark(!isAmusementPark); !isAmusementPark ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Amusement Park</span>
              {isAmusementPark && renderXClear()}
            </div>

            <div
              className={`flex items-center justify-center px-1 py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isDine
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsDine(!isDine); !isDine ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Dine</span>
              {isDine && renderXClear()}
            </div>

            <div
              className={`flex items-center justify-center px- py-1 text-xs rounded-full border focus:outline-none cursor-pointer transition-all ${isAdventure
                ? "border-primary-500  text-primary-700"
                : "border-neutral-300 dark:border-neutral-700"
                }`}
              onClick={() => { setIsAdventure(!isAdventure); !isAdventure ? selectTab("Casino") : selectTab("Ticketing") }}
            >
              <span>Adventure</span>
              {isAdventure && renderXClear()}
            </div>
            </div>
        </div>


      );
    } else {
      return null;
    }
  };
//*********************************End: This is for radio buttons, let code stay we can use later *******************************************

  const renderCard = (listing: any) => {

    return <ProductListing key={listing.id} data={listing}
      href={"/product?productId=" + listing.id + "&name=" + listing.title}
    />;
  };
  function selectTab(item: string) {
    settabActive(item);    
    setNextVal(1) 
    selectTab1(item, "", "");
  }

  function selectTab1(item: string, title: string, description: string, sorttype?:string, isDesc?:boolean) {
    //console.log(title + " "+ description )    
    let searchTextFromParams = searchParams.get("SearchText");
    if (item === "Rides") {
      //get location details
      let lat: string = ""
      let longi: string = ""
      const location = navigator && navigator.geolocation.getCurrentPosition((position: any) => {
        lat = position.coords.latitude.toString();
        longi = position.coords.longitude.toString();
        fetchRides((data: any) => {
          
          if(data?.length >= 1){
            setHasmore(true);
          }
          else{
            setHasmore(false);
          }
          settabActive("Rides")
          setslectedTicketingTypes([])
          setSlectedYachtTypes([])
          //setSlectedVehicleTypes([])
          setListings(data);
        }, lat, longi, slectedVehicleTypes, title, description,1, pageSize, sorttype , isDesc,searchTextFromParams??"");
      })
    }
    else if (item === "Yachts") {
      fetchYachts((data: any) => {
        
        if(data?.length >= 1){
          setHasmore(true);
        }
        else{
          setHasmore(false);
        }
        settabActive("Yachts")
        setslectedTicketingTypes([])
        //setSlectedYachtTypes([])
        setSlectedVehicleTypes([])
        setListings(data);
      }, slectedYachtTypes, title, description,1, pageSize, sorttype, isDesc, searchTextFromParams??"");
    }
    else if (item === "Ticketing") {
      fetchThingsToDo((data: any) => {        
        if(data?.length >= 1){
          setHasmore(true);
        }
        else{
          setHasmore(false);
        }
        settabActive("Ticketing")
        //setslectedTicketingTypes([])
        setSlectedYachtTypes([])
        setSlectedVehicleTypes([])
        setListings(data);
      }, slectedTicketingTypes, title, description,1, pageSize, sorttype, isDesc, searchTextFromParams??"");
    }
    else if (item === "Zoo") {
      fetchZoo((data: any) => {
        setListings(data);
      }, title, description,1, pageSize);
    }
    else if (item === "Event") {
      fetchEvent((data: any) => {
        setListings(data);
      }, title, description,1, pageSize);
    }
    else if (item === "WaterSport") {
      fetchWaterSport((data: any) => {
        setListings(data);
      }, title, description,1, pageSize);
    }
    else if (item === "Casino") {
      fetchCasino((data: any) => {
        setListings(data);
      }, title, description,1, pageSize);
    }
    else if (item === "Museum") {
      fetchMuseum((data: any) => {
        setListings(data);
      }, title, description,1, pageSize);
    }
    else if (item === "All") {
      fetchAll((data: any) => {
        setListings(data);
        if(data?.length >= 1){
          setHasmore(true);
        }
        else{
          setHasmore(false);
        }
        settabActive("All")
      }, title, description,1, pageSize, sorttype, isDesc, searchTextFromParams??"");

    }if (item === "Places To Visit") {
      //get location details
      let lat: string = ""
      let longi: string = ""
      const location = navigator && navigator.geolocation.getCurrentPosition((position: any) => {
        lat = position.coords.latitude.toString();
        longi = position.coords.longitude.toString();
        fetchPlacesToVisit((data: any) => {
          
          if(data?.length >= 1){
            setHasmore(true);
          }
          else{
            setHasmore(false);
          }
          settabActive("Places To Visit")
          setslectedTicketingTypes([])
          setSlectedYachtTypes([])
          //setSlectedVehicleTypes([])
          setListings(data);
        },title, description,1, pageSize,searchTextFromParams ?? "");
      })
    }
    else {
      setListings([]);
    }
  }
  
  const fetchMoreData = () => {
    let searchTextFromParams = searchParams.get("SearchText");
    if(tabActive == "Rides")
    {
      let lat: string = ""
      let longi: string = ""
      const location = navigator && navigator.geolocation.getCurrentPosition((position: any) => {
        lat = position.coords.latitude.toString();
        longi = position.coords.longitude.toString();
        fetchRides((data: any) => {
          
          if(data?.length >= 1){
            setHasmore(true);
          }
          else{
            setHasmore(false);
          }
          //settabActive("Rides")
          setNextVal(nextval + 1);
          setslectedTicketingTypes([])
          setSlectedYachtTypes([])
          //setSlectedVehicleTypes([])
          setListings(listings.concat(data));
        }, lat, longi, slectedVehicleTypes, "", "",nextval + 1, pageSize,undefined,undefined,searchTextFromParams??"");
      })
    }
    else if(tabActive == "Ticketing")
    {
      fetchThingsToDo((data: any) => {
        
        if(data?.length >= 1){
          setHasmore(true);
        }
        else{
          setHasmore(false);
        }
        //settabActive("Ticketing")
        setNextVal(nextval + 1);
        //setslectedTicketingTypes([])
        setSlectedYachtTypes([])
        setSlectedVehicleTypes([])
        setListings(listings.concat(data));
      }, slectedTicketingTypes, "", "",nextval + 1, pageSize,undefined,undefined,searchTextFromParams??"");
    }
    else if(tabActive == "Yachts")
    {
      fetchYachts((data: any) => {
        
        if(data?.length >= 1){
          setHasmore(true);
        }
        else{
          setHasmore(false);
        }
        //settabActive("Yachts");
        setNextVal(nextval + 1);        
        setslectedTicketingTypes([])
        //setSlectedYachtTypes([])
        setSlectedVehicleTypes([])  
        setListings(listings.concat(data));      
      },slectedYachtTypes, "", "",nextval + 1, pageSize,undefined,undefined,searchTextFromParams??"");
    }
    else if(tabActive == "Places To Visit")
    {
      fetchPlacesToVisit((data: any) => {
        
        if(data?.length >= 1){
          setHasmore(true);
        }
        else{
          setHasmore(false);
        }
        setNextVal(nextval + 1);        
        setslectedTicketingTypes([])
        setSlectedVehicleTypes([])  
        setListings(listings.concat(data));      
      }, "", "",nextval + 1, pageSize);
    }
    else{
      fetchAll((data: any) => {
        setListings(listings.concat(data));
        if(data?.length >= 1){
          setHasmore(true);
        }
        else{
          setHasmore(false);
        }
        //settabActive("All");
        setNextVal(nextval + 1);
      }, "", "",nextval + 1, pageSize,undefined,undefined,searchTextFromParams ?? "")
    }
  };

  return (
    <div>
      <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
        <div className="container pt-14 sm:pt-10 pb-24 lg:pb-32">
          <div className="nc-AllListings relative">
            <HeaderFilter
              tabActive={tabActive}
              subHeading={subHeadings}
              tabs={tabs}
              heading={heading}
              onClickTab={(item) => { selectTab(item) }}
              isViewAll={false}
            />
            <div className="hidden px-6 pb-1 lg:flex nc-AllListings relative">
            {tabActive === "Ticketing"?renderTabsTypeOfTicketing():""}
            {tabActive === "Rides"?renderTabsTypeOfVehicle():""}
            {tabActive === "Yachts"?renderTabsTypeOfYacht():""}
            
            </div>
            <div className="container lg:hidden space-x-4">
            {tabActive === "Ticketing"?renderTabsTypeOfTicketing():""}
            {tabActive === "Rides"?renderTabsTypeOfVehicle():""}
            {tabActive === "Yachts"?renderTabsTypeOfYacht():""}
            </div>
            {tabActive != "" ? 
              <InfiniteScroll
                dataLength={listings?.length} //This is important field to render the next data
                next={fetchMoreData}
                hasMore={hasmore}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <div className={`py-6 grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 ${gridClass}`}>
                {listings.sort((a:any,b:any) => (a.weight > b.weight) ? -1 : ((b.weight > a.weight) ? 1 : 0)).map((stay) => renderCard(stay))}
                </div>
              </InfiniteScroll>
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllListingsHome;

