import React from "react";
import { Link } from "react-router-dom";
import eSanchari_logo from "../../images/eSanchari_logo.png";


export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = eSanchari_logo,
  imgLight = eSanchari_logo,
  className = "w-30",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
        <img
          className={`block max-h-14 ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt="Logo"
        />
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
