import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

export interface ResetPasswordGetNumberProps {
  className?: string;
}

const ResetPasswordGetNumber: FC<ResetPasswordGetNumberProps> = ({ className = "" }) => {
  const [errormessage, seterrormessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [phone_number, setphone_number] = useState("");
  const [register_mobile, setRegister_mobile] = useState("");
  
  function handleSubmit() {
    //var mobile = (document.getElementById("register_mobile") as HTMLInputElement).value;
    if (!register_mobile.startsWith('+') || register_mobile == '' || register_mobile == null) {
      seterrormessage("Please provide a Country Dial Code followed by mobile number e.g. +919876543210");
      return
    }
    if (isValidPhoneNumber(register_mobile) === false) {
      seterrormessage("Please provide a Country Dial Code followed by mobile number e.g. +919876543210");
      return
    }
    try {
      let dregister_mobile = register_mobile.replace(" ", "");
      Auth.forgotPassword(dregister_mobile)
        .then(data => {
          window.location.assign("#/forgot-pass-setpassword?mobile=" + dregister_mobile);
        }).catch(e => {
          seterrormessage(e);
        });


    } catch (error) {
      seterrormessage(error + "");
    }
  }
  const handleChangePhone = (e: any) => {
    seterrormessage("");
    setRegister_mobile(e);
  }
  
  return (
    <div className={`nc-ResetPasswordGetNumber  ${className}`} data-nc-id="ResetPasswordGetNumber">
      <Helmet>
        <title>Sign up || eSanchari</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Reset Password
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          
          {/* FORM */}
          <div className="grid grid-cols-1 gap-6" >
            
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Mobile Number
              </span>

              <PhoneInput
                className="h-11"
                id="register_mobile"
                style={{"--PhoneInputCountryFlag-height":"1.3rem","width":"100%"}}
                  international
                  defaultCountry="IN"
                  value={phone_number}
                  onChange={handleChangePhone}
                  placeholder="Mobile Number &#42;"
                  required />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-red-800 ">
                {errormessage}
              </span>
              
            </label>
            
            <ButtonPrimary onClick={()=> handleSubmit()}>Get OTP on mobile</ButtonPrimary>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="#/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordGetNumber;
