import axios from 'axios';

import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const RefundPolicy: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-RefundPolicy overflow-hidden relative ${className}`}
      data-nc-id="RefundPolicy"
    >
      <Helmet>
        <title>Cancellation, Return and Refund Policy  || eSanchari</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-10 lg:py-28 space-y-16 lg:space-y-5">
        <SectionHero
          rightImg={rightImg}
          heading="Cancellation, Return and Refund Policy  "
          btnText=""
          subHeading=""
        />
        <div className='mx-3 mb-4 aboutushead p-3' >

          <p className='px-4'>
            Welcome to eSanchari.com, This Website/App is owned and operated by Digiverti Infotech Pvt. Ltd., having its registered office at No. 15/1, New No. 48, 4th Floor, Kanakapura Main Road, Yediyur Circle, Basavanagudi, Bangalore 560004 Karnataka, INDIA. By using our eSanchari.com services, you are agreeing to be bound by our terms and conditions of this Policy.
            <br /> <br /> If this document is not in a language that you understand, you shall contact eSanchari.com at the email info@digiverti.com. Failure to do so within 24 (Twenty-Four) hours from the time of receipt of this document and your acceptance of this document by clicking “I ACCEPT” button shall be considered as your understanding of this document.
            <br /> <br /> This electronic record is generated by a computer system and does not require any physical or digital signatures. By clicking on the “I ACCEPT” button on this electronic contract, you are consenting to be bound by this User Agreement. Please ensure that you read and understand all the provisions of this document before you start using the eSanchari.com, as you shall be bound by all the terms and conditions herein upon clicking on the “ACCEPT & CONTINUE” button on this electronic contract. If you do not agree or accept any of the Terms and Conditions contained herein, then please do not use the eSanchari.com’s digital platform or avail any of the services being provided therein.
            <br /> <br />Please read this Policy before making a purchase on our website, so that you understand your rights as well as what you can expect from Mobile App if you are not happy with your purchase.
            <br /> <br /> Please see below for more information on our return policy.

          </p>

          <br />
          <b>General:  </b>
          <br />
          <p className='px-4'>
            ●	This Website or Mobile App (the “Website/Site/Mobile App”) is owned and operated by Digiverti Infotech Pvt. Ltd. having its registered office at No. 15/1, New No. 48, 4th Floor, Kanakapura Main Road, Yediyur Circle, Basavanagudi, Bangalore 560004 Karnataka, INDIA.
            <br />● 	By visiting our website and accessing the information, services we provide, you understand and agree to accept and adhere to the terms and conditions as stated in the Terms and Conditions (please refer to the Terms and Conditions Policy link of this Website for more information).
            <br />● 	We reserve the right to change this User Agreement from time to time without notice. You acknowledge and agree that it is your responsibility to review this User Agreement periodically to familiarize yourself with any modifications. Your continued use of this site after such modifications will constitute acknowledgment and agreement of the modified terms and conditions.
          </p>

          <br />


          <b>Cancellation of the Subscription of a Service  </b>
          <br />
          <p className='px-4'>
            ●	The subscription of service or any one-time payment while consuming the service shall be governed by the Terms of Use, Privacy Policy and this Cancellation & Refund Policy.
            <br />●	 The eSanchari.com immediately informs the concerned vendor or partner about your appointment and arrangements are done accordingly by the team. Since, the expenses are incurred on such arrangements and bookings, therefore eSanchari.com follow partial refund policy which is subjected to the time and other conditions.
            <br />●	 We make every effort to facilitate the service or fulfil order placed with us as per the specifications and timeline mentioned against each service. However, you may request for the cancellation and refund for a service before ending of the prescribed cancellation timeline mentioned in clause 2.5 of this Policy.
            <br />●	 The refunds shall be made only after deducting the applicable taxes and platform fee of eSanchari.com that varies as per service to service.
            The refunds shall be processed in the following manner.
            <p className='px-8'>
              ●	If you make a cancellation request within 60 minutes of placing the order, then you shall be entitled for 100% refund after deducting the applicable taxes and platform fee.
              <br />●  If you make a cancellation request before less than 24 hours of starting a service, then the you shall be entitled for 50% of the amount.

              <br />●  You shall not be entitled for any refunds if only 6 hours are left to start the service as by that time all the arrangements wound already have been made for you.

            </p>

          </p>

          <br /><b>Cancellation Process </b>
          <br />
          <p className='px-4'>
            ●	To cancel the subscription of a service, please email customer service at [Email of the Grievance Officer/HR] or make a cancellation request at the eSanchari.com Mobile App, to obtain a Cancellation Request Number.

            <br /> ●	The cancellation of the Service shall depend upon the individual item or service, type of service etc., for which the details will be available on the Mobile App.

            <br /> ●	Upon receiving the return request, we shall verify the request and if found genuine, we will arrange a cancellation of the service.
            <br /> ●	If you have any questions or comments about Cancellation and Refund Policy as outlined above, you can contact us at:
            <p className='px-8'>
              <br /> eSanchari.com
              <br /> A digital property of Digiverti Infotech Pvt. Ltd.
              <br /> No. 15/1, New No. 48, 4th Floor, Kanakapura Main Road, Yediyur
              <br /> Circle, Basavanagudi, Bangalore 560004 Karnataka, INDIA
              <br /> Phone: +91 63619 94339

              <br /> Email: info@digiverti.com
            </p>
          </p>
          <br />

          <br /> <b>Exceptions</b>
                    <br /> ●	Damages due to misuse of product.
          <br /> ●	Incidental damage due to malfunctioning of product.
          <br /> ●	Any consumable item which has been used or installed.
          <br /> ●	Products with tampered or missing serial / UPC numbers.
          <br /> ●	Any damage / defect which is not covered under the manufacturer's warranty.
          <br /> ●	Any product that is returned without all original packaging and accessories, including the box, manufacturer's packaging if any, and all other items originally included with the product(s) delivered.
          <br /> ●	Some special rules for promotional offers may override “2 Day Returns Policy”

          <br />

          <br /> <b>Refund Process: </b><br />
          eSanchari.com hereby points out the following exceptions to this Cancellation and Refund Policy.
          <br /> ●	The refunds shall not be processed and summarily rejected if you make a request for cancellation before  less than 6 hours of starting of the service.
          <br /> ●	eSanchari.com shall not process the refunds if the booking is done for a wrong person or place.
          <br /> ●	eSanchari.com and you, both shall be bound by the terms and condition of the respective partner or vendor. Therefore, if you flout any of the conditions specifically mentioned by the partners or vendors, then the refunds shall not be processed.
          <br /> ●	At certain places, you may need to show the ID Cards to verify your identity. If you fail to prove your identity, then you be stopped at the entry gate only and in such cases, the refunds shall not be processed.
          <br />

          <br /> <b>Response Time: </b>
          <br />A refund is normally processed within 7 Business days after the completion of formal procedure at the end of eSanchari.com.
          <br /> ●	Refunds may take 1-2 billing cycles to appear on your credit card statement, depending on your credit card company. We will notify you by email when your return has been processed.
          <br /> ●	If you have made the payment through UPI or Bank transfer then the refund shall be deposited in your bank account by eSanchari.com within 7 working days.
          <br /> ●	The period of refund may also depend on various banking and payment channels, and we will not be liable for any errors or delay in a refund due to banks or third-party service providers.

          <br />

          <br /> <b>Refusal of a Cancellation or Refund Request </b>
          <br /> ●	We reserve the right to refuse or cancel any cancellation request if such a request is not in compliance with this Policy or Applicable laws.
          <br /> ●	It is hereby agreed by you that the cancellation of a service or subscription shall be processed as per the decision of the eSanchari.com  only. eSanchari.com shall have all the rights to reject the cancellation request made by you.
          <br />
          <br /><b>Contact Information </b>
          <br />
          <p className='px-4'>
            ●	If you have any questions or comments about Cancellation and Refund Policy as outlined above, you can contact us at:
            <p className='px-8'>
              <br /> eSanchari.com
              <br /> A digital property of Digiverti Infotech Pvt. Ltd.
              <br /> No. 15/1, New No. 48, 4th Floor, Kanakapura Main Road, Yediyur
              <br /> Circle, Basavanagudi, Bangalore 560004 Karnataka, INDIA
              <br /> Phone: +91 63619 94339

              <br /> Email: info@digiverti.com
            </p>
          </p>

          <br />

        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
