import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import kishor from "images/avatars/kishor.jpg";

export interface RelatedArticles {
  id: string;
  href: string;
  featuredImage: string;
  title: string;
  author: string;
  date: string;
}

const BlogPost1 = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Why one should consider taking a holiday?
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
          Going on a holiday can offer numerous benefits, both for mental and physical well-being. Here are some compelling reasons why people should consider taking a holiday.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11"
                imgUrl={kishor}
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    Kishor Kumar
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    July 22, 2024
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <h3>Stress Relief</h3>
        <p>
        A change of scenery can help reduce stress and provide a break from daily routines and responsibilities.
        </p>
        <h3>Mental Health Boost</h3>
        <p>
        Taking time off from work and everyday life can improve mental health, alleviate anxiety, and promote relaxation, leading to better overall well-being.
        </p>
        <figure>
          <img
            src="https://images.unsplash.com/photo-1501493870936-9c2e41625521?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1045&amp;q=80"
            alt="nc blog"
            className="rounded-2xl"
          />
          <figcaption>
          Overall, taking a holiday can greatly enhance quality of life, and it’s often essential for maintaining balance in a busy world.
          </figcaption>
        </figure>
        <h3>Reconnect with Loved Ones</h3>
        <p>
        Holidays provide an opportunity to spend quality time with family and friends, strengthening relationships and creating lasting memories.
        </p>
        <h3>Cultural Exposure</h3>
        <p>
        Travelling allows individuals to experience new cultures, cuisines, and traditions, broadening their perspectives and fostering appreciation for diversity.
        </p>
        <h3>Inspiration and Creativity</h3>
        <p>
        New experiences can spark creativity and inspiration, offering fresh ideas and motivation when returning to daily life.
        </p>
        <h3>Physical Activity</h3>
        <p>
        Many holidays involve physical activities like hiking, swimming, or exploring, which can contribute to improved fitness and health.
        </p>
        <h3>Improved Productivity</h3>
        <p>
        Taking a break can lead to increased productivity and focus when returning to work, as it helps prevent burnout.
        </p>
        <h3>Personal Growth</h3>
        <p>
        Travelling can encourage personal growth, as navigating new environments and situations enhances adaptability and problem-solving skills.
        </p>
        <h3>Adventure and Fun</h3>
        <p>
        Holidays provide opportunities for adventure and enjoyment, whether through activities like exploring, sightseeing, or simply relaxing.
        </p>
        <h3>Rejuvenation</h3>
        <p>
        A holiday can serve as a time to recharge and rejuvenate, allowing individuals to return to their regular lives with renewed energy and enthusiasm.
        </p>
      </div>
    );
  };

  const relatedposts: RelatedArticles[] = [
    {
      id: "1",
      href: "/blogpost2",
      featuredImage: "https://images.pexels.com/photos/3155666/pexels-photo-3155666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
      title: "The travel industry in India is a significant and rapidly growing sector of the economy",
      author: "Kishor Kumar",
      date: "July 29, 2024"
    },
    {
      id: "2",
      href: "/blogpost3",
      featuredImage: "https://images.pexels.com/photos/8241135/pexels-photo-8241135.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
      title: "Holiday spendings by an Indian",
      author: "Kishor Kumar",
      date: "Aug 12, 2024"
    }
  ]

  const renderPostRelated = (post: RelatedArticles) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Travel" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Single Blog || eSanchari</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://images.unsplash.com/photo-1605487903301-a1dff2e6bbbe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1957&q=80"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
      </div>
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-8 lg:py-14 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {/*  */}
            {relatedposts.map(renderPostRelated)}
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost1;
