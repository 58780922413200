import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageCollaborateProps {
  className?: string;
}

const PageCollaborate: FC<PageCollaborateProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Collaborate || eSanchari</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-10 lg:py-28 space-y-16 lg:space-y-5">
        <SectionHero
          rightImg={rightImg}
          heading="Partner with us!!"
          btnText="Sign up as a Vendor!"
          subHeading="Want to take your business to the next level?"          
        />
        <div className='mx-3 mb-4 text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 p-3'>
              <p className='py-2'>
              <a href="http://esanchari.com/" className="text-primary-500">eSanchari</a> initiated its journey to serve the tourism industry with instant ticket booking and comprehensive choices, offering a range of categories with latest technology and top-notch customer support. Become a business Partner and enjoy the following benefits:
              </p>
              <p className='px-4'>
              ●	Simplified & easy-to-use Admin Panel
              <br />●	Easy on-boarding
              <br />●	Small business can use it as micro-website for showcasing their services
              <br />●	Easy Product & Price Management
              <br />●	Manage Offers
              <br />●	Manage Policies
              <br />●	Extensive marketing at eSanchari's cost
              <br />●	Enhance your business PAN India
              <br />●	Dedicated Support Team to address your queries
              <br />●	Quick Payment settlements before guest/customer coming to your venue
              <br />●	MIS Reports
              </p>
            </div>                
    <div className="relative py-16">
        <SectionSubscribe2 />
        </div>
      </div>
    </div>
  );
};

export default PageCollaborate;
