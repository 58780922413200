import React, { FC, Fragment, useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import { GeneralPackage, PriceRates, ProductDataType, ProductDetailsDataType, packageItems } from "../../data/types";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ClampLines from 'react-clamp-lines';
import ReadMore from "common/ReadMore";
import ModalDatePrice from "./ModalDatePrice";
import moment from "moment";
import ModalBooking from "./ModalBooking";
import constants from "common/constants";

export interface PackageCardProps {
  className?: string;
  product:ProductDataType| undefined;
  post: GeneralPackage;
  onRateSelected:any;
  setdateValue: any;
  dateValue: moment.Moment | null;
  productType:any;
  selectedAnchor:any;
  productDetails: ProductDetailsDataType | undefined;
}

const PackageCard: FC<PackageCardProps> = ({ className = "h-full", post,setdateValue, dateValue,
productType,onRateSelected,product,productDetails}) => {
  const { title, description, forKids, thumbnailUrl, fromAge, toAge, stage, sun, mon, tue, wed, thu, fri, sat, termsAndCondition } = post;
  const [isOpenBookingModal, setIsOpenBookingModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedPackage, setselectedPackage] = useState<GeneralPackage>();
  const [selectedRate, setselectedRate] = useState<PriceRates>();
  const [selectedPackageId, setSelectedPackageId] = useState<string>();
  const [selectedAnchor, setSelectedAnchor] = useState<any>();
  const [amount, setAmount] = useState<number>();

  function closeBookingModal() {
    setIsOpenBookingModal(false);
  }

  function openBookingModal() {
    setIsOpenBookingModal(true);
  }
  const renderBookingModal = () => {
    return (
      <ModalBooking closeBookingModal={closeBookingModal} openBookingModal={openBookingModal}
      isOpenModal={isOpenBookingModal} product={product} selectedPackageId={selectedPackageId}
      selectedPackage={selectedPackage} selectedRate={selectedRate}
      dateValue={dateValue} selectedAnchor={selectedAnchor}
      setdateValue={setdateValue} productDetails={productDetails}/>
    );
  }
  let dt = dateValue?.format('DD MMM YYYY');

  useEffect(() => {
    var daynum = dateValue?.toDate().getDay();
    if (post) {
      var amountobj = daynum == 1 ? post.mon : daynum == 2 ? post.tue : daynum == 3 ? post.wed :
        daynum == 4 ? post.thu : daynum == 5 ? post.fri : daynum == 6 ? post.sat : post.sun
      setAmount(amountobj);
      GetViewRate(post);
    }
  }, [selectedPackage, dateValue,selectedRate?.basePrice]);

  const renderModal = () => {
    return (
      <ModalDatePrice onPackageSelected={onPackageSelected} setdateValue={setdateValue} 
      isOpenModal={isOpenModal} closeModal={closeModal} openModal={openModal} 
      post={post} productType={productType} onRateSelected={onRateSelected}
      />
    );
  }
  function closeModal() {
    setIsOpenModal(false);
  }

  function openModal() {
    setIsOpenModal(true);
  }

  let [isTandCModal, setTandCOpenModal] = useState(false);
  const renderTandCModal = () => {
    return (
      <Transition appear show={isTandCModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeTandCModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Terms and Conditions
                    </h3>
                    <span className="absolute right-3 top-3">
                      <ButtonClose onClick={closeTandCModal} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300">
                    {termsAndCondition?.split(('\n')).map((item, i) => (
                      
                      <div
                        key={item.toString()}
                        className="flex items-center py-2.5  "
                      >
                        <i
                          className={`text-4xl `}
                        ></i>
                        <span>{item?.toString()}</span>
                      </div>
                    ))}
                  </div>
                  {/* <div className="px-8 text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <div className="flow-root py-3">
                      <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
                        <span>
                                {termsAndCondition?.split('\n').map( (it, i) => {
                                return <> 
                                <span key={'x'+i}>{it}
                                  </span> 
                                  <br/>
                                  </>
                                }) }

                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  function closeTandCModal() {
    setTandCOpenModal(false);
  }

  function openTandCModal() {
    setTandCOpenModal(true);
  }
  const onPackageSelected = (item: GeneralPackage) => {
    setselectedPackage(item);
    setSelectedPackageId(item.id);
    setSelectedAnchor(item.packageItems?.filter((item: packageItems) => item.title == "Anchoring details")[0])
  }
  const onBuySelected = (item: GeneralPackage) => {
    onPackageSelected(item);
    openBookingModal(); 
  }

  async function GetViewRate(selPackage: GeneralPackage) {
    let packageStartDate = moment(dateValue).format('yyyy-MM-DD');
    let packageEndDate = moment(dateValue).format('yyyy-MM-DD');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        packageId: selPackage?.id,
        productType: product?.type.includes('Yacht') ? "Yacht" : product?.type,
        endDate: packageEndDate,
        startDate: packageStartDate
      })
    };
    await fetch(constants.API_CONTEXT_URL + '/rate/viewRates', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setselectedRate(data.response![0]);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  return (
    <div className={`nc-Card3 relative flex flex-col-reverse sm:flex-row sm:items-center rounded-[40px] group ${className}`}
      data-nc-id="Card3">
      <div className="flex flex-col flex-grow">
        <div className="mb-4 flex listingSection__wrap bg-neutral-100 justify-between" style={{ flexDirection: 'initial' }}>
          {/* For Web Version */}
          <div className="hidden lg:block">
            <div className="flex">
              <div className={`d-none d-lg-block alignselfstart flex-shrink-0 sm:w-56 sm:ml-6 rounded-3xl overflow-hidden sm:mb-0`}
                style={{ width: "15rem" }}>
                <span className={`block w-full h-0 aspect-h-9 sm:aspect-h-16 aspect-w-16 `}>
                  <NcImage
                    containerClassName="absolute inset-0"
                    src={thumbnailUrl}
                    alt={title}
                  />
                  <span>
                  </span>
                </span>
              </div>
              <div className="px-7 flex flex-col justify-between" style={{ marginTop: '0' }}>
                <div>
                  <h2
                    className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}
                  >
                    <span className="line-clamp-2" title={title}>
                      {title}
                    </span>
                  </h2>
                  <div className="sm:block" style={{ display: "flex" }}>
                    <span className="text-neutral-500 dark:text-neutral-400 text-base">
                      Activity Timings : &nbsp;
                    </span>
                    <div className="flex">
                      <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                        {dt}
                      </span>
                      {post?.packageStartTime ?
                        <>
                          <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
                            ·
                          </span>
                          <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                            {moment(post?.packageStartTime.toString(), "hh:mm A").format('hh:mm A')}
                          </span>
                        </>
                        : null}
                    </div>
                  </div>
                  <div className="sm:block">
                    <span className="text-neutral-500 dark:text-neutral-400">
                      {description ?
                        <>
                          <ReadMore>
                            {description}
                          </ReadMore>
                        </>
                        : null}
                    </span>
                  </div>
                </div>
                <div
                  className='nc-PostCardMeta inline-flex items-center fledx-wrap text-neutral-800 dark:text-neutral-200 text-sm leading-none'>
                </div>
                <div className="flex justify-between" style={{ lineHeight: "1rem" }}>
                  <ButtonPrimary onClick={() => onBuySelected(post)}>
                    Buy now
                  </ButtonPrimary>
                  <ButtonSecondary className="bg-neutral-300" onClick={openModal}>
                    <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                      Price Chart
                    </span>
                  </ButtonSecondary>
                  <ButtonSecondary className="bg-neutral-300" onClick={openTandCModal}>
                    <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                      T&C
                    </span>
                  </ButtonSecondary>
                </div>
              </div>
            </div>
          </div>
          {/* For Mobile Version */}
          <div className="block lg:hidden space-y-1">
            <div className={`d-none d-lg-block  flex-shrink-0 sm:w-56 sm:ml-6 rounded-3xl overflow-hidden mb-2`}
              style={{ width: "20rem", marginInline:"Auto" }}>
              <span className={`text-sm block w-full h-0 items-center aspect-h-9 sm:aspect-h-16 aspect-w-16`}>
                <NcImage
                  containerClassName="absolute inset-0"
                  src={thumbnailUrl}
                  alt={title}
                />
                <span>
                </span>
              </span>
            </div>
            <div className="px-7 flex flex-col justify-between" style={{ marginTop: '0' }}>
              <div>
                <h2
                  className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}
                >
                  <span className="line-clamp-2" title={title}>
                    {title}
                  </span>
                </h2>
                <div className="sm:block" style={{ display: "flex" }}>
                  <span className="text-neutral-500 dark:text-neutral-400 text-base">
                    Activity Timings : &nbsp;
                  </span>
                  <div className="flex">
                    <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                      {dt}
                    </span>
                    {post?.packageStartTime ?
                      <>
                        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
                          ·
                        </span>
                        <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                          {moment(post?.packageStartTime.toString(), "hh:mm A").format('hh:mm A')}
                        </span>
                      </>
                      : null}
                  </div>
                </div>
                <div className="sm:block" style={{ display: "flex" }}>
                  <span className="text-neutral-500 font-semibold dark:text-neutral-400 text-base">
                    Package Price : <span className="text-primary-700">&nbsp; ₹ {amount}</span>
                  </span>
                </div>
                <div className="sm:block">
                  <span className="text-neutral-500 dark:text-neutral-400">
                    {description ?
                      <>
                        <ReadMore>
                          {description}
                        </ReadMore>
                      </>
                      : null}
                  </span>
                </div>
              </div>
              <div
                className='nc-PostCardMeta inline-flex items-center fledx-wrap text-neutral-800 dark:text-neutral-200 text-sm leading-none'>
              </div>
              <div className="flex justify-between" style={{ lineHeight: "1rem" }}>
                <ButtonPrimary onClick={() => onBuySelected(post)}>
                  Buy now
                </ButtonPrimary>
                <ButtonSecondary className="bg-neutral-300" onClick={openModal}>
                  <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                    Price Chart
                  </span>
                </ButtonSecondary>
                <ButtonSecondary className="bg-neutral-300" onClick={openTandCModal}>
                  <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                    T&C
                  </span>
                </ButtonSecondary>
              </div>
            </div>
          </div>

          <div className="px-3 flex border-neutral-300 hidden sm:inline-block" style={{ alignItems: "center", borderLeft: "1px solid" }}>
            <div className="text-primary-700 mx-5">
              <span className="block font-medium text-xl">
                Package Price:
              </span>
              <span className="block font-medium text-3xl">
                ₹ {amount} /-
              </span>
            </div>
          </div>
          {renderModal()}
          {renderTandCModal()}
          {renderBookingModal()}
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
