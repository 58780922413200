import React, { Component } from 'react'
// import QrReader from 'react-qr-scanner'
import QrReader from 'modern-react-qr-reader'
import QrcodeScannerCordova from './QrcodeScannerCordova';
import QrcodeScannerWeb from './QrcodeScannerWeb';

class QrcodeScanner extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 100,
      result: 'No result',
    }
  }
  
  handleScanResult(data){
      if(data != null) {
          window.location.href = data;
        this.setState({
            result: data,
          })
      }
    
  }
  handleError(err){
    console.error(err)
  }

  isCordovaSupported() {
    return window.cordova != null;
  }
  render(){
    const previewStyle = {
      height: 350,
      width: 350,
    }

    return(
      <div>
        <div className='flex justify-center'>
          {
            window.cordova != null ?
               <QrcodeScannerCordova handleScanResult={this.handleScanResult}/>
               : <QrcodeScannerWeb handleScanResult={this.handleScanResult} />
          }
        
          </div>
        <a href={this.state.result}>{this.state.result}</a>
      </div>
    )
  }
}

export default QrcodeScanner;