import React, { FC, ReactNode, useEffect, useState } from "react";
import { fetchAll, fetchThingsToDo, fetchRides, fetchYachts } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import ProductListing from "components/StayCard/ProductListing";
import { fetchFilteredProducts, fetchPlacesToVisit } from "../../data/listings";
import InfiniteScroll from "react-infinite-scroll-component";

interface Item {
  label: string;
  value: string;
}

export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  ProductTypeList?: string[];
  SortProductType?: Item;
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({

  gridClass = "",
  heading = "Featured things to do",
  subHeading = "Popular things to do that eSanchari recommends for you",
  headingIsCenter,
  ProductTypeList,
  SortProductType
}) => {
  const renderCard = (listing: any) => {

    return <ProductListing key={listing.id} data={listing}
      href={"/product?productId=" + listing.id + "&name=" + listing.title}
    />;
  };

  const [listings, setListings] = React.useState([]);
  const [nextval, setNextVal] = React.useState(1);
  const [hasmore, setHasmore] = React.useState(true);
  const [lat, setlat] = React.useState("");
  const [long, setlong] = React.useState("");

  useEffect(() => {
    GetFilteredProducts();
  }, []);

  const GetFilteredProducts = () => {
    setHasmore(false);
      
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position:any) => {
            let lati: string = ""
            let longi: string = ""
            lati = position.coords.latitude.toString();
            longi = position.coords.longitude.toString();
            setlat(lati);
            setlong(longi);
            setListingData(lati,longi);
        },
        (error) => {
          setListingData("","");
        }
      );
    } else {
      alert("Geolocation API is not supported by this browser.");
      setListingData("","");
    }
  }
  const setListingData = (lati:string, longi:string) => {
    if (SortProductType?.value == "Distance" || SortProductType?.value == "Featured") {
      fetchFilteredProducts((data: any) => {
        setHasmore(false);
        if (data?.length >= 1) {
          setHasmore(true);
        }
        setListings(data);
      }, ProductTypeList, 1, 12, SortProductType?.value, lati, longi);
  }
  else {
    fetchFilteredProducts((data: any) => {
      setHasmore(false);
      if (data?.length >= 1) {
        setHasmore(true);
      }
      setListings(data);
    }, ProductTypeList, 1, 12, SortProductType?.value, "", "");
  }
  }
  const fetchMoreData = () => {
    setHasmore(false);
    if (SortProductType?.value == "Distance" || SortProductType?.value == "Featured") {
        fetchFilteredProducts((data: any) => {
          setListings(listings.concat(data));
          setHasmore(false);
          if (data?.length >= 1) {
            setHasmore(true);
          }
          setNextVal(nextval + 1);
        }, ProductTypeList, nextval + 1, 12, SortProductType?.value, lat, long)
      
    }
    else {
      fetchFilteredProducts((data: any) => {
        setListings(listings.concat(data));
        setHasmore(false);
        if (data?.length >= 1) {
          setHasmore(true);
        }
        setNextVal(nextval + 1);
      }, ProductTypeList, nextval + 1, 12, SortProductType?.value, "", "")
    }
  }

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      {/* <HeaderFilter
        tabActive={"Featured"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={(item) => {selectTab(item)}}
        isViewAll={true}
      /> */}
      {/* <div className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 ${gridClass}`}>
        {listings.slice(0, itemsToShow).sort((a:any,b:any) => (a.weight > b.weight) ? -1 : ((b.weight > a.weight) ? 1 : 0)).map((stay) => renderCard(stay))}
      </div> */}
      <InfiniteScroll
        dataLength={listings?.length} //This is important field to render the next data
        next={fetchMoreData}
        hasMore={hasmore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <div className={`py-6 grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 ${gridClass}`}>
          {listings.sort((a: any, b: any) => (a.weight > b.weight) ? -1 : ((b.weight > a.weight) ? 1 : 0)).map((stay) => renderCard(stay))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default SectionGridFeaturePlaces;
